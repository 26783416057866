<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<div class="main-content">
    <div class="access-title">
        <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
            <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
        </a>
        <span class="access-subtitle">{{'USERDESKTOP_TITLE' | translate}} {{user}} </span>
        <span class="access-subtitle">{{getRoleType()}}</span>
    </div>
    <div class="command-list">
        <button mat-mdc-button class="title-button" (click)="accessSettings()">{{'CONFIG_SUBSCRIPTIONS' | translate}} </button>
        <button mat-mdc-button class="title-button" (click)="notificationsSettings()">{{'NOTIFICATION_MANAGEMENT' | translate}} </button>
        <button mat-mdc-button class="title-button" (click)="usersSettings()">{{'USERS_MANAGEMENT' | translate}} </button>
    </div>
</div>
