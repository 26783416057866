import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class ToggleFullscreenService {

  constructor() { }


  public goFullscreen() {
    const elem = document.body;
    const methodToBeInvoked = elem.requestFullscreen;
    // const methodToBeInvoked = elem.requestFullscreen /*||
    //   elem.webkitRequestFullScreen*/ || elem['mozRequestFullscreen']
    //   ||
    //   elem['msRequestFullscreen'];
    if (methodToBeInvoked) { methodToBeInvoked.call(elem); }

  }

  public goOutFullscreen() {
    const methodToBeInvoked = document.exitFullscreen;
    // const methodToBeInvoked = document.exitFullscreen /*||
    // elem.webkitRequestFullScreen*/ || document['mozCancelFullScreen']
    //   ||
    //   document['msExitFullscreen'];
    if (methodToBeInvoked) { methodToBeInvoked.call(document); }
  }

  public isFullscreen() {
    return document.fullscreenElement !== null;// || document['mozFullScreenElement'] ||
    //||      elem.webkitRequestFullScreen*/ document['msFullscreenElement'];
  }

}
