import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewTableComponent } from './new-table.component';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NewTableService {

    constructor(private dialog: MatDialog) {
        this.dialog = dialog;
    }

    public askColRow(title: string): Observable<any> {
        const dialogRef: MatDialogRef<NewTableComponent> = this.dialog.open(NewTableComponent, {
            width: '300px'
        });
        dialogRef.componentInstance.title = title;
        return dialogRef.afterClosed();
    }

}


