import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmExitDialogComponent } from '../confirm-exit-dialog/confirm-exit-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ConfirmationService {

    constructor(private dialog: MatDialog) {
        this.dialog = dialog;
    }

    public confirm(title: string, message: string, okCaption: string, cancelCaption: string): Observable<boolean> {
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.okCaption = okCaption;
        dialogRef.componentInstance.cancelCaption = cancelCaption;
        return dialogRef.afterClosed();
    }

    public confirmExit(title: string, message: string, exitCaption: string, waitCaption: string): Observable<boolean> {
        const dialogRef: MatDialogRef<ConfirmExitDialogComponent> = this.dialog.open(ConfirmExitDialogComponent);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.exitCaption = exitCaption;
        dialogRef.componentInstance.waitCaption = waitCaption;
        return dialogRef.afterClosed();
    }

}
