import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SmeService } from 'src/app/core/sme/sme.service';
import { DesmosConfigDto } from '../../map-edit/desmos-config/desmosConfig-dto';
import { UserPreferenceService } from '../user-preference.service';
import { ExtraItem, ExtraService } from '../../map-edit/commands/extra.service';


@Injectable({
    providedIn: 'root'
})
export class DesmosService {


    public onToggleDesmosPanel = new EventEmitter<boolean>();
    public desmosPanelOpen = false;
    public desmosUrl: SafeResourceUrl = false;

    public DesmosCalculator: boolean = false;
    public DesmosScientific: boolean = false;
    public DesmosGeometry: boolean = false;
    public DesmosFourFunction: boolean = false;

    public typeDesmos = 'calculator';

    private languageNode = 'it';
    private searchwindow: any;


    constructor(
        private domSanitizer: DomSanitizer,
        private extraService: ExtraService
    ) { }


    // DA RIMETTERE SE SI VUOLE APRIRE IN FINESTRA (COME GOOGLE IMMAGINI)
    private openWindow(URL: string, width: number = 400) {
        let left = window.screen.availWidth - width;
        if ((window.screenLeft + window.outerWidth + width) < (window.screen.availWidth)) {
            left = window.screenLeft + window.outerWidth;
        }
        const top = window.screenTop;
        const height = window.innerHeight + (window.outerHeight - window.innerHeight) - 54;
        this.desmosUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(URL);
        this.searchwindow = window.open(URL, 'smxdesmos', 'toolbar=yes,top=' + top + ',left=' + left + ',width=' + width + ',height=' + height);
        this.searchwindow.focus();
        // this.toggleDesmosPanel();
    }

    public toggleDesmosPanel(): boolean {

        this.desmosPanelOpen = !this.desmosPanelOpen;
        const URL = 'https://www.desmos.com/' + this.typeDesmos + '?lang=' + this.languageNode;
        this.desmosUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(URL);
        console.log('Toggle Extra: ' + this.desmosPanelOpen);
        this.onToggleDesmosPanel.emit(this.desmosPanelOpen);

        return this.desmosPanelOpen;
    }

    public openDesmos(type?: string, lang?: string) {
        if (type) {
            this.typeDesmos = type;
        }
        if (lang) {
            this.languageNode = lang;
        }
        this.desmosPanelOpen = true;
        const URL = 'https://www.desmos.com/' + this.typeDesmos + '?lang=' + this.languageNode;
        this.desmosUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(URL);
        console.log('Toggle Extra: ' + this.desmosPanelOpen);
        this.openWindow(URL, 400);
    }

    public openExtra(type?: string) {
        if (type === 'autodraw') {
            const URL = 'https://www.autodraw.com/';
            this.extraService.toggleExtraPanel(ExtraItem.OPEN_BROWSER, URL);
            // this.openWindow(URL, 400);
        }
    }

}
