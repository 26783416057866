export class HistoryMapDto {
  public id: string;
  public lastUpdate: Date;
  public lastDayUpdate: string;
  public mapJpeg: any;

  constructor(public json: any) {
    this.id = json.id;
    this.lastUpdate = new Date(json.modifiedTime);
    this.lastDayUpdate = json.modifiedTime.substr(0, json.modifiedTime.indexOf('T'));
  }
}



