import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '../core/i18n.service';
import { LoginComponent } from './login.component';
import { ForgotPasswordSentComponent } from './forgot-password-sent/forgot-password-sent.component';
import { MessageSentComponent } from '../login-form/message-sent/message-sent.component';
import { PasswordRequestComponent } from './password-request/password-request.component';

const routes: Routes = [
    { path: 'login/:redirect', component: LoginComponent, data: { title: extract('LOGIN_TITLE') } },
    { path: 'forgot-password-sent', component: ForgotPasswordSentComponent },
    { path: 'signin-link-sent', component: MessageSentComponent },
    { path: 'password-request', component: PasswordRequestComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class LoginRoutingModule { }
