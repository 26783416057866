import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Logger } from '../logger.service';
import { FirebaseUser, FirebaseAuthService } from '../firebase/firebase-auth.service';
import { RegisteredUserService } from './registered-user.service';
import { map, retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { User, UserInfo } from 'firebase/auth';
import { getAuth } from '@angular/fire/auth';
import { FirebaseService } from '../firebase/firebase.service';

const logger: Logger = new Logger('AuthenticationService');

export interface Credentials {
    key: string;
    isDomain: boolean;
    valid: boolean;
    locked: boolean;
    username: string;
    firebaseUserId: string;
    fullName: string; // firebase user
    imageURL: any;
    googleUserId: string | null | undefined;
    googleUserEmail: string | null | undefined;
    googleUserName: string | null | undefined;
    googleUserImageUrl: string | null | undefined;
    admin: any;
    startDate: string;
    earlyStart: boolean;
    expireDate: string;
    expired: boolean;
    role: string;
    eligibleForDemo: boolean;
    userType: string;
    accessToken: string;
    refresh_token: string;
    id_token: any;
    providerId: string;
    config: string;

}

export interface UserBaseInfo {
    isDomain: boolean;
    valid: boolean;
    locked: boolean;
    admin: any;
    startDate: string;
    earlyStart: boolean;
    expireDate: string;
    expired: boolean;
    role: string;
    eligibleForDemo: boolean;
    userType: string;
    newUser: boolean;
    providerId: string;
    isNativeGoogleUser: boolean;
}

export interface ModuleConfig {
    outline: boolean;
    math: boolean;
    pdf: boolean;
}

const credentialsKey = 'credentials';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    public redirectPage: string | null | undefined;
    public redirectParams: any | undefined;
    public redirectQueryParams: string | null | undefined;

    private _credentials: Credentials | undefined | null;
    public isUserSignin = false;


    findRegisteredUserSubscription: Subscription | undefined;

    constructor(
        private http: HttpClient,
        private firebaseAuthService: FirebaseAuthService,
        private firebaseService: FirebaseService,
        private registeredUserService: RegisteredUserService,
        private router: Router,
    ) {
        this.reloadCredentials();

    }

    public isNativegoogleUser() {
        return this.firebaseAuthService.isNativeGoogleUser();
    }

    // signIn(responsePayload: any) {
    //   return new Promise<any>((resolve, reject) => {
    //     logger.debug('signIn');
    //     // Redirects to google login page (no promise management)
    //     // this.googleService.initTokenClient((tokenResponse: any) => {
    //     //   const creds = this.credentials;
    //     //   creds.accessToken = tokenResponse.access_token;
    //       const user = {
    //         id: responsePayload.sub,
    //         email: responsePayload.email,
    //         fullName: responsePayload.name,
    //         imageUrl: responsePayload.picture
    //       };
    //       return resolve(user);
    //     });
    //   });
    // }

    // signIn() {

    //   // logger.debug('signIn');
    //   return this.http.post('/api/loginGoogle', {})
    //     .pipe(
    //       map(
    //         (res: Response) => res
    //       ),
    //       catchError((error: any) => {
    //         logger.error(error);
    //         return observableThrowError(error);
    //       }));


    // }

    /**
     * Logs out the user and clear credentials.
     * return {Observable<boolean>} True if the user was logged out successfully.
     */
    logout(): Observable<boolean> {
        this.setCredentials();
        logger.info('Logout');

        return this.firebaseAuthService.logout()
            .pipe(retry(3),
                map(() => {
                    return true;
                }));
    }


    getUserBaseInfoFromFirebase(email: string): Promise<UserBaseInfo> {
        return new Promise((resolve, reject) => {
            this.registeredUserService.findRegisteredUserPromise(email)
                .then((registeredUser: any) => {
                    // console.log(`SignInApplication 6: registeredUser: ${JSON.stringify(registeredUser)}`);
                    let userBaseInfo: UserBaseInfo;
                    //const googleUserInfo = this.getGoogleUserData();
                    if (registeredUser.valid) {
                        const today = new Date().toISOString().substr(0, 10);


                        userBaseInfo = {
                            isDomain: registeredUser.isDomain,
                            valid: registeredUser.valid,
                            locked: registeredUser.locked,
                            admin: registeredUser.admin,
                            startDate: registeredUser.startDate,
                            earlyStart: (today < registeredUser.startDate),
                            expireDate: registeredUser.expireDate,
                            expired: (today > registeredUser.expireDate),
                            role: registeredUser.role,
                            eligibleForDemo: false,
                            userType: registeredUser.userType,
                            providerId: '',
                            newUser: registeredUser.newUser,
                            isNativeGoogleUser: registeredUser.isNativeGoogleUser
                        };

                        return resolve(userBaseInfo);
                    } else {
                        // return reject('not authorized');
                        // console.log(`SignInApplication 9: not authorized`);
                        userBaseInfo = {
                            isDomain: registeredUser.isDomain,
                            valid: registeredUser.valid,
                            locked: registeredUser.locked,
                            admin: null,
                            startDate: '2000-01-01',
                            earlyStart: false,
                            expireDate: '2000-01-01',
                            expired: true,
                            role: '',
                            eligibleForDemo: registeredUser.eligibleForDemo,
                            userType: '',
                            providerId: '',
                            newUser: registeredUser.newUser,
                            isNativeGoogleUser: registeredUser.isNativeGoogleUser
                        };

                        return resolve(userBaseInfo);
                    }
                }).catch((err: any) => {
                    console.error('UserBaseInfo set ERROR: ' + err.message);
                    return reject(err);
                });

        });
    }

    signInApplicationWithPassword
        (user: any, clientId: string): Promise<Credentials> {
        return new Promise((resolve, reject) => {
            this.isUserSignin = true;
            this.registeredUserService.findRegisteredUserPromise(user.email)
                .then((registeredUser: any) => {
                    // console.log(`SignInApplication 6: registeredUser: ${JSON.stringify(registeredUser)}`);
                    let credentials: Credentials;
                    const googleUserInfo = this.getGoogleUserData();
                    if (registeredUser.valid) {
                        const today = new Date().toISOString().substr(0, 10);
                        const token = user.token;
                        const googleUser = this.getGoogleUserData();
                        const accessToken = (token && token.access_token ? token.access_token : '');
                        credentials = {
                            key: registeredUser.key,
                            isDomain: registeredUser.isDomain,
                            valid: registeredUser.valid,
                            locked: registeredUser.locked,
                            username: user.email,
                            firebaseUserId: user.id,
                            fullName: user.fullName,
                            imageURL: user.picture,
                            googleUserId: googleUserInfo?.uid,
                            googleUserEmail: googleUserInfo?.email,
                            googleUserName: googleUserInfo?.displayName,
                            googleUserImageUrl: googleUserInfo?.photoURL,
                            // googleUserId: googleUser.getBasicProfile().getId(),
                            // googleUserEmail: googleUser.getBasicProfile().getEmail(),
                            // googleUserName: googleUser.getBasicProfile().getName(),
                            // googleUserImageUrl: googleUser.getBasicProfile().getImageUrl(),
                            admin: registeredUser.admin,
                            startDate: registeredUser.startDate,
                            earlyStart: (today < registeredUser.startDate),
                            expireDate: registeredUser.expireDate,
                            expired: (today > registeredUser.expireDate),
                            role: registeredUser.role,
                            eligibleForDemo: false,
                            userType: registeredUser.userType,
                            id_token: user.id_token,
                            accessToken: user.token,
                            refresh_token: user.refresh_token,
                            config: registeredUser.config,
                            providerId: user.providerId
                        };
                        // console.log(`SignInApplication 7: settina credentials`);
                        this.setCredentials(credentials);
                        // this.isUserSignin = false;

                        // console.log(`SignInApplication 8: credentials set`);
                        return resolve(credentials);
                    } else {
                        // return reject('not authorized');
                        // console.log(`SignInApplication 9: not authorized`);
                        credentials = {
                            key: registeredUser.key,
                            isDomain: registeredUser.isDomain,
                            valid: registeredUser.valid,
                            locked: registeredUser.locked,
                            username: user.email,
                            firebaseUserId: user.uid,
                            fullName: user.fullName,
                            imageURL: user.picture,
                            googleUserId: googleUserInfo?.uid,
                            googleUserEmail: googleUserInfo?.email,
                            googleUserName: googleUserInfo?.displayName,
                            googleUserImageUrl: googleUserInfo?.photoURL,
                            admin: null,
                            startDate: '2000-01-01',
                            earlyStart: false,
                            expireDate: '2000-01-01',
                            expired: true,
                            role: '',
                            eligibleForDemo: registeredUser.eligibleForDemo,
                            userType: '',
                            accessToken: '',
                            refresh_token: user.refresh_token,
                            id_token: '',
                            config: '',
                            providerId: user.providerId
                        };
                        // console.log(`SignInApplication 10: settina credentials`);
                        this.setCredentials(credentials);
                        // console.log(`SignInApplication 11: credentials set`);
                        //this.isUserSignin = false;
                        return resolve(credentials);
                    }
                }).catch((err: any) => {
                    console.error('Credentials set ERROR: ' + err.message);
                    this.isUserSignin = false;
                    return reject(err);
                });

        });
    }

    signInApplicationWithGoogle(user: any, clientId: string): Promise<Credentials> {
        return new Promise((resolve, reject) => {
            this.isUserSignin = false;
            let firebaseUser: FirebaseUser;
            // let googleUser: GoogleUser;
            // console.log(`SignInApplication 1: before sign in`);
            this.firebaseAuthService.signInWithGoogle(user.id_token)
                .then((_firebaseUser: FirebaseUser) => {
                    // console.log(`SignInApplication 2: after signin, _firebaseUser: ${JSON.stringify(_firebaseUser)}`);
                    firebaseUser = _firebaseUser;
                    //   return this.googleService.getCurrentUser();
                    // }).then((_googleUser: GoogleUser) => {
                    //   // console.log(`SignInApplication 3: after getCurrentUser: ${JSON.stringify(_googleUser)}`);
                    //   googleUser = _googleUser;
                    // return this.registeredUser.findRegisteredUserPromise(AccessDto.removePointFromEmail(firebaseUser.email));
                    // bugfix per cambio dominio da gov a edu
                    // console.log(`SignInApplication 4: about to get email`);
                    // console.log(`SignInApplication 4: getBasicProfile: ${JSON.stringify(googleUser.getBasicProfile())}`);
                    // console.log(`SignInApplication 4: getBasicEmail: ${JSON.stringify(googleUser.getBasicProfile().getEmail())}`);
                    // const email = googleUser.getBasicProfile().getEmail();
                    // console.log(`SignInApplication 5: email: ${email}`);
                    // return this.registeredUserService.findRegisteredUserPromise(AccessDto.removePointFromEmail(user.email));
                    return this.registeredUserService.findRegisteredUserPromise(user.email);
                }).then((registeredUser: any) => {
                    // console.log(`SignInApplication 6: registeredUser: ${JSON.stringify(registeredUser)}`);
                    let credentials: Credentials;
                    const googleUserInfo = this.getGoogleUserData();
                    if (registeredUser.valid) {
                        const today = new Date().toISOString().substr(0, 10);
                        const token = user.token;

                        const accessToken = (token && token.access_token ? token.access_token : '');
                        credentials = {
                            key: registeredUser.key,
                            isDomain: registeredUser.isDomain,
                            valid: registeredUser.valid,
                            locked: registeredUser.locked,
                            username: firebaseUser.email,
                            firebaseUserId: firebaseUser.uid,
                            fullName: user.given_name + ' ' + user.family_name,
                            imageURL: user.picture,
                            googleUserId: googleUserInfo?.uid,
                            googleUserEmail: googleUserInfo?.email,
                            googleUserName: googleUserInfo?.displayName,
                            googleUserImageUrl: googleUserInfo?.photoURL,
                            // googleUserId: googleUserInfo?.uid,
                            // googleUserEmail: googleUser.getBasicProfile().getEmail(),
                            // googleUserName: googleUser.getBasicProfile().getName(),
                            // googleUserImageUrl: googleUser.getBasicProfile().getImageUrl(),
                            admin: registeredUser.admin,
                            startDate: registeredUser.startDate,
                            earlyStart: (today < registeredUser.startDate),
                            expireDate: registeredUser.expireDate,
                            expired: (today > registeredUser.expireDate),
                            role: registeredUser.role,
                            eligibleForDemo: false,
                            userType: registeredUser.userType,
                            id_token: user.id_token,
                            accessToken: user.token,
                            refresh_token: user.refresh_token,
                            config: registeredUser.config,
                            providerId: 'google.com'
                        };
                        // console.log(`SignInApplication 7: settina credentials`);
                        this.setCredentials(credentials);
                        // console.log(`SignInApplication 8: credentials set`);
                        return resolve(credentials);
                    } else {
                        // return reject('not authorized');
                        // console.log(`SignInApplication 9: not authorized`);
                        credentials = {
                            key: registeredUser.key,
                            isDomain: registeredUser.isDomain,
                            valid: registeredUser.valid,
                            locked: registeredUser.locked,
                            username: firebaseUser.email,
                            firebaseUserId: firebaseUser.uid,
                            fullName: user.given_name + ' ' + user.family_name,
                            imageURL: user.picture,
                            googleUserId: googleUserInfo?.uid,
                            googleUserEmail: googleUserInfo?.email,
                            googleUserName: googleUserInfo?.displayName,
                            googleUserImageUrl: googleUserInfo?.photoURL,
                            admin: null,
                            startDate: '2000-01-01',
                            earlyStart: false,
                            expireDate: '2000-01-01',
                            expired: true,
                            role: '',
                            eligibleForDemo: registeredUser.eligibleForDemo,
                            userType: '',
                            accessToken: '',
                            refresh_token: user.refresh_token,
                            id_token: '',
                            config: '',
                            providerId: 'google.com'
                        };
                        // console.log(`SignInApplication 10: settina credentials`);
                        this.setCredentials(credentials);
                        this.isUserSignin = false;
                        // console.log(`SignInApplication 11: credentials set`);
                        return resolve(credentials);
                    }
                }).catch((err: any) => {
                    this.isUserSignin = false;
                    console.error('Credentials set ERROR: ' + err.message);
                    return reject(err);
                });
        });
    }

    getGoogleUserData(): UserInfo | null {
        const auth = getAuth().currentUser;
        if (auth !== null) {
            const userInfo = this.getGoogleProvider(auth);
            return userInfo;
        } else {
            return null;
        }
    }

    getGoogleProvider(user: User): UserInfo | null {
        let res = null;
        let i = 0
        while (!res && i < user.providerData.length) {
            if (user.providerData[i].providerId === 'google.com') {
                res = user.providerData[i];
            } else {
                i++;
            }
        }
        return res;
    }

    /**
     * Ridireziona alla pagina richiesta dopo aver effettuato il login
     */

    public redirectToRequestPage(isinFrame = false) {
        this.redirectPage = localStorage.getItem('redirectPage');
        this.redirectParams = localStorage.getItem('redirectParams');
        this.redirectQueryParams = localStorage.getItem('redirectQueryParams');

        localStorage.removeItem('redirectPage');
        localStorage.removeItem('redirectParams');
        localStorage.removeItem('redirectQueryParams');


        // if (redirectQueyParams) {
        //   try {
        //     this.redirectQueryParams = JSON.parse(redirectQueyParams);
        //   } catch (error) {
        //     logger.error('error JSON ' + redirectQueyParams);
        //   }
        // }
        logger.debug('redirectPage        : ' + this.redirectPage);
        logger.debug('redirectParams      : ' + this.redirectParams);
        logger.debug('redirectQueryParams : ' + this.redirectQueryParams);

        if (this.redirectPage && this.redirectQueryParams) {
            window.location.href = this.redirectPage + '?' + this.redirectQueryParams;
            // if (isinFrame) {
            //   window.location.href = this.redirectPage + '?' + this.redirectQueryParams;
            // } else {
            //   this.router.navigate([this.redirectPage], { queryParams: this.redirectQueryParams });
            // }
        } else {
            if (this.redirectPage && this.redirectParams) {
                window.location.href = '/' + this.redirectPage + '/' + this.redirectParams.replace(/,/g, '/');
                // if (isinFrame) {
                //     window.location.href = '/' + this.redirectPage + '/' + this.redirectParams;
                // } else {
                //     this.router.navigate([this.redirectPage, this.redirectParams]);
                // }
            } else {
                if (this.redirectPage) {
                    if (isinFrame) {
                        window.location.href = '/' + this.redirectPage;
                    } else {
                        this.router.navigate([this.redirectPage]);
                    }
                } else {
                    if (isinFrame) {
                        window.location.href = '/home';
                    } else {
                        this.router.navigate(['home']);
                    }
                }
            }
        }
    }

    /**
     * Checks is the user is authenticated.
     * return {boolean} True if the user is authenticated.
     */
    public isAuthenticated(): boolean {
        return !!this.credentials && this.firebaseAuthService.getFirebaseUser() !== null && this.firebaseAuthService.getFirebaseUser() !== undefined;
    }

    /**
     * @param scopes
     * @returns {boolean} True if the user accepts all scopes
     */
    // public isValidScopes(scopes: any): boolean {
    //   return this.googleService.checkScopes(scopes);
    // }

    getUserEmail(): string {
        if (this.isAuthenticated()) {
            // RITORNIAMO SEMPRE LA GOOGLE EMAIL PER IL PROBLEMA DI CAMBIO DOMINIO DA GOV A EDU
            return (this.credentials && this.credentials.username) ? this.credentials?.username : '';
        } else return '';
    }


    getUserFullname(): string {
        if (this.isAuthenticated()) {

            return (this.credentials && this.credentials.fullName) ? this.credentials?.fullName : '';
        } else return '';
    }


    // getGoogleUserId(): string {
    //     if (this.isAuthenticated()) {
    //         return (this.credentials && this.credentials.f) ? this.credentials.googleUserId : '';
    //     } else return '';
    // }

    // getGoogleUserEmail(): string {
    //     if (this.isAuthenticated()) {
    //         return (this.credentials && this.credentials.googleUserEmail) ? this.credentials?.googleUserEmail : '';
    //     } else return '';
    // }

    // getGoogleUserName(): string {
    //     if (this.isAuthenticated()) {
    //         return (this.credentials && this.credentials.googleUserName) ? this.credentials.googleUserName : '';
    //     } else return '';
    // }

    // getGoogleUserImageUrl(): string {
    //     if (this.isAuthenticated()) {
    //         return (this.credentials && this.credentials.googleUserImageUrl) ? this.credentials.googleUserImageUrl : '';
    //     } else return '';
    // }

    getUserImageUrl(): string {
        if (this.isAuthenticated()) {
            return (this.credentials && this.credentials.imageURL) ? this.credentials.imageURL : '';
        } else return '';
    }

    getUserName(): string {
        if (this.isAuthenticated()) {
            return (this.credentials && this.credentials.fullName) ? this.credentials.fullName : '';
        } else return '';
    }

    getUserRefreshToken(): string {
        if (this.isAuthenticated()) {
            return (this.credentials && this.credentials.refresh_token) ? this.credentials.refresh_token : '';
        } else return '';
    }

    getUserAccessToken(): string {
        if (this.isAuthenticated()) {
            return (this.credentials && this.credentials.accessToken) ? this.credentials.accessToken : '';
        } else return '';
    }




    getTokenId(): Promise<string> {
        return this.firebaseAuthService.getTokenId();
    }

    /**
     * Gets the user credentials.
     * return {Credentials} The user credentials or null if the user is not authenticated.
     */
    get credentials(): Credentials | null | undefined {
        return this._credentials;
    }

    reloadCredentials(): Credentials | null | undefined {
        const savedCredentials = localStorage.getItem(credentialsKey);
        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
        }
        return this._credentials;
    }

    updateRegisteredUser(startDate: any, expireDate: any, admin?: any, config?: string) {
        const today = new Date().toISOString().substr(0, 10);
        const newCredential = this._credentials;
        if (newCredential) {
            if (admin) {
                newCredential.admin = admin;
            }
            newCredential.startDate = startDate;
            newCredential.earlyStart = (today < startDate);
            newCredential.expireDate = expireDate;
            newCredential.expired = (today > expireDate);
            newCredential.config = config + '';
            this.setCredentials(newCredential);
        }

    }

    /**
     * Sets the user credentials.
     * The credentials may be persisted across sessions by setting the `remember` parameter to true.
     * Otherwise, the credentials are only persisted for the current session.
     * param {Credentials=} credentials The user credentials.
     * param {boolean=} remember True to remember credentials across sessions.
     */
    public setCredentials(credentials?: Credentials, remember?: boolean) {

        this._credentials = credentials || null;
        remember = remember || true;

        if (credentials) {
            const storage = remember ? localStorage : sessionStorage;
            storage.setItem(credentialsKey, JSON.stringify(credentials));

        } else {
            sessionStorage.removeItem(credentialsKey);
            localStorage.removeItem(credentialsKey);
        }

    }

    public isUserInWhitelist(whitelist: string[]): boolean {
        const userEmail = this.getUserEmail();
        let goOn = false;
        if (whitelist) {
            // Consenti accesso per tutti gli utenti anastasis
            goOn = userEmail.endsWith('@anastasis.it');
            if (!goOn) {
                if (!this.checkAccessAuthorization(userEmail, whitelist)) {
                    // Accesso non consentito
                    logger.debug('Access denied for user ' + userEmail + ', redirecting...');
                } else {
                    goOn = true;
                }
            }
        }
        return goOn;
    }

    private checkAccessAuthorization(userEmail: string, whitelist: string[]): boolean {
        let accessOk = false;
        for (let i = 0; i < whitelist.length; i++) {
            const validEmail = whitelist[i];
            if (validEmail !== null) {
                if (validEmail === '*') {
                    accessOk = true;
                } else {
                    if (validEmail.startsWith('@')) {
                        if (userEmail.endsWith(validEmail)) {
                            accessOk = true;
                        }
                    } else {
                        if (userEmail === validEmail) {
                            accessOk = true;
                        }
                    }
                }
            }
        }

        if (accessOk) { console.log('access OK'); } else { console.log('accesso Denied'); }
        return accessOk;
    }

    public getExpiryDate() {
        let res = '';
        if (this._credentials) {
            res = this._credentials.expireDate;
        }
        return res;
    }

    public isUserExpired() {
        let res = false;
        if (this._credentials) {
            res = this._credentials.expired;
        }
        return res;
    }

    public isGoogleUser() {
        let res = false;
        if (this._credentials) {
            res = this._credentials.providerId === 'google.com';
        }
        return res;
    }

    linkedWithGoogle() {
        // return (this.autenticationService.credentials && this.autenticationService.credentials.username ? true : false);
        return (this.credentials && this.credentials?.googleUserEmail !== null && this.credentials?.googleUserEmail !== undefined && this.credentials?.googleUserEmail != '') ? true : false;
    }

    public isUserAboutToExpire() {
        let res = false;
        if (this._credentials) {
            const date = this._credentials.expireDate;
            const timeDifference = new Date(date).getTime() - new Date().getTime();
            const days = Math.ceil(timeDifference / (1000 * 3600 * 24));
            res = (days >= 0 && days <= 30);
        }
        return res;

    }

    public hasSubscriptions() {
        let res = false;
        if (this._credentials && this._credentials.admin) {
            res = (this._credentials.admin.length > 0);
        }
        return res;
    }

    public getModuleConfig(): any {
        let cfg = {};
        if (this.credentials) {
            const encConfig = this.credentials.config;
            if (encConfig) {
                try {
                    const sCfg = atob(encConfig);
                    cfg = JSON.parse(sCfg);
                } catch (e: any) {
                    console.log('***ATOB error: ' + e.message);
                    cfg = {};
                }
            }
        }
        return cfg;
    }

    public isOutlineEnabled(): boolean {
        let res = false;
        const cfg = this.getModuleConfig();
        if (cfg && cfg.outline !== undefined) {
            res = cfg.outline;
        }
        return res;
    }

    public isMathEnabled(): boolean {
        let res = false;
        const cfg = this.getModuleConfig();
        if (cfg && cfg.math !== undefined) {
            res = cfg.math;
        }
        return res;
    }

    public isPdfEnabled(): boolean {
        let res = false;
        const cfg = this.getModuleConfig();
        if (cfg && cfg.pdf !== undefined) {
            res = cfg.pdf;
        }
        return res;
    }

    public isLab(): boolean {
        return (this.isOutlineEnabled() && this.isMathEnabled() && this.isPdfEnabled());
    }

    public setIcon(): void {
        if (this.isLab()) {
            const favIcon: HTMLLinkElement | null = (document.getElementById('favicon') as HTMLLinkElement);
            if (favIcon) {
                favIcon.href = `../../../../favicon_lab.ico`;
            }
        }
    }

}
// function observableThrowError(error: any): any {
//   throw new Error('Function not implemented.');
// }

