import { getDownloadURL } from '@angular/fire/storage';
import { Logger } from '../logger.service';
import { FirebaseService } from './firebase.service';

const logger = new Logger('FirebaseStorageUrlResolver');

// JHUBA: non si possono esportare Interface da un modulo Angular!!! Così manca controllo implementazione!!!

export class FirebaseStorageUrlResolver {

    constructor(private firebaseService: FirebaseService) { }

    public resolveUrl(url: string): Promise<string> {
        return new Promise<string>((resolve) => {
            if (url === '') {
                logger.error('resolveUrl: url is empty!');
                return Promise.resolve('');
            } else {
                const mapRef = this.firebaseService.getRef(url);
                return getDownloadURL(mapRef).then((fbsUrl: string) => {
                    const firebasePrefix = 'https://firebasestorage.googleapis.com';
                    // const remappedUrl = fbsUrl.replace(firebasePrefix, '/remote-assets');
                    const remappedUrl = fbsUrl;
                    console.log('FireBase Storage URL ' + fbsUrl + '--> Remapped to ' + remappedUrl);
                    resolve(remappedUrl);
                }).catch((error: any) => {
                    logger.error('FireBase Storage NOT FOUND: ' + error.message);
                    resolve('');
                });

            }
        });
    }

    public repairNoImage(url: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (url === '') {
                logger.error('resolveUrl: url is empty!');
                return Promise.resolve('');
            } else {
                const noImage = this.firebaseService.getRef('/assets/noimage.png');
                // const mapRef = firebase.storage().ref().child(url);
                return this.firebaseService.downloadResourceAsBlob('/assets/noimage.png', 'image/png').subscribe({
                    next: (data: any) => {
                        // uploadBase64(resource, workResource, 'image/jpg');
                        this.firebaseService.uploadBlob(data, url).subscribe(() => {
                            this.resolveUrl(url).then((remappedUrl) => {
                                resolve(remappedUrl);
                            });

                        });
                    },
                    error: error => {
                        logger.error('FireBase Storage : ' + error.message);
                        resolve('');
                    }
                });
            }
        });
    }


}
