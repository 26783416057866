import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SmEventBroker } from '../sm-event-broker';
import { MapStateService } from '../map-state.service';

@Injectable({
    providedIn: 'root'
})
export class WebSearchService implements OnDestroy {

    private _imageFilter = '';
    private selectionDataSubscription: Subscription | undefined;
    public onToggleWebSearchPanel = new EventEmitter<boolean>();
    public webSearchPanelOpen = false;
    public textSearch = '';
    private typeSearch = 'google-img';
    private magicSearch = false;
    private languageNode = 'it-IT';
    private searchwindow: any;
    private previousURL = '';

    constructor(
        private smEventBroker: SmEventBroker,
        private mapStateService: MapStateService
    ) {
        if (this.smEventBroker.selectionData) {
            this.selectionDataSubscription = this.smEventBroker.selectionData.subscribe((_selectionData: any) => {
                if (_selectionData) {
                    this.textSearch = _selectionData.titleText;
                    if (_selectionData.language) {
                        this.languageNode = _selectionData.language;
                    }
                }
                if (this.magicSearch && _selectionData.titleText !== '') {
                    this.openSearchWindow();
                }

            });
        }
    }

    ngOnDestroy(): void {
        if (this.selectionDataSubscription) { this.selectionDataSubscription.unsubscribe(); }
        this.smEventBroker.clearData();
    }

    // N.B. Per chi tornera' qua perche' le pagine google si aprono piu' di una e non utilizza sempre quella.
    // Se una tab google chrome si apre passando da un sito radice google tipo google.com o images.google.com, chrome non ritorna l'handler della tab aperta e quindi ne aprira' sempre una nuova
    // Invece se si usa un URL composto per una ricerca, l'handler viene ritornato. Credo sia un problema solo di Chrome e Google
    private openSearchWindow() {
        const width = 400;
        let left = window.screen.availWidth - width;
        if ((window.screenLeft + window.outerWidth + width) < (window.screen.availWidth)) {
            left = window.screenLeft + window.outerWidth;
        }
        const top = window.screenTop;
        const height = window.innerHeight + (window.outerHeight - window.innerHeight) - 54;
        let URL = 'https://www.google.com/search?tbm=isch&tbs' + this.getImageFilter() + '&safe=active&q=' + this.textSearch;
        switch (this.typeSearch) {
            case 'google-img':
                if (this.textSearch === '') {
                    URL = 'https://images.google.com';
                    //return;
                }
                break;

            case 'google':
                if (this.textSearch === '') {
                    URL = 'https://www.google.com';
                    // return;
                } else {
                    URL = 'https://www.google.com/search?q=' + this.textSearch;
                }
                break;

            case 'youtube':
                if (this.textSearch === '') {
                    return;
                }
                URL = 'https://www.youtube.com/results?search_query=' + this.textSearch;
                break;

            case 'wikipedia':
                if (this.textSearch === '') {
                    return;
                }
                URL = this.getWikipediaURLByLang(this.languageNode);
                break;
        }
        // if (this.searchwindow && this.searchwindow.window && this.searchwindow.window.location) {
        //   this.searchwindow.window.location.href = URL;
        // } else {
        // this.searchwindow = window.open(URL, 'smxsearch', 'toolbar=yes,top=' + top + ',left=' + left + ',width=' + width + ',height=' + height);
        // }
        //
        // if (URL !== this.previousURL) {
        //   this.searchwindow = window.open(URL, 'smxsearch', 'toolbar=yes,top=' + top + ',left=' + left + ',width=' + width + ',height=' + height);
        //   this.previousURL = URL;
        // }
        this.searchwindow = window.open(URL, 'smxwebsearch', 'toolbar=yes,top=' + top + ',left=' + left + ',width=' + width + ',height=' + height);
        //  this.searchwindow.focus();
        (window as any).searchWindow = this.searchwindow;
    }

    private getWikipediaURLByLang(lang: string): string {
        const l = lang.substr(0, 2);
        const url = 'https://' + l + '.m.wikipedia.org/w/index.php?search=' + encodeURI(this.textSearch);
        return url;
    }

    public toggleWebSearchPanel(): boolean {
        if (!this.textSearch) {
            this.textSearch = '';
        }
        this.webSearchPanelOpen = !this.webSearchPanelOpen;
        console.log('Toggle Extra: ' + this.webSearchPanelOpen);
        this.onToggleWebSearchPanel.emit(this.webSearchPanelOpen);
        if (!this.webSearchPanelOpen && this.magicSearch) {
            this.disableMagicSearch();
        }
        return this.webSearchPanelOpen;
    }

    public searchOnWeb(type?: string) {
        if (type) {
            this.typeSearch = type;
        }
        this.magicSearch = true;
        console.log("!!!!!!!!!!!!!!!!!!!!!!!! SEARCH ON WEB " + type);
        this.openSearchWindow();

    }

    public refreshSearchOnWebIfActive() {
        if (this.magicSearch) {
            this.openSearchWindow();
        }
    }


    public disableMagicSearch() {
        this.magicSearch = false;
    }

    public getImageFilter(): string {
        return this._imageFilter;
    }

    public setImageFilter(filter: string) {
        this._imageFilter = filter;
    }
}
