import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { MessageBoxService } from '../../shared/dialog/messagebox.service';
import { UiConstants } from 'src/app/shared/ui-constants';

@Component({
    selector: 'app-user-privacy',
    templateUrl: './user-privacy.component.html',
    styleUrls: ['./user-privacy.component.scss']
})
export class UserPrivacyComponent implements OnInit {
    @Output() acceptedPrivacy = new EventEmitter<any>();
    @Input() isUserAdmin = false;
    @Input() isDemo = false;

    public privacyForm: any;

    public newsletterChecked: boolean = false;
    public safePrivacyHeader: SafeHtml = '';
    public safePrivacyContent: SafeHtml = '';
    // public safePrivacyPurpose: SafeHtml = '';
    // public safePrivacyPurposeA: SafeHtml = '';
    // public safePrivacyPurposeB: SafeHtml = '';
    // public safePrivacyPurposeC: SafeHtml = '';
    public privacyContentGeneric: string = '';
    // public contractGeneric: string = '';
    public logType: string = '';
    public isLab: boolean = false;
    // public userType = '';
    // public safeDPAContent: SafeHtml;
    // public safeContractContent: SafeHtml = '';

    public nlInfoTooltip: string;

    public userTypes = UiConstants.USER_TYPES;
    public ageRanges = UiConstants.AGE_RANGES;

    private _userType = '';
    get userType(): string {
        return this._userType;
    }
    set userType(value: string) {
        this._userType = value;
        this.updatePrivacyContent();
    }

    private _ageRangeSelected = '';
    get ageRangeSelected(): string {
        return this._ageRangeSelected;
    }
    set ageRangeSelected(value: string) {
        this._ageRangeSelected = value;
        // if (value === 'OVER_18') {
        //     this.userType = 'PRIVATO';
        // } else {
        //     this.userType = '';
        // }
        // this.newsletterChecked = false;
    }

    constructor(
        private formBuilder: FormBuilder,
        private translateService: TranslateService,
        private sanitizer: DomSanitizer,
        private messageBoxService: MessageBoxService,
        private authenticationService: AuthenticationService
    ) {
        this.isLab = this.authenticationService.isLab();
        this.updatePrivacyContent();

        this.newsletterChecked = false;
        this.safePrivacyHeader = this.sanitizer.bypassSecurityTrustHtml(this.translateService.instant('PRIVACY_HEADER'));

        const planId = (this.isLab ? 'LAB' : 'EDU');
        // this.safePrivacyPurpose = this.sanitizer.bypassSecurityTrustHtml(this.translateService.instant('PRIVACY_PURPOSE'));
        // this.safePrivacyPurposeA = this.sanitizer.bypassSecurityTrustHtml(this.translateService.instant('PRIVACY_PURPOSE_A').replace('%planId%', planId));
        // this.safePrivacyPurposeB = this.sanitizer.bypassSecurityTrustHtml(this.translateService.instant('PRIVACY_PURPOSE_B').replace('%planId%', planId));
        // this.safePrivacyPurposeC = this.sanitizer.bypassSecurityTrustHtml(this.translateService.instant('PRIVACY_PURPOSE_C').replace('%planId%', planId));

        this.privacyContentGeneric = this.translateService.instant('PRIVACY_CONTENT_GENERIC');
        // this.contractGeneric = this.translateService.instant('PRIVACY_DOC_CONTRACT_GENERIC');

        // this.safeDPAContent = this.sanitizer.bypassSecurityTrustHtml(this.translateService.instant('PRIVACY_DOC_DPA_EDU'));
        // this.safeDPAContent = this.sanitizer.bypassSecurityTrustHtml(this.translateService.instant('PRIVACY_DOC_DPA_EDU'));

        this.nlInfoTooltip = this.translateService.instant('NEWSLETTER_INFO');
        this.populateUserTypes();
        this.populateAgeRanges();
        this.ageRangeSelected = '';
        const credentials = this.authenticationService.credentials;
        this.logType = credentials?.userType ? credentials?.userType : '';

        // JHUBA: DEBUG: COMMENTARE!!!
        // this.isLab = false;
        // logType: s-adm / m-adm / m-usr / d-adm / d-usr
        // this.logType = 's-adm';
        // this.logType = 'm-adm';
        // this.logType = 'm-usr';
        // this.logType = 'd-adm';
        // this.logType = 'd-usr';
        ///////////////////////////////////////////////////


        // if (this.userType === '') {
        //     this.userType = 'SCUOLA'
        // }
        // this.updatePrivacyContent();

        console.log('+++++DETECTED LOGGED USER TYPE: ' + this.logType + ' - ISLAB: ' + this.isLab);
    }

    updatePrivacyContent() {
        let keyPrivacy = '';
        // let keyContract = '';
        if (!this.isLab) {
            // EDU
            if (this.userType !== 'SCUOLA' && this.userType !== 'UNIVERSITA') {
                keyPrivacy = 'PRIVACY_CONTENT_PRIVATE_EDU_LINK';
                // keyContract = 'PRIVACY_DOC_CONTRACT_PRIVATE_EDU';
            } else if (this.userType === 'SCUOLA') {
                keyPrivacy = 'PRIVACY_CONTENT_SCHOOL_EDU_LINK';
                // keyContract = 'PRIVACY_DOC_CONTRACT_SCHOOL_EDU';
            } else if (this.userType === 'UNIVERSITA') {
                keyPrivacy = 'PRIVACY_CONTENT_UNIVERSITY_EDU_LINK';
                // keyContract = 'PRIVACY_DOC_CONTRACT_UNIVERSITY_EDU';
            }
        } else {
            // LAB
            if (this.userType !== 'SCUOLA' && this.userType !== 'UNIVERSITA') {
                keyPrivacy = 'PRIVACY_CONTENT_PRIVATE_LAB_LINK';
                // keyContract = 'PRIVACY_DOC_CONTRACT_PRIVATE_LAB';
            } else if (this.userType === 'SCUOLA') {
                keyPrivacy = 'PRIVACY_CONTENT_SCHOOL_LAB_LINK';
                // keyContract = 'PRIVACY_DOC_CONTRACT_SCHOOL_LAB';
            } else if (this.userType === 'UNIVERSITA') {
                keyPrivacy = 'PRIVACY_CONTENT_UNIVERSITY_LAB_LINK';
                // keyContract = 'PRIVACY_DOC_CONTRACT_UNIVERSITY_LAB';
            }
        }
        const privacyPolicy = this.translateService.instant(keyPrivacy);
        this.safePrivacyContent = this.sanitizer.bypassSecurityTrustHtml(this.privacyContentGeneric.replace('%privacypolicy%', privacyPolicy));
        // const contract = this.translateService.instant(keyContract);
        // this.safeContractContent = this.sanitizer.bypassSecurityTrustHtml(this.contractGeneric.replace('%contract%', contract));
    }

    populateAgeRanges() {
        for (let i = 0; i < this.ageRanges.length; i++) {
            if (this.ageRanges[i].value !== '') {
                this.ageRanges[i].name = this.translateService.instant('PRIVACY_AGE_' + this.ageRanges[i].value);
            }
        }
    }

    populateUserTypes() {
        for (let i = 0; i < this.userTypes.length; i++) {
            if (this.userTypes[i].value !== '') {
                this.userTypes[i].name = this.translateService.instant('IC_USER_TYPE_' + this.userTypes[i].value);
            }
        }
    }

    ageRangeValidator(c: FormControl) {
        const isValid = (c.value === 'OVER_18');
        if (isValid) {
            return null;
        } else {
            return { validateAgeRange: { valid: false } };
        }
    }

    newsLetterInfo() {
        // (window as any).alert('newsLetterInfo()');
        const title = this.translateService.instant('NEWSLETTER_DIALOG_TITLE');
        const message = this.translateService.instant('PRIVACY_NEWSLETTER_INFO');
        this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK, title, message);
    }

    ngOnInit() {
        const isPrivate = this.logType === 's-adm'; // || this.logType === 'm-adm' || this.logType === 'm-usr';
        // const isSchool = this.logType === 'd-adm' || this.logType === 'm-adm';
        // const isAdmin = this.logType === 's-adm' || this.logType === 'm-adm' || this.logType === 'd-adm';
        // const isOver18 = this.ageRangeSelected === 'OVER_18';
        this.privacyForm = this.formBuilder.group({
            checkPrivacy: [false, Validators.requiredTrue],
            // checkPurposeA: [false, Validators.requiredTrue],
            // checkPurposeB: [false, Validators.requiredTrue],
            // checkPurposeC: [false, Validators.requiredTrue],
            selectAgeRange: [false, (isPrivate ? [Validators.required] : null)],
            selectUserType: [false, null], //[Validators.required]],
            checkNewsletter: [false, null] //,
            // checkDPA: [false, (isSchool ? [Validators.requiredTrue] : null)],
            // checkContract: [false, (isAdmin ? [Validators.requiredTrue] : null)]
        });
        if (this.logType === 'm-usr' || this.logType === 'd-usr') {
            this.userType = this.userTypes[0].value;
        }
    }

    onReset() {
        // this.acceptedPrivacy.emit({ accepted: false, newsletterChecked: false });
        const oldUsertype = this.userType;
        this.privacyForm.reset();
        this.userType = oldUsertype;
    }

    onSubmit() {
        if (this.privacyForm.invalid) {
            return;
        }
        this.acceptedPrivacy.emit({ accepted: true, newsletterChecked: this.newsletterChecked, userType: this.userType, ageRange: this.ageRangeSelected });
    }

    enabledForm() {
        let en = false;
        if (this.privacyForm) {
            en = this.privacyForm.valid;
            if (en) {
                en = en && (!this.privacyForm.pristine);
            }
        }
        return en;
    }

    get f() { return this.privacyForm.controls; }
}
