import { Injectable } from "@angular/core";
import { MathReaderBaseService, VERTBAR } from "./math-reader-base.service";
import { ReadEntityDto } from "./math-reader.interface";

@Injectable({
    providedIn: 'root'
})
export class MathReaderEnService extends MathReaderBaseService {

    private op: string = '';
    private elm: any = null;
    private parentElm: any = null;
    private accent: boolean = false;
    private child0: any = null;
    private child1: any = null;
    //
    private r: ReadEntityDto = new ReadEntityDto();
    private tag: string = '';
    private parentTag: string = '';

    override readEntity(op: string, elm: any, parentElm: any, accent: boolean, child0: any, child1: any): ReadEntityDto {
        this.r = new ReadEntityDto();
        this.op = op;
        this.elm = elm;
        this.parentElm = parentElm;
        this.accent = accent;
        this.child0 = child0;
        this.child1 = child1;
        this.tag = elm.tagName;
        this.parentTag = parentElm?.tagName;
        if (!this.r.found()) this.operators();
        if (!this.r.found()) this.mathBaseOperators();
        if (!this.r.found()) this.parenthesis();
        if (!this.r.found()) this.entities();
        if (!this.r.found()) this.sets();
        if (!this.r.found()) this.greek();
        if (!this.r.found()) this.geometry();
        if (!this.r.found()) this.trigonometry();
        if (!this.r.found()) this.logarhythmAndExponentials();
        if (!this.r.found()) this.others();
        return this.r;
    }

    private operators() {
        switch (this.op) {
            case 'sqrt':
                this.r.text = 'square root of'
                this.r.suffix = ''
                break;
            case 'root':
                if (this.child1) {
                    const index = this.readElement(this.child1);
                    this.r.text = 'root with index ' + index + ' of'
                    this.r.suffix = ''
                }
                break;
            case '∫':
                this.r.text = 'integral';
                this.r.prefixSub = 'from';
                this.r.prefixSup = 'to';
                this.r.suffix = 'of';
                break;
            case '∑':
                this.r.text = 'summation';
                this.r.prefixSub = 'from';
                this.r.prefixSup = 'to';
                this.r.suffix = 'of';
                break;
            case '∏':
                this.r.text = 'production';
                this.r.prefixSub = 'from';
                this.r.prefixSup = 'to';
                this.r.suffix = 'of';
                break;
            case 'lim':
                this.r.text = 'limit';
                this.r.prefixSub = 'for';
                this.r.suffix = 'of';
                break;
            case 'frac':
                this.r.text = 'fraction;';
                this.r.prefixSup = '';
                this.r.suffixSup = '';
                this.r.prefixSub = 'over;';
                this.r.suffixSub = '';
                this.r.suffix = '';
                break;
            case '|':
                if (this.vertBar === VERTBAR.absValue) {
                    this.r.text = 'absolute value of';
                    this.r.prefixSup = '';
                    this.r.suffixSup = '';
                    this.r.suffix = '';
                } else {
                    this.r.text = 'such as';
                    this.r.prefixSup = '';
                    this.r.suffixSup = '';
                    this.r.suffix = '';
                }
                break;
        }
    }

    private mathBaseOperators() {
        switch (this.op) {
            case ".":
                this.r.text = 'point';
                break
            case ",":
                this.r.text = 'comma';
                break
            case '+':
                this.r.text = 'plus';
                break
            case '-':
                this.r.text = 'minus';
                break
            case '⋅':
                this.r.text = 'times';
                break
            case '×':
                this.r.text = 'times';
                break
            case ':':
                this.r.text = 'divided by';
                break
            case '/':
                this.r.text = 'divided by';
                break
            case '=':
                this.r.text = 'equal to';
                break
            case '≠':
                this.r.text = 'not equal to';
                break
            case '<':
                this.r.text = 'less than';
                break
            case '>':
                this.r.text = 'greater than';
                break
            case '≤':
                this.r.text = 'less or equal than';
                break
            case '≥':
                this.r.text = 'greater or equal to';
                break
            case '±':
                this.r.text = 'plus or minus';
                break
            case '°':
                this.r.text = 'degrees';
                break
            case '%':
                this.r.text = 'percent';
                break
        }
    }

    private parenthesis() {
        switch (this.op) {
            case '(':
                this.r.text = 'open round bracket';
                break
            case ')':
                this.r.text = 'close round bracket';
                break
            case '[':
                this.r.text = 'open square bracket';
                break
            case ']':
                this.r.text = 'close square bracket';
                break
            case '{':
                this.r.text = 'open curly bracket';
                break
            case '}':
                this.r.text = 'close curly bracket';
                break
        }
    }

    private entities() {
        switch (this.op) {
            case '∞':
                this.r.text = 'infinity';
                break;
            case '→':
                this.r.text = 'that tends to';
                break;
        }
    }

    private sets() {
        switch (this.op) {
            case '∪':
                this.r.text = 'union';
                break
            case '∩':
                this.r.text = 'intersection';
                break
            case '∈':
                this.r.text = 'in';
                break
            case '¬':
                this.r.text = 'negation of';
                break
            case '⊂':
                this.r.text = 'strictly included in';
                break;
            case '⊆':
                this.r.text = 'included in';
                break;
            case '⊃':
                this.r.text = 'includes strictly';
                break;
            case '⊇':
                this.r.text = 'includes';
                break;
            case '∀':
                this.r.text = 'for each';
                break;
            case '∃':
                this.r.text = 'exists';
                break;
            case '∄':
                this.r.text = 'does not exist';
                break;
            case '∨':
                this.r.text = 'or';
                break;
            case '∧':
                this.r.text = 'and';
                break;
            case '⊻':
                this.r.text = 'exclusive or';
                break;
            case '⟹':
                this.r.text = 'implies';
                break;
            case '⟸':
                this.r.text = 'is implied by';
                break;
            case '↔':
                this.r.text = 'if and only if';
                break;
            case '∅':
                this.r.text = 'empty set';
                break;
            case 'ℕ':
                this.r.text = 'natural numbers set';
                break;
            case 'ℤ':
                this.r.text = 'integer numbers set';
                break;
            case 'ℚ':
                this.r.text = 'rational numbers set';
                break;
            case 'ℝ':
                this.r.text = 'real numbers set';
                break;
            case 'ℂ':
                this.r.text = 'complex numbers set';
                break;
            case 'ℜ':
                this.r.text = 'relation';
                break;
        }
    }

    private greek() {
        switch (this.op) {
            case 'Α':
            case 'α':
            case '∝': this.r.text = 'alpha'; break;
            case 'Β':
            case 'β': this.r.text = 'beta'; break;
            case 'Γ':
            case 'γ': this.r.text = 'gamma'; break;
            case 'Δ':
            case 'δ': this.r.text = 'delta'; break;
            case 'Ε':
            case 'ε':
            case 'ϵ': this.r.text = 'epsilon'; break;
            case 'Ζ':
            case 'ζ': this.r.text = 'zeta'; break;
            case 'Η':
            case 'η': this.r.text = 'eta'; break;
            case 'Θ':
            case 'θ': this.r.text = 'teta'; break;
            case 'Ι':
            case 'ι': this.r.text = 'iota'; break;
            case 'Κ':
            case 'κ': this.r.text = 'kappa'; break;
            case 'Λ':
            case 'λ': this.r.text = 'lambda'; break;
            case 'Μ':
            case 'μ': this.r.text = 'mu'; break;
            case 'Ν':
            case 'ν': this.r.text = 'nu'; break;
            case 'Ξ':
            case 'ξ': this.r.text = 'xi'; break;
            case 'Ο':
            case 'ο': this.r.text = 'omicron'; break;
            case 'Π':
            case 'π': this.r.text = 'pi'; break;
            case 'Ρ':
            case 'ρ': this.r.text = 'rho'; break;
            case 'Σ':
            case 'σ':
            case 'ς': this.r.text = 'sigma'; break;
            case 'Τ':
            case 'τ': this.r.text = 'tau'; break;
            case 'Υ':
            case 'υ': this.r.text = 'upsilon'; break;
            case 'Φ':
            case 'φ':
            case 'ϕ': this.r.text = 'fi'; break;
            case 'Χ':
            case 'χ': this.r.text = 'ki'; break;
            case 'Ψ':
            case 'ψ': this.r.text = 'psi'; break;
            case 'Ω':
            case 'ω': this.r.text = 'omega'; break;
        }
    }

    private geometry() {
        switch (this.op) {
            case '≅':
                this.r.text = 'is congruent with';
                break;
            case '≡':
                this.r.text = 'is coincident with';
                break;
            case '∼':
                this.r.text = 'is similar to';
                break;
            case '∥':
                this.r.text = 'is parallel to';
                break;
            case '⊥':
                this.r.text = 'is perpendicular to';
                break;
        }
    }

    private trigonometry() {
        switch (this.op) {
            case 'sin':
            case 'sen':
                this.r.text = 'sine of';
                break;
            case 'cos':
                this.r.text = 'cosine of';
                break;
            case 'tan':
                this.r.text = 'tangent of';
                break;
            case 'sec':
                this.r.text = 'secant of';
                break;
            case 'csc':
                this.r.text = 'cosecant of';
                break;
            case 'cot':
                this.r.text = 'cotangent of';
                break;
            case 'arcsin':
                this.r.text = 'arcosine of';
                break;
            case 'arccos':
                this.r.text = 'arcocosine of';
                break;
            case 'arctan':
                this.r.text = 'arcotangent of';
                break;
            // NON ESISTE IN LATEX/MATHML
            case 'arccot':
                this.r.text = 'arcocotangent of';
                break;
        }
    }

    private logarhythmAndExponentials() {
        switch (this.op) {
            case 'log':
                if (this.parentTag === 'msub') {
                    this.r.text = 'logarythm with base';
                    this.r.suffix = 'of';
                } else {
                    this.r.text = 'logarythm of';
                }
                break;
            case 'ln':
                this.r.text = 'natural logarythm of';
                break;
        }
    }

    private others() {
        if (this.parentTag === 'mover' || this.parentTag === 'msup' || this.parentTag === 'msubsup') {
            if (this.accent) {
                if (this.child1 && this.child1.tagName === 'mo' && this.child1.textContent === '→') {
                    // Vector
                    this.r.prefix = 'vector'
                    this.r.text = this.op;
                    this.r.skipChild = true;
                } else if (this.child1 && this.child1.tagName === 'mo' && this.child1.textContent === '⌒') {
                    // Angle
                    this.r.prefix = 'angle'
                    this.r.text = this.op;
                    this.r.skipChild = true;
                } else {
                    // Overbar
                    if (this.op.length === 1 && /^[a-z]+$/.test(this.op)) {
                        // Measure
                        this.r.prefix = 'measure of'
                        this.r.text = this.op;
                    } else if (this.op.length === 1 && /^[A-Z]+$/.test(this.op)) {
                        // Complementary set
                        this.r.prefix = 'complementary set of'
                        this.r.text = this.op;
                    } else if (this.op.length >= 2 && /^[A-Z]+$/.test(this.op)) {
                        // Segment
                        this.r.prefix = 'segment'
                        this.r.text = this.op;
                    } else if (/^[0-9]+$/.test(this.op)) {
                        // Periodic number
                        this.r.prefix = ''
                        this.r.text = this.op;
                        this.r.suffix = 'periodic'
                    }
                }
            } else {
                if (this.child1 && this.child1.tagName === 'mo' && this.child1.textContent === '⌢') {
                    // Arc
                    this.r.prefix = 'arc'
                    this.r.text = this.op;
                    this.r.skipChild = true;
                } else {
                    // Something ^ and/or _
                    this.r.text = this.op;
                    this.r.prefixSup = 'to the power of';
                    this.r.prefixSub = '';
                }
            }
        } else {
            // Keep op as it is
            // console.log('--->UNKNOWN OP: ' + op);
            this.r.text = this.op;
        }
    }

    //----------------------------------------------------------------------------
    // Simplification
    //
    override getSimpleFraction(num: string, denom: string): string {
        let text;
        let n1 = '';
        let n2 = '';
        if (num === '1') {
            n1 = 'un';
            n2 = 'uno';
        } else {
            n1 = n2 = num;
        }
        let d = '';
        /*
        */

        switch (denom) {
            case '2': d = (num === '1' ? 'half' : 'halves'); break;
            case '3': d = (num === '1' ? 'third' : 'thirds'); break;
            case '4': d = (num === '1' ? 'fourth' : 'fourths'); break;
            case '5': d = (num === '1' ? 'fifth' : ''); break;
            case '6': d = (num === '1' ? 'sixth' : ''); break;
            case '7': d = (num === '1' ? 'seventh' : ''); break;
            case '8': d = (num === '1' ? 'eighth' : ''); break;
            case '9': d = (num === '1' ? 'ninth' : ''); break;
            case '10': d = (num === '1' ? 'tenth' : ''); break;
            case '11': d = (num === '1' ? 'eleventh' : ''); break;
            case '12': d = (num === '1' ? 'twelfth' : 'twelfths'); break;
            case '13': d = (num === '1' ? 'thirteenth' : 'thirteenths'); break;
            case '14': d = (num === '1' ? 'fourteenth' : 'fourteenths'); break;
            case '15': d = (num === '1' ? 'fifteenth' : 'fifteenths'); break;
            case '16': d = (num === '1' ? 'sixteenth' : 'sixteenths'); break;
            case '17': d = (num === '1' ? 'seventeenth' : 'seventeenths'); break;
            case '18': d = (num === '1' ? 'eighteenth' : 'eighteenths'); break;
            case '19': d = (num === '1' ? 'nineteenth' : 'nineteenths'); break;
            case '20': d = (num === '1' ? 'twentieth' : 'twentieths'); break;
            case '21': d = (num === '1' ? 'twenty-first' : 'twenty-firsts'); break
            case '22': d = (num === '1' ? 'twenty-second' : 'twenty-seconds'); break
            case '23': d = (num === '1' ? 'twenty-third' : 'twenty-thirds'); break
            case '24': d = (num === '1' ? 'twenty-fourth' : 'twenty-fourths'); break
            case '25': d = (num === '1' ? 'twenty-fifth' : 'twenty-fifths'); break
            case '26': d = (num === '1' ? 'twenty-sixth' : 'twenty-sixths'); break
            case '27': d = (num === '1' ? 'twenty-seventh' : 'twenty-sevenths'); break
            case '28': d = (num === '1' ? 'twenty-eighth' : 'twenty-eighths'); break
            case '29': d = (num === '1' ? 'twenty-ninth' : 'twenty-ninths'); break
            case '30': d = (num === '1' ? 'thirtieth' : 'thirtieths'); break
            case '31': d = (num === '1' ? 'thirty-first' : 'thirty-firsts'); break
            case '32': d = (num === '1' ? 'thirty-second' : 'thirty-seconds'); break
            case '33': d = (num === '1' ? 'thirty-third' : 'thirty-thirds'); break
            case '34': d = (num === '1' ? 'thirty-fourth' : 'thirty-fourths'); break
            case '35': d = (num === '1' ? 'thirty-fifth' : 'thirty-fifths'); break
            case '36': d = (num === '1' ? 'thirty-sixth' : 'thirty-sixths'); break
            case '37': d = (num === '1' ? 'thirty-seventh' : 'thirty-sevenths'); break
            case '38': d = (num === '1' ? 'thirty-eighth' : 'thirty-eighths'); break
            case '39': d = (num === '1' ? 'thirty-ninth' : 'thirty-ninths'); break
            case '40': d = (num === '1' ? 'fortieth' : 'fortieths'); break
            case '41': d = (num === '1' ? 'forty-first' : 'forty-firsts'); break
            case '42': d = (num === '1' ? 'forty-second' : 'forty-seconds'); break
            case '43': d = (num === '1' ? 'forty-third' : 'forty-thirds'); break
            case '44': d = (num === '1' ? 'forty-fourth' : 'forty-fourths'); break
            case '45': d = (num === '1' ? 'forty-fifth' : 'forty-fifths'); break
            case '46': d = (num === '1' ? 'forty-sixth' : 'forty-sixths'); break
            case '47': d = (num === '1' ? 'forty-seventh' : 'forty-sevenths'); break
            case '48': d = (num === '1' ? 'forty-eighth' : 'forty-eighths'); break
            case '49': d = (num === '1' ? 'forty-ninth' : 'forty-ninths'); break
            case '50': d = (num === '1' ? 'fiftieth' : 'fiftieths'); break
            case '51': d = (num === '1' ? 'fifty-first' : 'fifty-firsts'); break
            case '52': d = (num === '1' ? 'fifty-second' : 'fifty-seconds'); break
            case '53': d = (num === '1' ? 'fifty-third' : 'fifty-thirds'); break
            case '54': d = (num === '1' ? 'fifty-fourth' : 'fifty-fourths'); break
            case '55': d = (num === '1' ? 'fifty-fifth' : 'fifty-fifths'); break
            case '56': d = (num === '1' ? 'fifty-sixth' : 'fifty-sixths'); break
            case '57': d = (num === '1' ? 'fifty-seventh' : 'fifty-sevenths'); break
            case '58': d = (num === '1' ? 'fifty-eighth' : 'fifty-eighths'); break
            case '59': d = (num === '1' ? 'fifty-ninth' : 'fifty-ninths'); break
            case '60': d = (num === '1' ? 'sixtieth' : 'sixtieths'); break
            case '61': d = (num === '1' ? 'sixty-first' : 'sixty-firsts'); break
            case '62': d = (num === '1' ? 'sixty-second' : 'sixty-seconds'); break
            case '63': d = (num === '1' ? 'sixty-third' : 'sixty-thirds'); break
            case '64': d = (num === '1' ? 'sixty-fourth' : 'sixty-fourths'); break
            case '65': d = (num === '1' ? 'sixty-fifth' : 'sixty-fifths'); break
            case '66': d = (num === '1' ? 'sixty-sixth' : 'sixty-sixths'); break
            case '67': d = (num === '1' ? 'sixty-seventh' : 'sixty-sevenths'); break
            case '68': d = (num === '1' ? 'sixty-eighth' : 'sixty-eighths'); break
            case '69': d = (num === '1' ? 'sixty-ninth' : 'sixty-ninths'); break
            case '70': d = (num === '1' ? 'seventieth' : 'seventieths'); break
            case '71': d = (num === '1' ? 'seventy-first' : 'seventy-firsts'); break
            case '72': d = (num === '1' ? 'seventy-second' : 'seventy-seconds'); break
            case '73': d = (num === '1' ? 'seventy-third' : 'seventy-thirds'); break
            case '74': d = (num === '1' ? 'seventy-fourth' : 'seventy-fourths'); break
            case '75': d = (num === '1' ? 'seventy-fifth' : 'seventy-fifths'); break
            case '76': d = (num === '1' ? 'seventy-sixth' : 'seventy-sixths'); break
            case '77': d = (num === '1' ? 'seventy-seventh' : 'seventy-sevenths'); break
            case '78': d = (num === '1' ? 'seventy-eighth' : 'seventy-eighths'); break
            case '79': d = (num === '1' ? 'seventy-ninth' : 'seventy-ninths'); break
            case '80': d = (num === '1' ? 'eightieth' : 'eightieths'); break
            case '81': d = (num === '1' ? 'eighty-first' : 'eighty-firsts'); break
            case '82': d = (num === '1' ? 'eighty-second' : 'eighty-seconds'); break
            case '83': d = (num === '1' ? 'eighty-third' : 'eighty-thirds'); break
            case '84': d = (num === '1' ? 'eighty-fourth' : 'eighty-fourths'); break
            case '85': d = (num === '1' ? 'eighty-fifth' : 'eighty-fifths'); break
            case '86': d = (num === '1' ? 'eighty-sixth' : 'eighty-sixths'); break
            case '87': d = (num === '1' ? 'eighty-seventh' : 'eighty-sevenths'); break
            case '88': d = (num === '1' ? 'eighty-eighth' : 'eighty-eighths'); break
            case '89': d = (num === '1' ? 'eighty-ninth' : 'eighty-ninths'); break
            case '90': d = (num === '1' ? 'ninetieth' : 'ninetieths'); break
            case '91': d = (num === '1' ? 'ninety-first' : 'ninety-firsts'); break
            case '92': d = (num === '1' ? 'ninety-second' : 'ninety-seconds'); break
            case '93': d = (num === '1' ? 'ninety-third' : 'ninety-thirds'); break
            case '94': d = (num === '1' ? 'ninety-fourth' : 'ninety-fourths'); break
            case '95': d = (num === '1' ? 'ninety-fifth' : 'ninety-fifths'); break
            case '96': d = (num === '1' ? 'ninety-sixth' : 'ninety-sixths'); break
            case '97': d = (num === '1' ? 'ninety-seventh' : 'ninety-sevenths'); break
            case '98': d = (num === '1' ? 'ninety-eighth' : 'ninety-eighths'); break
            case '99': d = (num === '1' ? 'ninety-ninth' : 'ninety-ninths'); break
            case '100': d = (num === '1' ? 'one hundredth' : 'one hundredths'); break
        }
        if (d) {
            text = n1 + ' ' + d;
        } else {
            text = n2 + ' fratto ' + denom;
        }
        return text;
    }

    override getSimpleElevation(elev: string): string {
        let text = '';
        let s = '';
        switch (elev) {
            case '2': s = 'squared'; break;
            case '3': s = 'cubed'; break;
        }
        if (s) {
            text = s;
        } else {
            text = 'to the power of ' + elev;
        }
        return text;
    }

    override getSimpleZeroFrom(symbol: string): string {
        return symbol;
    }

    override getddx(): string {
        return "dee on dee ex";
    }
}
