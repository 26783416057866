import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthenticationService, UserBaseInfo } from 'src/app/core/authentication/authentication.service';
import { DeviceService } from 'src/app/core/device.service';
import { FirebaseAuthService } from 'src/app/core/firebase/firebase-auth.service';
import { InvalidCredentialsComponent } from 'src/app/logged-in/invalid-credentials/invalid-credentials.component';
import { MessageBoxService } from 'src/app/shared/dialog/messagebox.service';
import { EMAIL_VALIDATOR_PATTERN, emailStrictlyValidator } from 'src/app/validators/email-strictly.validator';
import { environment } from 'src/environments/environment';
import { MessageSentComponent } from 'src/app/login-form/message-sent/message-sent.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SmeService } from 'src/app/core/sme/sme.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

declare const google: any;

@Component({
    selector: 'app-email-demo-request',
    templateUrl: './email-demo-request.component.html',
    styleUrl: './email-demo-request.component.scss'
})
export class EmailDemoRequestComponent implements OnInit, AfterViewInit {

    @Output() requestPassword: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('emailInput') emailInput?: ElementRef;

    readonly emailPattern = EMAIL_VALIDATOR_PATTERN;

    private routes = {
        apiloginGoogle: '/api/logingoogle2gen',
        apiloggein: '/api/loggedin2gen'
    };


    version: string = environment.version;

    passwordChangeSubscription: Subscription | undefined;


    isLoading = false;
    redirect = '';
    showingError = false;
    loginErrorMessage = '';
    clickableMsg = false;
    loginUri = '';
    googleSignInAvailable = true;
    noGoogleLoginContent = '';
    nGLCC = 0;
    MAX_GLCC = 10;
    gRendered = false;
    isOpenedRequestPassword = false;


    public isMobile: boolean;

    APP_ID = environment.configFirebase.messagingSenderId;
    CLIENT_ID = environment.firebaseClientId;

    isError = false;
    errorMessage = '';


    form: FormGroup = new FormGroup({
        username: new FormControl('',
            { validators: [emailStrictlyValidator] })
    });

    @Input() error: string | null = "";

    public expired = false;
    public id_token = '';

    public doubleAccess = false;
    public askPrivacy = false;
    public isUserAdmin = false;
    public showDemo = false;
    public invalidCredentials = false;
    public eligibleForDemo = false;
    public isDemo = false;
    @Input() demoFromStart = false;
    public scopeToAccept: any;
    // requestSent: boolean = false;
    logoutSubscription: Subscription | undefined;
    //checkAndUnlockAdminOrdersSubscription: Subscription | undefined;
    setUserInfoSubscription: Subscription | undefined;
    hasUserAcceptedPrivacySubscription: Subscription | undefined;
    hide: boolean = false;
    loginTitle = this.translateService.instant('LOGIN_TITLE');
    compressed = false;
    constructor(
        private dialog: MatDialog,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        deviceService: DeviceService,
        private firebaseAuthService: FirebaseAuthService,
        private messageBoxService: MessageBoxService,
        private router: Router,
        private route: ActivatedRoute,
        private smeService: SmeService,
        private breakpointObserver: BreakpointObserver
    ) {
        this.isMobile = deviceService.isMobileOrTabletDevice();
        this.breakpointObserver
            .observe([Breakpoints.XSmall])
            .subscribe((state: BreakpointState) => {
                this.compressed = state.matches;
            });

        // (window as any).alert('FORCED MOBILE!');
        // this.isMobile = true;

    }

    ngOnInit(): void {
        this.loginUri = window.origin + this.routes.apiloginGoogle;
        localStorage.setItem('demoFromStart', 'true');
        // @ts-ignore
        this.checkGoogleLogin();
        // @ts-ignore
        // google.accounts.id.prompt((notification: PromptMomentNotification) => { });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.emailInput?.nativeElement.focus();
        });
    }

    get f() { return this.form?.controls; }

    checkGoogleLogin(): void {
        console.log('*GLCC* #' + this.nGLCC);
        if (!this.gRendered && this.nGLCC < this.MAX_GLCC) {
            if ((window as any).google) {
                google.accounts.id.initialize({
                    client_id: environment.firebaseClientId,
                    login_uri: this.loginUri,
                    auto_select: true,
                    context: 'use',
                    ux_mode: 'redirect',
                    cancel_on_tap_outside: true,
                });
                // @ts-ignore
                google.accounts.id.renderButton(
                    // @ts-ignore
                    document.getElementById('google-button'),
                    { type: 'standard', theme: 'outline', size: 'large', width: 200, text: 'continue_with' }
                );
                this.gRendered = true;
                console.log('GL button rendered');
            } else {
                // alert('Google is undefined!!!'); Mettere qui una segnalazione che non è possibile fare LOGIN
                // this.googleSignInAvailable = false;
                setTimeout(() => {
                    this.nGLCC++;
                    this.checkGoogleLogin();
                }, 100);
            }
        } else {
            if (!this.gRendered) {
                this.googleSignInAvailable = false;
                console.log('GL not loaded');
            }
        }
    }

    submit() {
        if (this.form.valid) {
            // if (id == 'login') {
            this.isLoading = true;
            const username = this.form.get('username')?.value;
            this.authenticationService.getUserBaseInfoFromFirebase(username)
                .then((userBaseInfo: UserBaseInfo) => {
                    if (userBaseInfo) {
                        this.isLoading = false;
                        if (userBaseInfo.newUser) {

                            // if (userBaseInfo.eligibleForDemo) {
                            this.demoFromStart = true;

                            this.requestAccess();
                            // } else {
                            //     alert('Demo non disponibile!')

                            // }
                        } else {
                            if (userBaseInfo.isNativeGoogleUser) {
                                const dialogRef = this.messageBoxService.showTextMessage(
                                    this.messageBoxService.MODE_TYPE.OK,
                                    this.translateService.instant('WARNING'),
                                    this.translateService.instant('GOOGLE_SIGNIN_MESSAGE')).subscribe(() => {
                                        // this.dialog.closeAll();

                                    });

                            } else {
                                //alert('Demo non disponibile!');

                                this.authenticationService.logout().subscribe(() => {

                                    this.router.navigate(['/login', 'home'], { queryParams: { username: username } });


                                });

                                // this.requestPassword.emit(username);
                            }

                        }

                    }
                }).catch((error) => {
                    this.isLoading = false;
                    this.error = error;

                });




            // this.loginNoGoogle(this.form.value);
            // } else {
            //     this.submitCreate.emit(this.form.value);
            // }
        } else {
            this.isLoading = false;
        }
    }


    // requestDemo() {
    //     this.isLoading = true;

    //     this.smeService.activateDemo('', this.form.get("username")?.value).subscribe({
    //         next: (resp) => {
    //             this.isDemo = true;


    //             this.router.navigate(['signin-link-sent'], { queryParams: { username: this.form.get("username")?.value } });

    //         },
    //         error: (error: any) => {
    //             this.isError = true;
    //             this.errorMessage = error?.message ?? error;
    //             this.isLoading = false;
    //         }
    //     });

    // }


    requestAccess() {

        this.isLoading = true;
        this.firebaseAuthService.auth.languageCode = this.translateService.currentLang;
        this.firebaseAuthService.sendSigninLink(this.form.get("username")?.value, this.demoFromStart)
            .then(() => {
                // this.requestSent = true;
                this.isLoading = false;

                /*
                const dialogRef = this.dialog.open(MessageSentComponent, {
                    width: '640px', disableClose: true
                });
                dialogRef.componentInstance.initialize(this.form.get("username")?.value);
                dialogRef.afterClosed().subscribe(() => {
                    this.dialog.closeAll();
                })
                */

                // Signin link email sent!

                this.dialog.closeAll();
                this.router.navigate(['signin-link-sent'], { queryParams: { username: this.form.get("username")?.value } });
                // this.dialog.closeAll();


            })
            .catch((error) => {
                this.error = error;
                this.isLoading = false;
            });
    }



    findUserDemo(userBaseInfo: UserBaseInfo) {
        let isDemo = false;
        if (userBaseInfo && userBaseInfo.admin) {
            isDemo = (userBaseInfo.admin.length === 1 && userBaseInfo.admin[0].origin === 'demo');
        }
        return isDemo;
    }

    findUserAdmin(userBaseInfo: UserBaseInfo) {
        let isAdmin = false;
        if (userBaseInfo && userBaseInfo.admin) {
            for (let i = 0; i < userBaseInfo.admin.length; i++) {
                const adm = userBaseInfo.admin[i];
                if (adm.type === 'd' || adm.type === 'm') {
                    isAdmin = true;
                }
            }
        }
        return isAdmin;
    }

    @HostListener('keypress', ['$event'])
    keypress(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            if (this.form.valid) {
                this.submit();
            }
        }
    }
}
