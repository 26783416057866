import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { LoggedInRoutingModule } from './logged-in-routing.module';
import { LoggedInComponent } from './logged-in.component';
import { UserPrivacyComponent } from './user-privacy/user-privacy.component';
import { InvalidCredentialsComponent } from './invalid-credentials/invalid-credentials.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { InvalidCredentialsRoutingModule } from './invalid-credentials/invalid-credentials-routing.module';
import { DemoModule } from '../demo/demo.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forRoot(),
        SharedModule,
        FormsModule,
        //FlexLayoutModule,
        MaterialModule,
        HttpClientModule,
        LoggedInRoutingModule,
        DemoModule,
        InvalidCredentialsRoutingModule
    ],
    declarations: [
        LoggedInComponent,
        UserPrivacyComponent,
        InvalidCredentialsComponent
    ]
})
export class LoggedInModule { }
