import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Logger } from '../core/logger.service';
import { throwError as observableThrowError } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { TokenInterceptor } from '../core/http/token.interceptor';
import { OrderDto } from './common/order-dto';

const routes = {
    apiFindSubscription: '/api/findsubscription2gen',
    apiFindRegisteredUser: '/api/findregistereduser2gen',
    apiCreateAccess: 'api/createaccess2gen',
    // apiListAccess: 'api/listAccess',
    apiSearchSubscriptions: '/api/searchsubscriptions2gen',
    apiCreateOrder: '/api/orders',
    apiUpdateOrder: '/api/updateorder2gen',
    apiSendMail: '/api/sendmail2gen',
    apiAcceptContract: '/api/acceptcontract2gen'
};

const logger: Logger = new Logger('registeredUserService');

@Injectable({
    providedIn: 'root'
})

export class AccessConfigService {


    public pageEvent: PageEvent | undefined;
    public orderId = '';

    constructor(private http: HttpClient) { }


    public findSubscription(orderId: string, randomId: string): any {
        const body = {
            orderId: orderId,
            randomId: randomId
        };

        return this.http.post<Response>(routes.apiFindSubscription, body, { headers: TokenInterceptor.buildNoTokenHeaders() })
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public createAccess(orderId: string, randomId: string, access: any, operator = '', sendMail = false): any {

        const body = {
            orderId: orderId,
            randomId: randomId,
            sendMail: sendMail,
            operator: operator,
            access: access
        };

        return this.http.post<Response>(routes.apiCreateAccess, body, { headers: TokenInterceptor.buildNoTokenHeaders() })
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public createOrder(order: OrderDto, days: number, operator: string, sendMail: boolean): any {
        const body = {
            orderId: order.orderId,
            userType: order.userType,
            planId: order.planId,
            origin: order.origin,
            refOrderId: order.refOrderId,
            networkUserId: order.networkUserId,
            userEmail: order.userEmail,
            fullName: order.fullName,
            licenseType: order.licenseType,
            domainLics: order.domainLics.toString(),
            slotCount: order.slotCount.toString(),
            startDate: order.startDate,
            days: days.toString(),
            operator: operator,
            sendMail: sendMail
        };
        return this.http.post<Response>(routes.apiCreateOrder, body, { withCredentials: false })
            .pipe(
                map((res: Response) => res), catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));

    }


    public searchSubscriptions(search = '', includeExpired = false, page = 1, perPage = 10, orderBy: any = '', orderAsc: any = '') {
        const body = {
            search: search,
            includeExpired: includeExpired,
            page: page,
            perPage: perPage,
            orderBy: orderBy,
            orderAsc: orderAsc
        };

        // return this.http.post(routes.apiSearchSubscriptions, body, { headers: TokenInterceptor.buildNoTokenHeaders() })
        return this.http.post<Response>(routes.apiSearchSubscriptions, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public updateOrder(orderId: string, randomId: string, userEmail: string, fullName: string, startDate: string, expireDate: string, origin: string, suspended: boolean, notes: string, operator: string, slotCount: number, domainLics: number, planId: string, ocrMathScanQuota: number, ocrMathScansPolicy: string, userType: string) {
        const body = {
            orderId: orderId,
            randomId: randomId,
            userEmail: userEmail,
            fullName: fullName,
            startDate: startDate,
            expireDate: expireDate,
            origin: origin,
            suspended: suspended,
            notes: notes,
            slotCount: slotCount.toString(),
            domainLics: domainLics,
            operator: operator,
            userType: userType,
            planId: planId,
            ocrMathScanQuota: ocrMathScanQuota,
            ocrMathScanPolicy: ocrMathScansPolicy
        };
        return this.http.post<Response>(routes.apiUpdateOrder, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error)
                }));
    }

    public sendMail(orderId: string, randomId: string, mailTo: string, fullName: string, toEndUser = false) {
        const body = {
            orderId: orderId,
            randomId: randomId,
            mailTo: mailTo,
            fullName: fullName,
            toEndUser: toEndUser
        };
        return this.http.post<Response>(routes.apiSendMail, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public acceptContract(subscriptionId: string, randomId: string) {
        const body = {
            subscriptionId: subscriptionId,
            randomId: randomId
        };
        return this.http.post<Response>(routes.apiAcceptContract, body, { headers: TokenInterceptor.buildNoTokenHeaders() })
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

}
