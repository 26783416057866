import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RecentPdfDto } from '../recent-pdf-dto';
import { PdfService } from '../../pdf.service';
import { Overlay } from '@angular/cdk/overlay';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
    selector: 'app-pdf-recent',
    templateUrl: './pdf-recent.component.html',
    styleUrls: ['./pdf-recent.component.scss']
})
export class PdfRecentComponent {

    @Output() fromRecent: EventEmitter<RecentPdfDto> = new EventEmitter<RecentPdfDto>();
    // public pdfRecents: RecentPdfDto[] = [];
    // public recentPdfsSortedFiltered: RecentPdfDto[] = [];
    @Input() recentPdf: RecentPdfDto = new RecentPdfDto();

    @Output() fromLocal: EventEmitter<any> = new EventEmitter<any>();
    @Output() fromLink: EventEmitter<any> = new EventEmitter<any>();
    @Output() fromDrive: EventEmitter<any> = new EventEmitter<any>();
    @Output() removeItem: EventEmitter<any> = new EventEmitter<any>();
    private itemMenuClicked = false;

    @Output() downloadItem: EventEmitter<RecentPdfDto> = new EventEmitter<RecentPdfDto>();
    @Output() renameItem: EventEmitter<RecentPdfDto> = new EventEmitter<RecentPdfDto>();

    constructor(private pdfService: PdfService) {



    }

    itemMenuClick(item: RecentPdfDto, event: any) {
        event?.stopPropagation();
        this.itemMenuClicked = true;
        this.recentPdf = item;

    }

    downloadRecent(recent: RecentPdfDto) {
        this.downloadItem.emit(recent);
    }

    removePdf(item: RecentPdfDto) {
        this.removeItem.emit(item);
    }

    renamePdf(item: RecentPdfDto) {
        this.renameItem.emit(item);
    }


    changeIconColor(item: RecentPdfDto) {
        this.recentPdf.color = item.color;
        this.pdfService.addOrUpdatePdf(item.idPdf, item.name, item.googleId, item.lastUpdated, item.lastOpened, item.imported, item.storageRef, item.link, item.color);

    }



    openPDFFromLocal(event: any) {

        this.fromLocal.emit(event);
    }


    openPDFFromDrive() {
        this.fromDrive.emit();
    }

    openPDFLink(link: string) {
        this.fromLink.emit(link)
    }

    loadPdfFromRecent(item: RecentPdfDto) {
        this.fromRecent.emit(item);
    }







}
