import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MapStateService } from '../map-state.service';
import { UsersService } from '../commands/users.service';
import { SmeService } from '../../core/sme/sme.service';

@Component({
  selector: 'app-users-toolbar',
  templateUrl: './users-toolbar.component.html',
  styleUrls: ['./users-toolbar.component.scss']
})
export class UsersToolbarComponent implements OnInit, OnDestroy {

  private onEventUserSubscription: Subscription | undefined;
  private getIntroMapsSubscription: Subscription | undefined;

  public isUsersEnabled: boolean;

  constructor(
    private mapStateService: MapStateService,
    public usersService: UsersService,
    private smeService: SmeService) {
    this.isUsersEnabled = false;
  }

  ngOnInit() {
    if (this.getIntroMapsSubscription) { this.getIntroMapsSubscription.unsubscribe(); }
    this.getIntroMapsSubscription = this.smeService.getIntroMaps().subscribe((introMaps: any) => {
      if (introMaps) {
        if (introMaps.length > 0) {
          this.isUsersEnabled = !introMaps.includes(this.mapStateService.id);
        } else {
          this.isUsersEnabled = true;
        }
      }
    });
    this.onEventUserSubscription = this.mapStateService.onEventUser.subscribe((user: any) => {
      this.usersService.applyUserEvent(user);
    });
  }

  ngOnDestroy() {
    if (this.getIntroMapsSubscription) { this.getIntroMapsSubscription.unsubscribe(); }
    if (this.onEventUserSubscription) { this.onEventUserSubscription.unsubscribe(); }
  }

}
