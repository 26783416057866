import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { GdriveFolderPickerComponent } from './gdrive-folder-picker.component';
import { AuthenticationGuard } from '../core/authentication/authentication.guard';

const routes: Routes = Route.withShell([
    { path: 'gdrive-folder-picker/:mapId', component: GdriveFolderPickerComponent, data: { title: extract('GDRIVE_PICKER_TITLE') }, canActivate: [AuthenticationGuard] },
]);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthenticationGuard]
})
export class GdriveFolderPickerRoutingModule { }
