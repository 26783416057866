import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener, NgZone, Input, AfterContentChecked } from '@angular/core';
import { CustomizeToolbarService } from './customize-toolbar.service';
import { CustomToolbarDto } from './custom-toolbar-dto';
import { Subscription } from 'rxjs';
import { QuickEditService } from '../../../shared/commands/quick-edit.service';
import { WebSearchService } from '../../../shared/commands/web-search.service';
import { ExtraItem, ExtraService } from '../../commands/extra.service';
import { MapShowService } from '../../../map-show/map-show.service';
import { EditDataService } from '../../../shared/commands/edit-data.service';
import { CreateGoogleDocService } from '../../../shared/commands/create-google-doc.service';
import { MapStateService } from '../../../shared/map-state.service';
import { DesmosService } from '../../../shared/commands/desmos.service';
import { ChangeDetectorRef } from '@angular/core';
// import { WebService } from 'src/app/shared/commands/web.service';
import { environment } from '../../../../environments/environment';
import { OutlineService } from '../../outline/outline.service';


@Component({
    selector: 'app-custom-toolbar',
    templateUrl: './custom-toolbar.component.html',
    styleUrls: ['./custom-toolbar.component.scss']
})

export class CustomToolbarComponent implements OnInit, OnDestroy, AfterContentChecked {

    @Input() isLoading = false;
    alertToolbar: HTMLElement | undefined;
    public noSelectionVisible = true;
    public quickEditVisible = false;
    public speechEditVisible = false;
    public formatTextVisible = false;
    public formatElementVisible = false;
    public formatEdgeVisible = false;
    public formatTableVisible = false;
    public formatImageVisible = false;
    public hideLanguage = true;
    public hideImage = true;
    public moreToolbar = false;
    public totButtonVisible = 0;
    public formatButtonVisible = 0;
    public hideAlign = true;
    public hideSize = true;
    public isEditShowMode: boolean;
    public editorIsOpen: boolean;
    public showGoogleDoc = false;
    public hasGoogleDoc = false;
    public hasNotes: boolean;
    public envDevelop: boolean = false;

    customToolbarData: CustomToolbarDto | undefined;
    onChangeToolbarVisibilitySubscription: Subscription | undefined;
    onChangeTotButtonVisibleSubscription: Subscription | undefined;
    onQuickEditEnableSubscription: Subscription | undefined;
    onSpeechEditEnableSubscription: Subscription | undefined;
    onSwitchToEditShowSubscription: Subscription | undefined;
    onOpenEditorSubscription: Subscription | undefined;
    onGoogleDocIdChangeSubscription: Subscription | undefined;

    hideMoreToolbar() {
        this.moreToolbar = false;
        const at = document.getElementById('alert-toolbar');
        if (at) {
            this.alertToolbar = at;
            this.alertToolbar.style.display = 'none';
        }
    }

    constructor(private customizeToobarService: CustomizeToolbarService,
        private quickEditService: QuickEditService,
        private webSearchService: WebSearchService,
        private desmosService: DesmosService,
        // private webService: WebService,
        private extraService: ExtraService,
        private mapShowService: MapShowService,
        private editDataService: EditDataService,
        private createGoogleDocService: CreateGoogleDocService,
        private changeDetectorRef: ChangeDetectorRef,
        private mapStateService: MapStateService,
        private outlineService: OutlineService) {
        this.isEditShowMode = false;
        this.editorIsOpen = false;
        this.hasNotes = false;
        this.envDevelop = environment.env === 'dev';
    }
    ngAfterContentChecked(): void {
        this.changeDetectorRef.detectChanges();
    }


    showMoreToolbar() {
        this.moreToolbar = !this.moreToolbar;
        const at = document.getElementById('alert-toolbar');
        if (at) {
            this.alertToolbar = at;
            this.alertToolbar.style.display = this.moreToolbar ? 'flex' : 'none';
        }
    }

    changeToolbarVisibility() {
        // // visibilità toolbar
        this.hideMoreToolbar();
        this.hideLanguage = this.customizeToobarService.hideLanguage;
        this.hideImage = this.customizeToobarService.hideImage;
        this.hideAlign = this.customizeToobarService.hideAlign;
        this.hideSize = this.customizeToobarService.hideSize;
        if (this.customToolbarData) {
            this.noSelectionVisible = this.customToolbarData?.noSelection;

            this.formatTextVisible = this.customToolbarData?.edgeSelected
                || this.customToolbarData.nodeSelected || (this.customToolbarData.isTableSelected && !this.customToolbarData.onlyNodeInTableSelected);
            this.formatElementVisible = this.customToolbarData.nodeSelected || this.customToolbarData.edgeSelected
                || (this.customToolbarData.isTableSelected && !this.customToolbarData.onlyNodeInTableSelected);
            this.formatTableVisible = this.customToolbarData.onlyNodeInTableSelected;
            this.formatEdgeVisible = this.customToolbarData.edgeSelected;
            this.formatImageVisible = this.customToolbarData.imageSelected;
        }
        this.formatButtonVisible = this.customizeToobarService.formatElementButtonVisible;
    }

    ngOnInit() {
        this.onChangeToolbarVisibilitySubscription =
            this.customizeToobarService.onChangeVisibilityToolbar.subscribe((_customData: CustomToolbarDto) => {
                this.customToolbarData = _customData;
                this.changeToolbarVisibility();
            });
        this.onChangeTotButtonVisibleSubscription =
            this.customizeToobarService.onChangeTotButtonVisible.subscribe((_tot: any) => {
                if (!this.formatTableVisible) {
                    this.totButtonVisible = _tot;
                    this.formatButtonVisible = this.customizeToobarService.formatElementButtonVisible;
                }
            });
        this.onQuickEditEnableSubscription = this.quickEditService.onQuickEditEnable.subscribe((enabled: boolean) => {
            this.quickEditVisible = enabled;
        });

        this.onSpeechEditEnableSubscription = this.quickEditService.onSpeechEditEnable.subscribe((enabled: boolean) => {
            if (this.quickEditService.origin === this.quickEditService.ORIGIN.MAP) {
                this.speechEditVisible = enabled;
            }
        });
        this.onSwitchToEditShowSubscription = this.mapShowService.onSwitchToEditShow.subscribe((isEditShow: boolean) => {
            this.isEditShowMode = isEditShow;
        });

        this.onOpenEditorSubscription = this.editDataService.onOpenEditor.subscribe((editorIsOpen: boolean) => {
            this.editorIsOpen = editorIsOpen;
        });

        this.showGoogleDoc = false;
        this.hasGoogleDoc = false;
        if (this.onGoogleDocIdChangeSubscription) { this.onGoogleDocIdChangeSubscription.unsubscribe(); }
        this.onGoogleDocIdChangeSubscription = this.createGoogleDocService.onGoogleDocFileIdChange.subscribe((id: string) => {
            this.hasGoogleDoc = (id !== '');
            this.showGoogleDoc = true;
        });
        this.getHasExtraNotes();
    }

    getHasExtraNotes() {
        this.hasNotes = this.extraService.hasNotes();
        setTimeout(() => {
            this.mapStateService.onMapIsMine.emit(this.mapStateService.isMine);
        }, 100);
    }

    disableQuickEdit() {
        if (this.quickEditService.isQuickEditEnabled()) {
            this.quickEditService.toggleQuickEdit();
        }
    }

    disableSpeechEdit() {
        if (this.quickEditService.isSpeechEditEnabled()) {
            this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.MAP);
        }
    }

    searchOnInternet() {
        this.webSearchService.toggleWebSearchPanel();
    }

    openBlockNotes() {
        this.extraService.toggleExtraPanel(ExtraItem.OPEN_NOTEPAD);
    }

    openOutline() {
        this.extraService.toggleExtraPanel(ExtraItem.OPEN_OUTLINE);
    }

    openDesmos(type: string) {
        this.desmosService.openDesmos(type);
    }

    openAutodraw() {
        this.extraService.toggleExtraPanel(ExtraItem.OPEN_BROWSER, 'https://autodraw.com');
    }

    openWebPanel() {
        this.extraService.toggleExtraPanel(ExtraItem.OPEN_BROWSER, 'https://www.geogebra.org/?lang=it');
        // this.webService.openWeb('https://www.geogebra.org/?lang=it');
    }

    ngOnDestroy() {
        if (this.onChangeToolbarVisibilitySubscription) { this.onChangeToolbarVisibilitySubscription.unsubscribe(); }
        if (this.onChangeTotButtonVisibleSubscription) { this.onChangeTotButtonVisibleSubscription.unsubscribe(); }
        if (this.onQuickEditEnableSubscription) { this.onQuickEditEnableSubscription.unsubscribe(); }
        if (this.onSpeechEditEnableSubscription) { this.onSpeechEditEnableSubscription.unsubscribe(); }
        if (this.onSwitchToEditShowSubscription) { this.onSwitchToEditShowSubscription.unsubscribe(); }
        if (this.onOpenEditorSubscription) { this.onOpenEditorSubscription.unsubscribe(); }
        if (this.onGoogleDocIdChangeSubscription) { this.onGoogleDocIdChangeSubscription.unsubscribe(); }
    }

    createOrUpdateGoogleDoc() {
        this.createGoogleDocService.createGoogleDoc();
    }

    openGoogleDoc() {
        this.createGoogleDocService.openGoogleDoc(this.mapStateService.googleDocFileId);
    }
}
