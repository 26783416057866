<div class="mat-typography  container">
    <div mdc-dialog-title mdc-dialog-draggable-title translate>PASTE-OPTIONS</div>
    <div class="paste-container">
        <div class="spacer"></div>
        <div class="inline-center">
            <mat-icon class="custom-icon" svgIcon="table-plus"></mat-icon>
        </div>
        <div class="inline-center">
            <span translate>PASTE-AS-TABLE</span>
        </div>
        <div class="spacer"></div>
        <div class="inline-center">
            <button mat-button class="option-button" (click)="clicked('one-column')">
                <div class="option-button-title">
                    <span class="option-button-title" translate>PASTE-OPTIONS-ONE-COLUMN</span>
                </div>
                <img class="option-button-image" src="../../assets/paste-tab/colonna1.png?v=1" />
            </button>
            <button mat-button class="option-button" (click)="clicked('one-row')">
                <div class="option-button-title">
                    <span class="option-button-title" translate>PASTE-OPTIONS-ONE-ROW</span>
                </div>
                <img class="option-button-image" src="../../assets/paste-tab/riga1.png?v=1" />
            </button>
        </div>
        <div class="spacer"></div>
        <div class="inline-center">
            <mat-icon class="custom-icon" svgIcon="add-node"></mat-icon>
        </div>
        <div class="inline-center">
            <span translate>PASTE-AS-NODE</span>
        </div>
        <div class="spacer"></div>
        <div class="inline-center">
            <button mat-button class="option-button" (click)="clicked('single-node')">
                <div class="option-button-title">
                    <span class="option-button-title" translate>PASTE-OPTIONS-ONE-NODE</span>
                </div>
                <img class="option-button-image" src="../../assets/paste-tab/nodo1.png?v=1" />
            </button>
            <button mat-button class="option-button" (click)="clicked('nodes-vert')">
                <div class="option-button-title">
                    <span class="option-button-title" translate>PASTE-OPTIONS-NODES-VERT</span>
                </div>
                <img class="option-button-image" src="../../assets/paste-tab/nodi_elenco.png?v=1" />
            </button>
            <button mat-button class="option-button" (click)="clicked('nodes-horiz')">
                <div class="option-button-title">
                    <span class="option-button-title" translate>PASTE-OPTIONS-NODES-HORIZ </span>
                </div>
                <img class="option-button-image" src="../../assets/paste-tab/nodi_elenco_orizz.png?v=1" />
            </button>
        </div>
        <div *ngIf="!hasDeepContent" class="spacer"></div>
        <div *ngIf="hasDeepContent" class="spacer">
            <mat-divider class="divider" />
            <div class="inline-center">
                <span translate>PASTE-AS-DEEP</span>
                <mat-slide-toggle class="deep-switch" color="accent" [checked]="isPasteAsDeepChecked" id="SETS" (change)="OnDeepToggleChange($event)"></mat-slide-toggle>
            </div>
        </div>
    </div>
</div>
