<div>
    <script src="https://accounts.google.com/gsi/client" async defer></script>

    <canvas #canvas [hidden]="true" width="96" height="96"></canvas>

    <app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

    <div *ngIf="askPrivacy" class="header-container">
        <div class="smx-logo-extended" routerLink="/home" translate>
            <img class="smx-logo-image-extended" src="../../assets/login/supermappex.png?v=1" />
        </div>
        <!-- <div> -->

        <!-- <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}"> -->
        <!-- <img src="../../assets/login/supermappex.png" /> -->
        <!-- </a> -->
        <!-- </div>  -->
    </div>

    <div class="privacy-container" *ngIf="askPrivacy">

        <app-user-privacy (acceptedPrivacy)="acceptedPrivacy($event)" [isUserAdmin]="isUserAdmin" [isDemo]="isDemo"></app-user-privacy>
    </div>
    <div class="invalid-credentials-container" *ngIf="invalidCredentials">
        <app-invalid-credentials (requestDemo)="requestDemo()" [eligibleForDemo]="eligibleForDemo"></app-invalid-credentials>
    </div>
    <!-- <div *ngIf="askConsent" class="message-container">
    <div class="message-title">SUPERMAPPEX</div>

    <div class="message-text" translate>ERR_TOKEN_EXPIRED</div>
    <div class="ok-button" (click)="requestAccessToken()" translate>BUTTON_OK</div>

  </div> -->
    <!-- <div class="error" *ngIf="isError" (click)="loginClick()">
    <div class="error-content">
      <div class="error-text-header" translate>OOPS</div>
      <div class="error-text-message">{{errorMessage}}</div>
      <div class="back-button" translate>ERR_LOGIN_BACK_TO_LOGIN</div>
    </div>
  </div> -->
    <mat-card appearance="outlined" *ngIf="isError" class="container">
        <div class="message-title" translate>WARNING</div>
        <div class="message-text" [innerHTML]="errorMessage"></div>
        <button  mat-flat-button color="warn" (click)="loginClick()" >{{'BUTTON_OK' | translate}} </button>
    </mat-card>
</div>
