import { AccessDto } from './access-dto';
import { OrderDto } from './order-dto';

export class SubscriptionDto {
    order: OrderDto;
    access: AccessDto;
    showOrder: boolean;
    panelOpenState: boolean;
    finds: Array<any>;
    findsDescription: string;
    isSuspended: boolean;
    isExpired: boolean;
    ocrMathScanQuota: number;
    ocrMathScanPolicy: string;
    // ocrMathScansLeft: string;

    constructor(public json: any) {
        this.order = new OrderDto(json.order);
        this.access = new AccessDto(json.access);
        this.showOrder = false;
        this.panelOpenState = false;
        this.finds = json.finds;
        this.findsDescription = '';
        this.isSuspended = false;
        this.isExpired = false;
        this.ocrMathScanQuota = (json.order && json.order.ocrMathScanQuota ? json.order.ocrMathScanQuota : 0)
        this.ocrMathScanPolicy = (json.oder && json.order.ocrMathScanPolicy ? json.order.ocrMathScanPolicy : 'FREE');
        // this.ocrMathScansLeft = '';
    }
}
