export class DesmosConfigDto {
    public calculator: boolean = false;
    public scientific: boolean = false;
    public geometry: boolean = false;
    public function: boolean = false;
    public autoDraw: boolean = false;

    constructor(config: any) {
        this.calculator = false;
        this.scientific = false;
        this.geometry = false;
        this.function = false;
        this.autoDraw = false;

        if (config !== '' && config !== null && config != undefined) {
            if (config.calculator) this.calculator = config.calculator;
            if (config.function) this.function = config.function;
            if (config.geometry) this.geometry = config.geometry;
            if (config.scientific) this.scientific = config.scientific;
            if (config.autoDraw) this.autoDraw = config.autoDraw;
        }
    }
}
