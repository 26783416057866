<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<div class="main-content">
    <div class="access-title">
        <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
            <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
        </a><br />
        <span class="access-subtitle">
            {{'REPORT_STATS' | translate}} {{nomeConfigurazione}}
        </span>
    </div>

    <div class="user-stats">
        <div class="detail">
            <span>{{'SCHOOL_YEAR' | translate}}</span>
            <span>
                <select type="number" [(ngModel)]="selectedYear" (change)="selectedYearChanged($event)">
                    <option *ngFor="let year of years" [ngValue]="year">{{year.name}}</option>
                </select>
            </span>
        </div>
    </div>

    <hr />

    <div class="user-stats">
        <div class="detail">{{'REPORT_STATORDER1' | translate}} {{nStudentiAttivi}} {{'REPORT_STATORDER2' | translate}} {{nMappeNuove}} {{'REPORT_STATORDER3' | translate}} {{nMappeAperte}}</div>
        <hr />
        <div class="stat-title">{{'REPORT_STUDENTWORKS' | translate}}</div>
        <div class="stats-insert">
            <table class="stats-table">
                <tr>
                    <td>
                        <div>
                            <div [hidden]="!isLoadedStep3"><img class="icon" src="assets/stats/studenti.png?v=2" /></div>
                            <div [hidden]="isLoadedStep3"><img class="smx-loader-small" src="assets/smx_loader.gif?v=1" alt="Loading" /></div>
                        </div>
                    </td>
                    <td>
                        <div [hidden]="!isLoadedStep1"><img class="icon" src="assets/stats/mappa_touch.png?v=2" /></div>
                        <div [hidden]="isLoadedStep1"><img class="smx-loader-small" src="assets/smx_loader.gif?v=1" alt="Loading" /></div>
                    </td>
                    <td>
                        <div [hidden]="!isLoadedStep2"><img class="icon" src="assets/stats/mappa_touch.png?v=2" /></div>
                        <div [hidden]="isLoadedStep2"><img class="smx-loader-small" src="assets/smx_loader.gif?v=1" alt="Loading" /></div>
                    </td>
                </tr>
                <tr>
                    <td (click)="showDetailChartStudenti()" [ngClass]="{'selected':selectedStat==='users'}">
                        <span>{{nStudentiAttivi}}<br />{{'REPORT_STUDENTS' | translate}}</span>
                    </td>
                    <td (click)="showDetailChartMappeNuove()" [ngClass]="{'selected':selectedStat==='newmaps'}">
                        <span>{{nMappeNuove}}<br />{{'REPORT_NEWMAPS' | translate}}</span>
                    </td>
                    <td (click)="showDetailChartMappeAperte()" [ngClass]="{'selected':selectedStat==='openmaps'}">
                        <span>{{nMappeAperte}}<br />{{'REPORT_OPENMAPS' | translate}}</span>
                    </td>
                </tr>
            </table>
        </div>

        <!-- GRAFICO STATISTICHE -->
        <div [hidden]="selectedStat!=='users'" class="chart-title">{{'REPORT_STUDENTS' | translate}}</div>
        <div [hidden]="selectedStat!=='newmaps'" class="chart-title">{{'REPORT_NEWMAPS' | translate}}</div>
        <div [hidden]="selectedStat!=='openmaps'" class="chart-title">{{'REPORT_OPENMAPS' | translate}}</div>
        <div class="months-chart">
            <table class="m-graph">
                <thead>
                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="qtr m1">
                        <th scope="row">{{'REPORT_SET' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[0]/tickMultiplier*40}">
                            <p>{{monthStats[0]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m2">
                        <th scope="row">{{'REPORT_OTT' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[1]/tickMultiplier*40}">
                            <p>{{monthStats[1]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m3">
                        <th scope="row">{{'REPORT_NOV' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[2]/tickMultiplier*40}">
                            <p>{{monthStats[2]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m4">
                        <th scope="row">{{'REPORT_DIC' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[3]/tickMultiplier*40}">
                            <p>{{monthStats[3]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m5">
                        <th scope="row">{{'REPORT_GEN' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[4]/tickMultiplier*40}">
                            <p>{{monthStats[4]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m6">
                        <th scope="row">{{'REPORT_FEB' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[5]/tickMultiplier*40}">
                            <p>{{monthStats[5]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m7">
                        <th scope="row">{{'REPORT_MAR' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[6]/tickMultiplier*40}">
                            <p>{{monthStats[6]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m8">
                        <th scope="row">{{'REPORT_APR' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[7]/tickMultiplier*40}">
                            <p>{{monthStats[7]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m9">
                        <th scope="row">{{'REPORT_MAG' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[8]/tickMultiplier*40}">
                            <p>{{monthStats[8]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m10">
                        <th scope="row">{{'REPORT_GIU' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[9]/tickMultiplier*40}">
                            <p>{{monthStats[9]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m11">
                        <th scope="row">{{'REPORT_LUG' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[10]/tickMultiplier*40}">
                            <p>{{monthStats[10]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m12">
                        <th scope="row">{{'REPORT_AGO' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[11]/tickMultiplier*40}">
                            <p>{{monthStats[11]}}</p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="ticks">
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickMultiplier*6}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickMultiplier*5}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickMultiplier*4}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickMultiplier*3}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickMultiplier*2}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickMultiplier}}</p>
                </div>
            </div>
        </div>

        <hr />
        <div class="stat-subtitle">{{'REPORT_ACTIVESTUDENTS' | translate}}</div>

        <div class="search">
            <div [hidden]="tipoLicenza!=='d'" fxHide.xs="true" fxHide.sm="true" class="button-search" matTooltip="{{'SEARCH_PLACEHOLDER' | translate}}" (click)="searchClick(textSearch);">
                <mat-icon>search</mat-icon>
            </div>
            <input [hidden]="tipoLicenza!=='d'" fxHide.xs="true" fxHide.sm="true" type="text" placeholder="{{'SEARCH_PLACEHOLDER' | translate}}" autocomplete="off" (change)="searchClick($event)" (keyup)="searchClick($event);" class="input-search" value="{{textSearch}}" />
            <div [hidden]="tipoLicenza!=='d'" fxHide.xs="true" fxHide.sm="true" class="button-clear" matTooltip="{{'SEARCH_CLEAR' | translate}}" (click)="clearSearch()">
                <mat-icon svgIcon="close"></mat-icon>
            </div>
        </div>

        <table class="users-table" [hidden]="isLoadedStep3">
            <img class="smx-loader-small" src="assets/smx_loader.gif?v=1" alt="Loading" />
        </table>
        <table class="users-table" [hidden]="!isLoadedStep3">
            <tbody>
                <ng-container *ngFor="let utente of utentiRicerca; let i = index;">
                    <tr>
                        <td [ngStyle]="{'width.px': 40}">
                            <div>{{i+1}}</div>
                        </td>
                        <td [ngStyle]="{'width.px': 200}">
                            <div>{{utente.email}}</div>
                        </td>
                        <td>
                            <div>{{utente.name}}</div>
                        </td>
                        <td>
                            <div>{{utente.notes}}</div>
                        </td>
                        <td [ngStyle]="{'width.px': 120}" (click)="showDetailStat(utente.email)">
                            <span class="report-link">{{'REPORT_GOTOREPORT' | translate}}</span>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

    </div>

</div>
