import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { SmService } from 'supermappe-core';
import { GoogleService } from '../core/google/google.service';
import { extract, I18nService } from '../core/i18n.service';
import { SmeService } from '../core/sme/sme.service';
import { ImageMapsService } from '../map-edit/commands/image-maps.service';
import { QuickEditService } from './commands/quick-edit.service';
import { ShareLinkService } from './commands/share-link.service';
import { InputDialogService } from './dialog/input-dialog.service';
import { MessageBoxService } from './dialog/messagebox.service';
import { MapOperationType } from './map-operations';
import { MapStateService } from './map-state.service';
import { UiConstants } from './ui-constants';

@Injectable({
    providedIn: 'root'
})
export class LinksService implements OnDestroy {

    private openLinkDialogSubscription: Subscription | undefined;
    private fileChoosenSubscription: Subscription | undefined;
    private getLinkPreviewSubscription: Subscription | undefined;
    private askUrlSubscription: Subscription | undefined;
    private findMapLinkInfoSubscription: Subscription | undefined;
    private askNodeChangeSubscription: Subscription | undefined;

    picker: any;

    constructor(
        private mapStateService: MapStateService,
        private smService: SmService,
        private smeService: SmeService,
        private googleService: GoogleService,
        private shareLinkService: ShareLinkService,
        private imageMapsService: ImageMapsService,
        private inputDialogService: InputDialogService,
        private i18nService: I18nService,
        private translateService: TranslateService,
        private quickEditService: QuickEditService,
        private messageBoxService: MessageBoxService
    ) { }


    ngOnDestroy(): void {
        if (this.openLinkDialogSubscription) { this.openLinkDialogSubscription.unsubscribe(); }
        if (this.fileChoosenSubscription) { this.fileChoosenSubscription.unsubscribe(); }
        if (this.getLinkPreviewSubscription) { this.getLinkPreviewSubscription.unsubscribe(); }
        if (this.askUrlSubscription) { this.askUrlSubscription.unsubscribe(); }
        if (this.findMapLinkInfoSubscription) { this.findMapLinkInfoSubscription.unsubscribe(); }
        if (this.askNodeChangeSubscription) { this.askNodeChangeSubscription.unsubscribe(); }
    }

    public editLink() {
        let uri = '';
        if (this.mapStateService.selectionData.element && this.mapStateService.selectionData.element.mainLinkIndex >= 0) {
            uri = this.mapStateService.selectionData.element.links[this.mapStateService.selectionData.element.mainLinkIndex].uri;
            uri = decodeURIComponent(uri);
        }
        const node = this.smService.getSelectedNode();
        this.mapStateService.lockUI(true);
        if (this.openLinkDialogSubscription) { this.openLinkDialogSubscription.unsubscribe(); }
        this.openLinkDialogSubscription = this.openLinkDialog(uri).subscribe((result: any) => {
            if (result) {
                let res = result.name;
                res = res.trim();
                if (res !== '' && !res.startsWith('data:image') && !res.startsWith('http://') && !res.startsWith('https://')) {
                    res = 'https://' + res;
                }
                this.smService.editMainLink(res, node);
                if (this.mapStateService && this.mapStateService.mapOperations && res.includes('youtube') || res.includes('youtu.be')) {
                    this.mapStateService.mapOperations?.add(MapOperationType.STAT_ADDVIDEO);
                } else {
                    this.mapStateService.mapOperations?.add(MapOperationType.STAT_ADDLINK);
                }
                if (!result.isChecked) {
                    if (this.getLinkPreviewSubscription) { this.getLinkPreviewSubscription.unsubscribe(); }
                    this.getLinkPreviewSubscription = this.shareLinkService.getLinkPreview(res).subscribe((data: any) => {
                        const d = data;
                        // const node = this.smService.sm.map.getSelectedNode();
                        if (node && node.video && node.video.preview) {
                            this.mapStateService.dropping = true;
                            if (data.res.title) {
                                const elem = document.createElement('div');
                                elem.innerHTML = UiConstants.getDefaultHtmlForTitle('<span>' + data.res.title + '</span>', true);
                                this.insertHtmlText(elem, node.id);
                            }
                            this.imageMapsService.insertImageFromUri(node.video.preview);
                        } else {
                            if (d.res.title || d.res.description) {
                                if (d.res.description === null) { d.res.description = ''; }
                                const elem = document.createElement('div');
                                elem.innerHTML = UiConstants.getDefaultHtmlForTitle('<span>' + data.res.title + '</span>', true);
                                this.insertHtmlText(elem, node.id);
                            }
                            if (d.res.image) {
                                this.imageMapsService.insertImageFromUri(d.res.image, '', node.id);
                            }
                        }
                    });
                }
            }
            this.mapStateService.forceAutoSave();
            this.mapStateService.lockUI(false);
        });
    }

    editLinkGoogleDrive(type: string) { // type: 'drive' or 'map' or 'video'
        const node = this.smService.getSelectedNode();
        let accessToken = '';
        if (this.fileChoosenSubscription) { this.fileChoosenSubscription.unsubscribe(); }
        this.fileChoosenSubscription = this.googleService.fileChoosen.subscribe((result: any) => {
            const _fileId = result.fileId;
            console.log('Picked ID: ' + _fileId);
            if (_fileId && _fileId !== '') {
                const ext = result.name.split('.').pop();
                const isMap = (ext === 'sme');
                this.checkAskChanges(node, isMap).then((change: boolean) => {
                    if (isMap) {
                        const name = result.name.split('.').slice(0, -1).join('.');
                        if (this.findMapLinkInfoSubscription) { this.findMapLinkInfoSubscription.unsubscribe(); }
                        this.findMapLinkInfoSubscription = this.smeService.findMapLinkInfo(window.origin, _fileId).subscribe((_info: any) => {
                            let info: any = null;
                            if (_info && _info.result) {
                                info = _info.result;
                            }
                            if (info && info.exists) {
                                this.smService.editMainLink(info.url, node, name);
                                this.smeService.setDriveDocumentAsPublic(_fileId, 'view').subscribe(() => {
                                    this.mapStateService.mapOperations?.add(MapOperationType.STAT_ADDLINK);
                                    if (change) {
                                        this.mapStateService.dropping = true;
                                        if (name) {
                                            const elem = document.createElement('div');
                                            elem.innerHTML = UiConstants.getDefaultHtmlForTitle('<span>' + name + '</span>', true);
                                            this.insertHtmlText(elem, node.id);
                                        }
                                        const thumbnailUrl = info.previewUrl;
                                        this.imageMapsService.insertImageFromUri(thumbnailUrl);
                                    }
                                });
                            } else {
                                // Map does not exist in firebase (must be opened at least one time)
                                const title = this.translateService.instant('OOPS');
                                const message = this.translateService.instant('MSG_MAP_DOES_NOT_EXIST');
                                this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK, title, message);
                            }
                        });
                    } else if (type === 'video') {
                        this.smService.editMainLink(result.url, node, result.name);
                        this.mapStateService.mapOperations?.add(MapOperationType.STAT_ADDLINK);
                        if (change) {
                            this.mapStateService.dropping = true;
                            if (result.name) {
                                const elem = document.createElement('div');
                                elem.innerHTML = UiConstants.getDefaultHtmlForTitle('<span>' + result.name + '</span>', true);
                                this.insertHtmlText(elem, node.id);
                            }
                            if (result.imageUrl) {
                                this.imageMapsService.insertImageFromUri(result.imageUrl);
                            }
                        }
                    } else {
                        const res = 'https://drive.google.com/file/d/' + _fileId + '/view?resourcekey=' + result.resourceKey;
                        this.smService.editMainLink(res, node, result.name);
                        this.smeService.setDriveDocumentAsPublic(_fileId, 'view').subscribe(() => {
                            this.mapStateService.mapOperations?.add(MapOperationType.STAT_ADDLINK);
                            if (change) {
                                this.mapStateService.dropping = true;
                                if (result.name) {
                                    const elem = document.createElement('div');
                                    elem.innerHTML = UiConstants.getDefaultHtmlForTitle('<span>' + result.name + '</span>', true);
                                    this.insertHtmlText(elem, node.id);
                                }
                                const thumbnailUrl = 'https://lh3.googleusercontent.com/d/' + _fileId;
                                this.imageMapsService.insertImageFromUri(thumbnailUrl);
                            }
                        });
                    }
                });
            }
            if (this.fileChoosenSubscription) this.fileChoosenSubscription.unsubscribe();
        });
        this.smeService.getAccessTokenPromise()
            .then((_accessToken: string) => {
                accessToken = _accessToken;
                return this.googleService.loadPicker();
            }).then(() => {
                const language = this.i18nService.language;
                if (type === 'drive') {
                    this.googleService.createPicker(accessToken, this.picker, language, false);
                } else if (type === 'map') {
                    this.googleService.createSMEPicker(accessToken, this.picker, language);
                } else if (type === 'video') {
                    let textToSearch = '';
                    if (node && node.titleText) {
                        textToSearch = node.titleText;
                    }
                    this.googleService.createVideoPicker(accessToken, language, textToSearch);
                }
            });
    }

    openLinkDialog(uri: string) {
        return new Observable((observer: Observer<string>) => {
            const title = this.translateService.instant(extract('INSERT_LINK'));
            const message = this.translateService.instant(extract('INPUT_LINK_URL'));
            const checkMessage = this.translateService.instant(extract('INPUT_LINK_URL_PREVIEW'));
            if (this.askUrlSubscription) { this.askUrlSubscription.unsubscribe(); }
            this.mapStateService.setFocusOnMap(false);
            this.mapStateService.setEditingState(true);
            this.smService.setEnableKeyPresses(false);
            this.quickEditService.onCanEdit.emit(false);
            this.askUrlSubscription = this.inputDialogService.askUrl(title, message, uri, this.mapStateService.id, '', checkMessage)
                .subscribe({
                    next: (result) => {
                        // if (result.name === '') {
                        //   result.name = uri;
                        // }
                        observer.next(result);
                        observer.complete();
                        this.mapStateService.setFocusOnMap(true);
                        this.smService.setEnableKeyPresses(true);
                        this.quickEditService.onCanEdit.emit(true);
                    },
                    error: (error) => {
                        observer.next(uri);
                        observer.complete();
                        this.mapStateService.setFocusOnMap(true);
                        this.smService.setEnableKeyPresses(true);
                        this.quickEditService.onCanEdit.emit(true);
                    }
                });
        });
    }

    private insertHtmlText(elem: any, nodeId: string) {
        const imgs = elem.getElementsByTagName('img');
        for (let index = imgs.length - 1; index >= 0; index--) {
            imgs[index].parentNode.removeChild(imgs[index]);
        }
        if (elem.innerHTML !== '') {
            // this.smService.pasteHtmlText(elem.innerHTML);
            this.smService.setNodeTitleCommand(nodeId, elem.innerHTML);
        }
    }

    public deleteMathLink() {
        const node = this.smService.getSelectedNode();
        this.smService.deleteMathLink(node);
        this.smService.updateAllWidgets();
    }

    public deleteLink() {
        const node = this.smService.getSelectedNode();
        this.smService.deleteMainLink(node);
        this.smService.updateAllWidgets();
    }

    private checkAskChanges(node: any, isMap: boolean): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (node.titleText.trim() !== '' || node.image.name !== '') {
                const title = this.translateService.instant('WARNING');
                const message = this.translateService.instant((isMap ? 'MSG_ASK_CHANGE_NODE_MAP' : 'MSG_ASK_CHANGE_NODE_LINK'));
                if (this.askNodeChangeSubscription) { this.askNodeChangeSubscription.unsubscribe(); }
                this.askNodeChangeSubscription = this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.YESNO, title, message).subscribe((result: any) => {
                    // console.log(JSON.stringify(result));
                    if (result === 'no') {
                        return resolve(false);
                    } else {
                        return resolve(true);
                    }
                });
            } else {
                return resolve(true);
            }
        });
    }

}
