export class ResourceUpload {
  $key = '';
  file: File;
  name = '';
  url = '';
  progress = 0;
  createdAt: Date = new Date();

  constructor(file: File) {
    this.file = file;
  }
}
