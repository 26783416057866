import { VirtualKeyboardLayout } from 'mathlive';

export const IT_DISEQ_KEYBOARD: VirtualKeyboardLayout = {
    id: "DISEQUATIONS",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            '[separator-5]'
        ],
        // Second row
        [
            {
                latex: '\\left]\\placeholder{}\\right[',
                tooltip: 'intervallo aperto'
            },
            {
                latex: '\\left(\\placeholder{}\\right)',
                tooltip: 'intervallo aperto'
            },
            {
                latex: '\\left]\\placeholder{}\\right] ',
                tooltip: 'intervallo aperto a sinistra e chiuso a destra'
            },
            {
                latex: '\\left(\\placeholder{}\\right]',
                tooltip: 'intervallo aperto a sinistra e chiuso a destra'
            },
            {
                latex: '\\left[\\placeholder{}\\right[',
                tooltip: 'intervallo chiuso a sinistra e paerto a destra'
            },
            {
                latex: '\\left[\\placeholder{}\\right)',
                tooltip: 'intervallo chiuso a sinistra e paerto a destra'
            },
            {
                latex: '\\left[\\placeholder{}\\right]',
                tooltip: 'intervallo chiuso'
            },
        ],
        // Third row
        [
            {
                latex: '\\infty',
                tooltip: 'infinito'
            },
            {
                latex: '\\epsilon',
                tooltip: 'epsilon'
            }
        ],
        // Fourth row
        [
            '[separator-5]'
        ]
    ]
};
