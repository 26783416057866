import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { GdriveNewComponent } from './gdrive-new.component';
import { AuthenticationGuard } from '../core/authentication/authentication.guard';

const routes: Routes = Route.withShell([
  { path: 'gdrive-new', component: GdriveNewComponent, data: { title: extract('GDRIVE_NEW_TITLE') }, canActivate: [AuthenticationGuard] },
]);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthenticationGuard]
})
export class GdriveNewRoutingModule { }
