import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { WebSearchService } from '../../shared/commands/web-search.service';

@Component({
    selector: 'app-search-web',
    templateUrl: './search-web.component.html',
    styleUrls: ['./search-web.component.scss'],
})
export class SearchWebComponent implements OnInit {
    public searchGoogleImgEnable = false;
    public searchGoogleEnable = false;
    public searchYoutubeEnable = false;
    public searchWikipediaEnable = false;
    public error = '';

    constructor(private webSearchService: WebSearchService) {

    }

    ngOnInit() {
        this.toggleSearchMagic('google-img');
    }

    closeExtra() {
        this.webSearchService.toggleWebSearchPanel();
        this.webSearchService.disableMagicSearch();
    }

    toggleSearchMagic(typeSearch: string) {
        console.log('Toggle search: ' + typeSearch);
        switch (typeSearch) {
            case 'google-img':
                this.searchGoogleImgEnable = !this.searchGoogleImgEnable;
                if (this.searchGoogleImgEnable) {
                    this.searchGoogleEnable = this.searchYoutubeEnable = this.searchWikipediaEnable = false;
                    this.webSearchService.searchOnWeb(typeSearch);
                } else {
                    this.webSearchService.disableMagicSearch();
                }
                break;
            case 'google':
                this.searchGoogleEnable = !this.searchGoogleEnable;
                if (this.searchGoogleEnable) {
                    this.searchGoogleImgEnable = this.searchYoutubeEnable = this.searchWikipediaEnable = false;
                    this.webSearchService.searchOnWeb(typeSearch);
                } else {
                    this.webSearchService.disableMagicSearch();
                }
                break;
            case 'youtube':
                this.searchYoutubeEnable = !this.searchYoutubeEnable;
                if (this.searchYoutubeEnable) {
                    this.searchGoogleEnable = this.searchGoogleImgEnable = this.searchWikipediaEnable = false;
                    this.webSearchService.searchOnWeb(typeSearch);
                } else {
                    this.webSearchService.disableMagicSearch();
                }
                break;
            case 'wikipedia':
                this.searchWikipediaEnable = !this.searchWikipediaEnable;
                if (this.searchWikipediaEnable) {
                    this.searchGoogleEnable = this.searchYoutubeEnable = this.searchGoogleImgEnable = false;
                    this.webSearchService.searchOnWeb(typeSearch);
                } else {
                    this.webSearchService.disableMagicSearch();
                }
                break;
        }

    }
}
