import { Directive, HostListener, OnInit, OnDestroy } from '@angular/core';
import { MatDialogContainer, MatDialogRef } from '@angular/material/dialog';
import { Subscription, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Position } from './modal-position.cache';

/* tslint:disable */
@Directive({
  selector: '[mdc-dialog-draggable-title]'
})
/* tslint:enable */
export class DialogDraggableTitleDirective implements OnInit, OnDestroy {

  private _subscription: Subscription | undefined;

  mouseStart: Position | undefined;
  mouseDelta: Position | undefined;
  offset: Position | undefined;

  constructor(
    private matDialogRef: MatDialogRef<any>,
    private container: MatDialogContainer) {
  }

  ngOnInit() {
    const dialogType = this.matDialogRef.componentInstance.constructor;
    this.offset = this._getOffset();
  }

  ngOnDestroy() {
    if (this._subscription) { this._subscription.unsubscribe(); }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.mouseStart = { x: event.pageX, y: event.pageY };

    const mouseup$ = fromEvent(document, 'mouseup');
    this._subscription = mouseup$.subscribe(() => this.onMouseup());

    const mousemove$ = fromEvent(document, 'mousemove')
      .pipe(takeUntil(mouseup$))
      .subscribe((e: Event) => this.onMouseMove(e as MouseEvent));
    this._subscription.add(mousemove$);
  }

  onMouseMove(event: MouseEvent) {
    if (this.mouseStart && this.offset) {
      this.mouseDelta = { x: (event.pageX - this.mouseStart.x), y: (event.pageY - this.mouseStart.y) };

      this._updatePosition(this.offset.y + this.mouseDelta.y, this.offset.x + this.mouseDelta.x);
    }
  }

  onMouseup() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = undefined;
    }
    if (this.mouseDelta && this.offset) {
      this.offset.x += this.mouseDelta.x;
      this.offset.y += this.mouseDelta.y;
    }
  }

  private _updatePosition(top: number, left: number) {
    this.matDialogRef.updatePosition({
      top: top + 'px',
      left: left + 'px'
    });
  }

  private _getOffset(): Position {
    const box = this.container['_elementRef'].nativeElement.getBoundingClientRect();
    return {
      x: box.left + pageXOffset,
      y: box.top + pageYOffset
    };
  }
}
