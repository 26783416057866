import { UserStatsDto } from './user-stats-dto';

export class YearUserStatsDto {

  private year: number | undefined;
  private months: Array<UserStatsDto> | undefined;

  public init(schoolYear: number) {
    this.year = schoolYear;
    // Months: from schoolYear/09 to schoolYear+1/08
    this.months = [];
    for (let i = 0; i < 12; i++) {
      this.months.push(new UserStatsDto());
    }
  }

  public addStat(year: number, month: number, id: string, qty: number) {
    if (this.months) {
      const i = this.getIndexForMonth(year, month);
      if (i >= 0 && i < this.months.length) {
        const userStats = this.months?.[i];
        userStats.addStat(id, qty);
      }
    }
  }

  public getMonthsData(id: string) {
    const res = [];
    if (this.months) {
      for (let i = 0; i < this.months.length; i++) {
        const month = this.months[i];
        const qty = month.getStat(id);
        res.push(qty);
      }
    }
    return res;
  }

  public getMonthQty(i: number, id: string) {
    let qty = 0;
    if (this.months) {
      if (i > 0 && i < this.months.length) {
        const monthData = this.months[i];
        qty = monthData.getStat(id);
      }
    }
    return qty;
  }

  private getIndexForMonth(year: number, month: number): number {
    let i = -1;
    if (this.year) {
      if (year === this.year && month >= 9 && month <= 12) {
        i = month - 9;
      } else if (year === this.year + 1 && month >= 1 && month <= 8) {
        i = month + 3;
      } else {
        console.log('Date out of bounds for year/month: ' + year + '/' + month);
      }
    }
    return i;
  }

}
