import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
//// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { AboutRoutingModule } from './about-routing.module';
import { AboutComponent } from './about.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { AboutService } from './about.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        //  //FlexLayoutModule,
        MaterialModule,
        CoreModule,
        SharedModule,
        AboutRoutingModule
    ],
    providers: [
        AboutService
    ],
    declarations: [
        AboutComponent
    ]
})
export class AboutModule { }
