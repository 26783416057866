import { Unsubscribable } from 'rxjs';
import { UserDto } from './user-dto';

export class AccessDto {

  name: string;
  admin: UserDto;
  domain: string;
  users: UserDto[];
  allocatedSlots: number;
  constructor(_accessList: any = null) {
    this.name = '';
    this.admin = new UserDto(null);
    this.domain = '';
    this.users = [];
    this.allocatedSlots = 0;

    if (_accessList) {
      if (_accessList.name) {
        this.name = _accessList.name;
      }
      if (_accessList.admin) {
        this.admin = new UserDto(_accessList.admin);
      }
      if (_accessList.domain) {
        this.domain = _accessList.domain;
      }
      if (_accessList.users) {
        _accessList.users.forEach((el: any) => {
          this.users.push(el);
        });
      }
      if (_accessList.allocatedSlots) {
        this.allocatedSlots = _accessList.allocatedSlots;
      }
    }
  }

  public static removePointFromEmail(email: string): string {
    const pos = email.indexOf('@');
    const re = /\./gi;
    let result = email.substring(0, pos).replace(re, '');
    result = result + email.substring(pos);
    return result;
  }

  public static getNormalizedEmail(email: string): string {
    const s = this.removePointFromEmail(email);
    const safe = s.replace(/[^a-zA-Z0-9@|]/g, '-');
    return safe;
  }

}
