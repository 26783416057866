<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="'PLEASE_WAIT'" [versioned]="true"></app-loader>

<div id="div-stamp-image" class="stamp-image"></div>
<div id="div-map-qrcode" class="stamp-image"></div>
<div id="div-map-qrcode-symbol" class="stamp-image"></div>
<div id="div-gdoc-qrcode-symbol" class="stamp-image"></div>
<div id="div-gdoc-qrcode" class="stamp-image"></div>

<div class="dim-screen">
    <img [src]="imageBase64" class="print-map" (load)="printImage()" />
</div>

<div *ngIf="!error" class="main-content">
    <div class="header-container">
        <div class="header-smx-logo-container">
            <img *ngIf="!compressLogo" class="header-smx-logo" src="{{logoUrlExtended}}?v=1" />
            <img *ngIf="compressLogo" class="header-smx-logo" src="{{logoUrlCompressed}}?v=1" />
        </div>
        <div class="header-map-title-container">
            <div class="map-name">{{mapName}}</div>
            <button *ngIf="!canEdit" style="flex-shrink: 0;" mat-icon-button color="accent" [matMenuTriggerFor]="viewOnlyMenuMessage">
                <mat-icon color="accent" fontSet="material-icons-outlined">visibility</mat-icon>
                <!--<span translate>MAP_VIEW_VIEW_BUTTON</span>-->

            </button>
            <mat-menu #viewOnlyMenuMessage="matMenu">
                <ng-template matMenuContent>
                    <div class="map-view-button-message" translate>MAP_VIEW_VIEW_BUTTON_MESSAGE</div>
                </ng-template>
            </mat-menu>
        </div>
        <div class="toolbar-right">
            <button *ngIf="isRenewVisible" mat-flat-button color="warn" (click)="renewSubscription()">{{'ACCESSCONFIG_RENEW_SUBSCRIPTION' | translate}}</button>
            <app-users-toolbar></app-users-toolbar>
            <app-view-toolbar [mapId]="mapStateService.id" [isLoading]="isLoading" (startLoading)="startLoading()" (endLoading)="endLoading()" [viewToolbarCompressed]="compressHeaderMenu"></app-view-toolbar>
        </div>
    </div>
    <div class="container-map">
        <div class="sm" [ngClass]="{'sm-loading':isLoading}">
            <canvas id="sm-canvas-grid" class="sm-canvas sm-canvas-grid direct"></canvas>
            <div #mapcontainer class="sm-canvas-base-container">
                <canvas id="sm-canvas-base" class="sm-canvas-base direct" [ngClass]="{'interaction-mode-readonly': isReadonlyMode}"></canvas>
            </div>
            <canvas id="sm-canvas-over" class="sm-canvas sm-canvas-over direct"></canvas>
            <canvas id="sm-canvas-save" class="sm-canvas"></canvas>
            <div id="sm-tmp" class="hidden"></div>
            <app-real-time-markers></app-real-time-markers>
        </div>
        <app-map-find id="mapSearchPanel" #mapSearchPanel *ngIf="mapSearchVisible" class="item-mapsearch" cdkDrag cdkDragBoundary=".container-map" [cdkDragFreeDragPosition]="mapSearchDefaultPosition" [hidden]="!mapSearchVisible"></app-map-find>
    </div>
    <app-links-menu *ngIf="contextItems.length>0" [items]="contextItems"></app-links-menu>
</div>
<div *ngIf="error" class="container mat-typography">
    <mat-card appearance="outlined" class="message-container">
        <div class="message-title" translate>WARNING</div>
        <div class="message-text">{{error}}</div>
        <div class="ok-button" (click)="hideErrorGlobal()" translate>BUTTON_OK</div>
    </mat-card>
</div>
