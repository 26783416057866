import { VirtualKeyboardLayout } from 'mathlive';

export const IT_LOG_EXP_KEYBOARD: VirtualKeyboardLayout = {
    id: "LOG_EXP",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            '[separator-5]'
        ],
        // Second row
        [
            {
                latex: '\\log_{\\placeholder{}}\\left(\\placeholder{}\\right)',
                tooltip: 'logaritmo in base n'
            },
            {
                latex: '\\log\\left(\\placeholder{}\\right)',
                tooltip: 'logaritmo'
            },
            {
                latex: '\\log_{10}\\left(\\placeholder{}\\right)',
                tooltip: 'logaritmo in base 10'
            },
            {
                latex: '\\ln\\left(\\placeholder{}\\right)',
                tooltip: 'logaritmo naturale'
            }
        ],
        // Third row
        [
            {
                latex: '10^{\\placeholder{}}',
                tooltip: 'dieci elevato alla'
            },
            {
                latex: '{e}^{{x}}',
                tooltip: 'funzione esponenziale'
            }
        ],
        // Fourth row
        [
            '[separator-5]'
        ]
    ]
};
