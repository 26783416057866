import { VirtualKeyboardLayout } from 'mathlive';

/*
tooltip: '{ "noshift": {"it": "", "en": "" }, "shift": {"it": "", "en": "" } }'
*/

export const IT_BASIC_KEYBOARD: VirtualKeyboardLayout = {
    id: "BASIC",
    labelClass: 'MLK__tex',
    rows: [
        // First row
        [
            {
                key: '(',
                label: '(',
                shift: { latex: 'x', tooltip: 'x' },
                tooltip: 'aperta tonda'
            },
            {
                key: ')',
                label: ')',
                shift: { latex: 'n', tooltip: 'n' },
                tooltip: 'chiusa tonda'
            },
            '[separator-5]',
            {
                class: 'hide-shift',
                key: '7',
                label: '7',
                shift: { latex: '#@^7', tooltip: 'elevato alla settima' },
                tooltip: '7'
            },
            {
                class: 'hide-shift',
                key: '8',
                label: '8',
                shift: { latex: '#@^8', tooltip: 'elevato all\'ottava' },
                tooltip: '8'
            },
            {
                class: 'hide-shift',
                key: '9',
                label: '9',
                shift: { latex: '#@^9', tooltip: 'elevato alla nona' },
                tooltip: '9'
            },
            {
                class: 'hide-shift',
                latex: ':',
                label: ':',
                shift: { label: '/', latex: '/', tooltip: 'diviso' },
                tooltip: 'diviso'
            },
            '[separator-5]',
            {
                latex: '\\pi',
                shift: { latex: '\\overline{\\placeholder{}}', tooltip: 'barra superiore' },
                tooltip: 'pigreco'
            },
            {
                latex: '\\degree',
                shift: { latex: '\\left\\vert\\placeholder{}\\right\\vert', tooltip: 'valore assoluto' },
                tooltip: 'gradi'
            },
            {
                latex: '\\%',
                shift: { latex: '\\overrightarrow{\\placeholder{}}', tooltip: 'vettore' },
                tooltip: 'percento'
            },
        ],
        // Second row
        [
            {
                key: '[',
                label: '[',
                shift: { label: 'y', tooltip: 'y' },
                tooltip: 'aperta quadra'
            },
            {
                key: ']',
                label: ']',
                shift: { label: 'a', tooltip: 'a' },
                tooltip: 'chiusa quadra'
            },
            '[separator-5]',
            {
                class: 'hide-shift',
                key: '4',
                label: '4',
                shift: { latex: '#@^4', tooltip: 'elevato alla quarta' },
                tooltip: '4'
            },
            {
                class: 'hide-shift',
                key: '5',
                label: '5',
                shift: { latex: '#@^5', tooltip: 'elevato alla quinta' },
                tooltip: '5'
            },
            {
                class: 'hide-shift',
                key: '6',
                label: '6',
                shift: { latex: '#@^6', tooltip: 'elevato alla sesta' },
                tooltip: '6'
            },
            {
                class: '',
                latex: '\\cdot',
                shift: { latex: '\\times', tooltip: 'per' },
                tooltip: 'per'
            },
            '[separator-5]',
            {
                class: 'hide-shift small',
                latex: '\\frac{\\placeholder{}}{\\placeholder{}}',
                shift: { latex: '\\frac{1}{\\placeholder{}}', tooltip: '1 fratto' },
                tooltip: 'frazione'
            },
            {
                latex: '#@^{#0}}',
                class: 'hide-shift',
                shift: { latex: '#@_{#?}', tooltip: 'pedice' },
                tooltip: 'apice'
            },
            {
                class: 'hide-shift',
                latex: '\\sqrt{#0}',
                shift: { latex: '\\sqrt[#0]{#?}}', tooltip: 'radice n-esima' },
                tooltip: 'radice quadrata'
            },
        ],
        // Third row
        [
            {
                key: '{',
                label: '{',
                shift: { label: 'z', tooltip: 'z' },
                tooltip: 'aperta graffa'
            },
            {
                key: '}',
                label: '}',
                shift: { label: 'b', tooltip: 'b' },
                tooltip: 'chiusa graffa'
            },
            '[separator-5]',
            {
                class: 'hide-shift',
                key: '1',
                label: '1',
                shift: { latex: '#@^{-1}', tooltip: 'elevato alla meno uno' },
                tooltip: '1'
            },
            {
                class: 'hide-shift',
                key: '2',
                label: '2',
                shift: { latex: '#@^2', tooltip: 'al quadrato' },
                tooltip: '2'
            },
            {
                class: 'hide-shift',
                key: '3',
                label: '3',
                shift: { latex: '#@^3', tooltip: 'al cubo' },
                tooltip: '3'
            },
            {
                class: 'hide-shift',
                key: '-',
                label: '-',
                shift: { latex: '\\pm', tooltip: 'più o meno' },
                tooltip: 'meno'
            },
            '[separator-5]',
            {
                label: '<',
                latex: '<',
                class: 'hide-shift',
                shift: { latex: '\\le', label: '≤', tooltip: 'minore o uguale a' },
                tooltip: 'minore di'
            },
            {
                label: '>',
                latex: '>',
                class: 'hide-shift',
                shift: { latex: '\\ge', label: '≥', tooltip: 'maggiore o uguale a' },
                tooltip: 'maggiore di'
            },
            {
                label: '[backspace]',
                width: 1.0,
                tooltip: 'cancella'
            },
        ],
        // Fourth row
        [
            {
                label: '[shift]',
                width: 2.0,
                tooltip: 'shift'
            },
            '[separator-5]',
            {
                class: 'hide-shift',
                key: '0',
                label: '0',
                shift: { latex: '\\infty', tooltip: 'infinito' },
                tooltip: '0'
            },
            {
                class: 'hide-shift',
                key: ',',
                label: ',',
                shift: { latex: '.', tooltip: 'punto' },
                tooltip: 'virgola'
            },
            {
                class: 'hide-shift',
                key: '=',
                label: '=',
                shift: { latex: '≠', tooltip: 'diverso da' },
                tooltip: 'uguale'
            },
            {
                class: 'hide-shift',
                key: '+',
                label: '+',
                shift: { latex: '\\sum', tooltip: 'sommatoria' },
                tooltip: 'più'
            },
            '[separator-5]',
            {
                label: '[left]',
                tooltip: 'sinistra'
            },
            {
                label: '[right]',
                tooltip: 'destra'
            },
            {
                class: "svg-glyph",
                label: "<svg class='svg-glyph'><use xlink:href='#svg-commit' /></svg>",
                command: ["performWithFeedback", "commit"],
                tooltip: 'conferma'
            }
        ]
    ]
};
