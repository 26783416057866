<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<div class="main-content">
    <div class="access-title">
        <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
            <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
        </a><br />
        <span class="access-subtitle">
            {{'REPORT_STATS' | translate}} {{user}}
        </span>
    </div>

    <div class="user-stats">
        <div class="created-maps">{{'REPORT_CREATEDMAPS1' | translate}} {{allData.getStat('STAT_NEWMAP')}} {{'REPORT_CREATEDMAPS2' | translate}} </div>
        <div class="community"></div>
        <div class="detail">
            <span>{{'SCHOOL_YEAR' | translate}}</span>
            <span>
                <select type="number" [(ngModel)]="selectedYear" (change)="selectedYearChanged($event)">
                    <option *ngFor="let year of years" [ngValue]="year">{{year.name}}</option>
                </select>
            </span>
        </div>

        <hr />

        <div class="stat-title">{{'REPORT_NEWMAPS' | translate}}</div>

        <div class="stats-insert">
            <table class="stats-table">
                <tr>
                    <td>
                        <div><img class="icon" src="assets/stats/mappa_touch.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/mappa_touch.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/nodi.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/tabelle.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/parole.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/immagini.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/youtube_touch.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/link_on_touch.png?v=2" /></div>
                    </td>
                </tr>
                <tr>
                    <td (click)="showDetailChart('STAT_NEWMAP')" [ngClass]="{'selected':selectedMapStat==='STAT_NEWMAP'}">
                        <span>{{allData.getStat('STAT_NEWMAP')}}<br />{{'REPORT_NEWMAPS' | translate}}</span>
                    </td>
                    <td (click)="showDetailChart('STAT_OPENMAP')" [ngClass]="{'selected':selectedMapStat==='STAT_OPENMAP'}">
                        <span>{{allData.getStat('STAT_OPENMAP')}}<br />{{'REPORT_OPENMAPS' | translate}}</span>
                    </td>
                    <td (click)="showDetailChart('STAT_ADDNODE')" [ngClass]="{'selected':selectedMapStat==='STAT_ADDNODE'}">
                        <span>{{allData.getStat('STAT_ADDNODE')}}<br />{{'REPORT_NODES' | translate}}</span>
                    </td>
                    <td (click)="showDetailChart('STAT_ADDTABLE')" [ngClass]="{'selected':selectedMapStat==='STAT_ADDTABLE'}">
                        <span>{{allData.getStat('STAT_ADDTABLE')}}<br />{{'REPORT_TABLES' | translate}}</span>
                    </td>
                    <td (click)="showDetailChart('STAT_WORDS')" [ngClass]="{'selected':selectedMapStat==='STAT_WORDS'}">
                        <span>{{allData.getStat('STAT_WORDS')}}<br />{{'REPORT_WORDS' | translate}}</span>
                    </td>
                    <td (click)="showDetailChart('STAT_ADDIMAGE')" [ngClass]="{'selected':selectedMapStat==='STAT_ADDIMAGE'}">
                        <span>{{allData.getStat('STAT_ADDIMAGE')}}<br />{{'REPORT_IMAGES' | translate}}</span>
                    </td>
                    <td (click)="showDetailChart('STAT_ADDVIDEO')" [ngClass]="{'selected':selectedMapStat==='STAT_ADDVIDEO'}">
                        <span>{{allData.getStat('STAT_ADDVIDEO')}}<br />{{'REPORT_VIDEOS' | translate}}</span>
                    </td>
                    <td (click)="showDetailChart('STAT_ADDLINK')" [ngClass]="{'selected':selectedMapStat==='STAT_ADDLINK'}">
                        <span>{{allData.getStat('STAT_ADDLINK')}}<br />{{'REPORT_LINKS' | translate}}</span>
                    </td>
                </tr>
            </table>
        </div>

        <!-- GRAFICO STATISTICHE MAPPA -->
        <div class="chart-title">{{selectedMapStat | translate}}</div>
        <div class="months-chart">
            <table class="m-graph">
                <thead>
                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="qtr m1">
                        <th scope="row">{{'REPORT_SET' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[0]/tickDetailMultiplier*40}">
                            <p>{{monthStats[0]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m1">
                        <th scope="row">{{'REPORT_SET' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[0]/tickDetailMultiplier*40}">
                            <p>{{monthStats[0]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m2">
                        <th scope="row">{{'REPORT_OTT' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[1]/tickDetailMultiplier*40}">
                            <p>{{monthStats[1]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m3">
                        <th scope="row">{{'REPORT_NOV' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[2]/tickDetailMultiplier*40}">
                            <p>{{monthStats[2]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m4">
                        <th scope="row">{{'REPORT_DIC' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[3]/tickDetailMultiplier*40}">
                            <p>{{monthStats[3]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m5">
                        <th scope="row">{{'REPORT_GEN' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[4]/tickDetailMultiplier*40}">
                            <p>{{monthStats[4]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m6">
                        <th scope="row">{{'REPORT_FEB' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[5]/tickDetailMultiplier*40}">
                            <p>{{monthStats[5]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m7">
                        <th scope="row">{{'REPORT_MAR' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[6]/tickDetailMultiplier*40}">
                            <p>{{monthStats[6]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m8">
                        <th scope="row">{{'REPORT_APR' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[7]/tickDetailMultiplier*40}">
                            <p>{{monthStats[7]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m9">
                        <th scope="row">{{'REPORT_MAG' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[8]/tickDetailMultiplier*40}">
                            <p>{{monthStats[8]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m10">
                        <th scope="row">{{'REPORT_GIU' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[9]/tickDetailMultiplier*40}">
                            <p>{{monthStats[9]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m11">
                        <th scope="row">{{'REPORT_LUG' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[10]/tickDetailMultiplier*40}">
                            <p>{{monthStats[10]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m12">
                        <th scope="row">{{'REPORT_AGO' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': monthStats[11]/tickDetailMultiplier*40}">
                            <p>{{monthStats[11]}}</p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="ticks">
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickDetailMultiplier*6}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickDetailMultiplier*5}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickDetailMultiplier*4}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickDetailMultiplier*3}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickDetailMultiplier*2}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickDetailMultiplier}}</p>
                </div>
            </div>
        </div>

        <div class="stat-subtitle">{{'REPORT_DONE' | translate}}</div>
        <div class="stats-export">
            <table class="stats-table">
                <tr>
                    <td>
                        <div><img class="icon" src="assets/stats/google_doc.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/stampe.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/presentazioni.png?v=2" /></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>{{allData.getStat('STAT_GDOCCREATED')}}<br />{{'REPORT_GOOGLEDOCS2' | translate}}</span>
                    </td>
                    <td>
                        <span>{{allData.getStat('STAT_PRINT')}}<br />{{'REPORT_PRINTS2' | translate}}</span>
                    </td>
                    <td>
                        <span>{{allData.getStat('STAT_SHOW')}}<br />{{'REPORT_SHOW2' | translate}}</span>
                    </td>
                </tr>
            </table>
        </div>

        <hr />
        <div class="stat-title">{{'REPORT_SHARES' | translate}}</div>
        <div class="stats-share">
            <table class="stats-table">
                <tr>
                    <td>
                        <div><img class="icon" src="assets/stats/condivise.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/classroom.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/facebook.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/visualizza.png?v=2" /></div>
                    </td>
                    <td>
                        <div><img class="icon" src="assets/stats/modifica.png?v=2" /></div>
                    </td>
                </tr>
                <tr>
                    <td (click)="showShareChart('STAT_SHARELINK')" [ngClass]="{'selected':selectedShareStat==='STAT_SHARELINK'}">
                        <span>{{allData.getStat('STAT_SHARELINK')}}<br />{{'REPORT_LINK_QRCODE' | translate}}</span>
                    </td>
                    <td (click)="showShareChart('STAT_SHARECLASSROOM')" [ngClass]="{'selected':selectedShareStat==='STAT_SHARECLASSROOM'}">
                        <span>{{allData.getStat('STAT_SHARECLASSROOM')}}<br />{{'REPORT_CLASSROOM' | translate}}</span>
                    </td>
                    <td (click)="showShareChart('STAT_SHAREFB')" [ngClass]="{'selected':selectedShareStat==='STAT_SHAREFB'}">
                        <span>{{allData.getStat('STAT_SHAREFB')}}<br />{{'REPORT_FACEBOOK' | translate}}</span>
                    </td>
                    <td (click)="showShareChart('STAT_VIEWSHARED')" [ngClass]="{'selected':selectedShareStat==='STAT_VIEWSHARED'}">
                        <span>{{allData.getStat('STAT_VIEWSHARED')}}<br />{{'REPORT_VIEWS' | translate}}</span>
                    </td>
                    <td (click)="showShareChart('STAT_COPYSHARED')" [ngClass]="{'selected':selectedShareStat==='STAT_COPYSHARED'}">
                        <span>{{allData.getStat('STAT_COPYSHARED')}}<br />{{'REPORT_COPIES' | translate}}</span>
                    </td>
                </tr>
            </table>
        </div>

        <!-- GRAFICO STATISTICHE CONDIVISIONE -->
        <div class="chart-title">{{selectedShareStat | translate}}</div>
        <div class="months-chart">
            <table class="m-graph">
                <thead>
                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="qtr m1">
                        <th scope="row">{{'REPORT_SET' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': shareStats[0]/tickShareMultiplier*40}">
                            <p>{{shareStats[0]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m2">
                        <th scope="row">{{'REPORT_OTT' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': shareStats[1]/tickShareMultiplier*40}">
                            <p>{{shareStats[1]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m3">
                        <th scope="row">{{'REPORT_NOV' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': shareStats[2]/tickShareMultiplier*40}">
                            <p>{{shareStats[2]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m4">
                        <th scope="row">{{'REPORT_DIC' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': shareStats[3]/tickShareMultiplier*40}">
                            <p>{{shareStats[3]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m5">
                        <th scope="row">{{'REPORT_GEN' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': shareStats[4]/tickShareMultiplier*40}">
                            <p>{{shareStats[4]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m6">
                        <th scope="row">{{'REPORT_FEB' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': shareStats[5]/tickShareMultiplier*40}">
                            <p>{{shareStats[5]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m7">
                        <th scope="row">{{'REPORT_MAR' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': shareStats[6]/tickShareMultiplier*40}">
                            <p>{{shareStats[6]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m8">
                        <th scope="row">{{'REPORT_APR' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': shareStats[7]/tickShareMultiplier*40}">
                            <p>{{shareStats[7]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m9">
                        <th scope="row">{{'REPORT_MAG' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': shareStats[8]/tickShareMultiplier*40}">
                            <p>{{shareStats[8]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m10">
                        <th scope="row">{{'REPORT_GIU' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': shareStats[9]/tickShareMultiplier*40}">
                            <p>{{shareStats[9]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m11">
                        <th scope="row">{{'REPORT_LUG' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': shareStats[10]/tickShareMultiplier*40}">
                            <p>{{shareStats[10]}}</p>
                        </td>
                    </tr>
                    <tr class="qtr m12">
                        <th scope="row">{{'REPORT_AGO' | translate}}</th>
                        <td class="created bar" [ngStyle]="{'height.px': shareStats[11]/tickShareMultiplier*40}">
                            <p>{{shareStats[11]}}</p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="ticks">
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickShareMultiplier*6}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickShareMultiplier*5}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickShareMultiplier*4}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickShareMultiplier*3}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickShareMultiplier*2}}</p>
                </div>
                <div class="tick" [ngStyle]="{'height': '40px'}">
                    <p>{{tickShareMultiplier}}</p>
                </div>
            </div>
        </div>

        <!-- <div class="stat-subtitle">{{'REPORT_SOCIALS' | translate}}</div>
    <div class="stats-sharing">
      <table class="stats-table">
        <tr>
          <td>
            <div><img class="icon" src="assets/stats/classroom.png?v=2" /></div>
          </td>
          <td>
            <div><img class="icon" src="assets/stats/facebook.png?v=2" /></div>
          </td>
          <td>
            <div><img class="icon" src="assets/stats/link_on_touch.png?v=2" /></div>
          </td>
        </tr>
        <tr>
          <td>
            <span>{{allData.getStat('STAT_SHARECLASSROOM')}}<br />{{'REPORT_CLASSROOM' | translate}}</span>
          </td>
          <td>
            <span>{{allData.getStat('STAT_SHAREFB')}}<br />{{'REPORT_FACEBOOK' | translate}}</span>
          </td>
          <td>
            <span>{{allData.getStat('STAT_SHARELINK')}}<br />{{'REPORT_LINK' | translate}}</span>
          </td>
        </tr>
      </table>
    </div> -->

        <hr />
        <div class="stat-subtitle">{{'REPORT' | translate}}</div>

        <div class="stats-schools">
            <div *ngFor="let s of subscriptions">
                <div *ngIf="s.order.licenseType === 'd'">
                    <button mat-button class="stat-button stat-domain-button" (click)="showMultiStats(s.order.orderId)">
                        <span>{{s.access.name}} ({{s.access.domain}})</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="stats-classrooms">
            <div *ngFor="let s of subscriptions">
                <div *ngIf="s.order.licenseType === 'm'">
                    <button mat-button class="stat-button stat-multi-button" (click)="showMultiStats(s.order.orderId)">
                        <span>{{s.access.name}}</span>
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="role==='admin'" class="stats-admin">
            <button mat-button class="stat-button stat-admin-button" (click)="showAdminStats()">
                <span>Statistiche Amministratore</span>
            </button>
        </div>
    </div>
</div>
