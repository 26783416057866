import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { MathfieldElement, VirtualKeyboardLayout } from 'mathlive';
import { Subscription } from 'rxjs';
import { MathMLToLaTeX } from 'mathml-to-latex';

import { QuickEditService } from './quick-edit.service';
import { ImageMapsService } from 'src/app/map-edit/commands/image-maps.service';
import { SmeService } from 'src/app/core/sme/sme.service';
import { SmService } from 'supermappe-core';
import { MathReaderItService } from './math-reader/math-reader-it.service';
import { MathReaderEnService } from './math-reader/math-reader-en.service';

@Injectable({
    providedIn: 'root'
})
export class MathService implements OnDestroy {

    public mathToggleRequest = new EventEmitter<boolean>();
    public onErrorImageEmitter = new EventEmitter();
    public onSetLatexInEditor = new EventEmitter();

    private isMathEnabled: boolean = false;
    public isMathOpen: boolean = false;
    public isMathOpening: boolean = false;

    public startLatex: string = '';

    private latex2ImageSubscription: Subscription | undefined;

    constructor(
        private quickEditService: QuickEditService,
        private smService: SmService,
        private smeService: SmeService,
        private imageMapsService: ImageMapsService,
        private mathReaderItService: MathReaderItService,
        private mathReaderEnService: MathReaderEnService
    ) {
        // Compute Engine
        // const ce = (window as any).ce;
        (window as any).mathService = this;
    }

    ngOnDestroy(): void {
        if (this.latex2ImageSubscription) this.latex2ImageSubscription.unsubscribe();
    }

    init(isMathEnabled: boolean): void {
        this.isMathEnabled = isMathEnabled;
    }

    //Toggle apertura editor matematica
    public toggleMath() {
        if (this.isMathEnabled) {
            this.openMath(!this.isMathOpen);
        }
    }

    //Apri o chiudi editor matematica
    public openMath(open: boolean) {
        if (this.isMathEnabled) {
            if (open) {
                this.quickEditService.disableAll;
                this.smService.setEnableKeyPresses(false);
            } else {
                this.smService.setEnableKeyPresses(true);
            }
            if (open) {
                if (open) {
                    this.isMathOpening = true;
                    setTimeout(() => {
                        this.isMathOpening = false;
                    }, 250);
                }
            }
            this.isMathOpen = open;
            this.mathToggleRequest.emit(this.isMathOpen);
        }
    }

    //Recupera il mathfield
    public getMathFieldElement(): MathfieldElement | null {
        if (this.isMathEnabled) {
            const mfe = document.getElementById('mathfield') as MathfieldElement;
            return mfe;
        } else {
            return null;
        }
    }

    //Apre l'editor di matematica a partire dal nodo selezionato
    public editMath() {
        if (this.isMathEnabled) {
            let latex: string | null = '';
            const node = this.smService.getSelectedNode();
            if (node) {
                latex = this.getLatexFromNode(node);
            }
            if (latex != null) {
                this.setLatexInEditor(latex);
            }
        }
    }

    //Ritorna il latex di un nodo
    public getLatexFromNode(node: any): string | null {
        if (!node) return null;
        let latex = '';
        if (this.isMathEnabled) {
            const mathLinkIndex = node.getMathLinkIndex();
            if (mathLinkIndex >= 0 && mathLinkIndex < node.links.length) {
                const mathLink = node.links[mathLinkIndex];
                if (mathLink) {
                    latex = mathLink.uri;
                    latex = latex.trim();
                }
            }
        }
        return latex;
    }

    //Ritorna il MathML di un nodo
    private getMathMLFromNode(node: any): string | null {
        if (!node) return null;
        let latex = '';
        if (this.isMathEnabled) {
            const mathLinkIndex = node.getMathLinkIndex();
            if (mathLinkIndex >= 0 && mathLinkIndex < node.links.length) {
                const mathLink = node.links[mathLinkIndex];
                if (mathLink) {
                    latex = mathLink.uri;
                    latex = latex.trim();
                    const mfe = this.getMathFieldElement();
                    // mfe?.setValue(latex, { silenceNotifications: true });
                    // mfe.latexToMathML(latex);
                }
            }
        }
        return latex;
    }

    //Apre editor di matematica con il latex come parametro
    public setLatexInEditor(latex: string, openKeyboard: boolean = true) {
        if (this.isMathEnabled) {
            if (!this.isMathOpen) {
                this.toggleMath();
            }
            setTimeout(() => {
                const mfe = this.getMathFieldElement();
                mfe?.setValue(latex, { silenceNotifications: true });
                this.startLatex = latex;
                this.onSetLatexInEditor.emit(openKeyboard);
            }, 100);
        }
    }

    //Committa il contenuto dell'editor a un elemento oppure ne crea uno nuovo con quel contenuto
    public commitEdit(closeMathEditor: boolean) {
        if (this.isMathEnabled) {
            const mfe = this.getMathFieldElement();
            const node = this.smService.getSelectedNode();
            this.commitEditToNode(node, closeMathEditor);
        }
    }

    //Commit del contenuto dell'editor di matematica ad un nodo
    private commitEditToNode(editNode: any, closeMathEditor: boolean) {
        if (this.isMathEnabled) {
            const mfe = this.getMathFieldElement();
            if (!mfe) {
                console.log("++Commit math editor to node error: mathfield null");
                return;
            }
            if (!editNode) {
                console.log('+++LATEX CHANGED');
                this.smService.addNodeAndSelectIfNoSelection('');
                editNode = this.smService.getSelectedNode();
            }
            if (editNode) {
                this.smService.setImagePlaceholderToNodeId(editNode.id, true);
                const latex = mfe.value.trim();
                const mathML = mfe.getValue('math-ml');
                if (latex !== '') {
                    if (latex !== this.getLatexFromNode(editNode)) {
                        console.log('+++LATEX CHANGED');
                        const latexMJ = this.getMathJaxLatex(mathML);
                        // if (latexMJ !== '') {
                        this.latex2ImageSubscription = this.smeService.latex2image(latex, latexMJ).subscribe({
                            next: (response: any) => {
                                const dataUrl = response.result;
                                if (dataUrl) {
                                    console.log(dataUrl)
                                    this.smService.setLatexToNode(editNode.id, latex);
                                    this.imageMapsService.insertImageFromSvg(dataUrl, editNode.id);
                                    if (closeMathEditor) this.openMath(false);
                                } else {
                                    if (closeMathEditor) this.openMath(false);
                                }
                            },
                            error: (error: any) => {
                                this.smService.setImagePlaceholderToNodeId(editNode.id, false);
                                console.error(JSON.stringify(error));
                                const errMsg = error.toString();
                                const check = 'Error: TeX parse error: Undefined control sequence ';
                                if (errMsg.startsWith(check)) {
                                    const errItem = errMsg.substring(check.length).replace(/\\\\/g, '\\');
                                    console.log('ErrItem: ' + errItem);
                                    const fbLatex = latex.replace(errItem, '\\error{' + errItem + '}');
                                    const mfe = this.getMathFieldElement();
                                    mfe?.setValue(fbLatex);
                                }
                                if (closeMathEditor) this.openMath(false);
                                this.onErrorImageEmitter.emit();
                            }
                        });
                        // } else {
                        //     this.onErrorImageEmitter.emit();
                        // }
                    } else {
                        console.log('+++LATEX NOT CHANGED');
                        if (closeMathEditor) this.openMath(false);
                    }
                } else {
                    console.log('+++LATEX EMPTY');
                    this.smService.setLatexToNode(editNode.id, '');
                    this.smService.removeImageFromSelection();
                    if (closeMathEditor) this.openMath(false);
                }
            } else {
                console.log('+++NO NODE');
                if (closeMathEditor) this.openMath(false);
            }
        }
    }

    getMathJaxLatex(mathML: string): string {
        // Convert unsupported entities in MatjJax to something similar
        // For image generation
        let l = '';
        try {
            l = MathMLToLaTeX.convert('<math>' + mathML + '</math>');
            l = l.replace(/\\degree/g, '°');
            l = l.replace(/\\overarc/g, '\\widehat');
        } catch (err) {
            console.log('getMathJaxLatex ERROR: ' + JSON.stringify(err));
        }
        return l;
    }

    getMathLiveLatex(): string {
        // Convert unsupported operators in MathLive to something supported
        // After typing or pasting latex
        const mfe = this.getMathFieldElement();
        let l = '';
        if (mfe) {
            const origLatex = mfe.getValue();
            l = origLatex;
            l = l.replace(/\\sfrac/g, '\\frac');
            l = l.replace(/\\sen/g, '\\mbox{sen}');
            l = l.replace(/\\arccot/g, '\\mbox{arccot}'); // Non esiste in Latex, ma in MateMitica sì!
            if (l !== origLatex) {
                mfe.setValue(l);
            }
        }
        return l;
    }

    // Prove per lettura (DA FARE)
    public readLatex(latex: string, language: string) {
        if (!language) language = localStorage.getItem('locale') || 'it';
        let text = '';
        if (language === 'it') {
            text = this.mathReaderItService.getTextToRead(latex);
        } else {
            // Altre lingue: fallback su inglese
            text = this.mathReaderEnService.getTextToRead(latex);
        }
        return text;
    }

}
