import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { UserPreferenceService } from '../shared/user-preference.service';
import { RecentComponent } from './recent/recent.component';
import { RecentMenuComponent } from './recent/recent-menu/recent-menu.component';
// import { AboutModule } from '../about/about.module';


@NgModule({
    imports: [
        // AboutModule,
        CommonModule,
        TranslateModule.forRoot(),
        CoreModule,
        SharedModule,
        //FlexLayoutModule,
        MaterialModule,
        MatTooltipModule,
        MatInputModule,
        HomeRoutingModule,
    ],
    declarations: [
        HomeComponent,
        RecentComponent,
        RecentMenuComponent,
    ],
    providers: [
        UserPreferenceService
    ]
})
export class HomeModule { }
