import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';


import { extract } from '../core/i18n.service';
import { SmeService } from '../core/sme/sme.service';
import { Logger } from '../core/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { map } from 'rxjs/operators';
import { MessageBoxService } from '../shared/dialog/messagebox.service';
import { MapOperationType } from '../shared/map-operations';
import { FirebaseService } from '../core/firebase/firebase.service';

const logger = new Logger('GdriveOpenComponent');

@Component({
    selector: 'app-gdrive-open',
    templateUrl: './gdrive-open.component.html',
    styleUrls: ['./gdrive-open.component.scss']
})
export class GdriveOpenComponent implements OnInit, OnDestroy {
    isLoading = false;
    error = '';

    readStateSubscription: Subscription | undefined;
    statOpenMapSubscription: Subscription | undefined;

    constructor(private route: ActivatedRoute, private router: Router,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private smeService: SmeService,
        private firebaseService: FirebaseService,
        private messageBoxService: MessageBoxService) { }

    ngOnInit() {
        this.isLoading = true;
        localStorage.removeItem('redirectPage');
        localStorage.removeItem('redirectParams');
        localStorage.removeItem('redirectQueryParams');
        if (this.readStateSubscription) { this.readStateSubscription.unsubscribe(); }
        this.readStateSubscription = this.readState().subscribe({
            next: (state: any) => {
                if (!this.authenticationService.credentials) {
                    // User not logged
                    this.router.navigate(['login', window.location.href]);
                } else if (this.authenticationService.isUserExpired()) {
                    // User expired
                    this.isLoading = false;
                    this.showError('USER_EXPIRED');
                } else {
                    // Valid user logged in
                    const fileId = state.ids[0];
                    const googleUserId = state.userId;
                    if (state.action === 'create') {
                        return of(null); // GDRIVE-NEW
                    } else {
                        this.smeService.createWorkMapFromGoogleDrive(fileId, googleUserId).subscribe({
                            next: (data: any) => {
                                this.isLoading = false;
                                logger.info(`Map ${data.mapId} loaded`);
                                if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
                                this.statOpenMapSubscription = this.smeService.addCustomMapStat(data.mapId, MapOperationType.STAT_OPENMAP, this.authenticationService.getUserEmail()).subscribe((_data: any) => { });
                                this.firebaseService.addSharedStateChangeListener('', data.mapId, this.authenticationService.getUserEmail()).then(() => {
                                    if (data.readonly) {
                                        this.router.navigate(['map-view/', data.mapId]);
                                        // window.location.href = 'map-view/' + data.mapId;
                                    } else {
                                        this.router.navigate(['map-edit/', data.mapId]);
                                        // window.location.href = 'map-edit/' + data.mapId;
                                    }
                                });
                            },
                            error: (error: any) => {
                                this.isLoading = false;
                                let errorCode = '';
                                if (error.status === 500 && error.error && error.error.message === 'The domain administrators have disabled Drive apps.') {
                                    this.messageBoxService.showAdminDisabled3rdPartyApps().subscribe(() => {
                                        this.router.navigate(['home/']);
                                    });
                                } else if (error.status === 504) {
                                    errorCode = 'GATEWAY_TIMEOUT';
                                } else {
                                    errorCode = (error.error.code) ? error.error.code : 'GDRIVE_OPEN_FILE_ERROR_GENERIC_MAP_OPEN';
                                }
                                this.showError(errorCode);
                            }
                        });
                    }
                }
                return null;
            }
        });
    }

    ngOnDestroy() {
        if (this.readStateSubscription) { this.readStateSubscription.unsubscribe(); }
        if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
    }

    /**
     * Legge lo stato ricevuto da Google Drive tramite query string
     */
    private readState(): Observable<any> {
        return this.route.queryParams
            .pipe(
                map((queryParams: Params) => {
                    let stateStr = queryParams['state'];
                    stateStr = decodeURIComponent(stateStr);
                    let res;
                    try {
                        res = JSON.parse(stateStr);
                    } catch (e) {
                        res = stateStr;
                    }
                    return res;
                }));
    }

    backToHome() {
        this.router.navigate(['home']);
    }


    private showError(errorCode: string) {
        this.error = this.translateService.instant(extract(errorCode));
    }
}
