import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { MapImportComponent } from './map-import.component';
import { AuthenticationGuard } from '../core/authentication/authentication.guard';

const routes: Routes = Route.withShell([
    { path: 'map-import', component: MapImportComponent, data: { title: extract('TITLE_OPEN_MAP') } }
]);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthenticationGuard]
})
export class MapImportRoutingModule { }
