<div class="custom-toolbar">
    <button *ngIf="!multiselection" class="mat-tooltip-trigger" matTooltip="{{'INSERT_IMAGE' | translate}}" mat-menu mat-icon-button color="primary" [matMenuTriggerFor]="insertImage">
        <mat-icon color="primary" svgIcon="image-plus"></mat-icon>
    </button>
    <mat-menu #insertImage="matMenu" [overlapTrigger]="false">
        <app-image-menu></app-image-menu>
    </mat-menu>
    <ng-container *ngIf="imageSelected">
        <button mat-icon-button class="mat-tooltip-trigger" color="primary" matTooltip="{{'FORMAT_NODE_TOOLBAR-REMOVE-IMAGE' | translate}}" (click)="removeImage()">
            <mat-icon color="primary" [attr.aria-label]="'FORMAT_NODE_TOOLBAR-REMOVE-IMAGE' | translate" svgIcon="image-off"></mat-icon>
        </button>
        <button mat-icon-button class="mat-tooltip-trigger" color="primary" matTooltip="{{'EDIT_IMAGE' | translate}}" (click)="cropImage()">
            <mat-icon color="primary" [attr.aria-label]="'EDIT_IMAGE' | translate" svgIcon="image-edit-outline"></mat-icon>
        </button>
        <span *ngIf="isFrameAvailable">
            <button mat-icon-button class="mat-tooltip-trigger" color="primary" matTooltip="{{'FORMAT_NODE_TOOLBAR-FRAME-IMAGE' | translate}}" [ngClass]="{'button-down': isFramed}" (click)="toggleFrameImage()">
                <mat-icon color="primary" [attr.aria-label]="'FORMAT_NODE_TOOLBAR-FRAME-IMAGE' | translate" fontSet="material-icons-outlined">image</mat-icon>
            </button>
        </span>
    </ng-container>
</div>
