<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="'PLEASE_WAIT'"></app-loader>

<div class="user-profile">
    <mat-card class="user-container">
        <div class="header">
            <h2 translate>
                CREATE_PROFILE
            </h2>
        </div>

        <form [formGroup]="form" (ngSubmit)="submit()">
            <!-- FOTO PROFILO -->
            <div>
                <div class="avatar"> {{getInitials()}}</div>
            </div>


            <!-- NOME COMPLETO -->
            <hr />
            <h3>{{'FULLNAME' | translate}}</h3>
            <mat-form-field appearance="outline">
                <input type="text" matInput placeholder="{{'FULLNAME' | translate}}" formControlName="fullname" spellcheck="false" />
            </mat-form-field>

            <!-- EMAIL -->
            <hr />
            <h3>{{'USERNAME' | translate}}</h3>
            <span matTooltip="{{'USERNAME' | translate}}">{{this.username}}</span>



            <!-- PASSWORD -->
            <hr />
            <h3>{{'PASSWORD' | translate}}</h3>
            <div>
                <p translate>SET_PASS1</p>
                <ul>
                    <li translate>SET_PASS2</li>
                    <li translate>SET_PASS3</li>
                    <li translate>SET_PASS4</li>
                </ul>
                <p translate>SET_PASS5</p>
            </div>
            <div class="new-pwd">
                <mat-form-field appearance="outline" color="accent">
                    <input matInput [type]="hide ? 'password' :  'text'" type="password" formControlName="password" />
                    <mat-icon *ngIf="!isIEOrEdge()" matSuffix (click)="showHidePsw()">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                    <mat-hint>Inserisci la nuova password</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" color="accent">
                    <input [type]="hide ? 'password' :  'text'" matInput type="password" formControlName="ripetiPassword" />
                    <mat-icon *ngIf="!isIEOrEdge()" matSuffix (click)="showHidePsw()">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                    <mat-hint>Ripeti la password inserita</mat-hint>
                </mat-form-field>
            </div>
        </form>
        <div class="errore" *ngIf="form?.invalid && (form?.controls?.['password']?.errors || (form?.value?.ripetiPassword && form?.controls?.['ripetiPassword']?.errors) ||form?.errors) ">
            <mat-error *ngIf="form?.value.password && form?.controls?.['password']?.errors?.['unaLettera']" translate>
                ERR_ONE_LETTER
            </mat-error>
            <mat-error *ngIf="form?.value.password && form?.controls?.['password']?.errors?.['unSimbolo']" translate>
                ERR_ONE_SYMBOL
            </mat-error>
            <mat-error *ngIf="form?.value.password && form?.controls?.['password']?.errors?.['lunghezza']" translate>
                ERR_LENGTH_PASSWORD
            </mat-error>
            <mat-error *ngIf="form?.value.password && form?.value.vecchiaPassword && form?.controls?.['password']?.errors?.['stessaPassword']" translate>
                ERR_SAME_PASSWORD
            </mat-error>
            <mat-error *ngIf="form?.value.password && form?.value.ripetiPassword && form?.value.password !== form?.value.ripetiPassword" translate>
                ERR_DIFFERENT_PASSWORD
            </mat-error>
        </div>
        <mat-card-actions [align]="'end'">
            <button color="warn" [disabled]="!enabledForm" mat-flat-button class="ok-button" (click)="submit()" type="submit">{{'CREATE_PROFILE_BUTTON' | translate}}</button>
        </mat-card-actions>
    </mat-card>
</div>
