import { Guid } from "guid-typescript";

export class RecentPdfDto {

    public userId: string;
    public color: string;
    public idPdf: string;
    public googleId: string;
    public name: string;
    private _imported: string;

    public get imported() {
        return this._imported;

    }
    public set imported(value: string) {
        this._imported = value;
        this.dateImported = new Date(value);
    }

    public dateImported: Date | undefined;
    public _lastUpdated: string;
    public get lastUpdated() {
        return this._lastUpdated;

    }
    public set lastUpdated(value: string) {
        this._lastUpdated = value;
        this.dateLastUpdated = new Date(value);
    }
    public dateLastUpdated: Date | undefined;
    public _lastOpened: string;
    public get lastOpened() {
        return this._lastOpened;

    }
    public set lastOpened(value: string) {
        this._lastOpened = value;
        this.dateLastOpened = new Date(value);
    }
    public dateLastOpened: Date | undefined;
    public storageRef: string;
    public link: string;
    constructor() {
        this.userId = '';
        this.idPdf = '';
        this.color = 'primary';
        this.googleId = '';
        this.name = '';
        this.storageRef = '';
        this.link = '';

        this._imported = new Date().toISOString();
        this._lastUpdated = new Date().toISOString();
        this._lastOpened = new Date().toISOString();




    }





}

