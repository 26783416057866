<div class="notification-item" [ngClass]="{'to-read':!notification?.shown, 'notification-item-clickable': hasLink()}" (click)="$event.stopPropagation(); notificationClicked($event);" [matTooltip]="getNotificationTooltip()">
    <div class="icon-container">
        <mat-icon *ngIf="notification && notification.typeIcon === 'custom'" color="primary" class="material-icons-sharp" fontSet="material-icons-filled">
            notifications_active
        </mat-icon>
        <mat-icon *ngIf="notification && notification.typeIcon === 'subscription'" color="primary" class="material-icons-sharp" fontSet="material-icons-filled">
            event
        </mat-icon>
        <mat-icon *ngIf="notification && notification.typeIcon === 'update'" color="primary" fontSet="material-icons-outlined">
            new_releases
        </mat-icon>
        <mat-icon *ngIf="notification && notification.typeIcon === 'maintenance'" color="primary" class="material-icons-sharp" fontSet="material-icons-filled">
            build
        </mat-icon>
        <mat-icon *ngIf="notification && notification.typeIcon === 'webinar'" color="primary" class="material-icons-sharp" fontSet="material-icons-filled">
            connected_tv
        </mat-icon>
        <mat-icon *ngIf="notification && notification.typeIcon === 'share_add'" color="primary" fontSet="material-icons-outlined">
            people
        </mat-icon>
        <mat-icon *ngIf="notification && notification.typeIcon === 'share_delete'" color="primary" fontSet="material-icons-outlined">
            group_off
        </mat-icon>
    </div>
    <div class="text-container">
        <!--<span class="notification-title" matTooltip="{{notification?.['title']}}">{{notification?.['title']}}</span>-->
        <div class="description-container">
            <div class="description" [innerHTML]="notification?.['notes']"></div>
            <span class="date"> {{toLocalDate(notification?.['dateCreated'])}}</span>
        </div>
        <div><mat-icon *ngIf="notification?.['link']" color="primary">open_in_new</mat-icon></div>
    </div>
    <!--TOLTO DAL DESIGN-->
    <!--<div class="button-container" *ngIf="notification && notification.typeIcon.includes('share_')">
        <button mat-icon-button color="primary" (click)="deleteNotification($event)">
            <mat-icon class="material-icons-sharp" fontSet="material-icons-filled">delete_forever</mat-icon>
        </button>
    </div>-->
</div>
<mat-divider></mat-divider>
