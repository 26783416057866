import { EventEmitter, Injectable, Output } from '@angular/core';
import { CopyMapService } from './copy-map.service';
import { Router } from '@angular/router';
import { SmeService } from '../../core/sme/sme.service';
import { GoogleService } from '../../core/google/google.service';
import { MapStateService } from '../map-state.service';
import { Observable, Observer, Subscription } from 'rxjs';
import { InputDialogService } from '../dialog/input-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../core/i18n.service';
import { SmService } from 'supermappe-core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { MessageBoxService } from '../dialog/messagebox.service';
import { FirebaseService } from '../../core/firebase/firebase.service';


@Injectable({
    providedIn: 'root'
})
export class CopyAndOpenMapService {
    mapName = '';
    mapId = '';
    revId = '';
    //  @Output() endLoading = new EventEmitter<boolean>();

    onMapCopiedSubscription: Subscription;
    askNameSubscription: Subscription | undefined;
    openDialogCopyOpenRevisionSubscription: Subscription | undefined;
    copyRevisionSubscription: Subscription | undefined;
    createWorkMapFromGoogleDriveSubscription: Subscription | undefined;
    openDialogCopyAndOpenSubscription: Subscription | undefined;
    downloadJsonSubscription: Subscription | undefined;

    constructor(
        private copyMapService: CopyMapService,
        private router: Router,
        private smeService: SmeService,
        private smService: SmService,
        private translateService: TranslateService,
        private inputDialogService: InputDialogService,
        private mapStateService: MapStateService,
        private authenticationService: AuthenticationService,
        private firebaseService: FirebaseService,
        private messageBoxService: MessageBoxService) {
        // if (this.onMapCopiedSubscription) { this.onMapCopiedSubscription.unsubscribe(); }
        this.onMapCopiedSubscription = this.copyMapService.onMapCopied.subscribe((newMapId: string) => {

            // if (this.createWorkMapFromGoogleDriveSubscription) { this.createWorkMapFromGoogleDriveSubscription.unsubscribe(); }
            // this.createWorkMapFromGoogleDriveSubscription = this.smeService.createWorkMapFromGoogleDrive(newMapId)
            //     .subscribe({
            //         next: (data: any) => {
            this.mapStateService.setBusyState(false);
            this.mapStateService.setEditingState(false);
            this.smService.setEnableKeyPresses(true);
            this.mapStateService.setCanEnableQuickEdit(true);
            this.router.navigate(['/map-edit', newMapId], { replaceUrl: true });
            //     },
            //     error: (error: any) => {
            //         if (error.status === 500 && error.error && error.error.message === 'The domain administrators have disabled Drive apps.') {
            //             this.messageBoxService.showAdminDisabled3rdPartyApps().subscribe(() => {
            //                 this.router.navigate(['home/']);
            //             });
            //         } else {
            //             const errorCode = (error.code) ? error.code : error.statusText;
            //             this.mapStateService.setStateError(errorCode);
            //         }
            //     }
            // });

        });
    }

    openDialog(urlToOpen: string, preview?: string) {
        return new Observable((observer: Observer<boolean>) => {
            this.mapStateService.setEditingState(true);
            this.smService.setEnableKeyPresses(false);
            this.mapStateService.setCanEnableQuickEdit(false);
            this.mapStateService.setFocusOnMap(false);
            if (this.mapName !== '' && this.mapName.toLowerCase().endsWith('.sme')) {
                this.mapName = this.mapName.split('.')[0];
            }
            // UnsavedChangesDialog defined somewhere else and imported above
            if (this.askNameSubscription) { this.askNameSubscription.unsubscribe(); }
            this.askNameSubscription = this.inputDialogService.askName(this.translateService.instant(extract('TITLE_COPY_MAP')),
                this.translateService.instant(extract('INPUT_MAPNAME')), this.mapName,
                this.mapId, urlToOpen, '', preview)
                .subscribe({
                    next: result => {
                        this.mapStateService.setFocusOnMap(true);
                        observer.next(result);
                        observer.complete();
                    },
                    error: (error) => {
                        this.mapStateService.setFocusOnMap(true);
                        observer.next(false);
                        observer.complete();
                    }
                });
        });

    }

    copyAndOpenRevision(revId: string, mapId: string, mapName: string) {
        this.mapId = mapId;
        this.revId = revId;
        this.mapName = this.translateService.instant(extract('COPYOF')) + mapName;
        // this.openDialog(window.location.origin + '/map-copy?mapid=' + mapId + '&revid=' + revId)
        if (this.openDialogCopyOpenRevisionSubscription) { this.openDialogCopyOpenRevisionSubscription.unsubscribe(); }
        this.openDialogCopyOpenRevisionSubscription = this.openDialog('')
            .subscribe((result: any) => {
                if (result && result !== '' && result !== 'undefined') {
                    this.mapStateService.setBusyState(false);
                    this.mapStateService.setEditingState(false);
                    this.smService.setEnableKeyPresses(true);
                    this.mapStateService.setCanEnableQuickEdit(true);
                    this.router.navigate(['/map-copy'], { queryParams: { mapid: mapId, revid: revId, name: encodeURI(result.name) } });
                }
            });
    }

    copyRevision(revId: string, mapId: string, mapName: string) {
        if (this.copyRevisionSubscription) { this.copyRevisionSubscription.unsubscribe(); }
        this.copyRevisionSubscription = this.smeService.copyRevision(mapId, revId, mapName)
            .subscribe({
                next: (googleFileId) => {
                    if (this.createWorkMapFromGoogleDriveSubscription) { this.createWorkMapFromGoogleDriveSubscription.unsubscribe(); }
                    this.createWorkMapFromGoogleDriveSubscription = this.smeService.createWorkMapFromGoogleDrive(googleFileId)
                        .subscribe({
                            next: (data: any) => {
                                this.router.navigate(['/map-edit', data.mapId]);
                            },
                            error: ((error: any) => {
                                if (error.status === 500 && error.error && error.error.message === 'The domain administrators have disabled Drive apps.') {
                                    this.messageBoxService.showAdminDisabled3rdPartyApps().subscribe(() => {
                                        this.router.navigate(['home/']);
                                    });
                                } else {
                                    const errorCode = (error.code) ? error.code : error.statusText;
                                    this.mapStateService.setStateError(errorCode);
                                    this.mapStateService.setBusyState(false);
                                }
                            })
                        });
                },
                error: ((error: any) => {
                    if (error.status === 500 && error.error && error.error.message === 'The domain administrators have disabled Drive apps.') {
                        this.messageBoxService.showAdminDisabled3rdPartyApps().subscribe(() => {
                            this.router.navigate(['home/']);
                        });
                    } else {
                        const errorCode = (error.code) ? error.code : error.statusText;
                        this.mapStateService.setStateError(errorCode);
                        this.mapStateService.setBusyState(false);
                    }
                })
            });

    }

    // copyAndOpenMap(mapId: string, mapName: string, preview?: string, onCopy?: Function) {
    //     return new Promise((resolve, reject) => {
    //         const userId = this.authenticationService.credentials?.firebaseUserId;
    //         const storageWorkFilePath = `/maps/${userId}/work/${mapId}/map.json`;
    //         const storageSmeFilePath = `/maps/${userId}/mymaps/${mapId}.sme`;
    //         this.firebaseService.getDownloadUri(storageSmeFilePath).then((uri: any) => {
    //             if (!uri) {
    //                 // Scarica mapWorkJson
    //                 this.firebaseService.downloadResource(storageWorkFilePath).subscribe({
    //                     next: (_mapWorkJson: any) => {
    //                         const mapWorkJson = JSON.stringify(_mapWorkJson);
    //                         this.smeService.saveMapRequest(mapId, mapWorkJson, null).subscribe({
    //                             next: (smeBlob: any) => {
    //                                 this._copyMap(storageSmeFilePath, mapId, mapName, preview, onCopy).then(() => {
    //                                     return resolve(true);
    //                                 });
    //                             },
    //                             error: (err: any) => {
    //                                 return resolve(false);
    //                             }
    //                         });
    //                     },
    //                     error: (err: any) => {
    //                         return resolve(false);
    //                     }
    //                 });
    //             } else {
    //                 this._copyMap(storageSmeFilePath, mapId, mapName, preview, onCopy).then(() => {
    //                     return resolve(true);
    //                 });
    //             }
    //         });
    //     });
    // }

    // copyAndOpenMap(mapId: string, mapName: string, preview?: string, onCopy?: Function) {
    //     return new Promise((resolve, reject) => {
    //         this.mapId = mapId;
    //         this.mapName = this.translateService.instant(extract('COPYOF')) + mapName.replace('.sme', '');
    //         if (this.openDialogCopyAndOpenSubscription) { this.openDialogCopyAndOpenSubscription.unsubscribe(); }
    //         this.googleService.getSMXFolderIds().then((folderIds: any) => {
    //             if (this.downloadJsonSubscription) { this.downloadJsonSubscription.unsubscribe(); }
    //             this.downloadJsonSubscription = this.smeService.downloadJsonToEdit(mapId).subscribe((_mapData: any) => {
    //                 if (onCopy) onCopy();
    //                 this.openDialogCopyAndOpenSubscription = this.openDialog(window.location.origin + '/map-copy?mapid=' + mapId + '&googleFolderId=' + folderIds.smx, preview)
    //                     .subscribe((result: any) => {
    //                         this.mapStateService.setEditingState(false);
    //                         this.smService.setEnableKeyPresses(true);
    //                         this.mapStateService.setCanEnableQuickEdit(true);
    //                         this.mapStateService.setBusyState(false);
    //                         resolve(true);
    //                     });
    //             }, (error) => {
    //                 return reject(error);
    //             });
    //         });
    //     });
    // }


    copyAndOpenMap(mapId: string, mapName: string, preview?: string, onCopy?: Function) {
        return new Promise((resolve, reject) => {
            this.mapId = mapId;

            this.mapName = this.translateService.instant(extract('COPYOF')) + mapName.replace('.sme', '');
            if (this.mapName !== '' && this.mapName.toLowerCase().endsWith('.sme')) {
                this.mapName = this.mapName.split('.')[0];
            }
            if (this.openDialogCopyAndOpenSubscription) { this.openDialogCopyAndOpenSubscription.unsubscribe(); }
            // this.firebaseService.copyFile()
            if (this.downloadJsonSubscription) { this.downloadJsonSubscription.unsubscribe(); }

            this.downloadJsonSubscription = this.smeService.downloadJsonToEdit(mapId).subscribe({
                next: (_mapData: any) => {
                    if (onCopy) onCopy();
                    this.openDialogCopyAndOpenSubscription = this.openDialog(window.location.origin + '/map-copy?mapid=' + mapId, preview)
                        .subscribe((result: any) => {
                            this.mapStateService.setEditingState(false);
                            this.smService.setEnableKeyPresses(true);
                            this.mapStateService.setCanEnableQuickEdit(true);
                            this.mapStateService.setBusyState(false);
                            resolve(true);
                        });
                },
                error: (error) => {
                    return reject(error);
                }
            });
        });
        //     });
    }

    copyMap(mapId: string, mapName: string, googleFolderId: string) {
        console.log('copyMap(mapId: ' + mapId + ', mapName: ' + mapName + ')');
        if (mapName) {
            // this.mapName = mapName + '.sme';
            this.mapStateService.setBusyState(true);
            this.copyMapService.copyMap(mapId, mapName);
        } else {
            this.mapStateService.setStateError(this.translateService.instant(extract('NO_MAP_NAME')));
        }
    }

    _copyMap(storageFilePath: string, mapId: string, mapName: string, preview?: string, onCopy?: Function) {
        return new Promise((resolve, reject) => {
            this.mapId = mapId;
            this.mapName = this.translateService.instant(extract('COPYOF')) + mapName.replace('.sme', '');
            if (this.mapName !== '' && this.mapName.toLowerCase().endsWith('.sme')) {
                this.mapName = this.mapName.split('.')[0];
            }
            if (this.openDialogCopyAndOpenSubscription) { this.openDialogCopyAndOpenSubscription.unsubscribe(); }
            // this.firebaseService.copyFile()
            if (this.downloadJsonSubscription) { this.downloadJsonSubscription.unsubscribe(); }
            this.downloadJsonSubscription = this.firebaseService.downloadResourceAsBlob(storageFilePath, 'application/supermappex').subscribe((blob: any) => {
                //     this.downloadJsonSubscription = this.smeService.downloadJsonToEdit(mapId).subscribe((_mapData: any) => {
                if (onCopy) onCopy();
                this.openDialogCopyAndOpenSubscription = this.openDialog(window.location.origin + '/map-copy?mapid=' + mapId, preview)
                    .subscribe((result: any) => {
                        this.mapStateService.setEditingState(false);
                        this.smService.setEnableKeyPresses(true);
                        this.mapStateService.setCanEnableQuickEdit(true);
                        this.mapStateService.setBusyState(false);
                        return resolve(true);
                    });
            }, (error) => {
                return reject(error);
            });

        })
    }

}
