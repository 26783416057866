import { Component } from '@angular/core';
import { DeviceService } from '../core/device.service';

@Component({
    selector: 'app-about-device',
    templateUrl: './about-device.component.html',
    styleUrls: ['./about-device.component.scss']
})
export class AboutDeviceComponent {

    deviceInfo: string = '';

    constructor(
        private platform: DeviceService
    ) {

        if (platform.isMobileOrTabletDevice()) {
            this.deviceInfo =
                "DEVICE: " + this.platform.deviceDetectorService.device + '\n' +
                "OS: " + this.platform.deviceDetectorService.os + '\n' +
                "BROWSER: " + this.platform.deviceDetectorService.browser + " " + this.platform.deviceDetectorService.browser_version + '\n' +
                "ORIENTATION: " + this.platform.deviceDetectorService.orientation + '\n' +
                "USER AGENT: " + this.platform.deviceDetectorService.userAgent + '\n'
        } else {
            this.deviceInfo =
                "OS: " + this.platform.deviceDetectorService.os_version + '\n' +
                "BROWSER: " + this.platform.deviceDetectorService.browser + " " + this.platform.deviceDetectorService.browser_version + '\n' +
                "USER AGENT: " + this.platform.deviceDetectorService.userAgent + '\n'
        }
    }
}
