import { Injectable, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { SmEventBroker } from '../../../shared/sm-event-broker';
import { Subscription } from 'rxjs';
import { CustomToolbarDto } from './custom-toolbar-dto';

@Injectable({
    providedIn: 'root'
})
export class CustomizeToolbarService implements OnDestroy {
    public customToolbarDto = new CustomToolbarDto;
    public totButtonVisible = 0;
    public genericButtonVisible = 3;
    public formatElementButtonVisible = 0;
    public formatTextButtonVisible = 0;
    public formatTableButtonVisible = 19;
    public multiSelectButtonVisible = 2;
    public imageButtonVisible = 0;
    public hideAlign = true;
    public hideSize = true;
    public hideLanguage = true;
    public hideImage = true;
    public onChangeVisibilityToolbar = new EventEmitter<CustomToolbarDto>();
    public onChangeTotButtonVisible = new EventEmitter<any>();
    public onChangeLanguage = new EventEmitter<string>();
    selectionDataSubscription: Subscription | undefined;
    nLoop = 0;

    constructor(
        private smEventBroker: SmEventBroker,
    ) {
        if (this.smEventBroker.selectionData) {
            this.selectionDataSubscription = this.smEventBroker.selectionData.subscribe((_selectionData: any) => {
                this.changeToolbar(_selectionData);
            });
        }
    }

    ngOnDestroy(): void {
        if (this.selectionDataSubscription) { this.selectionDataSubscription.unsubscribe(); }
    }

    updateTotButton() {
        if (this.customToolbarDto.noSelection) {
            this.totButtonVisible = this.genericButtonVisible;
        } else {
            this.totButtonVisible = 2; // lingua extra e googledoc (tolto)
        }
        if (this.customToolbarDto.onlyNodeInTableSelected) {
            this.totButtonVisible += this.formatTableButtonVisible;
        }
        if (this.customToolbarDto.nodeSelected || this.customToolbarDto.edgeSelected) {
            this.totButtonVisible += this.formatTextButtonVisible;
        }
        if (this.customToolbarDto.nodeSelected || this.customToolbarDto.edgeSelected) {
            this.totButtonVisible += this.formatElementButtonVisible;
        }
        if (this.customToolbarDto.multiSelection) {
            this.totButtonVisible += this.multiSelectButtonVisible + 1;
            // aggiungo uno altrimenti il numero dei bottoni è uguale
        }
        if (this.customToolbarDto.imageSelected) {
            this.totButtonVisible += this.imageButtonVisible;
        }
        this.onChangeTotButtonVisible.emit(this.totButtonVisible);
        // console.log('button visibili ' + this.totButtonVisible);
    }

    changeToolbar(selectionData: any) {
        this.customToolbarDto.originalSelection = selectionData;
        this.customToolbarDto.elementselected = selectionData.nCells + selectionData.nCellsImage + selectionData.nEdges + selectionData.nNodes + selectionData.nNodesImage + selectionData.nNodesImageFrame + selectionData.nNodesText;
        this.customToolbarDto.nodeSelected = (selectionData.nNodes > 0 || selectionData.nNodesImage > 0
            || selectionData.nNodesImageFrame > 0 || selectionData.nNodesText > 0);
        this.customToolbarDto.edgeSelected = (selectionData.nEdges > 0);
        this.customToolbarDto.isTableSelected = selectionData.nCells > 0 || selectionData.nCellsImage > 0;
        this.customToolbarDto.onlyNodeInTableSelected = (selectionData.nCells + selectionData.nCellsImage) === 1 && !this.customToolbarDto.nodeSelected && !this.customToolbarDto.edgeSelected;
        this.customToolbarDto.imageSelected = selectionData.nCellsImage > 0 || selectionData.nNodesImage > 0 || selectionData.nodeType === 'image' || selectionData.nNodesImageFrame > 0;
        this.customToolbarDto.noSelection = !this.customToolbarDto.edgeSelected && !this.customToolbarDto.imageSelected &&
            !this.customToolbarDto.isTableSelected && !this.customToolbarDto.nodeSelected;
        if (selectionData.penColorEdge) {
            this.customToolbarDto.borderColor = selectionData.penColorEdge;
        }
        if (selectionData.penColorNode) {
            this.customToolbarDto.borderColor = selectionData.penColorNode;
        }
        this.customToolbarDto.multiSelection = (selectionData.ids && selectionData.ids.length > 1);
        this.customToolbarDto.canArrange = ((selectionData.nNodes + selectionData.nNodesImage + selectionData.nNodesImageFrame + selectionData.nNodesText) > 1) || (selectionData.nCells > 0 && this.customToolbarDto.nodeSelected);
        this.hideLanguage = this.customToolbarDto.noSelection || this.customToolbarDto.onlyNodeInTableSelected;
        // this.hideImage = this.customToolbarDto.noSelection || this.customToolbarDto.onlyNodeInTableSelected || (this.customToolbarDto.edgeSelected && !this.customToolbarDto.multiSelection) || (this.customToolbarDto.multiSelection && !this.customToolbarDto.edgeSelected) || (this.customToolbarDto.edgeSelected && this.customToolbarDto.elementselected > selectionData.nEdges + 1);
        this.hideImage = this.customToolbarDto.noSelection || this.customToolbarDto.onlyNodeInTableSelected || (this.customToolbarDto.edgeSelected && !this.customToolbarDto.multiSelection) || this.customToolbarDto.multiSelection;

        this.hideAlign = !this.customToolbarDto.canArrange;
        this.hideSize = !this.customToolbarDto.canArrange;
        this.customToolbarDto.isFramed = selectionData.framed;
        this.customToolbarDto.penWidth = selectionData.penWidth;
        this.customToolbarDto.brushColor = selectionData.brushColor;
        this.customToolbarDto.textLayout = selectionData.textLayout;
        this.customToolbarDto.ids = selectionData.ids;
        this.customToolbarDto.titleText = selectionData.titleText;
        this.customToolbarDto.titleHtml = selectionData.titleHtml;
        this.customToolbarDto.deepText = selectionData.deepText;
        this.customToolbarDto.deepHtml = selectionData.deepHtml;
        this.customToolbarDto.nodeType = selectionData.nodeType;
        this.customToolbarDto.edgeType = selectionData.edgeType;
        this.customToolbarDto.textInfo = selectionData.textInfo;
        this.customToolbarDto.language = selectionData.language;
        this.customToolbarDto.nNodes = selectionData.nNodes;
        this.customToolbarDto.nNodesImage = selectionData.nNodesImage;
        this.customToolbarDto.nNodesImageFrame = selectionData.nNodesImageFrame;
        this.customToolbarDto.nNodesText = selectionData.nNodesText;
        this.customToolbarDto.nCells = selectionData.nCells;
        this.customToolbarDto.nCellsImage = selectionData.nCellsImage;
        this.customToolbarDto.nEdges = selectionData.nEdges;
        this.customToolbarDto.nImages = selectionData.nImages;
        if (selectionData && selectionData.tableSize) {
            this.customToolbarDto.tableSize = { nRows: selectionData.tableSize.nRows, nCols: selectionData.tableSize.nCols };
        } else {
            this.customToolbarDto.tableSize = { nRows: 0, nCols: 0 };
        }
        this.multiSelectButtonVisible = 0;
        if (!this.hideAlign) {
            this.multiSelectButtonVisible++;
        }
        if (!this.hideSize) {
            this.multiSelectButtonVisible++;
        }
        this.onChangeVisibilityToolbar.emit(this.customToolbarDto);
        setTimeout(() => {
            this.onChangeLanguage.next(selectionData.language);
            // console.log('+++++++++++++++++++++++++++++++++++++UPDATE');
            if (this.nLoop < 10) {
                this.nLoop++;
                const win = (window as any);
                if (win && win.sm) {
                    win.sm.updateSelection();
                }
            }
        }, 10);
    }
}
