<div class="container">
    <form [formGroup]="formName">
        <mat-form-field appearance="outline" color="accent">
            <mat-label>Nome completo</mat-label>
            <input matInput type="text" formControlName="nome" required />
        </mat-form-field>
    </form>
    <div class="errore" *ngIf="erroreSalvataggio">Si è verificato un errore durante il salvataggio.</div>

    <div class="button-container">
        <button mat-stroked-button color="primary" (click)="cancel()">Annulla</button>
        <button mat-flat-button color="accent" type="submit" (click)="submit()">Salva</button>
    </div>
</div>
