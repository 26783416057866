import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { UserDesktopComponent } from './user-desktop.component';

const routes: Routes = [
    { path: 'user-desktop', component: UserDesktopComponent, data: {} }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class UserDesktopRoutingModule { }
