<h2 mat-dialog-title class="header">{{'MAIN_TOOLBAR_ADD_TOOLS' | translate}}</h2>

<mat-dialog-content>
    <span translate>DESMOS_DESC_3</span>
    <span translate>DESMOS_DESC_4</span>

    <h3 translate>DRAW_TOOLS</h3>
    <div>{{'AUTODRAW_DESC_1' | translate}} <a target="_blank" href="https://experiments.withgoogle.com/autodraw?lang=it">Google</a>. </div>
    <mat-checkbox [disabled]="isLoading" [(ngModel)]="autoDraw" (change)="change(4)">
        <label class="desmos-checkbox">
            <mat-icon class="material-icons-sharp" fontSet="material-icons-filled">draw</mat-icon>
            <span class="desmos-span">{{'AUTODRAW' | translate}}</span>
        </label>
    </mat-checkbox>

    <h3 translate>DESMOS_MATH_TOOLS</h3>
    <div>{{'DESMOS_DESC_1' | translate}} <a target="_blank" href="https://www.desmos.com/?lang=it">Desmos Studio</a>. </div>
    <div class="desmos-div-checkbox">
        <mat-checkbox [disabled]="isLoading" [(ngModel)]="desmosCalculator" (change)="change(0)">
            <label class="desmos-checkbox">
                <mat-icon class="material-icons-sharp">multiline_chart</mat-icon>
                <span class="desmos-span">{{'DESMOS_CALCULATOR' | translate}}</span>
            </label>
        </mat-checkbox>
        <mat-checkbox [disabled]="isLoading" [(ngModel)]="desmosScientific" (change)="change(1)">
            <label class="desmos-checkbox">
                <mat-icon class="material-icons-sharp" fontSet="material-icons-filled">superscript</mat-icon>
                <span class="desmos-span">{{'DESMOS_SCIENTIFIC' | translate}}</span>
            </label>
        </mat-checkbox>
        <mat-checkbox [disabled]="isLoading" [(ngModel)]="desmosFourFunction" (change)="change(2)">
            <label class="desmos-checkbox">
                <mat-icon class="material-icons-sharp" fontSet="material-icons-filled">calculate</mat-icon>
                <span class="desmos-span">{{'DESMOS_FOURFUNCTIOM' | translate}}</span>
            </label>
        </mat-checkbox>
        <mat-checkbox [disabled]="isLoading" [(ngModel)]="desmosGeometry" (change)="change(3)">
            <label class="desmos-checkbox">
                <mat-icon class="material-icons-sharp" fontSet="material-icons-filled">category</mat-icon>
                <span class="desmos-span">{{'DESMOS_GEOMETRY' | translate}}</span>
            </label>
        </mat-checkbox>
    </div>
    <div>
        <br />
        <span translate>DESMOS_DESC_2</span>
    </div>

    <mat-spinner *ngIf="isLoading" color="accent" mode="indeterminate" diameter="24"></mat-spinner>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
    <button mat-stroked-button color="primary" mat-dialog-close>
        {{'CANCEL' | translate}}
    </button>
    <button mat-flat-button color="accent" (click)="savePreference()" translate [disabled]="isLoading" cdkFocusInitial>
        {{'SAVE' | translate}}
    </button>
</mat-dialog-actions>
