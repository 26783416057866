import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { MapHistoryRoutingModule } from './map-history-routing.module';
import { MapHistoryComponent } from './map-history.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        CoreModule,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        MatListModule,
        SharedModule,
        //FlexLayoutModule,
        MaterialModule,
        MatTooltipModule,
        MatInputModule,
        MapHistoryRoutingModule,
    ],
    declarations: [
        MapHistoryComponent
    ],
    providers: [
    ]
})
export class MapHistoryModule { }
