<div class="custom-toolbar">
    <button [disabled]="error" class="mat-tooltip-trigger" matTooltip="{{'GENERIC_TOOLBAR_INSERT_NEW_NODE' | translate}}" mat-icon-button color="primary" (click)="addNode()">
        <mat-icon color="primary" svgIcon="add-node"></mat-icon>
    </button>
    <button class="mat-tooltip-trigger" matTooltip="{{'GENERIC_TOOLBAR_INSERT_NEW_TABLE' | translate}}" [disabled]="error" mat-icon-button color="primary" (click)="addTable()">
        <mat-icon color="primary" svgIcon="table-plus"></mat-icon>
    </button>
    <app-button-color-picker [toolTip]="backgroundTooltip" [pickerIcon]="backgroundIcon" [usedColorLabel]="usedColorLabel" [selectedColor]="selectedBackgroundColor" (onChangeColor)="changeBackgroundColor($event)" [usedSizeColors]="10" [colors]="colors" [showTransparent]="false"></app-button-color-picker>
    <button class="mat-tooltip-trigger" [disabled]="error" matTooltip="{{'GENERIC_TOOLBAR_GRID' | translate}}" mat-icon-button [ngClass]="{'grid-mode ': isGridVisible}" color="primary" (click)="setGrid()">
        <mat-icon color="primary" fontSet="material-icons-outlined">grid_3x3</mat-icon>
    </button>
    <button class="mat-tooltip-trigger" matTooltip="{{'GENERIC_TOOLBAR_ARRANGE' | translate}}" [disabled]="error" mat-icon-button color="primary" (click)="arrangeMap()">
        <mat-icon color="primary" svgIcon="arrange-map"></mat-icon>
    </button>
</div>
