import { Component, OnInit, OnDestroy } from '@angular/core';
import { SmService } from 'supermappe-core';
import { TranslateService } from '@ngx-translate/core';
import { MapStateService } from '../../map-state.service';

@Component({
  selector: 'app-dialog-image',
  templateUrl: './dialog-image.component.html',
  styleUrls: ['./dialog-image.component.scss']
})
export class DialogImageComponent implements OnInit, OnDestroy {

  imageLoaded = false;
  nodeId = '';
  nodeText = '';
  imageNodeUrl = '';
  // showCropper = false;
  editMode = false;

  constructor(
    private smService: SmService,
    private mapStateService: MapStateService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.smService.setEnableKeyPresses(false);
  }

  ngOnDestroy() {
    this.smService.setEnableKeyPresses(true);
  }

  public setImageNode(id: string) {
    this.nodeId = id;

    this.smService.getSelectedSingleNodeImageDataURL((dataUrl: string) => {
      this.imageNodeUrl = dataUrl;
      this.nodeText = this.smService.getNodeTitle(id);
      if (this.nodeText === '') {
        this.nodeText = this.translateService.instant('IMAGE_NODE');
      }
      this.imageLoaded = true;
    });
  }

  public editImage() {
    // this.showCropper = true;
    this.mapStateService.EditImage();
  }

  public openImage() {
    const html =
      '<div style="position:relative; height:100%; width:100%;">' +
      '<img src="' + this.imageNodeUrl + '" style="position:absolute; top:0; left:0; right:0; bottom:0; margin:auto" />' +
      '</div>';
    const w = window.open('', '_blank');
    w?.document.write(html);
  }

}
