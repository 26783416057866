
import { throwError as observableThrowError, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Logger } from '../logger.service';
import { map, catchError } from 'rxjs/operators';
import { TokenInterceptor } from '../http/token.interceptor';
import { AccessDto } from '../../access-config/common/access-dto';

const routes = {
    apiFindSubscription: '/api/findsubscription2gen',
    apiFindRegisteredUser: '/api/findregistereduser2gen',
    // //  apiCreateAccess: 'api/createAccess',
    //  apiListAccess: 'api/listAccess',
    //  apiSearchSubscriptions: '/api/searchSubscriptions',
};

const logger: Logger = new Logger('registeredUserService');

@Injectable({
    providedIn: 'root'
})
export class RegisteredUserService {

    constructor(private http: HttpClient) { }

    public findRegisteredUser(eMail: string): any {
        const email = AccessDto.removePointFromEmail(eMail);
        const body = {
            eMail: email
        };
        return this.http.post<Response>(routes.apiFindRegisteredUser, body, { headers: TokenInterceptor.buildNoTokenHeaders() })
            .pipe(map((res: Response) => res), catchError((error: any) => {
                logger.error(error);
                return observableThrowError(() => error);
            }));
    }


    public findRegisteredUserPromise(eMail: string): Promise<any> {
        // const email = AccessDto.removePointFromEmail(eMail);
        const body = {
            eMail: eMail
        };
        // return this.http.post(routes.apiFindRegisteredUser, body, { cache: false, withCredentials: false }).toPromise()
        //   .then(res => res.json());
        return new Promise((resolve, reject) => {
            this.http.post(routes.apiFindRegisteredUser, body, { headers: TokenInterceptor.buildNoTokenHeaders() })
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    }
                ).catch((error) => {
                    reject(error);
                });
        });

    }



}
