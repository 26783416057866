import { EventEmitter, Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Users class manages connected users list, icons and data
 */

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    private email = '';
    private colors: Array<string> = [
        '#dc4f4b', '#8dbc50', '#8b64a6', '#00aec8',
        '#e00000', '#ff0000', '#ffc100',
        '#6ed142', '#00b247', '#00b2f3', '#0070c4'
    ];
    private colorIndex = -1;
    public users: Array<any>;
    public onIntroMapsLoaded = new EventEmitter<any>();
    public onUsersLoaded = new EventEmitter<any>();

    private emitted = false;

    constructor(
        private translateService: TranslateService
    ) {
        this.users = new Array<any>();
    }

    public init(email: string, resetUsers: boolean) {
        if (resetUsers) {
            this.users = new Array<any>();
        }
        this.email = email;
    }

    /* userEvent:
        {
          type: type,
          guid: guid,
          user: {
            email: userEmail,
            name: userName,
            icon: userIcon
            ---> ADDED: color: (user icon border color)
            ---> ADDED: isMe: true if it's me
            ---> ADDED: note: if me, add this note
          },
          timestamp: new Date().toISOString(),
          payload: payload, --> ''
        }
    */
    public applyUserEvent(userEvent: any) {
        if (userEvent) {
            if (userEvent.type === 'user_in') {
                this.addOrPromoteUser(userEvent);
            } else if (userEvent.type === 'user_out') {
                this.deleteFromUsersList(userEvent);
            } else if (userEvent.type === 'command') {
                this.addOrPromoteUser(userEvent);
            }
        }
        setTimeout(() => {
            if (!this.emitted) {
                this.emitted = true;
                this.onUsersLoaded.emit(null);
                setTimeout(() => {
                    this.emitted = false;
                }, 1000);
            }
        }, 100);
    }

    public findUserByEmail(email: string) {
        let found = false;
        let i = 0;
        while (!found && i < this.users.length) {
            if (this.users[i].user.email === email) {
                found = true;
            } else {
                i++;
            }
        }
        if (found) {
            return this.users[i];
        } else {
            return null;
        }
    }

    private addOrPromoteUser(userEvent: any) {
        if (userEvent && userEvent.user) {
            let color = this.deleteFromUsersList(userEvent);
            if (!color) {
                color = this.assignUserColor();
            }
            userEvent.user.color = color;
            userEvent.user.isMe = (userEvent.user.email === this.email);
            userEvent.user.note = '';
            if (userEvent.user.isMe) {
                userEvent.user.note = this.translateService.instant('USER_IS_ME');
            }
            this.users.unshift(userEvent);
        }
    }

    private deleteFromUsersList(userEvent: any) {
        let color = null;
        const idx = this.findUser(userEvent);
        if (idx >= 0) {
            // Retrieve old user color (keep)
            color = this.users[idx].user.color;
            this.users.splice(idx, 1);
        }
        return color;
    }

    private findUser(user: any) {
        let found = false;
        let i = 0;
        while (!found && i < this.users.length) {
            if (this.users[i].user.email === user.user.email) {
                found = true;
            } else {
                i++;
            }
        }
        if (found) {
            return i;
        } else {
            return -1;
        }
    }

    private assignUserColor(): string {
        let color = null;
        this.colorIndex++;
        if (this.colorIndex >= this.colors.length) {
            this.colorIndex = 0;
        }
        color = this.colors[this.colorIndex];
        return color;
    }

}
