<div class="dialog-preferences">
    <div class="edit-panel">

        <!--NODE FONT STYLE------------------------------------------------------------------->
        <div *ngIf="nodeTabSelected">
            <!--Node text EFFECTS-->
            <mat-icon class="top-icon" svgIcon="rectangle"></mat-icon>

            <div class="edit-title">
                <span translate>PREF_TEXT_TITLE</span>
            </div>

            <div class="edit-items">

                <div class="edit-label">
                    <span class="custom-span" translate>PREF_EFFECTS</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="effectsMenu">
                    <span class="custom-span">{{nodeFontStyle['effect'] | translate}}</span>
                </div>
                <mat-menu #effectsMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item (click)="editDefaultStyle('title','effect','PREF_EFFECT_NONE')">
                        <span>{{'PREF_EFFECT_NONE' | translate}}</span>
                    </div>
                    <div mat-menu-item (click)="editDefaultStyle('title','effect','PREF_EFFECT_BOLD')">
                        <span>{{'PREF_EFFECT_BOLD' | translate}}</span>
                    </div>
                </mat-menu>
            </div>
            <!--Node text FONT-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_FONT</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="fontMenu">
                    <span [style.font-family]="nodeFontStyle['font']" class="custom-span">{{nodeFontStyle['font']}}</span>
                </div>
                <mat-menu #fontMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item *ngFor="let font of fontFamilies" (click)="editDefaultStyle('title','font',font.name)">
                        <span [style.font-family]="font.name">{{font.name}}</span>
                    </div>
                </mat-menu>
            </div>
            <!--Node text SIZE-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_SIZE</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="sizeMenu">
                    <span class="custom-span">{{nodeFontStyle['size']}}</span>
                </div>
                <mat-menu #sizeMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item *ngFor="let size of fontSizes" (click)="editDefaultStyle('title','size',size.value)">
                        <span>{{size.value}}</span>
                    </div>
                </mat-menu>
            </div>
            <!--Node text COLOR-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_COLOR</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <mcc-color-picker class="under" [selectedColor]="nodeFontStyle['color']" [hideButtons]="hideButtons" (clickOut)="cancelSelection()" (change)="editDefaultStyle('title','color',$event)" [usedSizeColors]="usedSizeColors" [usedColorLabel]="usedColorLabel" hideEmptyUsedColors [hideColorPickerSelector]="true" [hideHexForms]="true">
                    <mcc-color-picker-collection [colors]="colors" [size]="colors.length"></mcc-color-picker-collection>
                </mcc-color-picker>
            </div>

            <!--ASPETTO-->
            <div class="edit-title">
                <span translate>PREF_ASPECT_TITLE</span>
            </div>
            <!--Aspect - Shape-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_SHAPE</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="shapesMenu">
                    <span class="custom-span">{{nodeAspect['shape'] | translate}}</span>
                </div>
                <mat-menu #shapesMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item (click)="editDefaultNodeAspect('shape','PREF_SHAPE_RECT')">
                        <span translate>PREF_SHAPE_RECT</span>
                    </div>
                    <div mat-menu-item (click)="editDefaultNodeAspect('shape','PREF_SHAPE_ELLIPSE')">
                        <span translate>PREF_SHAPE_ELLIPSE</span>
                    </div>
                    <div mat-menu-item (click)="editDefaultNodeAspect('shape','PREF_SHAPE_ROUNDRECT')">
                        <span translate>PREF_SHAPE_ROUNDRECT</span>
                    </div>
                </mat-menu>
            </div>
            <!--Aspect - Background color-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_BACKGROUND_COLOR</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <mcc-color-picker class="under" [selectedColor]="nodeAspect['background_color']" [hideButtons]="hideButtons" (clickOut)="cancelSelection()" (change)="editDefaultNodeAspect('background_color',$event)" [usedSizeColors]="usedSizeColors" [usedColorLabel]="usedColorLabel" hideEmptyUsedColors [hideColorPickerSelector]="true" [hideHexForms]="true">
                    <mcc-color-picker-collection [colors]="colors" [size]="colors.length"></mcc-color-picker-collection>
                </mcc-color-picker>
            </div>
            <!--Aspect - Border color-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_BORDER_COLOR</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <mcc-color-picker class="under" [selectedColor]="nodeAspect['border_color']" [hideButtons]="hideButtons" (clickOut)="cancelSelection()" (change)="editDefaultNodeAspect('border_color',$event)" [usedSizeColors]="usedSizeColors" [usedColorLabel]="usedColorLabel" hideEmptyUsedColors [hideColorPickerSelector]="true" [hideHexForms]="true">
                    <mcc-color-picker-collection [colors]="colors" [size]="colors.length"></mcc-color-picker-collection>
                </mcc-color-picker>
            </div>

            <!--Immagine Nodo-->
            <div class="edit-title">
                <span translate>PREF_NODE_IMAGE_TITLE</span>
            </div>
            <div class="edit-label">
                <div class="desc-item" translate>PREF_DESC_NODE_IMAGE</div>
            </div>
            <div class="inline-center">
                <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [(value)]="framedImage" class="toggle-group-shape">
                    <mat-button-toggle class="option-button" (click)="clicked(false)" [value]="false">
                        <div class="option-button-title">
                            <span class="option-button-title" translate>WITHOUT_FRAME</span>
                        </div>
                        <img class="option-button-image" src="../../assets/toolbar/senza_cornice.png" />
                    </mat-button-toggle>
                    <mat-button-toggle class="option-button" (click)="clicked(true)" [value]="true">
                        <div class="option-button-title">
                            <span class="option-button-title" translate>WITH_FRAME</span>
                        </div>
                        <img class="option-button-image" src="../../assets/toolbar/con_cornice.png" />
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div *ngIf="nodeTabSelected" class="restore-button-container">
            <button mat-stroked-button (click)="restoreDefault('title')">{{'PREF_RESET' | translate}}</button>
        </div>
        <!-- <button mat-flat-button *ngIf="imageTabSelected" class="button-restore" (click)="restoreDefault('image')">{{'PREF_RESET' | translate}}</button> -->
        <!--EDGE TEXT STYLE-->
        <div *ngIf="edgeTabSelected">
            <mat-icon class="top-icon">call_made</mat-icon>
            <!--TESTO-->
            <div class="edit-title">
                <span translate>PREF_TEXT_TITLE</span>
            </div>
            <!--Edge text EFFECTS-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_EFFECTS</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="effectsMenu">
                    <span class="custom-span">{{edgeFontStyle['effect'] | translate}}</span>
                </div>
                <mat-menu #effectsMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item (click)="editDefaultStyle('edge','effect','PREF_EFFECT_NONE')">
                        <span translate>PREF_EFFECT_NONE</span>
                    </div>
                    <div mat-menu-item (click)="editDefaultStyle('edge','effect','PREF_EFFECT_BOLD')">
                        <span translate>PREF_EFFECT_BOLD</span>
                    </div>
                </mat-menu>
            </div>

            <!--Edge text FONTS------------------------------------------------------------------->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_FONT</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="fontMenu">
                    <span [style.font-family]="edgeFontStyle['font']" class="custom-span">{{edgeFontStyle['font']}}</span>
                    <mat-menu #fontMenu="matMenu" [overlapTrigger]="false">
                        <mat-option *ngFor="let font of fontFamilies" [value]="font.name" (click)="editDefaultStyle('edge','font',font.name)">
                            <span [style.font-family]="font.name">{{font.name}}</span>
                        </mat-option>
                    </mat-menu>
                </div>
            </div>
            <!--Edge text SIZE-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_SIZE</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="sizeMenu">
                    <span class="custom-span">{{edgeFontStyle['size']}}</span>
                </div>
                <mat-menu #sizeMenu="matMenu" [overlapTrigger]="false">
                    <mat-option *ngFor="let size of fontSizes" [value]="size.value" (click)="editDefaultStyle('edge','size',size.value)">
                        {{size.value}}
                    </mat-option>
                </mat-menu>
            </div>
            <!--Edge text COLOR-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_COLOR</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <mcc-color-picker class="under" [selectedColor]="edgeFontStyle['color']" [hideButtons]="hideButtons" (clickOut)="cancelSelection()" (change)="editDefaultStyle('edge','color',$event)" [usedSizeColors]="usedSizeColors" [usedColorLabel]="usedColorLabel" hideEmptyUsedColors [hideColorPickerSelector]="true" [hideHexForms]="true">
                    <mcc-color-picker-collection [colors]="colors" [size]="colors.length"></mcc-color-picker-collection>
                </mcc-color-picker>
            </div>

            <!--ASPETTO-->
            <div class="edit-title">
                <span translate>PREF_ASPECT_TITLE</span>
            </div>
            <!--Edge WIDTH-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_EDGE_WIDTH</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="edgeWidthMenu">
                    <span class="custom-span">{{edgeAspect['width']}}</span>
                </div>
                <mat-menu #edgeWidthMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item *ngFor="let w of edgeWidths" (click)="editDefaultEdgeAspect('width', w.value)">
                        <span>{{w.value}}</span>
                    </div>
                </mat-menu>
            </div>
            <!--Edge COLOR-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_COLOR</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <mcc-color-picker class="under" [selectedColor]="edgeAspect['color']" [hideButtons]="hideButtons" (clickOut)="cancelSelection()" (change)="editDefaultEdgeAspect('color',$event)" [usedSizeColors]="usedSizeColors" [usedColorLabel]="usedColorLabel" hideEmptyUsedColors [hideColorPickerSelector]="true" [hideHexForms]="true">
                    <mcc-color-picker-collection [colors]="colors" [size]="colors.length"></mcc-color-picker-collection>
                </mcc-color-picker>
            </div>
            <!--Edge TYPE-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_EDGE_TYPE</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="edgeTypeMenu">
                    <span class="custom-span">{{edgeAspect['type'] | translate}}</span>
                </div>
                <mat-menu #edgeTypeMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item (click)="editDefaultEdgeAspect('type','PREF_EDGE_STRAIGHT')">
                        <span translate>PREF_EDGE_STRAIGHT</span>
                    </div>
                    <div mat-menu-item (click)="editDefaultEdgeAspect('type','PREF_EDGE_CURVE')">
                        <span translate>PREF_EDGE_CURVE</span>
                    </div>
                </mat-menu>
            </div>
            <div class="edit-label">
                <div class="desc-item" translate>PREF_DESC_FREE_EDGE</div>
            </div>
            <div class="free-edge-container">
                <div class="free-edge">
                    <div>{{'FREE_EDGE' | translate}}</div>
                    <mat-slide-toggle color="accent" [checked]="edgeAspect['freeEdge']" id="FREE_EDGE" (change)="OnFreeEdgeToggleChange($event)"></mat-slide-toggle>
                </div>
            </div>
        </div>
        <div *ngIf="edgeTabSelected" class="restore-button-container">
            <button mat-stroked-button class="button-restore" (click)="restoreDefault('edge')">{{'PREF_RESET' | translate}}</button>
        </div>

        <!--DEEP TEXT STYLE------------------------------------------------------------------->
        <div *ngIf="deepTabSelected">
            <mat-icon class="top-icon material-icons-sharp" fontSet="material-icons-filled">chat</mat-icon>
            <!--deep text EFFECTS-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_EFFECTS</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="effectsMenu">
                    <span class="custom-span">{{deepFontStyle['effect'] | translate}}</span>
                </div>
                <mat-menu #effectsMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item (click)="editDefaultStyle('deep','effect','PREF_EFFECT_NONE')">
                        <span translate>PREF_EFFECT_NONE</span>
                    </div>
                    <div mat-menu-item (click)="editDefaultStyle('deep','effect','PREF_EFFECT_BOLD')">
                        <span translate>PREF_EFFECT_BOLD</span>
                    </div>
                </mat-menu>
            </div>
            <!--deep text FONTS-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_FONT</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="fontMenu">
                    <span [style.font-family]="deepFontStyle['font']" class="custom-span">{{deepFontStyle['font']}}</span>
                </div>
                <mat-menu #fontMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item *ngFor="let font of fontFamilies" (click)="editDefaultStyle('deep','font',font.name)">
                        <span [style.font-family]="font.name">{{font.name}}</span>
                    </div>
                </mat-menu>
            </div>
            <!--deep text SIZE-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_SIZE</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="sizeDeepMenu">
                    <span class="custom-span">{{deepFontStyle['size']}}</span>
                </div>
                <mat-menu #sizeDeepMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item *ngFor="let size of fontSizes" (click)="editDefaultStyle('deep','size',size.value)">
                        <span>{{size?.value}}</span>
                    </div>
                </mat-menu>
            </div>
            <!--deep text COLOR-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_COLOR</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <mcc-color-picker class="under" [selectedColor]="deepFontStyle['color']" [hideButtons]="hideButtons" (clickOut)="cancelSelection()" (change)="editDefaultStyle('deep','color',$event)" [usedSizeColors]="usedSizeColors" [usedColorLabel]="usedColorLabel" hideEmptyUsedColors [hideColorPickerSelector]="true" [hideHexForms]="true">
                    <mcc-color-picker-collection [colors]="colors" [size]="colors.length"></mcc-color-picker-collection>
                </mcc-color-picker>
            </div>
        </div>
        <div *ngIf="deepTabSelected" class="restore-button-container">
            <button mat-stroked-button class="button-restore" (click)="restoreDefault('deep')">{{'PREF_RESET' | translate}}</button>
        </div>
        <!--NOTE STYLE------------------------------------------------------------------->
        <div *ngIf="noteTabSelected">
            <mat-icon class="top-icon material-icons-sharp" fontSet="material-icons-filled">task</mat-icon>
            <!--note EFFECTS-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_EFFECTS</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="effectsMenu">
                    <span class="custom-span">{{noteFontStyle['effect'] | translate}}</span>
                </div>
                <mat-menu #effectsMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item (click)="editDefaultStyle('note','effect','PREF_EFFECT_NONE')">
                        <span translate>PREF_EFFECT_NONE</span>
                    </div>
                    <div mat-menu-item (click)="editDefaultStyle('note','effect','PREF_EFFECT_BOLD')">
                        <span translate>PREF_EFFECT_BOLD</span>
                    </div>
                </mat-menu>
            </div>
            <!--note FONTS-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_FONT</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="fontMenu">
                    <span [style.font-family]="noteFontStyle['font']" class="custom-span">{{noteFontStyle['font']}}</span>
                </div>
                <mat-menu #fontMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item *ngFor="let font of fontFamilies" (click)="editDefaultStyle('note','font',font.name)">
                        <span [style.font-family]="font.name">{{font.name}}</span>
                    </div>
                </mat-menu>
            </div>
            <!--note SIZE-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_SIZE</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div mat-menu-item class="edit-value" [matMenuTriggerFor]="sizeMenu">
                    <span class="custom-span">{{noteFontStyle['size']}}</span>
                </div>
                <mat-menu #sizeMenu="matMenu" [overlapTrigger]="false">
                    <div mat-menu-item *ngFor="let size of fontSizes" (click)="editDefaultStyle('note','size',size.value)">
                        <span>{{size.value}}</span>
                    </div>
                </mat-menu>
            </div>
            <!--note COLOR-->
            <div class="edit-items">
                <div class="edit-label">
                    <span class="custom-span" translate>PREF_COLOR</span>
                </div>
                <div>
                    <div class="arrow-right"></div>
                </div>
                <div class="color-right">
                    <mcc-color-picker class="under" [selectedColor]="noteFontStyle['color']" [hideButtons]="hideButtons" (clickOut)="cancelSelection()" (change)="editDefaultStyle('note','color',$event)" [usedSizeColors]="usedSizeColors" [usedColorLabel]="usedColorLabel" hideEmptyUsedColors [hideColorPickerSelector]="true" [hideHexForms]="true">
                        <mcc-color-picker-collection [colors]="colors" [size]="colors.length"></mcc-color-picker-collection>
                    </mcc-color-picker>
                </div>
            </div>
        </div>
        <div *ngIf="noteTabSelected" class="restore-button-container">
            <button mat-stroked-button class="button-restore" (click)="restoreDefault('note')">{{'PREF_RESET' | translate}}</button>
        </div>
        <!--MATEMATICA ------------------------------------------------------------------->
        <div *ngIf="isLab && mathTabSelected">
            <mat-icon class="top-icon material-icons-sharp" fontSet="material-icons-outlined">functions</mat-icon>
            <div class="desc-item desc-title" translate>PREF_MATH_KEYBOARDS</div>
            <div class="desc-item" translate>PREF_DESC_MATH_KEYBOARDS</div>
            <div class="inline-center">
                <mat-button-toggle-group [hideSingleSelectionIndicator]="true" class="option-group toggle-group-shape" (change)="onKeyboardLayoutToggleGroupChanged($event)" [value]="baseKeyboardType">
                    <mat-button-toggle #advancedKeyboardLayout class="option-button" mat-button-toggle value="BASIC">
                        <div class="option-button-title">
                            <span class="option-button-title" translate>PREF_MATH_KEYBOARD_TYPE_BASIC</span>
                        </div>
                        <img class="option-button-image" src="../../assets/toolbar/tastiera_completa.png" />
                    </mat-button-toggle>
                    <mat-button-toggle #baseKeyboardLayout class="option-button" mat-button-toggle value="PRIMARY">
                        <div class="option-button-title">
                            <span class="option-button-title" translate>PREF_MATH_KEYBOARD_TYPE_PRIMARY</span>
                        </div>
                        <img class="option-button-image" src="../../assets/toolbar/tastiera_base.png" />
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <div class="desc-keyb1" translate>PREF_DESC_MATH_KEYBOARD_TYPE_BASIC</div>
                <div class="desc-keyb2" translate>PREF_DESC_MATH_KEYBOARD_TYPE_PRIMARY</div>
            </div>
            <div class="math-keyboards-container">
                <div *ngIf="baseKeyboardType==='BASIC'" class="math-keyboard">
                    <mat-slide-toggle color="accent" [checked]="isMathSetsKbChecked" id="SETS" (change)="OnKeyboardToggleChange($event)"></mat-slide-toggle>
                    <div>{{'MATH_SETS_KEYBOARD' | translate}}</div>
                </div>
                <div *ngIf="baseKeyboardType==='BASIC'" class="math-keyboard">
                    <mat-slide-toggle color="accent" [checked]="isMathGeometryKbChecked" id="GEOMETRY" (change)="OnKeyboardToggleChange($event)"></mat-slide-toggle>
                    <div>{{'MATH_GEOMETRY_KEYBOARD' | translate}}</div>
                </div>
                <div *ngIf="baseKeyboardType==='BASIC'" class="math-keyboard">
                    <mat-slide-toggle color="accent" [checked]="isMathDisequationsKbChecked" id="DISEQUATIONS" (change)="OnKeyboardToggleChange($event)"></mat-slide-toggle>
                    <div>{{'MATH_DISEQUATIONS_KEYBOARD' | translate}}</div>
                </div>
                <div *ngIf="baseKeyboardType==='BASIC'" class="math-keyboard">
                    <mat-slide-toggle color="accent" [checked]="isMathPhysicsKbChecked" id="PHYSICS" (change)="OnKeyboardToggleChange($event)"></mat-slide-toggle>
                    <div>{{'MATH_PHYSICS_KEYBOARD' | translate}}</div>
                </div>
                <div *ngIf="baseKeyboardType==='BASIC'" class="math-keyboard">
                    <mat-slide-toggle color="accent" [checked]="isMathAnalysisKbChecked" id="ANALYSIS" (change)="OnKeyboardToggleChange($event)"></mat-slide-toggle>
                    <div>{{'MATH_ANALYSIS_KEYBOARD' | translate}}</div>
                </div>
                <div *ngIf="baseKeyboardType==='BASIC'" class="math-keyboard">
                    <mat-slide-toggle color="accent" [checked]="isMathTrigonometryKbChecked" id="TRIGONOMETRY" (change)="OnKeyboardToggleChange($event)"></mat-slide-toggle>
                    <div>{{'MATH_TRIGONOMETRY_KEYBOARD' | translate}}</div>
                </div>
                <div *ngIf="baseKeyboardType==='BASIC'" class="math-keyboard">
                    <mat-slide-toggle color="accent" [checked]="isMathLogExpKbChecked" id="LOG_EXP" (change)="OnKeyboardToggleChange($event)"></mat-slide-toggle>
                    <div>{{'MATH_LOG_EXP_KEYBOARD' | translate}}</div>
                </div>
                <div *ngIf="baseKeyboardType==='BASIC'" class="math-keyboard">
                    <mat-slide-toggle color="accent" [checked]="isMathGreekKbChecked" id="GREEK" (change)="OnKeyboardToggleChange($event)"></mat-slide-toggle>
                    <div>{{'MATH_GREEK_KEYBOARD' | translate}}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="edit-verticaltabs">
        <button mat-flat-button *ngIf="!nodeTabSelected" class="button-tab" (click)="selectTab('node')">{{'PREF_NODE_TITLE' | translate}}</button>
        <div *ngIf="nodeTabSelected" class="selected-tab">{{'PREF_NODE_TITLE' | translate}}</div>

        <button mat-flat-button *ngIf="!edgeTabSelected" class="button-tab" (click)="selectTab('edge')">{{'PREF_EDGE' | translate}}</button>
        <div *ngIf="edgeTabSelected" class="selected-tab">{{'PREF_EDGE' | translate}}</div>

        <button mat-flat-button *ngIf="!deepTabSelected" class="button-tab" (click)="selectTab('deep')">{{'PREF_DEEP' | translate}}</button>
        <div *ngIf="deepTabSelected" class="selected-tab">{{'PREF_DEEP' | translate}}</div>

        <button mat-flat-button *ngIf="!noteTabSelected" class="button-tab" (click)="selectTab('note')">{{'PREF_NOTE' | translate}}</button>
        <div *ngIf="noteTabSelected" class="selected-tab">{{'PREF_NOTE' | translate}}</div>

        <button mat-flat-button *ngIf="isLab && !mathTabSelected" class="button-tab" (click)="selectTab('math')">{{'PREF_MATH' | translate}}</button>
        <div *ngIf="mathTabSelected" class="selected-tab">{{'PREF_MATH' | translate}}</div>
    </div>
</div>
