<h2 mat-dialog-title class="title-text">{{title}}</h2>
<div mat-dialog-content mat-tipography>
    <div class="message" [innerHtml]="html"></div>
</div>
<div mat-dialog-actions>
    <button *ngIf="mode==='YESNO'" mat-stroked-button (click)="dialogRef.close('no')">
        <div translate>BUTTON_NO</div>
    </button>
    <button *ngIf="mode==='YESNO'" mat-flat-button color="primary" cdkFocusInitial (click)="dialogRef.close('yes')">
        <span translate>BUTTON_YES</span>
    </button>
    <button *ngIf="mode==='OK'" mat-flat-button color="primary" cdkFocusInitial (click)="closeDialog()">
        <span translate>BUTTON_OK</span>
    </button>
</div>
