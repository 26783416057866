<div [formGroup]="dateForm" [hidden]="!loaded">
    <div class="access-date">

        <span class="access-col1" translate>IC_USER_TYPE_TITLE</span>
        <mat-form-field appearance="fill" class="access-row1 access-col2">
            <select matNativeControl formControlName="selectUserType">
                <option class="user-type-option" *ngFor="let u of userTypes" [ngValue]="u.value">{{u.name}}</option>
            </select>
        </mat-form-field>

        <span class="access-col1" translate>ACCESSCONFIG_PLAN</span>
        <mat-form-field appearance="fill" class="access-col2">
            <select matNativeControl formControlName="planId">
                <option *ngFor="let p of planIds" [ngValue]="p.id">{{p.name | translate}}</option>
            </select>
        </mat-form-field>

        <!-- <span class="access-col1" translate>ACCESSCONFIG_OCR_QUOTA</span>
        <div class="access-col2">
            <input class="form-input" type="number" autocomplete="off" spellcheck="false" formControlName="ocrMathScanQuota" />
        </div>

        <span class="access-col1" translate>ACCESSCONFIG_OCR_POLICY</span>
        <mat-form-field appearance="fill" class="access-col2">
            <select matNativeControl formControlName="ocrMathScanPolicy">
                <option *ngFor="let s of scanPolicies" [ngValue]="s.id">{{s.name | translate}}</option>
            </select>
        </mat-form-field> -->

        <span class="access-col1" translate>START_DATE</span>
        <mat-form-field class="date-field access-col2">
            <input matInput [matDatepicker]="startDate" autocomplete="off" spellcheck="false" formControlName="startDate" readonly />
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <span *ngIf="f.startDate.errors && f.startDate.errors['required']" class="invalid-feedback" translate>FIELD_REQUIRED</span>
        <span *ngIf="!f.startDate.errors"> </span>

        <span class="access-col1" translate>EXPIRE_DATE</span>
        <mat-form-field class="date-field access-col2">
            <input matInput [matDatepicker]="expireDate" autocomplete="off" spellcheck="false" formControlName="expireDate" readonly />
            <mat-datepicker-toggle matSuffix [for]="expireDate"></mat-datepicker-toggle>
            <mat-datepicker #expireDate></mat-datepicker>
        </mat-form-field>
        <span *ngIf="f.expireDate.errors && f.expireDate.errors['required']" class="invalid-feedback" translate>FIELD_REQUIRED</span>
        <span *ngIf="!f.expireDate.errors"> </span>

        <span class="access-col1" translate>ACCESSCONFIG_ORIGIN</span>
        <mat-form-field appearance="fill" class="access-col2">
            <select matNativeControl formControlName="origin" (change)="originChanged($event)">
                <option *ngFor="let o of origins" [ngValue]="o.id">{{o.name | translate}}</option>
            </select>
        </mat-form-field>

        <span class="access-col1" translate>SEND_MAIL</span>
        <input class="access-col2" type="checkbox" spellcheck="false" formControlName="sendMail" />

    </div>
</div>
