import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { AccessConfigService } from '../access-config.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessDto } from '../common/access-dto';
import { AdminConfigComponent } from '../common/admin-config/admin-config.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrderDto } from '../common/order-dto';
import { Logger } from '../../core/logger.service';
import { Router } from '@angular/router';
import { OrderListComponent } from '../common/order-list/order-list.component';

const logger: Logger = new Logger('spain-desktop');

@Component({
    selector: 'app-spain-desktop',
    templateUrl: './spain-desktop.component.html',
    styleUrls: ['./spain-desktop.component.scss']
})
export class SpainDesktopComponent implements OnInit, OnDestroy {

    public isLoading = false;
    public user: string | undefined;
    public role: string | undefined;
    private order: OrderDto | undefined;
    orderId: string | undefined;
    randomId: string | undefined;
    public accessName: string | undefined;
    public panelOpenState = false;
    accessList: AccessDto | undefined;
    accessConfigForm: FormGroup = this.formBuilder.group({
        accessName: ['', Validators.required],
    });
    @ViewChild(AdminConfigComponent) adminconfig: AdminConfigComponent | undefined;
    @ViewChild(OrderListComponent) orderlist: OrderListComponent | undefined;

    createOrderSubscription: Subscription | undefined;
    saveAccesslistSubscription: Subscription | undefined;

    @HostListener('window:beforeunload', ['$event'])
    onbeforeunload(event: any) {
        if (this.adminconfig?.parentForm.dirty || this.accessConfigForm.dirty) {
            const confirmationMessage = '\o/';
            event.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
            return confirmationMessage;              // Gecko, WebKit, Chrome <34
        } else return '';
    }

    constructor(private authenticationService: AuthenticationService, private router: Router, private accessConfigService: AccessConfigService, private translateService: TranslateService, private formBuilder: FormBuilder) {
        this.panelOpenState = false;
    }

    ngOnInit() {
        // this.isLoading = true;
        this.user = this.authenticationService.getUserName();
        this.role = this.authenticationService.credentials?.role;
        if (this.role !== 'spain' && this.role !== 'anastasis' && this.role !== 'admin') {
            this.router.navigate(['/home']);
        }

        this.accessConfigForm = this.formBuilder.group({
            accessName: ['', Validators.required],
        });
    }

    ngOnDestroy() {
        if (this.createOrderSubscription) { this.createOrderSubscription.unsubscribe(); }
        if (this.saveAccesslistSubscription) { this.saveAccesslistSubscription.unsubscribe(); }
    }
    startLoadingList() {
        this.isLoading = true;
    }

    endLoadingList() {
        this.isLoading = false;
    }

    saveData() {

        this.order = new OrderDto('');
        this.order.orderId = new Date().toISOString().replace(/[^a-zA-Z0-9@|]/g, '-');
        this.order.origin = 'spain';
        this.order.userEmail = this.authenticationService.getUserEmail();
        this.order.domainLics = 0;
        this.order.fullName = this.authenticationService.getUserName();
        this.order.licenseType = 'd';
        this.order.networkUserId = 1;
        this.order.refOrderId = '';
        this.order.slotCount = 5;
        this.isLoading = true;

        this.accessList = new AccessDto('');
        this.accessList.name = this.accessConfigForm?.get('accessName')?.value;
        this.accessList.admin.eMail = this.adminconfig?.parentForm.get('adminEmail')?.value;
        this.accessList.admin.name = this.adminconfig?.parentForm.get('adminName')?.value;
        this.accessList.admin.notes = this.adminconfig?.parentForm.get('adminNotes')?.value;
        this.accessList.domain = '@' + this.adminconfig?.parentForm.get('domain')?.value;
        this.randomId = 'security-override';

        // Days a 365 verrà ignorato perché la scadenza viene letta a seconda del ruolo (Spain ha scadenza fissa scritta sul DB)
        // Lo lascio perché se un operatore anastasis usa la scrivania Spain al momento deve creare ordini con scadenza annuale.
        const email = this.authenticationService.credentials?.username ? this.authenticationService.credentials.username : '';
        this.createOrderSubscription = this.accessConfigService.createOrder(this.order, 365, email, true)
            .subscribe((res: any) => {
                if (res.ok && this.order && this.randomId && this.authenticationService.credentials) {
                    this.saveAccesslistSubscription = this.accessConfigService.createAccess(this.order.orderId, this.randomId, this.accessList, this.authenticationService.getUserEmail(), true).subscribe((resp: any) => {
                        console.log(resp);
                        this.isLoading = false;
                        if (resp.ok === true) {
                            alert(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_SUCCESS'));
                            this.accessConfigForm.get('accessName')?.setValue('');
                            this.orderlist?.reloadData();
                        } else {
                            alert(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL'));
                        }
                    }, (error: any) => {
                        this.isLoading = false;
                        logger.error(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL') + '\n' + error.message);
                    });
                } else {
                    this.isLoading = false;
                    logger.error(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL'));
                }
            }, (error: any) => {
                this.isLoading = false;
                logger.error(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL') + '\n' + error.message);
            });
    }

    get f() { return this.accessConfigForm.controls; }

    enabledForm() {
        let en = false;
        if (this.accessConfigForm && this.adminconfig) {
            en = this.accessConfigForm.valid && this.adminconfig.parentForm.valid;
            if (en) {
                en = en && (!this.accessConfigForm.pristine || !this.adminconfig.parentForm.pristine);
            }
        }
        return en;
    }

    enabledDetail() {
        let en = false;
        if (this.accessConfigForm && this.adminconfig) {
            en = (!this.accessConfigForm.pristine || !this.adminconfig.parentForm.pristine);
        }
        return en;
    }

    getRoleType() {
        return this.translateService.instant('ROLE_' + (this.role?.toLocaleUpperCase() || 'USER'));
    }

    onSubmit() {
        if (this.accessConfigForm.invalid || this.adminconfig?.parentForm.invalid) {
            return;
        }

        this.panelOpenState = false;
        this.saveData();

    }

}
