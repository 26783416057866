<button *ngIf="mapStateService.currentRecentGroup!=='trash'" mat-menu-item (click)="open()">
    <mat-icon color="primary" fontSet="material-icons-outlined">open_in_new</mat-icon>
    <span *ngIf="!isUserExpired" translate>MAIN_MENU_FILE-OPEN-MAP</span>
    <span *ngIf="isUserExpired" translate>MAIN_MENU_FILE-VIEW-MAP</span>
</button>
<hr *ngIf="mapStateService.currentRecentGroup!=='trash'" />
<button *ngIf="mapStateService.currentRecentGroup!=='trash'" mat-menu-item color="primary" (click)="shareMap()">
    <mat-icon color="primary">share</mat-icon>
    <span translate>SHARE_RECENT</span>
</button>
<button *ngIf="mapStateService.currentRecentGroup!=='trash' && !isUserExpired && recentMap.isMine" mat-menu-item (click)="renameRecent()">
    <mat-icon color="primary" svgIcon="rename-box"></mat-icon>
    <span translate>RENAME_RECENT_MAP</span>
</button>
<hr *ngIf="mapStateService.currentRecentGroup!=='trash'" />
<button *ngIf="mapStateService.currentRecentGroup!=='trash' && !isUserExpired" mat-menu-item (click)="createCopy()">
    <mat-icon color="primary" svgIcon="content-duplicate"></mat-icon>
    <span translate>CREATE_RECENT_COPY</span>
</button>
<button *ngIf="mapStateService.currentRecentGroup!=='trash'" mat-menu-item color="primary" (click)="downloadMap()">
    <mat-icon color="primary" svgIcon="download"></mat-icon>
    <span color="primary" translate>DOWNLOAD_RECENT</span>
</button>
<hr *ngIf="mapStateService.currentRecentGroup!=='trash'" />
<!-- SPOSTA NEL CESTINO -->
<button *ngIf="mapStateService.currentRecentGroup!=='trash' && recentMap.isMine" mat-menu-item color="primary" (click)="trashRecent()">
    <mat-icon color="primary" svgIcon="delete"></mat-icon>
    <span color="primary" translate>TRASH_RECENT_MAP</span>
</button>
<!-- RIMUOVI DALLA LISTA -->
<button *ngIf="mapStateService.currentRecentGroup!=='trash' && !recentMap.isMine" mat-menu-item color="primary" (click)="removeRecent()">
    <mat-icon color="primary" svgIcon="broom"></mat-icon>
    <span color="primary" translate>REMOVE_RECENT_MAP</span>
</button>
<!-- CANCELLA MAPPA -->
<button *ngIf="mapStateService.currentRecentGroup==='trash'" mat-menu-item color="primary" (click)="deleteRecent()">
    <mat-icon color="primary">delete_forever</mat-icon>
    <span color="primary" translate>DELETE_RECENT_MAP</span>
</button>
<!-- RIPRISTINA -->
<button *ngIf="mapStateService.currentRecentGroup==='trash'" mat-menu-item color="primary" (click)="untrashRecent()">
    <mat-icon color="primary">restore</mat-icon>
    <span color="primary" translate>UNTRASH_RECENT_MAP</span>
</button>
<!-- <button *ngIf="recentMap.isPubliclyShared && recentMap.isMine" mat-menu-item color="primary" (click)="deleteSharing()">
    <mat-icon color="primary" svgIcon="earth-off"></mat-icon>
    <span color="primary" translate>STOP_SHARING_MAP</span>
</button> -->
