<h2 mat-dialog-title class="title-text">{{title}}</h2>
<div mat-dialog-content class="mat-typography">
    <div class="message" [innerHTML]="message"></div>
</div>
<mat-dialog-actions>
    <button mat-stroked-button color="warn" (click)="dialogRef.close(ok)" translate>
        <div translate>{{exitCaption}}</div>
    </button>
    <button mat-flat-button color="primary" *ngIf="waitCaption" cdkFocusInitial (click)="dialogRef.close(false)">
        <div>{{waitCaption}}</div>
    </button>
</mat-dialog-actions>
