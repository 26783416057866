import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SmeService } from '../core/sme/sme.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { MapOperationType } from '../shared/map-operations';
import { TranslateService } from '@ngx-translate/core';
import { MessageBoxService } from '../shared/dialog/messagebox.service';
import { extract } from '../core/i18n.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
    selector: 'app-map-open',
    templateUrl: './map-open.component.html',
    styleUrl: './map-open.component.scss'
})
export class MapOpenComponent implements OnInit, OnDestroy {

    public isLoading = false;
    public error = ''

    paramMapSubscription: Subscription | undefined;
    statOpenMapSubscription: Subscription | undefined;

    mapId = '';


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private messageBoxService: MessageBoxService,
        private smeService: SmeService
    ) {
    }
    setLanguage(arg0: string) {
        throw new Error('Method not implemented.');
    }

    ngOnInit() {
        this.paramMapSubscription = this.route.params.subscribe((params: Params) => {
            this.mapId = params['mapId'];
            console.log('Opening map: ' + this.mapId);
            this.prepareAndOpenMap(this.mapId);
        });
    }

    ngOnDestroy(): void {
        if (this.paramMapSubscription) { this.paramMapSubscription.unsubscribe(); }
        if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
    }

    private prepareAndOpenMap(mapId: string) {
        this.isLoading = true;
        const email = this.authenticationService.credentials?.username + '';
        const userId = this.authenticationService.credentials?.firebaseUserId + '';
        // this.smeService.prepareMap(id).subscribe({
        this.smeService.getShareData(email, userId, mapId).subscribe({
            next: (_data: any) => {
                const data = _data;
                const viewMode = (data.shareData.isReadonly || this.authenticationService.isUserExpired() || !data.shareData.isMine);
                this.openMap(mapId, viewMode, email);
            },
            error: (error: any) => {
                if (error.status === 504) {
                    this.showError('GATEWAY_TIMEOUT');
                } else if (error.status === 500) {
                    if (error.error.message === 'Invalid Credentials') {
                        this.showError('ERROR_INVALID_CREDENTIALS');
                    } else if (error.error.message === 'GoogleId not found') {
                        this.showError('ERROR_GOOGLEID_NOT_FOUND');
                    } else {
                        this.showError('ERROR_OPEN_MAP');
                    }
                } else if (error && (error.error && error.error.code || error.status)) {
                    const errorCode = (error.error.code ? error.error.code : error.status);
                    this.showError(errorCode);
                } else {
                    this.showError('ERROR_OPEN_MAP');
                }
                this.isLoading = false;
            }
        });
    }

    private openMap(mapId: string, viewMode: boolean, email: string) {
        if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
        this.statOpenMapSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_OPENMAP, email)
            .subscribe({
                next: (_data: any) => {
                    if (viewMode) {
                        console.log('OPENING MAP IN VIEW MODE');
                        this.router.navigate(['map-view/', mapId]);
                        this.isLoading = false;
                    }
                    else {
                        console.log('OPENING MAP IN EDIT MODE');
                        this.router.navigate(['map-edit/', mapId]);
                        this.isLoading = false;
                    }
                },
                error: (err: any) => {
                    this.isLoading = false;
                    this.showError('ERROR_OPEN_MAP');
                }
            });
    }

    private showError(errorCode: string) {
        try {
            // JHUBA ERROR DEBUG---------------------------------------------------
            // throw new Error('cippo');
            // JHUBA ERROR DEBUG---------------------------------------------------
            this.isLoading = false;
            this.error = this.translateService.instant(extract(errorCode));
            const title = this.translateService.instant('WARNING');
            const message = this.translateService.instant(errorCode);
            this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK, title, message).subscribe(() => {
                this.router.navigate(['/home']);
            });
        } catch (err) {
            // this.error = 'ERROR: ' + errorCode;
            this.messageBoxService.showGenericError(errorCode).subscribe(() => {
                this.router.navigate(['/home']);
            });
        }
    }


}
