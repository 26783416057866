import { Component, OnInit, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SmeService } from '../core/sme/sme.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { SmService } from 'supermappe-core';
import { PrintMapService } from '../shared/commands/print-map.service';
import { SmEventBroker } from '../shared/sm-event-broker';
import { Title } from '@angular/platform-browser';
import { MapStateService } from '../shared/map-state.service';
import { Logger } from '../core/logger.service';
import { extract, I18nService } from '../core/i18n.service';
import { MapFoldingService } from '../shared/commands/map-folding.service';
import { ToggleFullscreenService } from '../shared/commands/toggle-fullscreen.service';
import { YoutubeService } from '../shared/commands/youtube.service';
import { ModalService } from '../shared/links-menu/modal-service';
import { MapShowService } from './map-show.service';
import { MessageBoxService } from '../shared/dialog/messagebox.service';
import { DeviceService } from '../core/device.service';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LinkData } from '../shared/links-menu-data';
import { MatomoTracker } from 'ngx-matomo-client';
import { Point } from '../shared/point';
import { FirebaseAuthService } from '../core/firebase/firebase-auth.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

const logger: Logger = new Logger('MapShowComponent');

const SMX_CONFIG = {
    'READ_ONLY': true,
    'SHOW_MODE': true,
    'SELECTION_MODE': 'SINGLE',
    'CONTINUOUS_IMAGE_UPDATE': false,
    'EDIT_ON_TEXT': false,
    'FIT_WHOLE_PAGE': false,
    'STAMP_IMAGE_NAME': 'stamp-image',
    'MAP_QRCODE_SYMBOL_NAME': 'map-qrcode-symbol',
    'MAP_QRCODE_NAME': 'map-qrcode',
    'GDOC_QRCODE_SYMBOL_NAME': 'gdoc-qrcode-symbol',
    'GDOC_QRCODE_NAME': 'gdoc-qrcode',
    'INTERNAL_MOUSEWHEEL': true,
    'SHOW_DEEP_STARS': false,
    'SHOW_NODE_MATH': false,
    'SHOW_NODE_REC': false,
    'URL': {
        'ROOT': '',
        'MAPLIST': 'resources',
        'BASE': '', // JHUBA TOLTO PER NUOVE FUNCTION IL CORE NON DEVE METTERLO!!!!!
        'ASSETS': './assets'
    },
    'isMobile': false
};

@Component({
    selector: 'app-map-show',
    templateUrl: './map-show.component.html',
    styleUrls: ['./map-show.component.scss']
})

export class MapShowComponent implements OnInit, OnDestroy {

    //LAYOUT
    public compressedToolbar: boolean = false;

    public logoUrl: string;
    isVideoRecSupported = false;
    //obscured = false;
    isLoading = false;
    loaded = false;
    _mapName = '';
    readyToPrint = false;
    mapId = '';
    error = '';
    imageBase64: any;
    // JHUBA CHECK
    contextItems: Array<LinkData> = new Array<LinkData>();
    isReadonlyMode = false;
    isFullScreen = false;
    buttonPressed = -1;
    moved = false;

    currentUser = '';

    onErrorSubscription: Subscription | undefined;
    downloadJsonSubscription: Subscription | undefined;
    paramSubscription: Subscription | undefined;
    onImageUpdatedSubscription: Subscription | undefined;
    interactionModeSubscription: Subscription | undefined;
    onDeepEditSubscription: Subscription | undefined;
    onShowImageSubscription: Subscription | undefined;
    selectionDataSubscription: Subscription | undefined;
    changedBackgroundcolorSubscription: Subscription | undefined;

    @ViewChild('mapcontainer') mapContainerElement: ElementRef | undefined;
    private startZoom = 1;
    getShareDataSubscription: any;

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        if (this.smService.sm) {
            this.smService.resizeCanvas();
            this.youtubeService.videoPictureInPicture();
            this.updateCanvasOrigin();
            this.smService.resizeCanvas();
        }
    }

    @HostListener('window:afterprint', ['$event'])
    onafterprint(event: any) {
        this.readyToPrint = false;
        this.imageBase64 = null;
        this.removeImgQrCode();
    }

    private removeImgQrCode() {
        let oldel = document.getElementById('map-qrcode');
        if (oldel) { oldel.remove(); }
        oldel = document.getElementById('gdoc-qrcode');
        if (oldel) { oldel.remove(); }
    }


    @HostListener('window:beforeprint', ['$event'])
    onbeforeprint(event: any) {
        if (!this.readyToPrint) {
            this.printService.printMap(this.mapStateService.limitPrintSize, false);
        }
    }

    @HostListener('window:keydown', ['$event'])
    keyDownEvent(event: KeyboardEvent) {
        if (!this.loaded) { event.preventDefault(); }
        this.checkShortcuts(event, event.shiftKey, event.ctrlKey, event.altKey, event.keyCode);
    }

    @HostListener('document:contextmenu', ['$event'])
    hideDefaultCtxMenu() {
        return false;
    }

    @HostListener('window:mousedown', ['$event'])
    mouseDownEvent(event: MouseEvent) {
        if ((event.target as any).id === 'sm-canvas-base') {
            this.buttonPressed = event.button;
            this.moved = false;
        }
    }

    @HostListener('window:mouseup', ['$event'])
    mouseUpEvent(event: MouseEvent) {
        this.buttonPressed = event.button;
    }

    @HostListener('window:mousemove', ['$event'])
    mouseMoveEvent(event: MouseEvent) {
        if ((event.target as any).id === 'sm-canvas-base' && this.buttonPressed >= 0) {
            this.moved = true;
        }
    }



    @HostListener('window:storage')
    onStorageChange() {
        console.log('change...');
        if (this.firebaseAuthService.auth.currentUser !== null && this.currentUser !== this.firebaseAuthService.auth.currentUser?.email) {
            {
                this.authenticationService.reloadCredentials();
                this.currentUser = this.authenticationService.getUserEmail();
                this.openMap();
                // location.reload();npm start
            }
        }
    }

    openMap() {
        if (this.getShareDataSubscription) { this.getShareDataSubscription.unsubscribe(); }
        const email = (this.authenticationService.getUserEmail() !== '' ? this.authenticationService.getUserEmail() : 'anonymous');
        const firebaseUserId = this.authenticationService.credentials?.firebaseUserId + '';
        this.getShareDataSubscription = this.smeService.getShareData(email, firebaseUserId, this.mapId).subscribe(
            (data: any) => {
                this.mapStateService.isMine = (data && data.shareData ? data.shareData.isMine : false);
                this.mapStateService.isShared = (data && data.shareData ? data.shareData.isShared : false);
                this.mapStateService.readOnly = (data && data.shareData ? data.shareData.isReadonly : false);
                if (data.shareData.isMine || data.shareData.isShared) {
                    this.downloadMap();
                } else {
                    this.showError(this.translateService.instant(extract('ERROR_MAP_NOT_SHARED')));
                    this.mapStateService.setStateError(this.translateService.instant(extract('ERROR_MAP_NOT_SHARED')));
                }
            },
            (error: any) => {
                this.isLoading = false;
                if (error.status === 404) {
                    this.showError(this.translateService.instant(extract('ERROR_MAP_NOT_SHARED')));
                    this.mapStateService.setStateError(this.translateService.instant(extract('ERROR_MAP_NOT_SHARED')));
                } else {
                    this.showError('MAPEDIT_ERROR_GENERIC_MAP_LOAD');
                    this.mapStateService.setStateError(this.translateService.instant(extract('MAPEDIT_ERROR_GENERIC_MAP_LOAD')));
                }
            }
        );

    }

    constructor(
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private smeService: SmeService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private smService: SmService,
        private printService: PrintMapService,
        private mapFoldingService: MapFoldingService,
        private smEventBroker: SmEventBroker,
        private titleService: Title,
        private toggleFullscreenService: ToggleFullscreenService,
        public mapStateService: MapStateService,
        private youtubeService: YoutubeService,
        private modalService: ModalService,
        private mapShowService: MapShowService,
        private messageBoxService: MessageBoxService,
        private deviceService: DeviceService,
        private i18nService: I18nService,
        private renderer2: Renderer2,
        private firebaseAuthService: FirebaseAuthService,
        private tracker: MatomoTracker,
        private breakpointObserver: BreakpointObserver,
        @Inject(DOCUMENT) private _document: any) {
        this.logoUrl = (this.authenticationService.isLab() ? 'assets/home/SMX_logo_lab.png' : 'assets/home/SMX_logo_edu.png');
        this.authenticationService.setIcon();

        this.breakpointObserver
            .observe(['(max-width:900px)'])
            .subscribe((state: BreakpointState) => {
                this.compressedToolbar = state.matches;
                logger.debug("**************COMPRESSED " + this.compressedToolbar);
            });
    }

    ngOnInit() {
        logger.debug('map-show ngOnInit()');
        this.isVideoRecSupported = this.checkIsVideoRecSupported();
        if (this.isVideoRecSupported) {
            this.injectVideoRecorder(this.i18nService.language);
        }
        this.youtubeService.videoPosition = this.youtubeService.VIDEO_POSITION.CENTER_SCREEN;
        this.currentUser = this.authenticationService.getUserEmail();
        // Param subscription
        this.mapStateService.setLoaded(false);
        this.paramSubscription = this.route.params.subscribe((params: Params) => {
            this.isLoading = true;
            this.mapId = params['mapId'];
            this.mapStateService.initialize();
            this.mapStateService.id = this.mapId;

            this.openMap();

        });
        // On error subscription
        this.onErrorSubscription = this.mapStateService.onError.subscribe((error: any) => {
            this.error = error;
            if (error !== '') {
                logger.debug('[map-show] ' + error);
                this.showError(error);
            } else {
                this.hideError();
            }
        });
        if (this.smEventBroker && this.smEventBroker.editDeepData) {
            this.onDeepEditSubscription = this.smEventBroker.editDeepData.subscribe((node: any) => {
                if (node && node.deepHtml && node.deepText && node.nodeData && node.nodeData.language) {
                    this.modalService.showDeepContent(node.id, node.deepHtml, node.deepText, node.nodeData.language);
                }
            });
        }
        if (this.smEventBroker && this.smEventBroker.showImageData) {
            this.onShowImageSubscription = this.smEventBroker.showImageData.subscribe((node: any) => {
                if (this.smService.sm) {
                    if (node && node.image && node.image.name) {
                        this.modalService.showImageContent(false, node.id);
                    }
                }
            });
        }
        if (this.smEventBroker && this.smEventBroker.selectionData) {
            this.selectionDataSubscription = this.smEventBroker.selectionData.subscribe((_selectionData: any) => {
                if (!this.moved && this.buttonPressed >= 0 && _selectionData.class === '') {
                    if (this.buttonPressed === 0) {
                        this.mapFoldingService.nextShowStep();
                    } else if (this.buttonPressed === 2) {
                        this.mapFoldingService.prevShowStep();
                    }
                }
                this.buttonPressed = -1;
            });
        }
        this.isFullScreen = this.toggleFullscreenService.isFullscreen();
        this.mapShowService.isMapShow = true;
    }



    injectVideoRecorder(language: string) {
        let lang = language.substr(0, 2);
        if (lang === 'ca' || lang === 'es') {
            // Catalano e castigliano: traduzioni pessime, impostare inglese
            lang = 'en';
        }
        const s = this.renderer2.createElement('script');
        s.type = 'text/javascript';
        s.src = 'https://api.apowersoft.cn/screen-recorder?lang=' + lang;
        s.text = ``;
        this.renderer2.appendChild(this._document.body, s);
    }

    downloadMap() {
        this.downloadJsonSubscription = this.smeService.downloadJsonToView(this.mapId, this.authenticationService.isAuthenticated())
            .subscribe({
                next: (data: any) => {
                    // this.smService.destroy();
                    //     this.mapStateService.initialize();
                    this.loadMap(data.mapJson);
                    //   this.mapStateService.readOnly = data.readonly;
                    this.mapStateService.viewMode = true;
                    this.mapFoldingService.startShow();
                    this.mapStateService.googleFileId = data.googleFileId;
                    this.mapStateService.googleUri = data.googleUri;
                    this.mapStateService.setLoaded(true);
                    try {
                        this.tracker.trackPageView('Presentazione ' + this.mapStateService.id + ' ' + this.mapStateService.name);
                    } catch (e: any) {
                        logger.error('Matomo error ' + e.message);
                    }
                    this.initListener();
                    this.loaded = true;
                    this.isLoading = false;
                    setTimeout(() => {
                        this.updateCanvasOrigin();
                    }, 100);
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.mapStateService.setBusyState(false);
                    if (error.status === 404) {
                        this.mapStateService.setStateError(this.translateService.instant(extract('ERROR_MAP_NOT_SHARED')));
                    } else {
                        const errorCode = (error.code) ? error.code : 'MAPEDIT_ERROR_GENERIC_MAP_LOAD';
                        this.mapStateService.setStateError(this.translateService.instant(extract(errorCode)));
                    }
                }
            });
    }

    initListener() {
        //this.smEventBroker.initialize();
        if (this.onImageUpdatedSubscription) { this.onImageUpdatedSubscription.unsubscribe(); }
        this.onImageUpdatedSubscription = this.printService.onImageUpdated.subscribe((imageBase64: any) => {
            if (!this.readyToPrint) {
                this.readyToPrint = true;
                this.imageBase64 = imageBase64.image;
            }
        });
    }

    updateCanvasOrigin() {
        if (this.mapContainerElement) {
            setTimeout(() => {
                const element: HTMLElement = this.mapContainerElement?.nativeElement;
                const rect = element.getBoundingClientRect();
                this.mapStateService.canvasOrigin = new Point(rect.left, rect.top);
                this.smService.setCanvasOrigin(rect.left, rect.top, rect.right, rect.bottom);
            });
        }
    }

    printImage() {
        if (this.imageBase64 != null) {
            setTimeout(() => {
                (<any>window).print();
            }, 1000);
        }
    }

    ngOnDestroy(): void {
        if (this.onErrorSubscription) { this.onErrorSubscription.unsubscribe(); }
        if (this.paramSubscription) { this.paramSubscription.unsubscribe(); }
        if (this.downloadJsonSubscription) { this.downloadJsonSubscription.unsubscribe(); }
        if (this.onImageUpdatedSubscription) { this.onImageUpdatedSubscription.unsubscribe(); }
        if (this.interactionModeSubscription) { this.interactionModeSubscription.unsubscribe(); }
        if (this.onDeepEditSubscription) { this.onDeepEditSubscription.unsubscribe(); }
        if (this.onShowImageSubscription) { this.onShowImageSubscription.unsubscribe(); }
        if (this.selectionDataSubscription) { this.selectionDataSubscription.unsubscribe(); }
        if (this.changedBackgroundcolorSubscription) { this.changedBackgroundcolorSubscription.unsubscribe(); }

        logger.debug('map-show ngOnDestroy()');
        this.smService.destroy();
        this.smEventBroker.clearData();
    }

    loadMap(mapJson: any) {
        logger.debug(`Loading map ${this.mapId}...`);

        const urlResolver = this.smeService.getUrlResolver();
        const eventBroker = this.smEventBroker;
        if (this.interactionModeSubscription) { this.interactionModeSubscription.unsubscribe(); }
        if (this.smEventBroker && this.smEventBroker.interactionMode) {
            this.interactionModeSubscription = this.smEventBroker.interactionMode.subscribe((_isReadonlyMode) => {
                this.isReadonlyMode = _isReadonlyMode;
            });
        }
        SMX_CONFIG.isMobile = this.deviceService.isMobileOrTabletDevice();
        this.smService.init(SMX_CONFIG, eventBroker, urlResolver);
        this.smService.resizeCanvas();
        this.smService.show(this.mapId, mapJson, false);
        this.smService.resizeMap();
        if (this.smEventBroker && this.smEventBroker.backgroundChangedData) {
            this.changedBackgroundcolorSubscription = this.smEventBroker.backgroundChangedData.subscribe((color: any) => {
                const cb = document.getElementById('sm-canvas-base');
                if (cb) {
                    cb.style.backgroundColor = color;
                }
            });
        }
        if (mapJson && mapJson && mapJson.mapProps) { this.mapName = mapJson.mapProps.mapName; }
        (<any>window).sm = this.smService.sm;
    }

    private showError(errorCode: string) {
        this.isLoading = false;
        this.error = this.translateService.instant(errorCode);
    }

    public hideError() {
        this.error = '';
        this.isLoading = false;
    }

    public hideErrorGlobal() {
        this.mapStateService.setStateError('');
        this.authenticationService.logout().subscribe(() => {
            this.router.navigate(['login', '/home']);
        });
    }

    get mapName(): string {
        return this._mapName;
    }

    set mapName(newMapName: string) {
        this._mapName = newMapName;
        const newTitle: string = newMapName.replace('.sme', '') + ' - ' + this.translateService.instant(extract('MAPVIEW_TITLE'));
        this.titleService.setTitle(newTitle);
    }

    // True if a shortcut is recognized and fired
    private checkShortcuts(event: KeyboardEvent, shiftKey: boolean, ctrlKey: boolean, altKey: boolean, key: number): boolean {
        let result = false;
        // No modifiers
        if (!ctrlKey && !altKey && !shiftKey && !event.metaKey) {
            if (key === 33 || key === 37 || key === 38) {
                // PgUp / Down / Right
                this.mapFoldingService.prevShowStep();
                result = true;
            } else if (key === 34 || key === 39 || key === 40) {
                // PgDn / Up / Left
                this.mapFoldingService.nextShowStep();
                result = true;
            } else if (key === 190) {
                // Zoom on selection on/off
                this.smService.toggleZoomOnSelection();
            } else if (key === 32) {
                // Obscure map area
                /*this.obscured = !this.obscured;
                result = true;*/
            }
        }
        return result;
    }

    private checkIsVideoRecSupported() {
        return this.deviceService.isDesktopDevice() &&
            !this.deviceService.isOsX() &&
            !this.deviceService.isLinux() &&
            !this.deviceService.isIOS() &&
            !this.deviceService.isAndroid() &&
            !this.deviceService.isMobileOrTabletDevice();// || environment.env === 'dev'; // JHUBA: solo per DEV!
    }

    public showRecordInfo() {
        this.messageBoxService.showVideoRecInfo();
    }

    public showRecordNotSupported() {
        this.messageBoxService.showVideoRecError();
    }

}
