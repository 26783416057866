import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-capture-camera',
    templateUrl: './capture-camera.component.html',
    styleUrls: ['./capture-camera.component.scss']
})
export class CaptureCameraComponent implements AfterViewInit, OnDestroy {
    @ViewChild('video')
    public video: ElementRef | undefined;

    @ViewChild('canvas')
    public canvas: ElementRef | undefined;

    private stream: any;

    constructor(private dialogRef: MatDialogRef<CaptureCameraComponent>) { }

    ngAfterViewInit() {
        navigator.mediaDevices.getUserMedia({ video: true }).then(_stream => {
            this.stream = _stream;
            // this.video.nativeElement.src = window.URL.createObjectURL(_stream);
            if (this.video) {
                this.video.nativeElement.srcObject = _stream;
                this.video.nativeElement.play();
            }
        });
    }

    ngOnDestroy() {
        this.stopCamera();
    }

    capture() {
        if (this.canvas && this.video) {
            this.canvas.nativeElement.getContext('2d', { willReadFrequently: true }).drawImage(this.video.nativeElement, 0, 0, 640, 480);
            const dataUrl = this.canvas.nativeElement.toDataURL('image/png');

            const result: any = this.dataUrlToBlob(dataUrl);
            const now = new Date();
            result.lastModifiedDate = now;
            result.name = `camera-${now}.png`;

            this.dialogRef.close(result);
        }
    }

    private stopCamera() {
        if (this.video) {
            this.video.nativeElement.pause();
            this.video.nativeElement.src = '';
        }
        if (this.stream) {
            this.stream.getTracks()[0].stop();
        }
    }

    /**
     * Convert base64/URLEncoded data component to raw binary data held in a string
     */
    private dataUrlToBlob = function (dataUrl: any) {
        let byteString;
        if (dataUrl.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataUrl.split(',')[1]);
        } else {
            byteString = decodeURI(dataUrl.split(',')[1]);
        }
        const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
        const array = [];
        for (let i = 0; i < byteString.length; i++) {
            array.push(byteString.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: mimeString });
    };
}
