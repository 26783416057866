import { Injectable, EventEmitter } from '@angular/core';
import { YoutubeService } from '../shared/commands/youtube.service';
import { SmService } from 'supermappe-core';
import { MapStateService } from '../shared/map-state.service';
import { MapOperationType } from '../shared/map-operations';
import { SmeService } from '../core/sme/sme.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../core/authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class MapShowService {
    public isMapShow = false;
    public onSwitchToEditShow = new EventEmitter<boolean>();
    public onChangeEditShow = new EventEmitter();
    public isEditShowMode = false;
    public canUndo = false;
    public canRedo = false;
    private statShareSubscription: Subscription | undefined;

    constructor(
        private youtubeService: YoutubeService,
        private smService: SmService,
        private mapStateService: MapStateService,
        private smeService: SmeService,
        private authenticationService: AuthenticationService
    ) { }

    private show(mapId: string) {
        // window.open('map-show/' + mapId, '_blank');
        // Stat
        if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
        this.statShareSubscription = this.smeService.addCustomMapStat(this.mapStateService.id, MapOperationType.STAT_SHOW, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
        //
        const _window: any = window;
        const width = _window.screen.availWidth * 0.9;
        const left = _window.screen.availLeft + (_window.screen.availWidth - width) / 2;
        const top = 0;
        const height = _window.screen.availHeight * 0.88;
        const showWindow = _window.open('map-show/' + mapId, 'smxsshow', 'toolbar=yes,top=' + top + ',left=' + left + ',width=' + width + ',height=' + height, false);
        try {
            showWindow.focus();
        } catch (err) {
            console.log('focus error: ' + err);
        }
    }

    public showing(mapId: string) {
        this.youtubeService.pauseAllVideos();
        this.show(mapId);
    }

    /**
     * Move to read order edit mode
     */
    public startEditShow() {
        this.onSwitchToEditShow.emit(true);
        this.isEditShowMode = true;
        this.smService.switchInteractionModeToShow();

        this.canUndo = false;
        this.canRedo = false;
    }

    /**
     * Hide all read order tags and return to map edit mode
     */
    public finishEditShow() {
        this.onSwitchToEditShow.emit(false);
        this.isEditShowMode = false;
        this.smService.switchInteractionModeToDirect();
    }

    /**
     * Remove all the read order tags
     */
    public clearReadOrder() {
        this.smService.clearReadOrder();
    }

    public setDefaultOrder() {
        this.smService.setDefaultReadOrder();
    }

    /**
     * undo last change in read order edit
     */
    public undo() {
        this.smService.roUndo();
    }

    /**
     * redo last change in read order edit
     */
    public redo() {
        this.smService.roRedo();
    }
}
