import { Injectable, EventEmitter } from '@angular/core';
import { SmeService } from '../../core/sme/sme.service';
import { MapStateService } from '../../shared/map-state.service';
import { GoogleService } from '../../core/google/google.service';
import { SmService } from 'supermappe-core';
import { Logger } from '../../core/logger.service';
import { Subscription } from 'rxjs';

const logger: Logger = new Logger('MapEditComponent');

@Injectable({
    providedIn: 'root'
})
export class AutosaveService {
    mapId: string;
    imgPreview: any;
    imgMap: any;

    saveMapSubscription: Subscription | undefined;

    public mapIsSaving = new EventEmitter<boolean>();

    constructor(
        private smeService: SmeService,
        private mapStateService: MapStateService,
        private googleService: GoogleService,
        private smService: SmService) {
        this.mapId = '';
    }

    autosave(index: number, mapId: string, mapWorkJson: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (
                !this.mapStateService.getBusyState() &&
                !this.mapStateService.getAutosaving() &&
                !this.mapStateService.getEditingState() &&
                !this.mapStateService.dropping &&
                this.mapStateService.id === mapId
            ) {
                this.mapIsSaving.emit(true);
                this.mapStateService.setAutosaving(true);

                logger.info('autosave server call...');
                const mapImages = this.smService.getMapImages();
                if (this.mapStateService.id === mapId) {
                    if (this.saveMapSubscription) { this.saveMapSubscription.unsubscribe(); }

                    const mapOperationsData = this.mapStateService.mapOperations?.getData();
                    if (this.mapStateService.mapOperations) this.mapStateService.mapOperations.clear();
                    this.saveMapSubscription = this.smeService.saveMap(mapId, mapImages.thumbnail, mapImages.image, mapWorkJson, mapOperationsData)
                        .subscribe({
                            next: (blob: Blob) => {
                                logger.info('autosave finished');

                                this.mapIsSaving.emit(false);
                                this.mapStateService.setAutosaving(false);
                                if (index === -1) { index = 0; }
                                return resolve(index);
                            },
                            error: (error: any) => {
                                this.mapIsSaving.emit(false);
                                this.mapStateService.setAutosaving(false);
                                logger.error('autosave ERROR: ' + error);
                                if (error.error.code !== 'WRONG_PARAMS') {
                                    return reject(error);
                                } else {
                                    return resolve(-1);
                                }
                            }
                        });
                } else {
                    this.mapIsSaving.emit(false);
                    this.mapStateService.setAutosaving(false);
                    logger.info('autosave canceled (map is different)');
                    return reject(new Error('Map is different'));
                }

            } else {
                this.mapIsSaving.emit(false);
                this.mapStateService.setAutosaving(false);
                logger.info('autosave canceled (map is busy)');
                // logger.info(`Busy: ${this.mapStateService.getBusyState()}`);
                // logger.info(`AutoSaving: ${this.mapStateService.getAutosaving()}`);
                // logger.info(`Editing: ${this.mapStateService.getEditingState()}`);
                // logger.info(`Dropping: ${this.mapStateService.dropping}`);
                // logger.info(`MapId is same: ${this.mapStateService.id === mapId} - stateId: ${this.mapStateService.id} - mapId: ${mapId}`);
                return resolve(-1);
            }
        });
    }

}
