import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SmeService } from '../../core/sme/sme.service';

@Component({
    selector: 'app-multi-stats',
    templateUrl: './multi-stats.component.html',
    styleUrls: ['../multi-stats/multi-stats.component.scss']
})

export class MultiStatsComponent implements OnInit, OnDestroy {
    public isLoading = false;
    public user = '';
    public role = '';
    public userEmail = '';

    public nStudentiAttivi = 0;
    public nMappeNuove = 0;
    public nMappeAperte = 0;
    public selectedStat = 'users';

    public orderId = '';
    public randomId = '';

    private paramSubscription: Subscription | undefined;
    private readOrderStatsStep1Subscription: Subscription | undefined;
    private readOrderStatsStep2Subscription: Subscription | undefined;
    private readOrderStatsStep3Subscription: Subscription | undefined;
    private readOrderStatsStep4Subscription: Subscription | undefined;

    public monthStats: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public tickMultiplier = 1;

    public utentiAttiviCorrenti: any[] = [];
    public tipoLicenza = '';
    public nomeConfigurazione = '';
    public numeroMappeNuoveCorrenti = 0;
    public numeroMappeAperteCorrenti = 0;
    public numeroUtentiAttivi: number[] = new Array<number>();
    public numeroMappeNuove: number[] = new Array<number>();
    public numeroMappeAperte: number[] = new Array<number>();



    public utentiRicerca: any[] = new Array<any>();

    public textSearch = '';

    public currentYear = 0;
    private currentMonth = 0;

    // private dataStats: any;
    public isLoadedStep1 = false;
    public isLoadedStep2 = false;
    public isLoadedStep3 = false;
    public isLoadedStep4 = false;

    public years: Array<any> = new Array<any>();
    public selectedYear: any;

    constructor(private route: ActivatedRoute, private router: Router,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private smeService: SmeService) { }

    ngOnInit() {
        if (this.authenticationService) {
            this.user = this.authenticationService.getUserName();
            this.role = (this.authenticationService.credentials ? this.authenticationService.credentials.role : '');
            this.userEmail = this.authenticationService.getUserEmail();
            const currentDate = new Date();
            this.currentYear = currentDate.getFullYear();
            this.currentMonth = currentDate.getMonth();
            if (this.currentMonth <= 8) {
                this.currentYear--;
            }
            this.years = new Array<any>();
            for (let i = this.currentYear; i >= 2019; i--) {
                this.years.push({ num: i, name: i + '/' + (i + 1) });
            }
            this.selectedYear = this.years[0];

            console.log(`++++++++++++++++++++++++++++REQUEST paramSubscription`);
            this.paramSubscription = this.route.params.subscribe((params: Params) => {
                this.orderId = params['orderId'];
                this.loadDataSet();
            });
        }
    }

    ngOnDestroy() {
        if (this.paramSubscription) { this.paramSubscription.unsubscribe(); }
        if (this.readOrderStatsStep1Subscription) { this.readOrderStatsStep1Subscription.unsubscribe(); }
        if (this.readOrderStatsStep2Subscription) { this.readOrderStatsStep2Subscription.unsubscribe(); }
        if (this.readOrderStatsStep3Subscription) { this.readOrderStatsStep3Subscription.unsubscribe(); }
        if (this.readOrderStatsStep4Subscription) { this.readOrderStatsStep4Subscription.unsubscribe(); }
    }

    loadDataSet() {
        // this.isLoading = true;
        this.isLoadedStep1 = false;
        this.isLoadedStep2 = false;
        this.isLoadedStep3 = false;
        this.isLoadedStep4 = false;
        const startDate = this.currentYear + '-09-01';
        const endDate = (this.currentYear + 1) + '-08-31';
        const res = { newmaps: null as any, openmaps: null as any, users: null as any, sub: null as any };
        console.log(`+++++RESPONSE paramSubscription orderId:${this.orderId}`);
        if (this.readOrderStatsStep1Subscription) { this.readOrderStatsStep1Subscription.unsubscribe(); }
        if (this.readOrderStatsStep2Subscription) { this.readOrderStatsStep2Subscription.unsubscribe(); }
        if (this.readOrderStatsStep3Subscription) { this.readOrderStatsStep3Subscription.unsubscribe(); }
        if (this.readOrderStatsStep4Subscription) { this.readOrderStatsStep4Subscription.unsubscribe(); }
        console.log(`+++++REQUEST read order stats Step 4 orderId:${this.orderId}`);
        this.readOrderStatsStep4Subscription = this.smeService.readOrderStatsStep4(this.orderId).subscribe((res4: any) => {
            res.sub = res4.res.sub;
            this.isLoadedStep4 = true;
            this.acquireData(res);
            this.updateDetail();
            console.log(`+++++REQUEST read order stats Step 3 orderId:${this.orderId}, startDate:${startDate}, endDate:${endDate}`);
            this.readOrderStatsStep3Subscription = this.smeService.readOrderStatsStep3(this.orderId, startDate, endDate).subscribe((res3: any) => {
                res.users = res3.res.users;
                this.isLoadedStep3 = true;
                this.acquireData(res);
                this.updateDetail();
                console.log(`+++++REQUEST read order stats Step 1 orderId:${this.orderId}, startDate:${startDate}, endDate:${endDate}`);
                this.readOrderStatsStep1Subscription = this.smeService.readOrderStatsStep1(this.orderId, startDate, endDate).subscribe((res1: any) => {
                    res.newmaps = res1.res.newmaps;
                    this.isLoadedStep1 = true;
                    console.log(`+++++END read order stats`);
                    console.log(JSON.stringify(res));
                    this.acquireData(res);
                    this.updateDetail();
                    console.log(`+++++REQUEST read order stats Step 2 orderId:${this.orderId}, startDate:${startDate}, endDate:${endDate}`);
                    this.readOrderStatsStep2Subscription = this.smeService.readOrderStatsStep2(this.orderId, startDate, endDate).subscribe((res2: any) => {
                        res.openmaps = res2.res.openmaps;
                        this.isLoadedStep2 = true;
                        this.acquireData(res);
                        this.updateDetail();
                        // this.isLoading = false;
                    });
                });
            });
        });
        // if (this.readOrderStatsSubscription) { this.readOrderStatsSubscription.unsubscribe(); }
        // console.log(`++++++++++++++++++++++++++++REQUEST read order stats orderId:${this.orderId}, year:${this.currentYear}`);
        // this.readOrderStatsSubscription = this.smeService.readOrderStats(this.orderId, this.currentYear + '-09-01', (this.currentYear + 1) + '-08-31').subscribe((res: any) => {
        //   console.log(`++++++++++++++++++++++++++++REQUEST read order stats orderId:${this.orderId}, year:${this.currentYear}`);
        //   this.dataStats = res.res;
        //   console.log(JSON.stringify(this.dataStats));
        //   this.acquireData(this.dataStats);
        //   this.updateDetail();
        //   this.isLoading = false;
        // });
    }

    selectedYearChanged(event: any) {
        if (event) {
            // JHUBA: CHECK
            if (this.currentYear !== this.selectedYear.num) {
                this.currentYear = this.selectedYear.num;
                this.loadDataSet();
            }
        }
    }

    acquireData(data: any) {
        this.nomeConfigurazione = '???';
        this.tipoLicenza = '';
        const emails = [];
        const names = [];
        const notes = [];
        if (data) {
            if (data.sub && data.sub.access) {
                this.nomeConfigurazione = data.sub.access.name;
            }
            if (data.sub && data.sub.order) {
                this.tipoLicenza = data.sub.order.licenseType;
            }
            this.nMappeNuove = 0;
            this.nMappeAperte = 0;
            this.nStudentiAttivi = 0;
            this.numeroUtentiAttivi = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            this.numeroMappeNuove = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            this.numeroMappeAperte = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            this.utentiAttiviCorrenti = [];
            // Utenti attivi
            if (data.users) {
                for (let i = 0; i < data.users.length; i++) {
                    const item = data.users[i];
                    const idx = this.getIndexForMonth(item.year, item.month);
                    if (idx >= 0 && idx <= this.numeroUtentiAttivi.length) {
                        this.numeroUtentiAttivi[idx] += 1;
                    }
                    // Email uniche degli utenti attivi in tutti i mesi
                    if (emails.indexOf(item.email) < 0) {
                        emails.push(item.email);
                        names.push(item.name);
                        notes.push(item.notes);
                    }
                }
            }
            // Mappe nuove
            if (data.newmaps) {
                for (let i = 0; i < data.newmaps.length; i++) {
                    const item = data.newmaps[i];
                    this.nMappeNuove += item.newmaps;
                    const idx = this.getIndexForMonth(item.year, item.month);
                    if (idx >= 0 && idx <= this.numeroMappeNuove.length) {
                        this.numeroMappeNuove[idx] += item.newmaps;
                    }
                }
            }
            // Mappe aperte
            if (data.openmaps) {
                for (let i = 0; i < data.openmaps.length; i++) {
                    const item = data.openmaps[i];
                    this.nMappeAperte += item.openmaps;
                    const idx = this.getIndexForMonth(item.year, item.month);
                    if (idx >= 0 && idx <= this.numeroMappeAperte.length) {
                        this.numeroMappeAperte[idx] += item.openmaps;
                    }
                }
            }
            // Ordinamento per email
            const emailsSorted = emails.slice(0);
            emailsSorted.sort();
            for (let j = 0; j < emailsSorted.length; j++) {
                const jj = emails.indexOf(emailsSorted[j]);
                const user = {
                    email: emailsSorted[j],
                    name: '',
                    notes: ''
                };
                if (jj >= 0) {
                    user.name = names[jj];
                    user.notes = notes[jj];
                }
                this.utentiAttiviCorrenti.push(user);
                this.nStudentiAttivi = this.utentiAttiviCorrenti.length;
            }
            this.searchClick('');
        }
    }

    getRoleType() {
        return this.translateService.instant('ROLE_' + (this.role.toLocaleUpperCase() || 'USER'));
    }

    updateDetail() {
        switch (this.selectedStat) {
            case 'users':
                this.showDetailChartStudenti();
                break;
            case 'newmaps':
                this.showDetailChartMappeNuove();
                break;
            case 'openmaps':
                this.showDetailChartMappeAperte();
                break;
        }
    }

    showDetailChartStudenti() {
        this.selectedStat = 'users';
        this.monthStats = this.numeroUtentiAttivi;
        this.calculateGraphSize();
    }

    showDetailChartMappeNuove() {
        this.selectedStat = 'newmaps';
        this.monthStats = this.numeroMappeNuove;
        this.calculateGraphSize();
    }

    showDetailChartMappeAperte() {
        this.selectedStat = 'openmaps';
        this.monthStats = this.numeroMappeAperte;
        this.calculateGraphSize();
    }

    calculateGraphSize() {
        let maxValue = 0;
        for (const val of this.monthStats) {
            if (maxValue < val) {
                maxValue = val;
            }
        }

        let multiplier = 1;
        if (maxValue >= 6) {
            const tick = maxValue / 5;
            multiplier = Math.ceil(tick / 5) * 5;
        }
        this.tickMultiplier = multiplier;

    }

    showDetailStat(userEmail: string) {
        window.open('user-stats/' + userEmail, '_blank');
        // this.router.navigate(['user-stats/', userEmail]);
    }

    searchClick(event: any) {
        const text = (event && event.target?.value) ? event.target.value : '';
        console.log('searchClick: ' + text);
        if (this.utentiAttiviCorrenti) {
            const txt = text.toLocaleLowerCase();
            this.utentiRicerca = [];
            for (let i = 0; i < this.utentiAttiviCorrenti.length; i++) {
                if (text === '' || this.utentiAttiviCorrenti[i].email.toLocaleLowerCase().includes(txt) || this.utentiAttiviCorrenti[i].name.toLocaleLowerCase().includes(txt) || this.utentiAttiviCorrenti[i].notes.toLocaleLowerCase().includes(txt)) {
                    this.utentiRicerca.push(this.utentiAttiviCorrenti[i]);
                }
            }
        }
    }

    clearSearch() {
        console.log('clearSearch');
        this.textSearch = '';
        this.searchClick('');
    }

    getIndexForMonth(year: number, month: number): number {
        let i = -1;
        if (year === this.currentYear && month >= 9 && month <= 12) {
            i = month - 9;
        } else if (year === this.currentYear + 1 && month >= 1 && month <= 8) {
            i = month + 3;
        } else {
            console.log('Date out of bounds for year/month: ' + year + '/' + month);
        }
        return i;
    }

}

