<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="'PLEASE_WAIT'" [versioned]="true"></app-loader>

<div id="div-stamp-image" class="stamp-image"></div>
<div id="div-map-qrcode" class="stamp-image"></div>
<div id="div-map-qrcode-symbol" class="stamp-image"></div>
<div id="div-gdoc-qrcode-symbol" class="stamp-image"></div>
<div id="div-gdoc-qrcode" class="stamp-image"></div>

<div class="dim-screen">
    <img [src]="imageBase64" class="print-map" (load)="printImage()" />
</div>

<div *ngIf="!error" class="main-content">
    <div class="header-container">
        <!--<div class="header-smx-logo-container" (click)="goHome()">-->
        <div class="header-smx-logo-container">
            <img class="header-smx-logo" src="{{logoUrl}}?v=1" alt="logo" />
        </div>
        <div class="header-map-title-container">
            <div class="map-name" *ngIf="!renameMapMode" (click)="editMapName()">{{mapName}}</div>
            <input spellcheck="false" class="map-input-name" type="text" #titleMap [hidden]="!renameMapMode" value="{{mapName}}" (keyup)="onKey($event)" (input)="onSearchChange($event)" (blur)="exitEditMapName()" />
            <!--<div class="status-message" [ngClass]="{'status-red':!saved}" matTooltipPosition="below">{{saveStatusMessage}}</div>-->
            <mat-icon class="save-status-icon" *ngIf="saveStatus===0" fontSet="material-icons-outlined" color="accent" matTooltipPosition="below" matTooltip="{{saveStatusMessage}}">done_all</mat-icon>
            <mat-icon class="save-status-icon rotate-icon" *ngIf="saveStatus===1" fontSet="material-icons-outlined" color="primary" matTooltipPosition="below" matTooltip="{{saveStatusMessage}}">sync</mat-icon>
            <mat-icon class="save-status-icon" *ngIf="saveStatus===2" fontSet="material-icons-outlined" color="warn" matTooltipPosition="below" matTooltip="{{saveStatusMessage}}">pending</mat-icon>
            <mat-icon class="save-status-icon" *ngIf="saveStatus===3" fontSet="material-icons-outlined" color="warn" matTooltipPosition="below" matTooltip="{{saveStatusMessage}}">wifi_off</mat-icon>
        </div>
        <div class="header-menu">
            <app-edit-menu [editMenuCompressed]="editMenuCompressed" [hidden]="isEditShowMode || editMenuHidden" [isLoading]="isLoading" (startLoading)="isLoading=true" (endLoading)="isLoading=false"></app-edit-menu>
        </div>
        <div class="toolbar-right">
            <app-users-toolbar #headerUserToolbar *ngIf="!isMobileMode"></app-users-toolbar>
            <app-chat-toolbar #headerChatToolbar *ngIf="!isMobileMode"></app-chat-toolbar>
            <app-edit-toolbar #headerEditToolbar *ngIf="!isEditShowMode" [mapId]="mapStateService.id" [editToolbarCompressed]="editToolbarCompressed"></app-edit-toolbar>
            <app-edit-show-toolbar #headerEditShowToolbar *ngIf="isEditShowMode" [editShowToolbarCompressed]="editShowToolbarCompressed"></app-edit-show-toolbar>
        </div>
    </div>

    <div class="save-error" *ngIf="saveError">
        <span>{{saveError}}</span>
    </div>

    <div #contentRef class="content">
        <!-- EXTRA MENU -->
        <div *ngIf="!isMobileMode" id="extra-menu" class="extra-menu-container" [ngClass]="isExtraMenuVisible? 'extra-menu-opened':'extra-menu-closed'">
            <div id="extraMenuToolsContainer" class="extra-menu-tools-container">
                <div class="extra-menu-tools">
                    <div *ngIf="isExtraPanelVisible" class="selector-container" [ngStyle]="getExtraMenuSelectorOffset()">
                        <div class="selector"></div>
                    </div>
                    <!-- OUTLINE BUTTON -->
                    <div *ngIf="isOutlineEnabled" id="outline-extra-menu-button" #outlineRef class="extra-menu-button" (click)="openOutline(outlineRef)" matTooltip="{{'MAIN_TOOLBAR_OUTLINE' | translate}}" matTooltipPosition="right">
                        <mat-icon [ngClass]="{'selected': isExtraPanelVisible && currentSelectedExtraMenuButtonId===outlineRef}" fontSet="material-icons-outlined">list_alt</mat-icon>
                    </div>
                    <!-- APPUNTI BUTTON -->
                    <div id="appunti-extra-menu-button" #appuntiRef class="extra-menu-button" (click)="openAppunti(appuntiRef)" matTooltip="{{'MAIN_TOOLBAR_BLOCKNOTES' | translate}}" matTooltipPosition="right">
                        <mat-icon *ngIf="!hasNotes" [ngClass]="{'selected': isExtraPanelVisible && currentSelectedExtraMenuButtonId===appuntiRef}" fontSet="material-icons-outlined">note_add</mat-icon>
                        <mat-icon *ngIf="hasNotes" [ngClass]="{'selected': isExtraPanelVisible && currentSelectedExtraMenuButtonId===appuntiRef}" class="material-icons-sharp" fontSet="material-icons-filled">task</mat-icon>
                    </div>
                    <!-- PDF BUTTON -->
                    <div *ngIf="isPdfEnabled" id="pdf-extra-menu-button" #pdfRef class="extra-menu-button" (click)="openPdf(pdfRef)" matTooltip="{{'MAIN_TOOLBAR_PDF' | translate}}" matTooltipPosition="right">
                        <mat-icon [ngClass]="{'selected': isExtraPanelVisible && currentSelectedExtraMenuButtonId===pdfRef}" class="material-icons-sharp" fontSet="material-icons-filled">picture_as_pdf</mat-icon>
                    </div>
                    <div *ngIf="extraAutodraw" id="autodraw-extra-menu-button" #autodrawRef class="extra-menu-button" (click)="openAutodraw(autodrawRef)" matTooltip="{{'AUTODRAW' | translate}}" matTooltipPosition="right">
                        <mat-icon [ngClass]="{'selected': isExtraPanelVisible && currentSelectedExtraMenuButtonId===autodrawRef}" class="material-icons-sharp" fontSet="material-icons-filled">draw</mat-icon>
                    </div>
                    <div *ngIf="desmosCalculator" id="desmos-extra-calculator" #extracalculator class="extra-menu-button" (click)="openDesmos('calculator')" matTooltip="{{'DESMOS_CALCULATOR' | translate}}" matTooltipPosition="right">
                        <mat-icon [ngClass]="{'selected': isExtraPanelVisible && currentSelectedExtraMenuButtonId===extracalculator}" class="material-icons-sharp" fontSet="material-icons-filled">multiline_chart</mat-icon>
                    </div>
                    <div *ngIf="desmosScientific" id="desmos-extra-scientific" #extrascientific class="extra-menu-button" (click)="openDesmos('scientific')" matTooltip="{{'DESMOS_SCIENTIFIC' | translate}}" matTooltipPosition="right">
                        <mat-icon [ngClass]="{'selected': isExtraPanelVisible && currentSelectedExtraMenuButtonId===extrascientific}" class="material-icons-sharp" fontSet="material-icons-filled">superscript</mat-icon>
                    </div>
                    <div *ngIf="desmosFourFunction" id="desmos-extra-fourfunction" #extrafourfunction class="extra-menu-button" (click)="openDesmos('FourFunction')" matTooltip="{{'DESMOS_FOURFUNCTIOM' | translate}}" matTooltipPosition="right">
                        <mat-icon [ngClass]="{'selected': isExtraPanelVisible && currentSelectedExtraMenuButtonId===extrafourfunction}" class="material-icons-sharp" fontSet="material-icons-filled">calculate</mat-icon>
                    </div>
                    <div *ngIf="desmosGeometry" id="desmos-extra-geometry" #extrageometry class="extra-menu-button" (click)="openDesmos('geometry')" matTooltip="{{'DESMOS_GEOMETRY' | translate}}" matTooltipPosition="right">
                        <mat-icon [ngClass]="{'selected': isExtraPanelVisible && currentSelectedExtraMenuButtonId===extrageometry}" class="material-icons-sharp" fontSet="material-icons-filled">category</mat-icon>
                    </div>
                    <div id="add-extra-menu-button" class="extra-menu-button" (click)="openExtraMenuConfig()" matTooltip="{{'MAIN_TOOLBAR_ADD_TOOLS' | translate}}" matTooltipPosition="right">
                        <mat-icon matBadgeSize="small" fontSet="material-icons-outlined">add</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <!-- EXTRA PANEL -->
        <div *ngIf="!isMobileMode" id="extra-panel" [hidden]="!isExtraPanelVisible" class="extra-panel">
            <app-extra-panel #extraPanel [extraOpenDto]="this.extraService.lastOpenDto"></app-extra-panel>
        </div>
        <!-- MAP -->
        <div #mapEditorContainer class="map-editor-container" [ngClass]="{'sm-loading':isLoading, 'sm-extra':isExtraPanelVisible}">
            <div id="map-toolbar" class="map-toolbar-container" *ngIf="alive">
                <app-custom-toolbar [isLoading]="isLoading"></app-custom-toolbar>
            </div>
            <div tabindex="0" id="sm-container" class="sm" [ngClass]="{'sm-loading':isLoading}" (dragover)="dragOver($event)" (drop)="drop($event)" (dragend)="dragEnd($event)">
                <canvas id="sm-canvas-grid" class="sm-canvas sm-canvas-grid"></canvas>
                <canvas #mapcontainer id="sm-canvas-base" class="sm-canvas sm-canvas-base" [ngClass]="{'interaction-mode-readonly': isReadonlyMode, 'copy-format-cursor': isFormatCopying}"> </canvas>
                <canvas id="sm-canvas-over" class="sm-canvas sm-canvas-over"></canvas>
                <canvas id="sm-canvas-save" class="sm-canvas sm-canvas-save"></canvas>
                <div id="sm-tmp" class="sm-tmp"></div>
                <app-real-time-markers></app-real-time-markers>
            </div>
            <app-math-editor *ngIf="mathService.isMathOpen && !isEditShowMode" [latex]="ltx"></app-math-editor>
        </div>
        <!-- <div *ngIf="desmosPanelVisible" class="item-extra">
        <app-desmos-panel #desmosPanel [hidden]="!desmosPanelVisible"></app-desmos-panel>
        </div> -->
        <!-- <div *ngIf="webPanelVisible" class="item-extra">
            <app-web-panel #webPanel [hidden]="!webPanelVisible"></app-web-panel>
        </div> -->
        <div *ngIf="autoDrawPanelVisible" class="item-extra">
            <app-autodraw #autoDrawPanel [hidden]="!autoDrawPanelVisible"></app-autodraw>
        </div>
        <div *ngIf="isChatPanelVisible && !isMobileMode" class="chat-panel">
            <app-chat-panel #chatPanel [hidden]="!isChatPanelVisible"></app-chat-panel>
        </div>
        <!-- EXTRA MENU TOGGLE BUTTON -->
        <div *ngIf="!isEditShowMode && !isMobileMode" class="extra-button-container">
            <div class="extra-button" [ngClass]="getExtraButtonClass()" (click)="toggleExtraMenuVisibility()">
                <mat-icon *ngIf="!isExtraMenuVisible" [style.background]="extraBackground" class="extra-button-color-background-closed" matTooltip="{{'MAIN_TOOLBAR_EXTRA' | translate}}" matTooltipPosition="right">assistant</mat-icon>
                <mat-icon *ngIf="isExtraMenuVisible" [style.color]="extraBackground" class="extra-button-color-background-opened" matTooltip="{{'BUTTON_CLOSE' | translate}}" matTooltipPosition="right">cancel</mat-icon>
            </div>
        </div>
        <!-- IL COMPONENTE DEVE ESSERE ISTANZIATO LONTANO POI BISOGNA SETTARE cdkDragFreeDragPosition per resettare onNgInit la posizione https://stackblitz.com/angular/bxalqlqlaly?file=src%2Fapp%2Fcdk-drag-drop-free-drag-position-example.ts-->
        <app-search-web id="webSearchPanel" #webSearchPanel *ngIf="webSearchPanelVisible" class="item-websearch" cdkDrag cdkDragBoundary=".content" [cdkDragFreeDragPosition]="webSearchDefaultPosition" [hidden]="!webSearchPanelVisible"></app-search-web>
        <app-map-find id="mapSearchPanel" #mapSearchPanel *ngIf="mapSearchVisible" class="item-mapsearch" cdkDrag cdkDragBoundary=".content" [cdkDragFreeDragPosition]="mapSearchDefaultPosition" [hidden]="!mapSearchVisible"></app-map-find>
    </div>
</div>

<textarea [hidden]="!pasteMap" id="pastearea" class="pastearea" #pastearea [style.top.px]="topQuick" [style.left.px]="leftQuick">{{'PASTE_HERE' | translate}}</textarea>

<app-map-editor #mapEditor (onOpened)="onEditorOpened()" (onClosed)="onEditorClosed($event)"></app-map-editor>
<app-links-menu *ngIf="contextItems.length>0" [items]="contextItems"></app-links-menu>
<app-selection-menu></app-selection-menu>
<input id="quickedit" class="quickedit" #quickEditInput autocomplete="off" [hidden]="!quickEditVisible" [style.top.px]="topQuick" [style.left.px]="leftQuick" (change)="onQuickEditChange($event)" (keyup)="onQuickEditKeyup($event)" />
<input id="speechedit" class="speechedit" #speechEditInput autocomplete="off" [hidden]="!speechEditVisible" [style.top.px]="topQuick" value="{{quickEditValue}}" [style.left.px]="leftQuick" readonly (keyup)="onSpeechEditKeyup($event)" />
<div class="speechedit-anim-icon" [hidden]="!speechEditVisible" [style.top.px]="topSpeechIcon" [style.left.px]="leftSpeechIcon">
    <mat-icon>mic</mat-icon>
</div>

<div *ngIf="error" class="container mat-typography">
    <mat-card appearance="outlined" class="message-container">
        <div class="message-title" translate>WARNING</div>
        <div class="message-text">{{error}}</div>
        <div class="ok-button" (click)="goHome()" translate>BUTTON_OK</div>
    </mat-card>
</div>
