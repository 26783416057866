import { Injectable } from '@angular/core';
import { SmService } from 'supermappe-core';
import { SmEventBroker } from '../sm-event-broker';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class InteractionmodeService {
  interactionModeChange: Observable<boolean> | undefined;
  isReadOnlyMode = false;
  constructor(private smService: SmService, eventBroker: SmEventBroker) {
    this.interactionModeChange = eventBroker.interactionMode;
  }

  // true: next clicks will add to selection (shift pressed)
  // false: next click will reset selection on stage or element
  public setSelectionAddMode(pressed: boolean) {
    this.smService.setShift(pressed);
  }

  // true: move map mode
  // false: normal interaction
  public setHandMode(pressed: boolean) {
    this.smService.setAlt(pressed);
    this.switchInteractionMode(pressed);
  }

  // Direct: normal map interaction (drag/modify included)
  // ReadOnly: tablet mode (no selection extender)
  public switchInteractionMode(isMoveMode: boolean) {
    if (isMoveMode) {
      this.smService.switchInteractionModeToMove(); // jhuba da rimettere quando esce dalla feature del core
    } else {
      this.smService.switchInteractionModeToDirect();
    }
  }

}
