import { throwError as observableThrowError } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Logger } from '../logger.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

const logger: Logger = new Logger('Backend');

const routes = {
    findOrderPriceInBackend: '/api/findorderpriceinbackend2gen',
};

@Injectable({
    providedIn: 'root'
})
export class BackendService {

    constructor(private http: HttpClient) {
    }

    public findOrderPriceInBackend(orderId: string): any {
        const body = {
            orderId: orderId
        };
        return this.http.post<Response>(routes.findOrderPriceInBackend, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

}
