<div>
    <p translate>SET_PASS1</p>
    <ul>
        <li translate>SET_PASS2</li>
        <li translate>SET_PASS3</li>
        <li translate>SET_PASS4</li>
    </ul>
    <p translate>SET_PASS5</p>
</div>
<form *ngIf="formGroup" [formGroup]="formGroup">
    <mat-form-field *ngIf="!isPasswordLessUser" appearance="outline" color="accent">
        <input [type]="hide ? 'password' :  'text'" matInput type="password" formControlName="vecchiaPassword" />
        <mat-icon *ngIf="!isIEOrEdge()" matSuffix (click)="showHidePsw()">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
        <mat-hint translate>HINT_OLD_PASSWORD</mat-hint>
    </mat-form-field>
    <div class="new-pwd">

        <mat-form-field appearance="outline" color="accent">
            <input [type]="hide ? 'password' :  'text'" matInput type="password" formControlName="password" />
            <mat-icon *ngIf="!isIEOrEdge()" matSuffix (click)="showHidePsw()">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
            <mat-hint translate>HINT_NEW_PASSWORD</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
            <input [type]="hide ? 'password' :  'text'" matInput type="password" formControlName="ripetiPassword" />
            <mat-icon *ngIf="!isIEOrEdge()" matSuffix (click)="showHidePsw()">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
            <mat-hint translate>HINT_REPEAT_PASSWORD</mat-hint>
        </mat-form-field>
    </div>
</form>
<div class="errore" *ngIf="erroreSalvataggio" translate>ERR_SAVE_PASSWORD</div>
<div class="errore" *ngIf="erroreOldPassword" translate>ERR_OLD_PASSWORD</div>
<div class="errore" *ngIf="formGroup?.invalid && (formGroup?.controls?.['password']?.errors || (formGroup?.value?.ripetiPassword && formGroup?.controls?.['ripetiPassword']?.errors)) ">
    <div *ngIf="formGroup?.value.password && formGroup?.controls?.['password']?.errors?.['unaLettera']" translate>
        ERR_ONE_LETTER
    </div>
    <div *ngIf="formGroup?.value.password && formGroup?.controls?.['password']?.errors?.['unSimbolo']" translate>
        ERR_ONE_SYMBOL
    </div>
    <div *ngIf="formGroup?.value.password && formGroup?.controls?.['password']?.errors?.['lunghezza']" translate>
        ERR_LENGTH_PASSWORD
    </div>
    <div *ngIf="formGroup?.value.password && formGroup?.value.vecchiaPassword && formGroup?.controls?.['password']?.errors?.['stessaPassword']" translate>
        ERR_SAME_PASSWORD
    </div>
    <div *ngIf="formGroup?.value.password !== formGroup?.value.ripetiPassword" translate>
        ERR_DIFFERENT_PASSWORD
    </div>
</div>

<div class="button-container">
    <button mat-stroked-button color="primary" (click)="cancel()">{{'CANCEL' | translate}}</button>
    <button mat-flat-button color="accent" type="submit" (click)="submit()">{{'SAVE' | translate}}</button>
</div>
