<!-- <div *ngIf="error" class="error" (click)="closePage()">
  <div class="error-content">
    <div class="error-text-header" translate>WAIT</div>
    <div class="error-text-message" translate>{{textMessage}}</div>
  </div>
</div> -->
<div *ngIf="error" class="container mat-typography">
    <mat-card appearance="outlined" class="message-container">
        <div class="message-title" translate>WAIT</div>
        <div class="message-text">{{error}}</div>
        <!-- <div class="ok-button" (click)="closePage()" translate>BUTTON_OK</div> -->
    </mat-card>
</div>
<!-- <div class="loader-container open-page" [hidden]="!isLoading"> -->
<app-loader *ngIf="isLoading" [isLoading]="isLoading" size="5" [message]="'GDRIVE_OPEN_TITLE'"></app-loader>
<!-- <span class="open-title mat-typography" translate>GDRIVE_OPEN_TITLE</span> -->
<!-- </div> -->
