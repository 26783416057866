import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AboutComponent } from './about.component';
import { AboutDeviceComponent } from '../about-device/about-device.component';

@Injectable({
    providedIn: 'root'
})
export class AboutService {

    constructor(private dialog: MatDialog) {
        this.dialog = dialog;
    }

    public show() {
        const dialogRef: MatDialogRef<AboutComponent> = this.dialog.open(AboutComponent);

        return dialogRef.afterClosed();
    }

    public showDeviceInfo() {
        const dialogRef: MatDialogRef<AboutDeviceComponent> = this.dialog.open(AboutDeviceComponent);

        return dialogRef.afterClosed();
    }
}
