<div class="mat-typography  container">
    <div mdc-dialog-title mdc-dialog-draggable-title translate>PASTE-OPTIONS</div>
    <div class="paste-container">
        <div class="spacer"></div>
        <div class="inline-center">
            <mat-icon class="custom-icon" svgIcon="table-plus"></mat-icon>
        </div>
        <div class="inline-center">
            <span translate>PASTE-AS-TABLE</span>
        </div>
        <div class="spacer"></div>
        <div class="inline-center">
            <button mat-button class="option-button" (click)="clicked('single-tab')">
                <div class="option-button-title">
                    <span class="option-button-title" translate>PASTE-OPTIONS-TAB</span>
                </div>
                <img class="option-button-image" src="../../assets/paste-tab/tabella1.png?v=1" />
            </button>
            <button [disabled]="noTabRowsCols" mat-button class="option-button" (click)="clicked('tab-cols')">
                <div class="option-button-title">
                    <span class="option-button-title" translate>PASTE-OPTIONS-COLUMNS</span>
                </div>
                <img class="option-button-image" src="../../assets/paste-tab/tabelle_colonne.png?v=1" />
            </button>
            <button [disabled]="noTabRowsCols" mat-button class="option-button" (click)="clicked('tab-rows')">
                <div class="option-button-title">
                    <span class="option-button-title" translate>PASTE-OPTIONS-ROWS</span>
                </div>
                <img class="option-button-image" src="../../assets/paste-tab/tabelle_righe.png?v=1" />
            </button>
        </div>
        <div class="spacer"></div>
        <div class="inline-center">
            <mat-icon class="custom-icon" svgIcon="add-node"></mat-icon>
        </div>
        <div class="inline-center">
            <span translate>PASTE-AS-NODE</span>
        </div>
        <div class="spacer"></div>
        <div class="inline-center">
            <button mat-button class="option-button" (click)="clicked('single-node')">
                <div class="option-button-title">
                    <span class="option-button-title" translate>PASTE-OPTIONS-ONE-NODE</span>
                </div>
                <img class="option-button-image" src="../../assets/paste-tab/nodo1.png?v=1" />
            </button>
            <button mat-button class="option-button" (click)="clicked('multi-node')">
                <div class="option-button-title">
                    <span class="option-button-title" translate>PASTE-OPTIONS-MANY-NODES</span>
                </div>
                <img class="option-button-image" src="../../assets/paste-tab/nodi_tanti.png?v=1" />
            </button>
        </div>
        <div *ngIf="!hasDeepContent" class="spacer"></div>
        <div *ngIf="hasDeepContent" class="spacer">
            <mat-divider class="divider" />
            <div class="inline-center">
                <span translate>PASTE-AS-DEEP</span>
                <mat-slide-toggle class="deep-switch" color="accent" [checked]="isPasteAsDeepChecked" id="SETS" (change)="OnDeepToggleChange($event)"></mat-slide-toggle>
            </div>
        </div>
    </div>
</div>
