import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { AdminUsersRoutingModule } from './admin-users-routing.module';
import { AdminUsersComponent } from './admin-users.component';
import { AdminListUsersComponent } from '../admin-list-users/admin-list-users.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormField, MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PaginatorI18n } from '../access-config/common/order-list/mat-paginatorI18n.service';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';



@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        FormsModule,
        CoreModule,
        ReactiveFormsModule,
        SharedModule,
        //FlexLayoutModule,
        MaterialModule,
        MatButtonModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        AdminUsersRoutingModule,
    ],
    declarations: [
        AdminUsersComponent,
        AdminListUsersComponent
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
        }
    ]
})
export class AdminUsersModule { }
