<div class="custom-toolbar">
    <div class="select-container mat-tooltip-trigger" matTooltip="{{'LANGUAGE_TOOLBAR' | translate}}" [matMenuTriggerFor]="languageMenu">
        <img mat-icon class="under-select" color="primary" [src]="selectedLangIcon" />
    </div>
    <mat-menu class="language-menu-container" #languageMenu="matMenu" color="primary" [overlapTrigger]="false">
        <div *ngFor="let l of languages" mat-menu-item class="language-menu-item-container" (click)="changeLanguage($event)" [id]="l.value">
            <img [src]="l.icon" mat-icon class="language-icon" />
            <span translate>{{l.viewValue}}</span>
        </div>
    </mat-menu>
</div>
