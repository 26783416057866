import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MapStateService } from '../map-state.service';
import { MapFindService } from './map-find.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-map-find',
    templateUrl: './map-find.component.html',
    styleUrls: ['./map-find.component.scss']
})
export class MapFindComponent implements OnInit, OnDestroy {

    @ViewChild('textToFind') inputFind: ElementRef | undefined;
    onMapDirtyStateChangeSubscription: Subscription | undefined;
    textSearch = '';
    empty = false;

    constructor(private mapStateService: MapStateService, private mapFindService: MapFindService) {

    }

    ngOnInit() {
        this.textSearch = '';
        this.empty = true;
        this.mapStateService.canEnableQuickEdit = false;
        this.onMapDirtyStateChangeSubscription = this.mapStateService.onDirtyStateChange.subscribe((value: boolean) => {
            this.mapFindService.closeFindText();
        });



        setTimeout(() => {
            this.inputFind?.nativeElement.focus();
        });
    }

    ngOnDestroy() {
        this.clearSearch();
        this.mapStateService.canEnableQuickEdit = true;
        if (this.onMapDirtyStateChangeSubscription) {
            this.onMapDirtyStateChangeSubscription.unsubscribe();
        }
    }

    enterEdit() {
        this.mapStateService.setFocusOnMap(false);
        this.mapStateService.canEnableQuickEdit = false;
    }

    exitEdit() {
        this.mapStateService.setFocusOnMap(true);
        this.mapStateService.canEnableQuickEdit = true;
    }

    clearSearch() {
        if (this.inputFind) {
            this.empty = true;
            this.textSearch = '';
            this.inputFind.nativeElement.value = '';
            this.mapFindService.clearSearch();
            //    this.changeDetectorRef.detectChanges();
        }
    }

    closeSearch() {
        this.mapFindService.closeFindText();
    }

    findNext() {
        this.ctrlText();
        this.mapFindService.findNext();
    }

    findPrevious() {
        this.ctrlText();
        this.mapFindService.findPrevious();
    }

    ctrlText() {
        if (this.textSearch === '') {
            this.findInMap();
        }
    }

    findInMap() {
        // if (this.textSearch !== '') {
        this.textSearch = this.inputFind?.nativeElement.value;
        this.mapFindService.findText(this.textSearch);
        this.mapStateService.setFocusOnMap(true);
        this.mapStateService.canEnableQuickEdit = true;
        // }
    }

    searchClick(evt: any) {
        if (evt.code === 'Enter' || evt.KeyCode === '13' || evt.code === 'Send') {
            this.textSearch = evt.target.value;
            this.mapFindService.findText(this.textSearch);
        }
        if (evt.get)
            this.empty = evt.target.value === '';
    }
}
