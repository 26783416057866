import { Component } from '@angular/core';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../core/authentication/authentication.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent {

    public version = '';
    public build = '';
    public expiry = '';
    public planId = '';

    constructor(
        private authenticationService: AuthenticationService
    ) {
        this.version = environment.version;
        this.build = environment.build;
        if (this.authenticationService.credentials?.expireDate) {
            const expDate = new Date(this.authenticationService.credentials.expireDate);
            this.expiry = expDate.toLocaleDateString();
            this.planId = (this.authenticationService.isLab() ? 'LAB' : 'EDU');
        }
    }

}
