import { Injectable, EventEmitter } from '@angular/core';
import { GenerateSmeService } from './generate-sme.service';
import { Logger } from '../../core/logger.service';
import * as FileSaver from 'file-saver';
import { MapStateService } from '../../shared/map-state.service';

const logger: Logger = new Logger('ExportSmeService');

@Injectable({
    providedIn: 'root'
})
export class ExportSmeService {

    constructor(
        private generateSmeService: GenerateSmeService,
        private mapStateService: MapStateService
    ) { }

    exportSme(mapId: string, workJson: any, mapName: string) {
        this.mapStateService.setBusyState(true);
        this.mapStateService.onExportChange.next(true);
        this.generateSmeService.generate(mapId, workJson)
            .then((sme: any) => {
                // const file = new File([smefile], mapName, { type: 'application/supermappe' });
                // FileSaver.saveAs(smeBlob);
                const smeBlob = new Blob([sme], { type: 'application/supermappe' });
                this.mapStateService.setBusyState(false);
                this.mapStateService.onExportChange.next(false);
                FileSaver.saveAs(smeBlob, mapName + '.sme');
            }).catch((error: any) => {
                // JHUBA: aggiungere stato e gestione errore
                this.mapStateService.setBusyState(false);
                this.mapStateService.onExportChange.next(false);
                logger.error(error);
            });
    }

}
