import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CopyAndOpenMapService } from '../shared/commands/copy-and-open-map.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../core/i18n.service';
import { MapStateService } from '../shared/map-state.service';
import { Subscription } from 'rxjs';
import { SmeService } from '../core/sme/sme.service';
import { MapOperationType } from '../shared/map-operations';
import { AuthenticationService } from '../core/authentication/authentication.service';

@Component({
    selector: 'app-map-copy',
    templateUrl: './map-copy.component.html',
    styleUrls: ['./map-copy.component.scss']
})
export class MapCopyComponent implements OnInit, OnDestroy {
    isLoading = true;
    textMessage: string;
    error: boolean;

    mapStateSubscription: Subscription | undefined;
    queryParamsSubscription: Subscription | undefined;
    getRecentMapsSubscription: Subscription | undefined;
    statShareSubscription: Subscription | undefined;

    @HostListener('window:beforeunload', ['$event'])
    onbeforeunload(event: any) {
        event.returnValue = false;
        return false;
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        switch (event.key) {
            case 'F5':
                //event.returnValue = false;
                event.preventDefault();
                return false;
            case 'KeyR': // R button//82:
                if (event.ctrlKey) {
                    //event.returnValue = false;
                    return false;
                }
        }
        return true;
    }


    constructor(private route: ActivatedRoute, private router: Router,
        private copyAndOpenMapService: CopyAndOpenMapService,
        private translateService: TranslateService, private mapStateService: MapStateService, private smeService: SmeService,
        private authenticationService: AuthenticationService) {
        this.textMessage = this.translateService.instant(extract('CREATING_COPY'));
        this.error = false;
    }

    ngOnInit() {
        this.mapStateSubscription = this.mapStateService.onError.subscribe((error: string) => {
            this.textMessage = error;
            this.error = true;
            this.isLoading = false;
        });

        this.queryParamsSubscription = this.route.queryParams
            .subscribe((queryParams: Params) => {
                //  console.log(JSON.stringify(queryParams));
                const par = JSON.stringify(queryParams);
                const mapId: string = queryParams['mapid'];
                const revId: string = queryParams['revid'];

                // // Confronta con mappe dell'utente
                // this.getRecentMapsSubscription = this.smeService.getRecentMaps().subscribe((resp: any) => {
                //   let mapIsMine = false;
                //   resp.forEach((recent: any) => {
                //     if (recent.id === mapId) {
                //       mapIsMine = true;
                //     }
                //   });
                //   if (!mapIsMine) {
                //     // add stat
                //     if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
                //     this.statShareSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_COPYSHARED), this.authenticationService.getUserEmail().subscribe((data: any) => { });
                //   }
                // });

                // I controlli di appartenenza della mappa sono fatti dalla funzione
                if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
                this.statShareSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_COPYSHARED, this.authenticationService.getUserEmail()).subscribe((data: any) => { });

                // const mapName: string = decodeURI(queryParams['name']);
                const mapName: string = decodeURI(queryParams['name'].replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
                const googleFolderId: string = queryParams['googleFolderID'];
                this.mapStateService.setFocusOnMap(true);
                if (revId) {
                    this.copyAndOpenMapService.copyRevision(revId, mapId, mapName);
                } else {
                    this.copyAndOpenMapService.copyMap(mapId, mapName, googleFolderId);
                }
            });

    }

    ngOnDestroy() {
        if (this.mapStateSubscription) { this.mapStateSubscription.unsubscribe(); }
        if (this.queryParamsSubscription) { this.queryParamsSubscription.unsubscribe(); }
        if (this.getRecentMapsSubscription) { this.getRecentMapsSubscription.unsubscribe(); }
        if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
    }

    closePage() {
        if (this.error) { this.router.navigate(['home']); }

    }
}
