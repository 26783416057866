<div class="searchweb-container">
    <div class="drag-handle" cdkDragHandle>
        <mat-icon fontSet="material-icons-outlined">drag_indicator</mat-icon>
    </div>
    <div class="search-button">
        <button mat-icon-button color="primary" [ngClass]="{'searchMagicEnable': searchGoogleImgEnable}" matTooltip="{{'SEARCH_GOOGLEIMG' | translate}}" (click)="toggleSearchMagic('google-img')">
            <mat-icon [attr.aria-label]="'SEARCH_GOOGLEIMG' | translate" svgIcon="image-search"></mat-icon>
        </button>
    </div>
    <div class="search-button">
        <button mat-icon-button color="primary" [ngClass]="{'searchMagicEnable': searchGoogleEnable}" matTooltip="{{'SEARCH_GOOGLE' | translate}}" (click)="toggleSearchMagic('google')">
            <mat-icon [attr.aria-label]="'SEARCH_GOOGLE' | translate" svgIcon="google"></mat-icon>
        </button>
    </div>
    <div class="search-button">
        <button mat-icon-button color="primary" [ngClass]="{'searchMagicEnable': searchYoutubeEnable}" matTooltip="{{'SEARCH_YOUTUBE' | translate}}" (click)="toggleSearchMagic('youtube')">
            <mat-icon [attr.aria-label]="'SEARCH_YOUTUBE' | translate" svgIcon="youtube"></mat-icon>
        </button>
    </div>
    <div class="search-button">
        <button mat-icon-button color="primary" [ngClass]="{'searchMagicEnable': searchWikipediaEnable}" matTooltip="{{'SEARCH_WIKIPEDIA' | translate}}" (click)="toggleSearchMagic('wikipedia')">
            <mat-icon [attr.aria-label]="'SEARCH_WIKIPEDIA' | translate" svgIcon="wikipedia"></mat-icon>
        </button>
    </div>

    <div class="close-button">
        <button mat-icon-button color="primary" matTooltip="{{'SEARCH_CLOSE' | translate}}" (click)="closeExtra();">
            <mat-icon [attr.aria-label]="'SEARCH_CLOSE' | translate" fontSet="material-icons-outlined">close
            </mat-icon>
        </button>
    </div>
</div>
