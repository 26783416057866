import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Logger } from '../../core/logger.service';
import { SmeService } from 'src/app/core/sme/sme.service';

const logger: Logger = new Logger('mathocrService');

@Injectable({
    providedIn: 'root'
})
export class MathocrService {

    constructor(
        private http: HttpClient,
        private smeService: SmeService
    ) { }

    public getFormulaFromImage(email: string, imageBase64: string, latexFormat: boolean): Observable<any> {
        return this.smeService.getOcrMathPix(email, imageBase64, latexFormat);
    }

}
