import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { UserDto } from '../user-dto';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
    @Input() usersForm = new FormGroup({
        slotCount: new FormControl<number | null>(0, { nonNullable: true }),
        usersList: new FormArray<FormGroup<any>>([], {})
    });

    @Input() showSlot = false;
    @Input() minSlot = 0;
    @Input() maxSlot = 500;
    @Input() showSendMail = false;
    @Output() sendEmail = new EventEmitter<any>();
    @Output() showStats = new EventEmitter<any>();
    @Input() slotCount = 0;
    @Input() withDomain = false;

    private users: Array<UserDto> | undefined;

    public length = 1;
    private pageIndex = 0;
    public pageSize = 50;
    public pageSizeOptions: number[] = [10, 25, 50, 100];
    public pageEvent: PageEvent | undefined;
    public startIndex = 0;
    public endIndex = 0;

    constructor(private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.usersForm = this.formBuilder.group({
            slotCount: [this.slotCount, [Validators.required, Validators.min(this.minSlot), Validators.max(this.maxSlot)]],
            usersList: this.formBuilder.array([this.createUsers()]) //, CustomValidators.uniqueBy('email'))
        });
        const sCount = this.usersForm.get('slotCount');
        sCount?.valueChanges.subscribe((val: any) => {
            if (this.usersForm?.get('slotCount')?.valid) {
                this.slotCount = val;
                this.pageIndex = 0;
                this.length = val;
                if (this.users) {
                    if (val < this.users.length) {
                        const nToRemove = this.users.length - val;
                        this.saveUsersPage();
                        this.users.splice(this.slotCount, nToRemove);
                    }
                    while (this.users.length < val) {
                        const userDto = new UserDto('');
                        this.users.push(userDto);
                    }
                    this.loadUsersPage();
                }
            }
        });
        if (!this.users) {
            this.users = new Array<UserDto>();
        }
        this.length = this.users.length;
        this.loadUsersPage();
    }

    createUsers(): FormGroup {
        return this.formBuilder.group({
            email: ['', Validators.email],
            name: '',
            notes: ''
        });
    }

    sendUserEmail(user: any) {
        this.sendEmail.emit(user);
    }

    showUserStats(user: any) {
        this.showStats.emit(user);
    }

    public initializeUsers(users: Array<UserDto> | undefined) {
        this.users = users;
        if (this.users) {
            this.length = this.users.length;
            // this.createUserSlots(this.pageSize);
            this.loadUsersPage();
            // //  const userslist = this.accessConfigForm.get('users') as FormArray;
            // const userControl = <FormArray>this.usersForm.controls.usersList;
            // userControl.controls.splice(0, userControl.controls.length);
            // this.users = users;
            // this.startIndex = this.pageIndex * this.pageSize;
            // this.endIndex = ((this.pageIndex + 1) * this.pageSize);
            // this.endIndex = Math.min(this.endIndex, this.slotCount);
            // for (let i = this.startIndex; i < this.endIndex; i++) {
            //   userControl.push(this.formBuilder.group({
            //     email: [users[i].eMail, Validators.email],
            //     name: users[i].name,
            //     notes: users[i].notes
            //   }));
            // };
        }
    }

    getControls() {
        return (<FormArray>this.usersForm.get('usersList')).controls;
    }

    getUsers(): Array<UserDto> {
        this.saveUsersPage();
        // this.users = [];
        // const userControl = <FormArray>this.usersForm.controls.usersList;
        // for (let i = 0; i < userControl.length; i++) {
        //   const email = userControl.controls[i].get('email').value;
        //   if (!this.users[i]) {
        //     if (email !== '') {
        //       const userDto = new UserDto('');
        //       this.users.push(userDto);
        //     }
        //   }
        //   if (this.users[i]) {
        //     this.users[i].eMail = userControl.controls[i].get('email').value;
        //     this.users[i].name = userControl.controls[i].get('name').value;
        //     this.users[i].notes = userControl.controls[i].get('notes').value;
        //   }
        // }
        return (this.users) ? this.users : new Array<UserDto>;
    }

    public changePage(event: PageEvent) {
        // this.createUserSlots(event.pageSize);
        this.saveUsersPage();
        this.pageEvent = event;
        if (this.pageSize !== event.pageSize) {
            this.pageIndex = 0;
        } else {
            this.pageIndex = event.pageIndex;
        }
        this.pageSize = event.pageSize;
        this.loadUsersPage();
    }

    loadUsersPage() {
        this.startIndex = this.pageIndex * this.pageSize;
        this.endIndex = ((this.pageIndex + 1) * this.pageSize);
        this.endIndex = Math.min(this.endIndex, this.slotCount);
        const userControl = <FormArray><unknown>this.usersForm.controls.usersList;
        userControl.controls.splice(0, userControl.controls.length);
        if (this.users)
            for (let i = this.startIndex; i < this.endIndex; i++) {

                if (i >= 0 && i < this.users.length) {
                    userControl.push(this.formBuilder.group({
                        email: [this.users[i].eMail, Validators.email],
                        name: this.users[i].name,
                        notes: this.users[i].notes
                    }));
                }
            }
    }

    saveUsersPage() {
        const userControl = <FormArray><unknown>this.usersForm.controls.usersList;
        if (this.users)
            for (let i = 0; i < userControl.length; i++) {
                const index = (this.pageIndex * this.pageSize) + i;
                if (index >= 0 && index < this.users.length) {
                    this.users[index].eMail = userControl.controls[i]?.get('email')?.value;
                    this.users[index].name = userControl.controls[i]?.get('name')?.value;
                    this.users[index].notes = userControl.controls[i]?.get('notes')?.value;
                }
            }
    }

}
