import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MapViewRoutingModule } from './map-view-routing.module';
import { MapViewComponent } from './map-view.component';
import { SmModule } from 'supermappe-core';
import { ViewToolbarComponent } from './toolbar/view-toolbar/view-toolbar.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
// import { //FlexLayoutModule } from '@angular/flex-layout';
import { SmEventBroker } from '../shared/sm-event-broker';
import { TtsService } from '../shared/commands/tts.service';
import { TranslateModule } from '@ngx-translate/core';
import { InteractionmodeService } from '../shared/commands/interactionmode.service';
import { ViewMenuComponent } from './menu/view-menu/view-menu.component';
import { ConvertToImageService } from '../shared/commands/convert-to-image.service';
import { FbService } from '../shared/commands/fb.service';
import { FacebookService, FacebookModule } from 'ngx-facebook';
import { PrintMapService } from '../shared/commands/print-map.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ZoomService } from '../shared/commands/zoom.service';
import { MapFoldingService } from '../shared/commands/map-folding.service';
import { ToggleFullscreenService } from '../shared/commands/toggle-fullscreen.service';
import { ShareMapService } from '../shared/share-map/share-map.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MapFindService } from '../shared/map-find/map-find.service';

@NgModule({
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MapViewRoutingModule,
        SmModule,
        SharedModule,
        MaterialModule,
        DragDropModule,
        //FlexLayoutModule,
        TranslateModule.forRoot()
    ],
    providers: [
        SmEventBroker,
        InteractionmodeService,
        ZoomService,
        ToggleFullscreenService,
        ConvertToImageService,
        TtsService,
        FbService,
        PrintMapService,
        ShareMapService,
        MapFoldingService,
        MapFindService
    ],
    declarations: [
        MapViewComponent,
        ViewToolbarComponent,
        ViewMenuComponent
    ]
})
export class MapViewModule { }
