import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { UserStatsComponent } from './user-stats.component';

const routes: Routes = [
  { path: 'user-stats/:userEmail', component: UserStatsComponent, data: {} }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class UserStatsRoutingModule { }
