<div class="custom-toolbar">
    <button *ngIf="isSingleElementSelected" class="mat-tooltip-trigger" mat-icon-button color="primary" [ngClass]="{'button-down': isFormatCopying}" matTooltip="{{'FORMAT_COPY_FORMAT' | translate}}" (click)="copyFormat()">
        <mat-icon color="primary" [attr.aria-label]="'FORMAT_COPY_FORMAT' | translate" svgIcon="format-paint"></mat-icon>
    </button>

    <app-button-color-picker *ngIf="nodeOrCellsSelected && !nodeText && (!imageSelected || isFramed)" [toolTip]="backgroundTooltip" [pickerIcon]="backgroundIcon" [usedColorLabel]="usedColorLabel" [selectedColor]="selectedBackgroundColor" (onChangeColor)="changeBackgroundColor($event)" [colors]="colors"></app-button-color-picker>
    <app-button-color-picker *ngIf="!nodeText && (!imageSelected || isFramed)" [toolTip]="borderTooltip" [pickerIcon]="borderIcon" [usedColorLabel]="usedColorLabel" [selectedColor]="selectedBorderColor" (onChangeColor)="changeBorderColor($event)" [colors]="colors"></app-button-color-picker>

    <button *ngIf="!hideNodeShape" mat-menu mat-icon-button class="toolbar-button mat-tooltip-trigger" matTooltip="{{'FORMAT_NODE_TOOLBAR-NODE-SHAPE' | translate}}" [matMenuTriggerFor]="nodeShape">
        <mat-icon color="primary" svgIcon="node-shape"></mat-icon>
    </button>
    <mat-menu #nodeShape="matMenu" color="primary" [overlapTrigger]="false">
        <div mat-menu-item *ngFor="let ns of nodeShapes" (click)="changeNodeShape(ns.value)">
            <mat-icon [svgIcon]="ns.icon" color="primary"></mat-icon>
            <span color="primary" translate>{{ns.viewValue}}</span>
        </div>
    </mat-menu>

    <button *ngIf="!nodeText && (!imageSelected || isFramed)" mat-menu mat-icon-button class="toolbar-button mat-tooltip-trigger" matTooltip="{{'FORMAT_NODE_TOOLBAR-BORDER-SIZE' | translate}}" [matMenuTriggerFor]="borderSize">
        <mat-icon color="primary" svgIcon="format-line-weight"></mat-icon>
    </button>
    <mat-menu #borderSize="matMenu" color="primary" [overlapTrigger]="false">
        <div mat-menu-item *ngFor="let b of bordersWidth" (click)="changeBorderWidth(b)">
            <mat-icon [svgIcon]="b.icon" color="primary"></mat-icon>
            <span color="primary">{{b.viewValue}}</span>
        </div>
    </mat-menu>

    <button *ngIf="nodeOrTableSelected" mat-menu mat-icon-button class="mat-tooltip-trigger" matTooltip="{{'FORMAT_NODE_TOOLBAR-TEXT-POSITION' | translate}}" [matMenuTriggerFor]="textPosition">
        <mat-icon color="primary" svgIcon="text-position"></mat-icon>
    </button>
    <mat-menu #textPosition="matMenu" color="primary" [overlapTrigger]="false">
        <div mat-menu-item *ngFor="let tp of textPositions" (click)="changeTextPosition(tp)">
            <mat-icon [svgIcon]="tp.icon" color="primary"></mat-icon>
            <span color="primary" translate>{{tp.viewValue}}</span>
        </div>
    </mat-menu>

    <ng-container *ngIf="edgeSelected">
        <button mat-icon-button color="primary" [ngClass]="{'button-down': edgeArrowLeftChecked}" class="mat-tooltip-trigger" matTooltip="{{'FORMAT_NODE_TOOLBAR-ARROW-LEFT' | translate}}" (click)="setSelectedEdgesTerminals('left')">
            <mat-icon [attr.aria-label]="'FORMAT_NODE_TOOLBAR-ARROW-LEFT' | translate" svgIcon="arrow-left" color="primary"></mat-icon>
        </button>
        <button mat-icon-button color="primary" class="mat-tooltip-trigger" matTooltip="{{'FORMAT_NODE_TOOLBAR-ARROW-RIGHT' | translate}}" [ngClass]="{'button-down': edgeArrowRightChecked}" (click)="setSelectedEdgesTerminals('right')">
            <mat-icon [attr.aria-label]="'FORMAT_NODE_TOOLBAR-ARROW-RIGHT' | translate" svgIcon="arrow-right" color="primary"></mat-icon>
        </button>

        <button mat-icon-button color="primary" class="mat-tooltip-trigger" matTooltip="{{'FORMAT_NODE_TOOLBAR-BREAK-LINK' | translate}}" (click)="breakEdge()">
            <mat-icon [attr.aria-label]="'FORMAT_NODE_TOOLBAR-BREAK-LINK' | translate" svgIcon="ray-vertex"></mat-icon>
        </button>

        <!--Archi curvi/dritti-->
        <button mat-menu mat-icon-button class="mat-tooltip-trigger" matTooltip="{{'FORMAT_NODE_TOOLBAR-CURVE-EDGE' | translate}}" [matMenuTriggerFor]="edgeType">
            <mat-icon color="primary" fontSet="material-icons-outlined">north_east</mat-icon>
        </button>
        <mat-menu #edgeType="matMenu" color="primary" [overlapTrigger]="false">
            <div mat-menu-item (click)="curvedEdge(false)">
                <mat-icon fontSet="material-icons-outlined" color="primary">east</mat-icon>
                <span color="primary" translate>EDGE_STRAIGHT</span>
            </div>
            <div mat-menu-item (click)="curvedEdge(true)">
                <mat-icon fontSet="material-icons-outlined" color="primary">redo</mat-icon>
                <span color="primary" translate>EDGE_CURVE</span>
            </div>
        </mat-menu>
    </ng-container>

    <button mat-icon-button color="primary" class="mat-tooltip-trigger" matTooltip="{{'FORMAT_NODE_TOOLBAR-BRING-TO-FRONT' | translate}}" (click)="bringSelectionToFront()">
        <mat-icon [attr.aria-label]="'FORMAT_NODE_TOOLBAR-BRING-TO-FRONT' | translate" svgIcon="arrange-bring-forward"></mat-icon>
    </button>
    <button mat-icon-button color="primary" class="mat-tooltip-trigger" matTooltip="{{'FORMAT_NODE_TOOLBAR-SEND-TO-BACK' | translate}}" (click)="sendSelectionToBack()">
        <mat-icon [attr.aria-label]="'FORMAT_NODE_TOOLBAR-SEND-TO-BACK' | translate" svgIcon="arrange-send-backward"></mat-icon>
    </button>
</div>
