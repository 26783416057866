import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PasteBulletsComponent } from './paste-bullets.component';

@Injectable({
    providedIn: 'root'
})
export class PasteBulletsService {

    constructor(private dialog: MatDialog) {
        this.dialog = dialog;
    }

    public show(hasDeepContent: boolean): Observable<boolean> {
        const dialogRef: MatDialogRef<PasteBulletsComponent> = this.dialog.open(PasteBulletsComponent);
        dialogRef.componentInstance.hasDeepContent = hasDeepContent;
        return dialogRef.afterClosed();
    }

}
