import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinkData {
  public id = 0;
  public name = '';
  public type = '';
  public uri = '';
  public currLanguage = '';
  public isEnabled = false;
  public tooltip = '';
}

@Injectable({
  providedIn: 'root'
})
export class LinksMenuData {
  public x = 0;
  public y = 0;
  public items: Array<LinkData> = new Array<LinkData>();

  public static build(x: number, y: number, items: Array<LinkData>, unavailableTooltip: string): LinksMenuData {
    const md = new LinksMenuData();
    md.x = x;
    md.y = y;
    md.items = items;
    for (const item of items) {
      item.isEnabled = (item.type === 'WWW' || item.type === 'deep');
      if (item.isEnabled) {
        item.tooltip = item.name;
        // item.tooltip = decodeURIComponent(item.uri);
      } else {
        item.tooltip = unavailableTooltip;
      }
    }
    return md;
  }
}

