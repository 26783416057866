import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './home/home.module';
import { AboutModule } from './about/about.module';

import { DemoModule } from './demo/demo.module';
import { LoginModule } from './login/login.module';
import { LoggedInModule } from './logged-in/logged-in.module';
import { MapEditModule } from './map-edit/map-edit.module';
import { NobrowserModule } from './nobrowser/nobrowser.module';
import { GdriveOpenModule } from './gdrive-open/gdrive-open.module';
import { GdriveNewModule } from './gdrive-new/gdrive-new.module';
import { GdrivePickerModule } from './gdrive-picker/gdrive-picker.module';
import { MapViewModule } from './map-view/map-view.module';
// import { AnalyticsService } from './core/google/analytics.service';


import { FontPickerModule } from 'ngx-font-picker';
import { FONT_PICKER_CONFIG } from 'ngx-font-picker';
import { FontPickerConfigInterface } from 'ngx-font-picker';

import { MapOpenModule } from './map-open/map-open.module';
import { MapCopyModule } from './map-copy/map-copy.module';
import { MapHistoryModule } from './map-history/map-history.module';
import { MccColorPickerModule } from 'material-community-components/color-picker';
import { MapShowModule } from './map-show/map-show.modules';
import { MapNewModule } from './map-new/map-new.modules';
import { AccessConfigModule } from './access-config/access-config.module';
import { UserStatsModule } from './stats/user-stats/user-stats.module';
import { MultiStatsModule } from './stats/multi-stats/multi-stats.module';
import { AdminStatsModule } from './stats/admin-stats/admin-stats.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateUniversalLoader } from './translate-universal-loader';
import { GdriveFolderPickerModule } from './gdrive-folder-picker/gdrive-folder-picker.module';
import { AdminPageModule } from './admin-page/admin-page.module';
import { AdminNotificationsModule } from './admin-notifications/admin-notifications.module';
import { AdminUsersModule } from './admin-users/admin-users.module';
import { MatIconRegistry } from '@angular/material/icon';
import { provideMatomo, withRouteData } from 'ngx-matomo-client';
import { withRouter } from 'ngx-matomo-client';
import { NgxCaptureModule } from 'ngx-capture';
import { SmModule } from 'supermappe-core';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from 'src/environments/environment';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
// import { MaintenanceModule } from './maintenance/maintenance.module';

//import { PdfPrintModule } from './map-edit/pdf-editor/pdf-print/pdf-print.module';
import { PdfPrintRoutingModule } from './map-edit/pdf-editor/pdf-print/pdf-print-routing.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UserProfileModule } from './user-profile/user-profile.module';
import { AboutDeviceComponent } from './about-device/about-device.component';
import { ShareClassroomModule } from './shared/share-classroom/share-classroom.module';
import { MapImportModule } from './map-import/map-import.module';


const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
    // Change this to your Google API key
    apiKey: 'AIzaSyAYibpse-MgQ4u5JxFeWqxMNfSKwx98y4M'
};

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateUniversalLoader
            }
        }),
        BrowserAnimationsModule,
        MaterialModule,
        CoreModule,
        SharedModule,
        HomeModule,
        AboutModule,
        DemoModule,
        LoginModule,
        UserProfileModule,
        SmModule,
        LoggedInModule,
        GdriveOpenModule,
        GdriveNewModule,
        GdrivePickerModule,
        GdriveFolderPickerModule,
        PdfPrintRoutingModule,
        MapEditModule,
        MapViewModule,
        MapShowModule,
        NobrowserModule,
        MapOpenModule,
        MapImportModule,
        MapCopyModule,
        MapNewModule,
        MapHistoryModule,
        ShareClassroomModule,
        FontPickerModule,
        MccColorPickerModule.forRoot({
            empty_color: 'transparent',
            selected_icon: '',
            disable_selected_icon: true,
            enable_alpha_selector: false
        }),
        AccessConfigModule,
        UserStatsModule,
        MultiStatsModule,
        AdminStatsModule,
        AdminPageModule,
        AdminNotificationsModule,
        AdminUsersModule,
        NgxCaptureModule,
        MatSnackBarModule,
        AppRoutingModule // <- Va lasciata per ultima altrimenti il routing si rompe
    ],
    declarations: [AppComponent, AboutDeviceComponent],
    providers: [
        // AnalyticsService,

        provideFirebaseApp(() => initializeApp(environment.configFirebase)),
        provideFirestore(() => getFirestore()),
        provideAuth(() => getAuth()),

        {
            provide: FONT_PICKER_CONFIG,
            useValue: DEFAULT_FONT_PICKER_CONFIG
        },
        provideMatomo({ trackerUrl: 'https://matomo.anastasis.it/', siteId: '2' }, withRouter(), withRouteData())
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        // We inject AnalyticsService here to keep it alive whole time
        // protected _analyticsService: AnalyticsService,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer) {
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
    }
}
