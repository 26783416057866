import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { MapCopyRoutingModule } from './map-copy-routing.module';
import { MapCopyComponent } from './map-copy.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        CoreModule,
        SharedModule,
        //FlexLayoutModule,
        MaterialModule,
        MatTooltipModule,
        MatInputModule,
        MapCopyRoutingModule,
    ],
    declarations: [
        MapCopyComponent
    ],
    providers: [
    ]
})
export class MapCopyModule { }
