import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '../core/i18n.service';
import { MapShowComponent } from './map-show.component';

const routes: Routes = [
  { path: 'map-shared/:mapId', redirectTo: 'map-view/:mapId' },
  { path: 'map-show/:mapId', component: MapShowComponent, data: { title: extract('MAPEDIT_TITLE') } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class MapShowRoutingModule { }
