<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<form class="search" [formGroup]="searchForm" novalidate (ngSubmit)="onSubmit()">
    <span class="section-title">{{'NOTIFICATIONS_LIST' | translate}}</span>
    <button mat-icon class="button-search" type="submit" matTooltip="{{'SEARCH' | translate}}">
        <mat-icon>search</mat-icon>
    </button>
    <input #searchtext type="text" placeholder="{{'SEARCH_PLACEHOLDER' | translate}}" ngDefaultControl autocomplete="off" spellcheck="false" class="input-search" formControlName="searchText" />
    <button mat-icon type="button" class="button-clear" matTooltip="{{'SEARCH_CLEAR' | translate}}" (click)="resetSearch()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</form>
<div [hidden]="!noList" class="center-text">
    <span class="title-span">{{'NO_NOTIFICATIONS' | translate}}</span>
</div>
<div [hidden]="noList" class="list-notification">
    <!-- <div [hidden]="!isLoaded">
    <mat-paginator class="paginator" #paginator [showFirstLastButtons]="true" [length]="length" [pageSize]="pageSize" (page)="pageEvent = $event; changePage($event)">
    </mat-paginator>
  </div> -->
    <mat-accordion multi="true" class="order-panel" *ngFor="let notification of notifications; let i = index;">
        <mat-expansion-panel [expanded]="notification.panelOpenState" [(ngModel)]="notification.panelOpenState">
            <mat-expansion-panel-header class="expansion-header">
                <mat-panel-title>
                    <div class="order-item">
                        <div class="item-1">
                            <span class="title-span">{{'NOTIFICATION_TITLE' | translate}}: </span>
                            <span class="item-span">{{notification.title}} </span>
                        </div>
                        <div class="item-2">
                            <span class="title-span">{{'NOTIFICATION_DATE' | translate}}</span>
                            <span class="item-span">{{notification.dateCreated}}</span>
                        </div>
                        <div class="item-3">
                            <span class="title-span">{{'NOTIFICATION_TYPE' | translate}}</span>
                            <img class="item-span" [src]="notification.icon" />
                        </div>
                        <div class="item-4">
                            <span class="title-span">{{'NOTIFICATION_CHECK' | translate}}: </span>
                            <span class="item-span">{{notification.active}}</span>
                        </div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="order-body">
                <div class="item-1 order-subitem">
                    <span class="title-span item-sub1">{{'NOTIFICATION_CHECK' | translate}} </span>
                    <span class="item-span item-sub2 bold">{{notification.active}} </span>
                    <!-- <span class="title-span item-sub1">{{'NOTIFICATION_ID' | translate}} </span>
          <span class="item-span item-sub2">{{notification.notificationId}} </span> -->
                    <span class="title-span item-sub1">{{'NOTIFICATION_DESC' | translate}} </span>
                    <span class="item-span item-sub2">{{notification.notes}} </span>
                    <span class="title-span item-sub1">{{'NOTIFICATION_LINK' | translate}} </span>
                    <span class="item-span">{{notification.link}} </span>
                </div>
                <div class="button-panel">
                    <button color="primary" mat-button class="title-button" (click)="editNotification(notification)">{{'EDIT_NOTIFICATION' | translate}}</button>
                </div>
                <div class="item-3" *ngIf="notification.edit">
                    <form [formGroup]="editNotificationForm" novalidate (ngSubmit)="updateForm(notification)">
                        <app-admin-edit-notification #notificationForm [notificationForm]="editNotificationForm" [notificationData]="notification"></app-admin-edit-notification>
                        <button class="ok-button" [disabled]="!canSave()" mat-button type="submit">{{'ACCESSCONFIG_SAVE' | translate}}
                        </button>
                    </form>
                </div>

            </div>
        </mat-expansion-panel>

    </mat-accordion>
</div>
