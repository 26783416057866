<form [formGroup]="privacyForm" novalidate (ngSubmit)="onSubmit()">
    <div>
        <mat-dialog-content>
            <div [innerHTML]="safePrivacyHeader"></div>

            <!-- Tipo di utenza -->
            <!-- <div *ngIf="logType === 's-adm' || logType === 'd-adm' || logType === 'm-adm'"> -->
            <!--    <div *ngIf="logType === 's-adm' || logType === 'd-adm' || logType === 'm-adm'"> -->
            <div class="user-type-container">
                <p translate>IC_USER_TYPE_TITLE</p>
                <span translate>IC_USER_TYPE</span>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="selectUserType" [(ngModel)]="userType">
                        <mat-option *ngFor="let u of userTypes" [value]="u.value">{{u.name}}</mat-option>
                    </mat-select>
                    <!-- <select class="user-type-select" formControlName="selectUserType" [(ngModel)]="userType">
                    <option class="user-type-option" *ngFor="let u of userTypes" [ngValue]="u.value">{{u.name}}</option>
                </select> -->

                </mat-form-field>
                <span class="star">*</span>
            </div>
            <!-- </div> -->
            <!-- </div> -->
            <br />

            <!-- Informativa privacy per demo, privati e classi (admin e user) -->
            <div *ngIf="userType!==''">
                <!-- <div *ngIf="showAll || (userType!=='' && (logType === 's-adm' || logType === 'd-adm' || logType === 'm-adm' || logType === 'm-usr' || logType === 'd-usr'))"> -->
                <div [innerHTML]="safePrivacyContent"></div>
                <div class="user-type-container">
                    <mat-checkbox class="bold-checkbox" formControlName="checkPrivacy">{{'PRIVACY_CONFIRM' | translate}}</mat-checkbox>
                    <span class="star">*</span>
                    <br />
                </div>
                <!-- <div [innerHTML]="safePrivacyPurpose"></div>
                <div [innerHTML]="safePrivacyPurposeA"></div>
                <mat-checkbox class="bold-checkbox" formControlName="checkPurposeA">{{'PRIVACY_CONFIRM' | translate}}</mat-checkbox>
                <span class="star">*</span>
                <br />
                <br />
                <div [innerHTML]="safePrivacyPurposeB"></div>
                <mat-checkbox class="bold-checkbox" formControlName="checkPurposeB">{{'PRIVACY_CONFIRM' | translate}}</mat-checkbox>
                <span class="star">*</span>
                <br />
                <br />
                <div [innerHTML]="safePrivacyPurposeC"></div>
                <mat-checkbox class="bold-checkbox" formControlName="checkPurposeC">{{'PRIVACY_CONFIRM' | translate}}</mat-checkbox>
                <span class="star">*</span>
                <br />
                <br /> -->
            </div>

            <!-- Richiesta range di età -->
            <div *ngIf="userType!=='' && logType === 's-adm'">
                <div>
                    <h2><b><span translate>PRIVACY_AGE_REQUEST</span></b><span class="star">*</span></h2>
                    <mat-radio-group formControlName="selectAgeRange" [(ngModel)]="ageRangeSelected">
                        <mat-radio-button [value]="ageRanges[0].value">{{ageRanges[0].name}}</mat-radio-button><br />
                        <mat-radio-button [value]="ageRanges[1].value">{{ageRanges[1].name}}</mat-radio-button><br />
                    </mat-radio-group>
                </div>
            </div>
            <br />

            <!-- Newsletter -->
            <div *ngIf="userType!=='' && logType === 's-adm' && ageRangeSelected === 'OVER_18'">
                <br />
                <div [innerHTML]="'NEWSLETTER_CONTENT_1' | translate"></div>
                <div>
                    <span class="newsletter-info-link" (click)="newsLetterInfo()" [matTooltip]="nlInfoTooltip" translate>NEWSLETTER_CONTENT_2</span>
                </div>
                <br />
                <mat-checkbox class="bold-checkbox" formControlName="checkNewsletter" [checked]="newsletterChecked" (change)="newsletterChecked = !newsletterChecked">{{'PRIVACY_NEWSLETTER_CONFIRM' | translate}}</mat-checkbox>
                <span (click)="newsLetterInfo()">
                    <mat-icon class="newsletter-info-icon" svgIcon="help-circle-outline" [matTooltip]="nlInfoTooltip"></mat-icon>
                </span>
                <br />
                <br />
            </div>

            <!-- Accettazione dei termini di servizio -->
            <!-- <p *ngIf="userType!=='' && (logType === 's-adm' || logType === 'd-adm' || logType === 'm-adm')" class="user-type-title" translate>PRIVACY_DOC_TITLE</p> -->

            <!-- DPA -->
            <!-- <div *ngIf="userType!=='' && (logType === 'd-adm' || logType === 'm-adm')">
                <div [innerHTML]="safeDPAContent"></div>
                <mat-checkbox class="bold-checkbox" formControlName="checkDPA">{{'PRIVACY_CONFIRM' | translate}}</mat-checkbox>
                <span class="star">*</span>
                <br />
                <br />
            </div> -->

            <!-- Contratto di servizio -->
            <!-- <div *ngIf="userType!=='' && ((logType === 's-adm' || logType === 'd-adm' || logType === 'm-adm'))">
                <div [innerHTML]="safeContractContent"></div>
                <mat-checkbox class="bold-checkbox" formControlName="checkContract">{{'PRIVACY_CONFIRM' | translate}}</mat-checkbox>
                <span class="star">*</span>
                <br />
            </div> -->

        </mat-dialog-content>
        <mat-dialog-actions class="action-container">

            <button type="reset" (click)="onReset()" mat-flat-button>{{'ACCESSCONFIG_RESET' | translate}}</button>

            <button color="accent" [disabled]="!enabledForm()" mat-flat-button type="submit">{{'ACCESSCONFIG_SAVE' | translate}}</button>

        </mat-dialog-actions>
    </div>
</form>
