import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'app-password-request',
    templateUrl: './password-request.component.html',
    styleUrls: ['./password-request.component.scss']
})
export class PasswordRequestComponent implements AfterViewInit {
    isLoading = false;
    error: any = '';
    //requestSent = false;
    // text = "";
    form: FormGroup = new FormGroup({
        password: new FormControl('', [Validators.required]),

    });
    email: string = "";

    hide: boolean = true;
    @Output() submitPassword: EventEmitter<string> = new EventEmitter<string>();
    @Output() forgotPassword: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('passwordInput') passwordInput?: ElementRef;

    constructor() { }
    get f() { return this.form?.controls; }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.passwordInput?.nativeElement.focus();
        });
    }

    init(email: string) {
        this.email = email;
    }
    showHidePsw(): void {
        this.hide = !this.hide;
    }

    isIEOrEdge(): boolean {
        return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    }

    submit() {

        //this.createUser();
        //this.requestAccess();
        //this.text = this.translateService.instant('RESPONSE_RP_2').replace('%email%', this.form.get("username")?.value);
        // this.requestSent = true;
        this.submitPassword.emit(this.form.get("password")?.value);
        // this.dialog.closeAll();

    }

    openForgotPassword() {
        this.forgotPassword.emit();

    }

    @HostListener('keypress', ['$event'])
    keypress(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            event.preventDefault();
            if (this.form.valid) {
                this.submit();
            }
        }
    }

}
