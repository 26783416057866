import { CustomSelectElement } from '../map-edit/toolbar/custom-toolbar/custom-select-element-dto';
import { TranslateService } from '@ngx-translate/core';
import { SmxFontStyle } from '../map-edit/dialog-preferences/smx-font-style';
import { SmxNodeAspect } from '../map-edit/dialog-preferences/smx-node-aspect';
import { SmxEdgeAspect } from '../map-edit/dialog-preferences/smx-edge-aspect';
// import { CustomizeToolbarService } from './toolbar/custom-toolbar/customize-toolbar.service';

export class UiConstants {

    // Modifiche da riportare in user-privacy.component.ts, mailchimp.handler.js e i files di localizzazione
    static USER_TYPES = [
        // { value: '', name: '' },
        { value: 'PRIVATO', name: '' },
        { value: 'INSEGNANTE', name: '' },
        { value: 'EDUCATORE', name: '' },
        // { value: 'FIGURA_CLINICA', name: '' },
        // { value: 'LOGOPEDISTA', name: '' },
        // { value: 'ASL', name: '' },
        { value: 'SCUOLA', name: '' },
        { value: 'UNIVERSITA', name: '' }
    ];

    static AGE_RANGES = [
        { value: 'OVER_18', name: '' },
        { value: 'UNDER_18', name: '' }
    ];

    /*
        Per modificare le origini inserire qui i valori (chiave_db, chiave_traduzione)
        poi aggiungere le chiavi nei file di localizzazione (it-IT.json ecc.)
    */
    static ORIGINS = [
        { id: 'manual', name: 'ORIGIN_MANUAL' },
        { id: 'demo', name: 'ORIGIN_DEMO' },
        { id: 'buy', name: 'ORIGIN_BUY' },
        { id: 'aid', name: 'ORIGIN_AID' },
        { id: 'spain', name: 'ORIGIN_SPAIN' },
        { id: 'webinar', name: 'ORIGIN_WEBINAR' },
        { id: 'fad', name: 'ORIGIN_FAD' },
        { id: 'rete-ana-scuola', name: 'ORIGIN_RETE-ANA-SCUOLA' },
        { id: 'usr-er', name: 'ORIGIN_USR-ER' },
        { id: 'doposcuola', name: 'ORIGIN_DOPOSCUOLA' },
        { id: 'ds-scuola-2020', name: 'ORIGIN_DS-SCUOLA-2020' },
        { id: 'piem-spaolo-2021', name: 'ORIGIN_PIEM-SPAOLO-2021' },
        { id: 'azione-smx-2020-2021', name: 'ORIGIN_AZIONE-SMX-2020-2021' },
        { id: 'azione-smx-2021-2022', name: 'ORIGIN_AZIONE-SMX-2021-2022' },
        { id: 'app-store', name: 'Apple App Store' },
        { id: 'play-store', name: 'Google Play Store' },
        { id: 'smx-scuole-2022', name: 'ORIGIN_SMX-SCUOLE-2022' }
    ];

    static ORIGINS_ALL = [
        { id: '*', name: 'ORIGIN_ALL' }
    ].concat(UiConstants.ORIGINS);

    static PLAN_IDS = [
        { id: 'edu', name: 'edu' },
        { id: 'lab', name: 'lab' }
    ]

    static OCRMATH_POLICIES = [
        { id: 'FREE', name: 'OCRSCAN_POLICY_FREE' },
        { id: 'MONTHLY', name: 'OCRSCAN_POLICY_MONTHLY' },
        { id: 'MONTHLY-USER', name: 'OCRSCAN_POLICY_MONTHLY-USER' }
    ];

    static OCRMATH_RULES = {
        single: { quota: 500, policy: 'FREE' },
        multiple: { quota: 20, policy: 'MONTHLY-USER' },
        domain: { quota: 2200, policy: 'MONTHLY' },

    };

    static MATH_KEYBOARDS = {
        BASIC: ['BASIC', 'SETS', 'GEOMETRY', 'DISEQUATIONS', 'PHYSICS', 'ANALYSIS', 'TRIGONOMETRY', 'LOG_EXP', 'GREEK'],
        PRIMARY: ['PRIMARY']
    }

    static SMXERRORS = class {
        public static readonly FORBIDDEN = 'FORBIDDEN';
        public static readonly WRONG_PARAMS = 'WRONG_PARAMS';
        public static readonly ERROR_WRONG_FILETYPE = 'ERROR_WRONG_FILETYPE';
        public static readonly ERROR_LINK_PREVIEW = 'ERROR_LINK_PREVIEW';
        public static readonly ERROR_AUTHENTICATION = 'ERROR_AUTHENTICATION';
        public static readonly ERROR_GOOGLEID_NOT_FOUND = 'ERROR_GOOGLEID_NOT_FOUND';
        public static readonly ERROR_GOOGLEDRIVE = 'ERROR_GOOGLEDRIVE';
        public static readonly ERROR_DATABASE = 'ERROR_DATABASE';
        public static readonly ERROR_GENERATESME = 'ERROR_GENERATESME';
        public static readonly ERROR_STORAGE_DOWNLOAD = 'ERROR_STORAGE_DOWNLOAD';
        public static readonly ERROR_OPENMAP = 'ERROR_OPENMAP';
        public static readonly ERROR_READFILE = 'ERROR_READFILE';
        public static readonly ERROR_WRITEFILE = 'ERROR_WRITEFILE';
        public static readonly ERROR_CONVERTDATAURL = 'ERROR_CONVERTDATAURL';
        public static readonly ERROR_GETPROTOCOL = 'ERROR_GETPROTOCOL';
        public static readonly ERROR_RESIZEPNG = 'ERROR_RESIZEPNG';
        public static readonly ERROR_UNSUPPORTEDPROTOCOL = 'ERROR_UNSUPPORTEDPROTOCOL';
        public static readonly ERROR_WRONGFILETYPE = 'ERROR_WRONGFILETYPE';
        public static readonly ERROR_MAPJSONEMPTY = 'ERROR_MAPJSONEMPTY';
        public static readonly ERROR_WRITEJSON = 'ERROR_WRITEJSON';
        public static readonly ERROR_ZIPPER = 'ERROR_ZIPPER';
        public static readonly ERROR_UNZIPMAP = 'ERROR_UNZIPMAP';
        public static readonly ERROR_GRAPHML2JSON = 'ERROR_GRAPHML2JSON';
    };

    static LANGUAGES = [
        new CustomSelectElement('it-IT', 'it-IT', 'assets/flags/it-IT.png'),
        new CustomSelectElement('en-GB', 'en-GB', 'assets/flags/en-GB.png'),
        new CustomSelectElement('ca-ES', 'ca-ES', 'assets/flags/ca-ES.png'),
        new CustomSelectElement('es-ES', 'es-ES', 'assets/flags/es-ES.png'),
        new CustomSelectElement('fr-FR', 'fr-FR', 'assets/flags/fr-FR.png'),
        new CustomSelectElement('de-DE', 'de-DE', 'assets/flags/de-DE.png')
    ];

    static FLAGS = [
        new CustomSelectElement('unknown', 'unknown', 'assets/flags/other.png'),
        new CustomSelectElement('it-IT', 'it-IT', 'assets/flags/it-IT.png'),
        new CustomSelectElement('en-GB', 'en-GB', 'assets/flags/en-GB.png'),
        new CustomSelectElement('en-US', 'en-US', 'assets/flags/en-US.png'),
        new CustomSelectElement('ca-ES', 'ca-ES', 'assets/flags/ca-ES.png'),
        new CustomSelectElement('es-ES', 'es-ES', 'assets/flags/es-ES.png'),
        new CustomSelectElement('es-US', 'es-US', 'assets/flags/es-US.png'),
        new CustomSelectElement('fr-FR', 'fr-FR', 'assets/flags/fr-FR.png'),
        new CustomSelectElement('de-DE', 'de-DE', 'assets/flags/de-DE.png'),
        new CustomSelectElement('hi-IN', 'hi-IN', 'assets/flags/hi-IN.png'),
        new CustomSelectElement('id-ID', 'id-ID', 'assets/flags/id-ID.png'),
        new CustomSelectElement('ja-JP', 'ja-JP', 'assets/flags/ja-JP.png'),
        new CustomSelectElement('ko-KR', 'ko-KR', 'assets/flags/ko-KR.png'),
        new CustomSelectElement('nl-NL', 'nl-NL', 'assets/flags/nl-NL.png'),
        new CustomSelectElement('pl-PL', 'pl-PL', 'assets/flags/pl-PL.png'),
        new CustomSelectElement('pt-BR', 'pt-BR', 'assets/flags/pt-BR.png'),
        new CustomSelectElement('ru-RU', 'ru-RU', 'assets/flags/ru-RU.png'),
        new CustomSelectElement('uk-UA', 'uk-UA', 'assets/flags/uk-UA.png'),
        new CustomSelectElement('zh-CN', 'zh-CN', 'assets/flags/zh-CN.png'),
        new CustomSelectElement('zh-HK', 'zh-HK', 'assets/flags/zh-HK.png'),
        new CustomSelectElement('zh-TW', 'zh-TW', 'assets/flags/zh-TW.png'),
    ];

    static NOTIFICATIONICONS = [
        new CustomSelectElement('custom', 'CUSTOM', '../../assets/notifications/personalizzata.png'),
        new CustomSelectElement('subscription', 'SUBSCRIPTION', '../../assets/notifications/abbonamento.png'),
        new CustomSelectElement('update', 'UPDATE', '../../assets/notifications/aggiornamento.png'),
        new CustomSelectElement('maintenance', 'MAINTENANCE', '../../assets/notifications/manutenzione.png'),
        new CustomSelectElement('webinar', 'WEBINAR', '../../assets/notifications/webinar.png'),
        // From Peer:
        new CustomSelectElement('share', 'SHARE', '../../assets/notifications/share.png')
    ];

    static TEXTSTYLE = class {
        public static readonly FONT = 'font';
        public static readonly FONTSIZE = 'fontsize';
        public static readonly TEXTCOLOR = 'textcolor';
        public static readonly BACKCOLOR = 'backcolor';
        public static readonly BOLD = 'bold';
        public static readonly ITALIC = 'italic';
        public static readonly UNDERLINE = 'underline';
        public static readonly SUPERSCRIPT = 'superscript';
        public static readonly SUBSCRIPT = 'subscript';
    };

    // @@@GWFONTS
    static FONTS = class {
        public static readonly fontFamilies = [
            // Standard fonts
            { name: 'Arial' },
            { name: 'Verdana' },
            { name: 'Courier New' },
            // Google Web Fonts (update index.html!!! in case of change)
            { name: 'Architects Daughter' },
            { name: 'IBM Plex Mono' },
            { name: 'Montserrat' },
            { name: 'Roboto' },
            { name: 'Special Elite' }
        ];
        public static readonly fontSizes = [
            { value: '8' },
            { value: '9' },
            { value: '10' },
            { value: '11' },
            { value: '12' },
            { value: '14' },
            { value: '16' },
            { value: '18' },
            { value: '24' },
            { value: '30' },
            { value: '36' },
            { value: '48' }
        ];
    };

    static EDGE = class {
        public static readonly widths = [
            { value: '1' },
            { value: '2' },
            { value: '3' },
            { value: '4' }
        ];
    }

    static PALETTE = class {
        public static readonly EXTENDED: string[] = [
            // Base colors
            '#ffffff', '#000000', '#eeece1', '#1f497d', '#c0504d', '#9bbb59', '#9bbb59', '#8064a2', '#4bacc6', '#f79646',
            // Color shades
            '#f2f2f2', '#808080', '#ddd9c2', '#c6d9f1', '#dce6f2', '#f2dcdb', '#ebf1de', '#e6e0ec', '#dbeef4', '#fdeada',
            '#d9d9d9', '#595959', '#c4bd96', '#8db3e3', '#b9cde5', '#e6b9b7', '#d7e4bc', '#ccc1da', '#b7dee8', '#fcd5b5',
            '#bfbfbf', '#404040', '#938953', '#548ed5', '#95b3d7', '#e6b9b7', '#c3d69b', '#b3a2c7', '#93cddd', '#fac090',
            '#bfbfbf', '#262626', '#4a452a', '#17365d', '#376092', '#943734', '#c3d69b', '#604a7b', '#31859b', '#e46c0a',
            '#a6a6a6', '#0d0d0d', '#1d1b11', '#17365d', '#254061', '#632523', '#4e6128', '#403152', '#215867', '#984807',
            // Primary colors
            '#c00000', '#ff0000', '#ffc000', '#ffff00', '#92d050', '#00b050', '#00b0f0', '#0070c0', '#002060', '#7030a0'
        ];

        public static readonly BASE: string[] = [
            '#ffffff', '#000000', '#ffec66', '#004880', '#796356', '#dc4f4b', '#8dbc50', '#8b64a6', '#00aec8', '#ff973d',
            '#00ff00', '#f2f2f2', '#fff2cc', '#bfdaf2', '#dfdac2', '#fadddc', '#e2efd9', '#e9e1ed', '#d4eef5', '#ffebda',
            '#00ffff', '#818181', '#ffc100', '0070c4', '#998675', '#ff0000', '#00b247', '#812ca4', '#00b2f3', '#ffc100'

            // '#ffffff', '#000000', '#efede1', '#004880', '#796356', '#dc4f4b', '#8dbc50', '#8b64a6', '#00aec8', '#ff973d',
            // '#f2f2f2', '#818181', '#dfdac2', '#bfdaf2', '#e4cebd', '#fadddc', '#e2efd9', '#e9e1ed', '#d4eef5', '#ffebda',
            // '#e00000', '#ff0000', '#ffc100', '#ffff00', '#6ed142', '#00b247', '#00b2f3', '#0070c4', '#001a62', '#812ca4'
            /*transparent*/
            //         '#000000', '#ffec66', '#004880', '#796356', '#dc4f4b', '#8dbc50', '#8b64a6', '#00aec8', '#ff973d',
            //   '#ffffff', '#f2f2f2', '#fff2cc', '#bfdaf2', '#dfdac2', '#fadddc', '#e2efd9', '#e9e1ed', '#d4eef5', '#ffebda',
            //   '#00ffff', '#818181', '#ffc100', '#0070c4', '#998675', '#ff0000', '#00b247', '#812ca4', '#00b2f3', '#ffc100'
        ];
        public static readonly PDFCOLOR: string[] = [
            '#000000', '#eeece1', '#1f497d', '#c0504d', '#9bbb59', '#ffff00', '#8064a2', '#4bacc6', '#f79646'
        ]
        public static readonly PDFHIGHLIGHTERS: string[] = [
            '#feed004d', '#00fff64d', '#00ff004d', '#ff4ce24d', '#0000FF4d', '#ff00004d', '#0000004d', '#ffffff4d'
            // '#ffffff4d', '#0000004d', '#ffec664d', '#0048804d', '#7963564d', '#dc4f4b4d', '#8dbc504d', '#8b64a64d', '#00aec84d', '#ff973d4d',
            // '#00ff004d', '#f2f2f24d', '#fff2cc4d', '#bfdaf24d', '#dfdac24d', '#fadddc4d', '#e2efd94d', '#e9e1ed4d', '#d4eef54d', '#ffebda4d',
            // '#00ffff4d', '#8181814d', '#ffc1004d', '0070c44d', '#9986754d', '#ff00004d', '#00b2474d', '#812ca44d', '#00b2f34d', '#ffc1004d'
        ];
        // public static readonly PDFHIGHLIGHTERS: string[] = [
        //     '#4dffffff', '#4d000000', '#4dffec66', '#4d004880', '#4d796356', '#4ddc4f4b', '#4d8dbc50', '#4d8b64a6', '#4d00aec8', '#4dff973d',
        //     '#4d00ff00', '#4df2f2f2', '#4dfff2cc', '#4dbfdaf2', '#4ddfdac2', '#4dfadddc', '#4de2efd9', '#4de9e1ed', '#4dd4eef5', '#4dffebda',
        //     '#4d00ffff', '#4d818181', '#4dffc100', '4d0070c4', '#4d998675', '#4dff0000', '#4d00b247', '#4d812ca4', '#4d00b2f3', '#4dffc100'
        // ];

        public static readonly HIGHLIGHTERS: string[] = [
            '#feed00', '#00fff6', '#00ff00', '#ff4ce2', '#fffe8c', '#f5d5ef', '#93ffff', '#ffffff', '#000000'
        ];

        public static readonly BACKGROUND: string[] = [
            // '#ffffff', '#f2f2f2', '#dfdac2', '#bfdaf2', '#e4cebd', '#fadddc', '#e2efd9', '#e9e1ed', '#d4eef5', '#ffebda'
            '#ffffff', '#f2f2f2', '#fff2cc', '#bfdaf2', '#dfdac2', '#fadddc', '#e2efd9', '#e9e1ed', '#d4eef5', '#ffebda'
        ];

    };

    static MODALITIES: Array<CustomSelectElement> = [
        new CustomSelectElement('VIEW_MODE', 'VIEW_MODE', 'theater'),
        new CustomSelectElement('EDIT_MODE', 'EDIT_MODE', 'square-edit-outline')
    ];


    static BORDERSWIDTH: Array<CustomSelectElement> = [
        new CustomSelectElement(1, '1px', 'border-1'),
        new CustomSelectElement(2, '2px', 'border-2'),
        new CustomSelectElement(3, '3px', 'border-3'),
        new CustomSelectElement(4, '4px', 'border-4')
    ];

    static TEXTPOSITIONS = [
        new CustomSelectElement('over', 'FORMAT_NODE_TOOLBAR-OVER', 'over'),
        new CustomSelectElement('inside', 'FORMAT_NODE_TOOLBAR-INSIDE', 'inside'),
        new CustomSelectElement('under', 'FORMAT_NODE_TOOLBAR-UNDER', 'under'),
        new CustomSelectElement('none', 'FORMAT_NODE_TOOLBAR-NONE', 'none')
    ];

    static TABLETEXTPOSITIONS = [
        new CustomSelectElement('inside', 'FORMAT_NODE_TOOLBAR-INSIDE', 'inside'),
        new CustomSelectElement('none', 'FORMAT_NODE_TOOLBAR-NONE', 'none')
    ];

    static NODESHAPES = [
        new CustomSelectElement('rectangle', 'FORMAT_NODE_TOOLBAR-RECTANGLE', 'rectangle'),
        new CustomSelectElement('ellipse', 'FORMAT_NODE_TOOLBAR-ELLIPSE', 'ellipse'),
        new CustomSelectElement('roundrect', 'FORMAT_NODE_TOOLBAR-ROUNDRECT', 'roundrect'),
        new CustomSelectElement('text', 'FORMAT_NODE_TOOLBAR-TEXT', 'text')
    ];

    static ALIGNMENTS = [
        new CustomSelectElement('left', 'ALIGNMENT_TOOLBAR-LEFT', 'format-horizontal-align-left'),
        new CustomSelectElement('centerheight', 'ALIGNMENT_TOOLBAR-CENTER', 'format-vertical-align-center'),
        new CustomSelectElement('right', 'ALIGNMENT_TOOLBAR-RIGHT', 'format-horizontal-align-right'),
        new CustomSelectElement('top', 'ALIGNMENT_TOOLBAR-TOP', 'format-vertical-align-top'),
        new CustomSelectElement('centerwidth', 'ALIGNMENT_TOOLBAR-CENTER', 'format-horizontal-align-center'),
        new CustomSelectElement('bottom', 'ALIGNMENT_TOOLBAR-BOTTOM', 'format-vertical-align-bottom')
    ];

    static SIZES = [
        new CustomSelectElement('both', 'SIZE_TOOLBAR-BOTH', 'arrow-all'),
        new CustomSelectElement('width', 'SIZE_TOOLBAR-WIDTH', 'arrow-expand-horizontal'),
        new CustomSelectElement('height', 'SIZE_TOOLBAR-HEIGHT', 'arrow-expand-vertical')
    ];

    public static frameNodeImage: boolean = false;
    public static nodeFontStyle: { [index: string]: any } = new SmxFontStyle('PREF_EFFECT_NONE', 'Arial', '14', '#000000');
    public static edgeFontStyle: { [index: string]: any } = new SmxFontStyle('PREF_EFFECT_NONE', 'Arial', '12', '#000000');
    public static deepFontStyle: { [index: string]: any } = new SmxFontStyle('PREF_EFFECT_NONE', 'Arial', '11', '#000000');
    public static noteFontStyle: { [index: string]: any } = new SmxFontStyle('PREF_EFFECT_NONE', 'Arial', '11', '#000000');

    public static nodeAspect: { [index: string]: any } = new SmxNodeAspect();
    public static edgeAspect: { [index: string]: any } = new SmxEdgeAspect();

    public static languages: CustomSelectElement[] = new Array<CustomSelectElement>();

    static getNotificationIcon(iconType: string) {
        let result = this.NOTIFICATIONICONS[0].icon;
        const notifications = this.NOTIFICATIONICONS;
        let found = false;
        let i = 0;
        while (!found && i < notifications.length) {
            const element = notifications[i];
            if (element.value === iconType) {
                result = element.icon;
                found = true;
            } else {
                i++;
            }
        }
        return result;
    }

    static getNotificationTypeIcon(icon: string) {
        let result = this.NOTIFICATIONICONS[0].value;
        const notifications = this.NOTIFICATIONICONS;
        let found = false;
        let i = 0;
        while (!found && i < notifications.length) {
            const element = notifications[i];
            if (element.icon === icon) {
                result = element.value;
                found = true;
            } else {
                i++;
            }
        }
        return result;
    }

    // Static methods
    static findLanguage(code: string, languages: Array<CustomSelectElement>): CustomSelectElement {
        let result = languages[0];
        if (code) {
            for (const langKey in Object.keys(languages)) {
                const language: CustomSelectElement = languages[langKey];
                if (language.value === code) {
                    result = language;
                }
            }
        }
        return result;
    }

    static insertSpan(html: string, span: string) {
        let i = html.indexOf('<p');
        let charIn = 0;
        let htmlNew = '';
        while (i > -1) {
            // htmlNew = htmlNew + html.substr(charIn, i + 3 - charIn);
            let len = html.indexOf('>', i);

            htmlNew = htmlNew + html.substring(charIn, len + 1);
            //i = i + 3;
            i = len + 1;
            len = html.indexOf('</p>', i);
            if (len > i) {
                htmlNew = htmlNew + span + html.substring(i, len) + '</span>';
            }
            charIn = len;
            i = html.indexOf('<p', len);
        }
        if (htmlNew === '') {
            htmlNew = html;
        } else {
            htmlNew = htmlNew + html.substring(charIn);
        }
        return htmlNew;
    }

    static styleAllLIs(html: string) {
        let htmlNew = html;
        let liP = html.indexOf('<li');
        while (liP >= 0) {
            const liPEnd = htmlNew.indexOf('>', liP);
            // |liP                                                                |liPEnd
            // <li style="margin-left: 0px; margin-right: 0px; text-align: center;">a</li>
            const oldStyle = htmlNew.substring(liP + 11, liPEnd - 1);
            let addStyle = '';
            if (oldStyle.indexOf('margin-left') === -1) {
                addStyle += '; margin-left:0px;';
            }
            if (oldStyle.indexOf('margin-right') === -1) {
                addStyle += '; margin-right:0px;';
            }
            if (oldStyle.indexOf('text-align') === -1) {
                addStyle += '; text-align:center;';
            }
            if (oldStyle.indexOf('font-size') === -1) {
                addStyle += '; font-size:' + UiConstants.nodeFontStyle['size'].trim() + 'pt;'
            }
            if (oldStyle.indexOf('font-family') === -1) {
                addStyle += '; font-family:' + UiConstants.nodeFontStyle['font'].trim() + ';';
            }
            if (oldStyle.indexOf('color') === -1) {
                addStyle += '; color:' + UiConstants.nodeFontStyle['color'].trim() + ';';
            }
            addStyle = addStyle.replace(/;;/g, ';');
            htmlNew = htmlNew.substring(0, liPEnd - 1) + addStyle + htmlNew.substring(liPEnd - 1);
            liP = htmlNew.indexOf('<li', liPEnd + addStyle.length + 1);
        }
        return htmlNew;
    }

    static insertStyle(html: string, style: string) {
        let tagToSearch = '<p>';
        let closeTag = false;
        let noTagFound = false;
        let i = html.indexOf(tagToSearch);
        if (i === -1) {
            tagToSearch = '<p';
            i = html.indexOf(tagToSearch);
        } else {
            closeTag = true;
        }
        let charIn = 0;
        let htmlNew = '';
        if (i === -1) {
            i = 0;
            noTagFound = true;
            closeTag = true;
        }
        while (i >= 0) {
            // htmlNew = htmlNew + html.substr(charIn, i + 2 - charIn);
            if (noTagFound) {
                htmlNew = '<p' + style;
            } else {
                htmlNew = htmlNew + html.substring(charIn, i + 2);
                i = i + tagToSearch.length;
                htmlNew = htmlNew + style;
            }
            if (closeTag) {
                htmlNew += '>';
            }
            if (noTagFound) {
                i = -1;
            } else {
                const len = html.indexOf('</p>', i);
                charIn = i;
                i = html.indexOf(tagToSearch, len);
            }
        }
        htmlNew = htmlNew + html.substring(charIn);
        if (noTagFound) {
            htmlNew += '</p>';
        }
        return htmlNew;
    }

    static getHtmlTitle(htmlTitle: string, isNode: boolean) {
        let html = htmlTitle;
        if (htmlTitle !== '') {
            html = this.styleAllLIs(htmlTitle);
            if (htmlTitle.indexOf('text-align') === -1) {
                html = this.insertStyle(html, ' style="text-align: center; "');
            }
            if (htmlTitle.indexOf('font-size') === -1) {
                const size = (isNode) ? UiConstants.nodeFontStyle['size'].trim() : UiConstants.edgeFontStyle['size'].trim();
                html = this.insertSpan(html, '<span style="font-size:' + size + 'pt;">');
            }
            if (htmlTitle.indexOf('font-family') === -1) {
                const fontFamily = (isNode) ? UiConstants.nodeFontStyle['font'].trim() : UiConstants.edgeFontStyle['font'].trim();
                html = this.insertSpan(html, '<span style="font-family:' + fontFamily + ';">');
            }
            if (htmlTitle.indexOf('color') === -1) {
                const color = (isNode) ? UiConstants.nodeFontStyle['color'].trim() : UiConstants.edgeFontStyle['color'].trim();
                html = this.insertSpan(html, '<span style="color:' + color + ';">');
            }
            if (htmlTitle.indexOf('font-weight') === -1) {
                const weight = (isNode) ? UiConstants.nodeFontStyle['effect'].trim() : UiConstants.edgeFontStyle['effect'].trim();
                if (weight !== 'PREF_EFFECT_NONE') {
                    html = this.insertSpan(html, '<span style="font-weight:bold">');
                }

            }
            if (!isNode) {
                html = this.insertSpan(html, '<span style="background-color:#ffffff;">');
            }
            html = html.replace('\n', '');
        }
        return html;
    }

    //--------------------------------------------------------------------------------------------------

    public static DEFAULT_STYLE_ID_START = '<div id="default-style"';
    public static DEFAULT_STYLE_ID_END = '</div>';

    static getDefaultHtmlStyleForTitleStart(isNode: boolean) {
        const font = (isNode) ? this.nodeFontStyle['font'] : this.edgeFontStyle['font'];
        const size = (isNode) ? this.nodeFontStyle['size'] : this.edgeFontStyle['size'];
        const color = (isNode) ? this.nodeFontStyle['color'] : this.edgeFontStyle['color'];
        const backgroundcolor = (isNode) ? 'transparent' : '#ffffff';
        let weight = 'normal';
        if (isNode) {
            if (this.nodeFontStyle['effect'] === 'PREF_EFFECT_BOLD') { weight = 'bold'; }
        } else {
            if (this.edgeFontStyle['effect'] === 'PREF_EFFECT_BOLD') { weight = 'bold'; }
        }
        const html = this.DEFAULT_STYLE_ID_START + ' style="text-align: center; text-indent: 0px; padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px; font-size: ' + size + 'pt; font-family: \'' + font + '\'; font-weight: ' + weight + '; color: ' + color + '; background-color: ' + backgroundcolor + '; text-decoration: none;">';
        return html;
    }

    static getDefaultHtmlStyleForTitleEnd() {
        return this.DEFAULT_STYLE_ID_END;
    }

    static getDefaultHtmlForTitle(text: string, isNode: boolean): string {
        if (text === '') { // &shy;
            text = '&nbsp;';
        }
        let html = this.getDefaultHtmlStyleForTitleStart(isNode);
        html += '<span>' + text.trim() + '</span>';
        html += this.getDefaultHtmlStyleForTitleEnd();
        return html;
    }

    static normalizeHtmlForTitle(html: string, isNode: boolean): string {
        if (!html.trim().startsWith(this.DEFAULT_STYLE_ID_START)) {
            html = this.getDefaultHtmlStyleForTitleStart(isNode) + html + this.getDefaultHtmlStyleForTitleEnd();
        }
        return html;
    }

    //--------------------------------------------------------------------------------------------------

    static getDefaultHtmlStyleForDeepStart() {
        const font = this.deepFontStyle['font'];
        const size = this.deepFontStyle['size'];
        const color = this.deepFontStyle['color'];
        const weight = (this.deepFontStyle['effect'] === 'PREF_EFFECT_BOLD') ? 'bold' : 'normal';
        const html = this.DEFAULT_STYLE_ID_START + ' style="text-align: left; text-indent: 0px; padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px; font-size: ' + size + 'pt; font-family: \'' + font + '\'; font-style: normal; font-weight: ' + weight + '; color: ' + color + '; background-color: transparent; text-decoration: none;">';
        return html;
    }

    static getDefaultHtmlStyleForDeepEnd() {
        return this.DEFAULT_STYLE_ID_END;
    }

    static getDefaultHtmlForDeep(text: string): string {
        if (text === '') {
            text = '&nbsp;';
        }
        let html = this.getDefaultHtmlStyleForDeepStart();
        html += '<span>' + text.trim() + '</span>';
        html += this.getDefaultHtmlStyleForDeepEnd();
        return html;
    }

    static normalizeHtmlForDeep(html: string): string {
        if (!html.trim().startsWith(this.DEFAULT_STYLE_ID_START)) {
            html = this.getDefaultHtmlStyleForDeepStart() + html + this.getDefaultHtmlStyleForDeepEnd();
        }
        return html;
    }

    //--------------------------------------------------------------------------------------------------

    static initLanguagesMobile(voices: SpeechSynthesisVoice[], translateService: TranslateService): CustomSelectElement[] {
        if (this.languages && this.languages.length > 0) {
            return this.languages;
        } else {
            let isPresentUkUa = false;
            const languages = new Array<CustomSelectElement>().concat(UiConstants.LANGUAGES);
            // Add all voices with standard info
            voices.forEach(voice => {
                //console.log("********** INIT LANGUAGE MOBILE: " + voice.name + " " + voice.lang);
                let l: CustomSelectElement;
                const found = languages.find(x => x.value === voice.lang);
                const localName = translateService.instant(voice.lang);
                if (found) {
                    if (localName !== found.value) {
                        // Translation found
                        found.viewValue = localName;
                    } else {
                        // Language not supported
                        found.icon = this.FLAGS[0].icon;
                    }
                } else {
                    const name = voice.name.substr(voice.name.indexOf('Google ') + 7);
                    const flag = this.FLAGS.find(x => x.value === voice.lang);
                    l = new CustomSelectElement(voice.lang, localName + ' - ' + name, flag == undefined ? this.FLAGS[0].icon : flag.icon, false, true);
                    languages.push(l);
                    if (l.value === 'uk-UA') {
                        isPresentUkUa = true;
                    }
                }
            });
            if (!isPresentUkUa) {
                const name = 'uk-UA';
                const localName = translateService.instant(name);
                const l = new CustomSelectElement(name, localName, 'assets/flags/uk-UA.png', false, false);
                languages.push(l);
            }
            this.languages = { ...languages };
            return languages;
        }
    }

    static initLanguagesDesktop(voices: SpeechSynthesisVoice[], translateService: TranslateService): CustomSelectElement[] {
        if (this.languages && this.languages.length > 0) {
            return this.languages;
        } else {
            let isPresentUkUa = false;
            const languages = new Array<CustomSelectElement>().concat(UiConstants.LANGUAGES);
            // Add all voices with standard info
            voices.forEach(voice => {
                let l: CustomSelectElement;
                if (!voice.localService) {
                    const found = languages.find(x => x.value === voice.lang);
                    const localName = translateService.instant(voice.lang);
                    if (found) {
                        if (localName !== found.value) {
                            // Translation found
                            found.viewValue = localName;
                        } else {
                            // Language not supported
                            found.icon = this.FLAGS[0].icon;
                        }
                    } else {
                        const name = voice.name.substr(voice.name.indexOf('Google ') + 7);
                        const flag = this.FLAGS.find(x => x.value === voice.lang);
                        l = new CustomSelectElement(voice.lang, localName + ' - ' + name, flag == undefined ? this.FLAGS[0].icon : flag.icon, false, true);
                        languages.push(l);
                        if (l.value === 'uk-UA') {
                            isPresentUkUa = true;
                        }
                    }
                }
            });
            if (!isPresentUkUa) {
                const name = 'uk-UA';
                const localName = translateService.instant(name);
                const l = new CustomSelectElement(name, localName, 'assets/flags/uk-UA.png', false, false);
                languages.push(l);
            }
            this.languages = { ...languages };
            return languages;
        }
    }

    static fixLatexFromOcr(ltx: string): string {
        let s = ltx;
        s = s.replace(/\(/g, '\\left (');
        s = s.replace(/\)/g, '\\right )');

        s = s.replace(/(?<!\\sqrt)\[/g, '\\left \\lbrack');
        s = s.replace(/(?<!\\sqrt)\]/g, '\\right \\rbrack');
        s = s.replace(/\\\{/g, '\\left \\lbrace');
        s = s.replace(/\\\}/g, '\\right \\rbrace');
        // Fix double left and right
        s = s.replace(/\\left\\left/g, '\\left');
        s = s.replace(/\\right\\right/g, '\\right');
        return s;
    }

    static getScansInfo(order: any, translateService: TranslateService) {
        let s = '';
        if (order && order.scansLeft > 0) {
            if (order.scansLeft === 1) {
                s = (order.licenseType === 's' ?
                    translateService.instant('OCRSCAN_LEFT_PERSONAL_1') :
                    translateService.instant('OCRSCAN_LEFT_GROUP_1')).replace('%n', order.scansLeft
                    ) + ' ' +
                    (order.ocrMathScanPolicy === 'FREE' ?
                        translateService.instant('OCRSCAN_PERIOD_ALL') :
                        translateService.instant('OCRSCAN_PERIOD_MONTH')
                    );
            } else if (order.scansLeft > 1) {
                s = (order.licenseType === 's' ?
                    translateService.instant('OCRSCAN_LEFT_PERSONAL_N') :
                    translateService.instant('OCRSCAN_LEFT_GROUP_N')).replace('%n', order.scansLeft
                    ) + ' ' +
                    (order.ocrMathScanPolicy === 'FREE' ?
                        translateService.instant('OCRSCAN_PERIOD_ALL') :
                        translateService.instant('OCRSCAN_PERIOD_MONTH')
                    );
            }
        } else {
            s = translateService.instant('OCRSCAN_OVER');
        }
        return s;
    }

}
