<h2 mat-dialog-title class="title-text">{{title}}</h2>
<div mat-dialog-content class="mat-typography form-content-container" (onKeyPress)="onKeyPress($event)">
    <div *ngIf="preview" class="recent-preview-container">
        <img class="recent-preview" src="{{ preview }}" title="{{tooltip}}" />
    </div>
    <div class="question-text" translate>{{question}}</div>
    <mat-form-field appearance="outline" class="form-field-container">
        <input matInput #dlgname class="form-input" type="text" spellcheck="false" autocomplete="false" contenteditable="true" [value]="name" (input)="onChange($event)" cdkFocusInitial />
    </mat-form-field>
    <mat-checkbox *ngIf="checkMsg" color="primary" value="{{isChecked}}" [checked]="isChecked" (change)="isChecked = !isChecked">{{checkMsg}}</mat-checkbox>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button type="button" (click)="cancelOp()" translate>
        <div translate>CANCEL</div>
    </button>
    <button mat-flat-button color="primary" type="button" (click)="closeDialog()" translate>
        <div translate>OK</div>
    </button>
</div>
