import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { GoogleImagesService } from '../../shared/commands/google.image.service';
import { ExtraItem, ExtraService } from '../commands/extra.service';
import { MapStateService } from '../../shared/map-state.service';
import { SmService } from 'supermappe-core';
import { UiConstants } from '../../shared/ui-constants';
import { CustomSelectElement } from '../toolbar/custom-toolbar/custom-select-element-dto';
import { Subscription } from 'rxjs';
import { QuickEditService } from '../../shared/commands/quick-edit.service';
import { TtsService } from '../../shared/commands/tts.service';
import { TranslateService } from '@ngx-translate/core';
import { WebSearchService } from '../../shared/commands/web-search.service';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { SpeechRecognitionService } from '../../shared/speech/speechrecognition.service';
import { SpeechRecognitionResult } from '../../shared/speech/speech-recognition-result';
import { SmeService } from '../../core/sme/sme.service';
import { extract } from '../../core/i18n.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { NgxCaptureService } from 'ngx-capture';
import { Subject, take, tap } from 'rxjs';
import { ImageMapsService } from '../commands/image-maps.service';
import { MapClipboardService } from '../commands/map-clipboard.service';
import { UserPreferenceService } from '../../shared/user-preference.service';
import { MathocrService as MathOcrService } from '../commands/mathocr.service';
import { MathService } from '../../shared/commands/math.service';
import { ConfirmationService } from 'src/app/shared/dialog/confirmation.service';
import { MathocrConfirmationService } from 'src/app/shared/dialog/mathocr-confirmation.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotepadService } from './notepad.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageBoxService } from 'src/app/shared/dialog/messagebox.service';
import { DeviceService } from 'src/app/core/device.service';

declare let CKEDITOR: any;

//#region CAPTURE
type Point = {
    x: number;
    y: number;
};

interface CropDimensions {
    x: number;
    y: number;
    width: number;
    height: number;
}

//#endregion

@Component({
    selector: 'app-notepad',
    templateUrl: './notepad.component.html',
    styleUrls: ['./notepad.component.scss'],
})
export class NotepadComponent implements OnInit, OnDestroy {
    @ViewChild('speechInput') speechInput: ElementRef | undefined;
    @ViewChild('htmlView') public htmlView: ElementRef | undefined;
    @ViewChild('titleAppuntiEdit') titleAppuntiEdit: ElementRef | undefined;
    @ViewChild('titleAppuntiView') titleAppuntiView: ElementRef | undefined;
    @ViewChild('extraContainer') extraContainer: ElementRef | undefined;
    @ViewChild('overpanel') overPanel: ElementRef | undefined;
    @ViewChild('speedInput') speedInput: ElementRef | undefined;

    public style: object = {};
    public styleover: object = {};

    public mapinteraction = false;
    private newTop = 0;
    public htmlSafe: SafeHtml | undefined;
    private html = '';
    private newWidth = 0;
    private plainText = '';
    private _currLanguage = UiConstants.LANGUAGES[0].value;
    public languages: CustomSelectElement[] = new Array<CustomSelectElement>();
    selectedLangIcon = UiConstants.LANGUAGES[0].icon;
    rateValue = 1;
    public speechEditEnabled: boolean;
    public isTalking: boolean = false;
    private onTTSTalkingStart: Subscription | undefined;
    private onTTSTalkingEnd: Subscription | undefined;
    onGetVoicesSubscription: Subscription | undefined;
    onToggleWebSearchSubscription: Subscription | undefined;
    findMapSharedInfoSubscription: Subscription | undefined;
    onSpeechEditEnableSubscription: Subscription | undefined;
    speechRecordSubscription: Subscription | undefined;
    onTextFromPdfReceivedSubscription: Subscription | undefined;
    onLoadedSubscription: Subscription | undefined;
    mapMoving: Subscription | undefined;
    public editVisible = false;
    resizing = false;
    public canEdit = false;
    public isTextSelected = false;

    private editor: any;

    private focusTimeout: any = undefined;
    private editTimeout: any = undefined;

    public canCaptureMath: boolean = true;
    public readOnlyMessage: string = '';

    //#region CAPTURE
    @ViewChild('screen', { static: true }) screen: any;
    @ViewChild('rect', { static: true }) rectangle?: ElementRef;
    @ViewChild('over', { static: true }) overlay?: ElementRef;

    @Input() offsetLeft: number = 75;
    @Input() offsetTop: number = 235;
    @Output() resultImage = new EventEmitter<string>();
    rect?: HTMLElement;
    captureZone?: HTMLElement;

    isDrawing = false;

    mouseStart: Point = { x: 0, y: 0 };

    cropDimensions: CropDimensions = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    };
    destroy$ = new Subject<void>();

    public capturingScreen: boolean = false;
    private captureTogglePressed: boolean = false;
    public percCropX: number = 0;
    public percCropY: number = 0;
    public percCropX2: number = 100;
    public percCropY2: number = 100;
    //#endregion

    private textNodes: Array<any> = new Array<any>();

    public checkText: boolean = false;

    public isLab: boolean = false;

    // Math OCR
    public mathOCR_icon: string = "assets/icons/icon_mathocr.png";
    public IsMathOCR: boolean = false;

    private latex2ImageSubscription: Subscription | undefined;

    constructor(
        public mathService: MathService,
        public googleImagesService: GoogleImagesService,
        private sanitizer: DomSanitizer,
        private extraService: ExtraService,
        private smService: SmService,
        private quickEditService: QuickEditService,
        private ttsService: TtsService,
        private translateService: TranslateService,
        private webSearchService: WebSearchService,
        private mapStateService: MapStateService,
        private speechRecognitionService: SpeechRecognitionService,
        private smeService: SmeService,
        private authenticationService: AuthenticationService,
        private userPreferencesService: UserPreferenceService,
        private readonly captureService: NgxCaptureService,
        private imageMapsService: ImageMapsService,
        private mapClipboardService: MapClipboardService,
        private mathOcrService: MathOcrService,
        private confirmationService: ConfirmationService,
        private mathocrConfirmationService: MathocrConfirmationService,
        private clipboard: Clipboard,
        private notepadService: NotepadService,
        private snackBar: MatSnackBar,
        private messageBoxService: MessageBoxService,
        public deviceService: DeviceService
    ) {
        this.isLab = this.authenticationService.isLab();
        const cLang = UiConstants.findLanguage(this.translateService.currentLang, UiConstants.LANGUAGES);
        this._currLanguage = cLang.value;
        this.selectedLangIcon = cLang.icon;

        CKEDITOR.config.language = this.translateService.currentLang.substring(0, 2);
        CKEDITOR.config.defaultLanguage = cLang.value.substr(0, 2);
        CKEDITOR.config.fullPage = false;
        CKEDITOR.config.resize_enabled = false;

        CKEDITOR.config.font_defaultLabel = UiConstants.noteFontStyle['font'];
        CKEDITOR.config.fontSize_defaultLabel = UiConstants.noteFontStyle['size'];
        // CKEDITOR.config.removePlugins = 'magicline';

        CKEDITOR.addCss('.cke_editable{cursor:text; font-size: 14px; font-family: Roboto, sans-serif;}');
        CKEDITOR.config.font_names = '';
        CKEDITOR.config.fontSize_sizes = '8/8pt;9/9pt;10/10pt;11/11pt;12/12pt;14/14pt;16/16pt;18/18pt;24/24pt;30/30pt;36/36pt;48/48pt';
        const myFonts = ['Arial', 'Verdana', 'sans-serif', 'Architects Daughter', 'IBM Plex Mono', 'Montserrat', 'Roboto', 'Special Elite'];
        for (let i = 0; i < myFonts.length; i++) {
            CKEDITOR.config.font_names = CKEDITOR.config.font_names + ';' + myFonts[i];
            myFonts[i] = 'http://fonts.googleapis.com/css?family=' + myFonts[i].replace(' ', '+');
        }
        CKEDITOR.config.contentsCss = ['/assets/ckeditor/contents.css'].concat(myFonts);
        CKEDITOR.config.fontStyle_size = '24px';
        CKEDITOR.config.toolbarLocation = 'top';

        CKEDITOR.config.allowedContent = true;

        this.speechEditEnabled = false;
        this.canEdit = false;
        this.readOnlyMessage = this.translateService.instant('NOTEPAD_READONLY');
    }

    ngOnInit() {
        this.quickEditService.onCanEdit.emit(false);
        //  this.mapStateService.setEditingState(true);

        if (this.findMapSharedInfoSubscription) { this.findMapSharedInfoSubscription.unsubscribe(); }
        this.findMapSharedInfoSubscription = this.smeService.findMapSharedInfo(this.mapStateService.id).subscribe({
            next: (data: any) => {
                // Only owner can edit notes

                this.canEdit = (this.authenticationService.credentials?.firebaseUserId === data.data.userId);
                this.quickEditService.onCanEdit.emit(this.canEdit);
                // CKEDITOR.config.readOnly = !this.canEdit;
            },
            error: (error: any) => {
                alert(`ERROR: ${JSON.stringify(error)}`);
            }
        });


        this.onGetVoicesSubscription = this.ttsService.onGetVoices.subscribe((voices: SpeechSynthesisVoice[]) => {
            if (this.deviceService.isMobileOrTabletDevice()) {
                this.languages = UiConstants.initLanguagesMobile(voices, this.translateService);
            } else {
                this.languages = UiConstants.initLanguagesDesktop(voices, this.translateService);
            }
        });

        if (this.ttsService.voices) {
            if (this.deviceService.isMobileOrTabletDevice()) {
                this.languages = UiConstants.initLanguagesMobile(this.ttsService.voices, this.translateService);
            } else {
                this.languages = UiConstants.initLanguagesDesktop(this.ttsService.voices, this.translateService);
            }
        }

        this.rateValue = this.getSavedReadSpeedByCurrentLanguage();
        this.ttsService.voiceRate = this.rateValue;

        this.onTTSTalkingStart = this.ttsService.TalkingStart.subscribe(() => {
            this.isTalking = true;
        });

        this.onTTSTalkingEnd = this.ttsService.TalkingEnd.subscribe(() => {
            this.isTalking = false;
        });

        this.onSpeechEditEnableSubscription = this.quickEditService.onSpeechEditEnable.subscribe((enabled: boolean) => {
            if (this.quickEditService.origin === this.quickEditService.ORIGIN.NOTES) {
                this.speechEditEnabled = enabled;
                if (enabled) {
                    this.startSpeechEdit();
                } else {
                    this.stopSpeechEdit();
                }
            }
        });

        this.mapStateService.onImageLoaded.subscribe(() => {
            this.canCaptureMath = true;
        });

        this.onLoadedSubscription = this.mapStateService.onLoaded.subscribe((value: any) => {
            if (value) {
                // inserisci contenuto appunti
                const notesData = this.smService.getNotes();
                if (!notesData.language) {
                    // notesData.language = 'it-IT';
                    notesData.language = this.translateService.currentLang;
                }
                // inserisci titolo appunti
                const titolo = notesData.title;
                if (notesData.language && notesData.language !== '' && notesData.language !== 'undefined') {
                    this.setLanguage(notesData.language);
                }
                const contentHtml = notesData.textHtml;
                this.setHtmlContent(contentHtml);

                const title = (titolo === '' || !titolo || titolo === 'undefined') ? this.translateService.instant('NOTES') : titolo;
                if (this.titleAppuntiEdit) {
                    this.titleAppuntiEdit.nativeElement.value = title;
                }
                if (this.titleAppuntiView) {
                    this.titleAppuntiView.nativeElement.innerText = title;
                }
            }
        });


        this.extraService.isExtraPanelOpen = true;

        // Cattura schermo
        setTimeout(() => {
            this.rect = this.rectangle?.nativeElement;
            this.captureZone = this.overlay?.nativeElement;

            if (!this.captureZone) {
                console.warn('"captureZone" is not set');
                return;
            }

            this.captureZone.onmousedown = (e) => this.startCapture(e);
            this.captureZone.onmousemove = (e) => this.drawRect(e);
            this.captureZone.onmouseup = () => this.endCapture();
            document.body.onmouseup = () => this.stopCapture();

        }, 2000);

        // inserisci testo proveniente da Pdf in fondo al testo
        this.onTextFromPdfReceivedSubscription = this.notepadService.onTextFromPdfReceived.subscribe((text: string) => {
            console.log("Incolla in notepad:" + text);
            //CKEDITOR.instances['appuntieditor-container'].insertText(text + ' ');
            const editor = CKEDITOR.instances['appuntieditor-container'];
            // Ottieni il contenuto del tuo editor
            const content = editor.getData();
            // Imposta il cursore alla fine del testo
            editor.setData(content + '<p>&nbsp;</p>' + text); // Aggiungi un paragrafo vuoto alla fine

            this.snackBar.open(
                this.translateService.instant(extract('COPY_ON_NOTEPAD_MSG')),
                '', {
                horizontalPosition: 'start',
                verticalPosition: 'bottom',
                duration: 3000,
            });
        });

    }

    private startSpeechEdit() {
        console.log('startSpeechEdit');
        if (this.speechInput) this.speechInput.nativeElement.innerHTML = '';
        if (this.speechRecordSubscription) { this.speechRecordSubscription.unsubscribe(); }
        this.speechRecordSubscription = this.speechRecognitionService.record(this._currLanguage)
            .subscribe({
                // listener
                next: (srr: SpeechRecognitionResult) => {
                    const text: string = srr.transcript;
                    if (srr.isFinal) {
                        // End speech recognition
                        if (text === 'ok' || text === 'stop') {
                            console.log('speech stopped!');
                            this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.NOTES);
                        } else {
                            console.log('speech complete!');
                            CKEDITOR.instances['appuntieditor-container'].insertText(text + ' ');
                            this.speechRecognitionService.DestroySpeechObject();
                            this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.NOTES);
                        }
                        console.log(text);
                    } else {
                        // Partial speech recognition
                        if (this.speechInput) this.speechInput.nativeElement.innerHTML = UiConstants.getDefaultHtmlForDeep(text);
                    }
                },
                // error
                error: (err) => {
                    if (err.error === 'no-speech') {
                        console.log('SpeechEdit error: no-speech');
                        this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.NOTES);
                    }
                },
                // completion
                complete: () => {
                    console.log('SpeechEdit: complete');
                }
            });
    }

    private stopSpeechEdit() {
        console.log('stopSpeechEdit');
        this.speechRecognitionService.DestroySpeechObject();
    }

    speechEdit() {
        this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.NOTES);
    }

    enterEdit() {
        this.mapStateService.setFocusOnMap(false);
        this.mapStateService.canEnableQuickEdit = false;
    }

    exitEdit() {
        this.mapStateService.setFocusOnMap(true);
        this.mapStateService.canEnableQuickEdit = true;
    }

    changeTitle(event: any) {
        const newTitle = (event && event.target?.value) ? event.target.value : '';
        this.smService.setNotesTitle(newTitle);
        this.mapStateService.forceAutoSave();
        if (this.titleAppuntiView) {
            this.titleAppuntiView.nativeElement.value = newTitle;
        }

        console.log('Change title: ' + newTitle);
    }

    enableEditing(forceFocus = false) {

        CKEDITOR.config.language = this.translateService.currentLang.substr(0, 2);
        CKEDITOR.config.defaultLanguage = this.translateService.currentLang.substr(0, 2);
        CKEDITOR.config.floatSpacePreferRight = false;

        CKEDITOR.config.removePlugins = 'showborders';
        CKEDITOR.config.removePlugins = 'magicline';

        CKEDITOR.config.format_tags = 'p;h1;h2;h3;pre';

        CKEDITOR.config.removeButtons = 'Image';
        CKEDITOR.config.allowedContent = true;

        const extraPlugins = 'language,selectall,colorbutton,justify,copyformatting,font,contextmenu,menu,maximize,quicktable,openlink';
        //TOLTO eqneditor PER PROBLEMI: 1) COL PDF 2) CON SCARICA MAPPA COME IMMAGINE

        if (!CKEDITOR.instances['appuntieditor-container']) {
            this.editor = CKEDITOR.inline('appuntieditor-container', {
                //this.editor = CKEDITOR.replace('appuntieditor-container', {
                //TOLTO eqneditor PER PROBLEMI COL PDF 
                readOnly: !this.canEdit,
                extraPlugins: extraPlugins,
                mathJaxLib: '//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML',
                language: CKEDITOR.config.language,
                toolbarLocation: (this.canEdit ? 'top' : 'none'),

                toolbar: [
                    { name: 'clipboard', items: ['Undo', 'Redo'] },
                    { name: 'styles', items: ['Format'] },
                    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
                    { name: 'colors', items: ['TextColor', 'BGColor'] },
                    { name: 'paragraph', items: ['-', 'Blockquote'] },

                    '/',
                    { name: 'basicstyles', items: ['Subscript', 'Superscript'] },
                    { name: 'paragraph', items: ['-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
                    // { name: 'insert', items: ['EqnEditor', 'Table', '-', 'SpecialChar', 'Image'] }
                    { name: 'insert', items: ['Table', '-', 'SpecialChar', 'Image'] }
                ],

                qtRows: 10, // Count of rows
                qtColumns: 10, // Count of columns
                qtBorder: '1', // Border of inserted table
                qtWidth: '90%', // Width of inserted table
                qtStyle: { 'border-collapse': 'collapse' },
                qtClass: 'test', // Class of table
                qtCellPadding: '0', // Cell padding table
                qtCellSpacing: '0', // Cell spacing table
                qtPreviewBorder: '4px double black', // preview table border 
                qtPreviewSize: '4px', // Preview table cell size 
                qtPreviewBackground: '#c8def4', // preview table background (hover)

                extraAllowedContent: 'img(*){*}[*]'

            });

            // CKEDITOR.replace('appuntieditor-container', {
            //     qtRows: 20, // Count of rows
            //     qtColumns: 20, // Count of columns
            //     qtBorder: '1', // Border of inserted table
            //     qtWidth: '90%', // Width of inserted table
            //     qtStyle: { 'border-collapse': 'collapse' },
            //     qtClass: 'test', // Class of table
            //     qtCellPadding: '0', // Cell padding table
            //     qtCellSpacing: '0', // Cell spacing table
            //     qtPreviewBorder: '4px double black', // preview table border 
            //     qtPreviewSize: '4px', // Preview table cell size 
            //     qtPreviewBackground: '#c8def4' // preview table background (hover)
            // });

            // CKEDITOR.replace('appuntieditor-container', {
            //     extraAllowedContent: 'img(*){*}[*]'
            // });

            this.checkText = true;
            this.checkTextSelection();

            this.editor.on('instanceReady', (evt: any) => {

                // VOCE MENU CUSTOM
                this.editor.addCommand('commandRead', {
                    exec: () => {
                        //Leggi
                        this.TTSRead();
                    }
                });
                this.editor.addCommand('commandSelectAll', {
                    exec: () => {
                        //Seleziona tutto
                        this.editor.execCommand('selectAll')
                    }
                });

                this.editor.contextMenu.addListener(function () {
                    return {
                        commandRead: CKEDITOR.TRISTATE_OFF,
                        commandSelectAll: CKEDITOR.TRISTATE_OFF
                    };
                });

                // Add custom group
                this.editor.addMenuGroup('customGroup');

                // groups: 'customGroup','image','table'
                this.editor.addMenuItems({
                    commandRead: {
                        label: 'Leggi',
                        command: 'commandRead',
                        group: 'customGroup',
                        order: 1
                    },
                    commandSelectAll: {
                        label: 'Seleziona tutto',
                        command: 'commandSelectAll',
                        group: 'customGroup',
                        order: 1
                    }
                });
                // fine VOCE MENU CUSTOM

                if (CKEDITOR.instances['appuntieditor-container']) {
                    CKEDITOR.instances['appuntieditor-container'].language = this.translateService.currentLang.substring(0, 2);
                    CKEDITOR.instances['appuntieditor-container'].resetDirty();
                    CKEDITOR.instances['appuntieditor-container'].on('selectionChange', (ev: any) => {

                        let text = ev.data.selection.getSelectedText();
                        if (text === '' && this.plainText !== 'undefined') {
                            text = this.plainText;
                        }
                        this.webSearchService.textSearch = text;
                        this.mapStateService.setSpellCheckerLanguages(this._currLanguage)

                    });
                    CKEDITOR.instances['appuntieditor-container'].on('change', (ev: any) => {
                        // Salva contenuto editor appunti
                        if (!this.resizing) {
                            const editorValue = this.editor.getData();
                            this.smService.setNotesText(editorValue);
                            this.mapStateService.isDirty = true;
                            // console.log(ev.editor.document.$.activeElement.outerText);
                            this.webSearchService.textSearch = ev.editor.document.$.activeElement.outerText;
                        }
                    });

                    if (this.focusTimeout !== undefined) {
                        clearTimeout(this.focusTimeout);
                        this.focusTimeout = undefined;
                    }

                    this.focusTimeout = setTimeout(() => {
                        if (this.plainText?.trim() !== 'undefined') {
                            this.webSearchService.textSearch = this.plainText;
                        }
                        CKEDITOR.instances['appuntieditor-container'].focus();
                        this.setLanguage(this._currLanguage);
                    }, 700);

                    this.editor.on('focus', (ev: any) => {
                        if (!this.resizing) {
                            this.smService.setEnableKeyPresses(false);
                            this.quickEditService.onCanEdit.emit(false);
                            // this.mapStateService.setEditingState(true);
                        }
                    });
                    this.editor.on('enter', (ev: any) => {
                        if (this.resizing) {
                            //this.onResizeEnd(ev);
                        }
                    });
                    this.editor.on('blur', (ev: any) => {
                        if (!this.resizing) {
                            if (this.mapStateService.index === -1) {
                                this.mapStateService.index = 0;
                            }
                            // this.mapStateService.isDirty = true;
                            this.smService.setEnableKeyPresses(true);
                            this.quickEditService.onCanEdit.emit(true);
                            // this.mapStateService.setEditingState(false);
                        }
                    });

                    // this.editor.setReadOnly(!this.canEdit);
                    // this.editor.container.focus();

                }
                // Restore standard toolbar
                CKEDITOR.config.removeButtons = '';

            });

            this.editor.on('key', function (evt: any) {
                if (evt.data.keyCode == 9 || evt.data.keyCode == (CKEDITOR.SHIFT + 9)) {
                    //trying to prevent tab and shift tab jumping focus out of editor
                    evt.cancel();
                    evt.stop();
                }
            }, null, null, 31); //priority is after indent list plugin's event handler
        }
    }

    disableEditing() {
        this.checkText = false;
        const CKeditor = CKEDITOR.instances['appuntieditor-container'];
        if (CKeditor) {
            CKeditor.destroy();
        }
    }

    closeExtra() {
        this.extraService.toggleExtraPanel(ExtraItem.CLOSE);
    }

    public setPlainContent(text: string, currLanguage: string) {
        this.plainText = text;
        this._currLanguage = currLanguage;
    }

    private applyDefaultStyle(): string {
        let res = '<p>';
        res += '<span style="font-family:' + UiConstants.noteFontStyle['font'] + ';">';
        res += '<span style="color:' + UiConstants.noteFontStyle['color'] + ';">';
        res += '<span style="font-size:' + UiConstants.noteFontStyle['size'] + 'pt;">';
        if (UiConstants.noteFontStyle['effect'] === 'PREF_EFFECT_BOLD') {
            res += '<strong>';
        }
        res += '&nbsp;';
        if (UiConstants.noteFontStyle['effect'] === 'PREF_EFFECT_BOLD') {
            res += '</strong>';
        }
        res += '</span></span></span></p>';
        return res;
    }

    private setLanguage(currLanguage: string) {
        this._currLanguage = currLanguage;
        if (this.languages) {
            const language = UiConstants.findLanguage(this._currLanguage, this.languages);
            if (language && language.icon) {
                this.selectedLangIcon = language.icon;
                this.rateValue = this.getSavedReadSpeedByCurrentLanguage();
                this.ttsService.voiceRate = this.rateValue;
            }
        }
        CKEDITOR.config.language = this._currLanguage.substr(0, 2);
        this.editor = CKEDITOR.instances['appuntieditor-container'];
        if (this.editor) {
            this.editor.language = this._currLanguage.substr(0, 2);
            const content = this.editor.getData();
            this.editor.setData(content);
        }
    }

    private getSavedReadSpeedByCurrentLanguage(): number {
        const usermail = this.authenticationService.getUserEmail();
        if (usermail != '') {
            const filter: Array<string> = [usermail, this._currLanguage];
            const savedReadSpeed = this.userPreferencesService.getReadSpeedCookie(filter);
            if (savedReadSpeed > 0) {
                return savedReadSpeed;
            } else {
                return 1;
            }
        }

        return 1;
    }

    changeLanguage(event: any) {
        this.setLanguage(event.currentTarget.id);
        this.smService.setNotesLanguage(this._currLanguage);
        this.mapStateService.setSpellCheckerLanguages(this._currLanguage);
        this.mapStateService.forceAutoSave();
    }

    changeSpeechRate(event: any) {
        this.rateValue = event.target.value;
        this.ttsService.voiceRate = event.target.value;
        const usermail = this.authenticationService.getUserEmail();
        if (usermail != '') {
            this.userPreferencesService.saveReadSpeedCookie(usermail, this._currLanguage, this.rateValue);
        }
    }

    public setHtmlContent(html: string) {
        const edit = (html === '');
        this.html = html;

        if (this.html === '') {
            this.html = this.applyDefaultStyle();
        }
        this.htmlSafe = this.sanitizer.bypassSecurityTrustHtml(this.html);

        if (this.editTimeout !== undefined) {
            clearTimeout(this.editTimeout);
            this.editTimeout = undefined;
        }

        this.editTimeout = setTimeout(() => {
            this.editAppunti(true);
        }, 10);
    }

    public editAppunti(forceFocus = false) {
        this.editVisible = !this.editVisible;
        const appuntieditor = document.getElementById('appuntieditor-container');
        if (!this.editVisible && appuntieditor) {
            this.disableEditing();
            this.html = appuntieditor.innerHTML;
            this.htmlSafe = this.sanitizer.bypassSecurityTrustHtml(appuntieditor.innerHTML);
            this.plainText = appuntieditor.innerText;
        } else {
            this.enableEditing(forceFocus);
        }
    }

    public TTSRead() {
        const textToRead = this.getText();
        this.ttsService.speak(textToRead, this._currLanguage, true);
    }

    public TTSStop() {
        this.ttsService.stop();
    }

    public saveImage(img: any) {
        console.log(img);
    }

    public InsertText() {
        const selection = CKEDITOR.instances['appuntieditor-container'].getSelection();
        const range = this.editor.getSelection().getRanges()[0],
            el = this.editor.document.createElement('div');
        el.append(range.cloneContents());
        // Verifica se il testo è selezionato
        if (selection.getType() === CKEDITOR.SELECTION_TEXT) {
            // Applica il colore di sfondo giallo al testo selezionato
            const selectedText = selection.getSelectedText();
            const yellowBackgroundStyle = new CKEDITOR.style({ element: 'span', styles: { 'background-color': 'yellow' } });
            yellowBackgroundStyle.applyToRange(selection.getRanges()[0]);
        }
        const dataHtml = el.getHtml();
        this.mapClipboardService.pasteFromHTML(dataHtml);
    }

    public InsertDeep() {
        const node = this.smService.getSelectedNode();
        if (node) {
            const selection = CKEDITOR.instances['appuntieditor-container'].getSelection();
            const range = this.editor.getSelection().getRanges()[0],
                el = this.editor.document.createElement('div');
            el.append(range.cloneContents());
            // Verifica se il testo è selezionato
            if (selection.getType() === CKEDITOR.SELECTION_TEXT) {
                // Applica il colore di sfondo giallo al testo selezionato
                const selectedText = selection.getSelectedText();
                const yellowBackgroundStyle = new CKEDITOR.style({ element: 'span', styles: { 'background-color': 'yellow' } });
                yellowBackgroundStyle.applyToRange(selection.getRanges()[0]);
            }
            const dataHtml = el.getHtml();
            const deep = node.deepHtml + '<p>&nbsp;</p>' + dataHtml;

            const data = { titleHtml: node.titleHtml, deepHtml: deep };
            data.deepHtml = UiConstants.normalizeHtmlForDeep(data.deepHtml);
            this.smService.editSelectedElement(data);

            this.snackBar.open(
                this.translateService.instant(extract('COPY_ON_DEEP_MSG')),
                '', {
                horizontalPosition: 'start',
                verticalPosition: 'bottom',
                duration: 3000,
            });
        } else {
            this.snackBar.open(
                this.translateService.instant(extract('NO_NODE_SELECTED')),
                '', {
                horizontalPosition: 'start',
                verticalPosition: 'bottom',
                panelClass: ['red-snackbar'],
                duration: 3000,
            });
        }
    }

    public getText() {
        let textToRead = this.getSelectionText();
        if (!textToRead) {
            const CKEditor = CKEDITOR.instances['appuntieditor-container'];
            let appuntiEditor = '';
            if (CKEditor) {
                appuntiEditor = CKEditor.getData();
            } else {
                appuntiEditor = this.html;
            }
            const elem = document.createElement('div');
            elem.innerHTML = appuntiEditor;
            this.plainText = elem.innerText;
            this.plainText = this.plainText.replace(/\r?\n|\r/gm, ' '); // remove line breaks
            this.plainText = this.plainText.replace(/\s\s+/g, ' ')?.trim(); // remove double spaces

            textToRead = this.plainText;
        }
        if (textToRead === undefined) {
            textToRead = '';
        }
        return textToRead;
    }

    getSelectionText() {
        console.log('GETSELECTIONTEXT');
        const editor = CKEDITOR.instances['appuntieditor-container']
        let text: string | undefined = '';
        if (this.editVisible) {
            const selection = editor.getSelection();
            text = selection.getSelectedText();
            if (!text) {
                const range = selection.getRanges()[0];
                // const elm = document.getElementById('appuntieditor-container');
                // const elm = editor.editable();
                text = this.getTextFromPosition(editor);
            }
        } else {
            if (window?.getSelection()) {
                text = window.getSelection()?.toString();
            }
        }
        return text;
    }

    getTextFromPosition(editor: any) {
        if (editor) {
            const selection = editor.getSelection();
            const range = selection.getRanges()[0];
            const sTempStr = '%$#';
            editor.insertText(sTempStr);
            const sNewText = range.root.$.textContent;
            setTimeout(() => {
                editor.execCommand('undo');
            }, 10);
            return sNewText.split(sTempStr)[1];
        } else {
            return '';
        }
    }

    clickLink(event: any) {
        const url = event.srcElement.href;
        if (!url) {
            return;
        }

        window.open(url, '_blank');
        event.preventDefault();
    }

    speedGotFocus() {
        // this.mapStateService.setEditingState(true);
        this.smService.setEnableKeyPresses(false);
        this.quickEditService.onCanEdit.emit(false);
        this.mapStateService.setFocusOnMap(false);
    }

    speedLostFocus() {
        this.checkSpeedValue();
        // this.mapStateService.setEditingState(true);
        this.smService.setEnableKeyPresses(true);
        this.quickEditService.onCanEdit.emit(true);
        this.mapStateService.setFocusOnMap(true);
    }

    checkSpeedValue() {
        if (this.rateValue < 0.5) {
            this.rateValue = 0.5;
            if (this.speedInput)
                this.speedInput.nativeElement.value = this.rateValue;
        }
        if (this.rateValue > 1.5) {
            this.rateValue = 1.5;
            if (this.speedInput)
                this.speedInput.nativeElement.value = this.rateValue;
        }
    }
    ngOnDestroy(): void {
        if (this.quickEditService.isQuickEditEnabled()) {
            this.quickEditService.toggleQuickEdit();
        }
        if (this.quickEditService.isSpeechEditEnabled()) {
            this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.NOTES);
        }

        if (this.focusTimeout !== undefined) {
            clearTimeout(this.focusTimeout);
            this.focusTimeout = undefined;
        }

        if (this.editTimeout !== undefined) {
            clearTimeout(this.editTimeout);
            this.editTimeout = undefined;
        }

        this.smService.setEnableKeyPresses(true);
        if (this.mapMoving) { this.mapMoving.unsubscribe(); }
        this.ttsService.stop();
        if (this.onGetVoicesSubscription) { this.onGetVoicesSubscription.unsubscribe(); }
        if (this.onToggleWebSearchSubscription) { this.onToggleWebSearchSubscription.unsubscribe(); }
        if (this.findMapSharedInfoSubscription) { this.findMapSharedInfoSubscription.unsubscribe(); }
        this.quickEditService.onCanEdit.emit(true);
        // this.mapStateService.setEditingState(false);

        if (CKEDITOR.instances['appuntieditor-container']) {
            this.disableEditing();
        }
        this.extraService.isExtraPanelOpen = false;
        if (this.onTextFromPdfReceivedSubscription) { this.onTextFromPdfReceivedSubscription.unsubscribe(); }
    }

    //#region CAPTURE
    public Capture() {
        this.IsMathOCR = false;
        if (this.capturingScreen) {
            this.stopCapture();
        } else {
            console.log("START CAPTURE");
            this.capturingScreen = true;
            this.SetReadonly(this.capturingScreen);
            this.percCropX = 0;
            this.percCropY = 0;
            this.percCropX2 = 0;
            this.percCropY2 = 0;

            this.captureTogglePressed = true;
            setTimeout(() => this.captureTogglePressed = false, 20);
        }
    }

    private SetReadonly(readOnly: boolean) {
        this.editor.element.$.disabled = readOnly;
        this.editor.element.$.contentEditable = !readOnly;
        this.editor.element.$.designMode = readOnly ? "off" : "on";
        this.editor.setReadOnly(readOnly);
    }

    private startCapture(e: any) {
        if (!this.capturingScreen) return;
        console.log('START CAPTURE');
        const mouse = this.setMousePosition(e, true);
        this.isDrawing = true;
        this.cropDimensions = {
            x: mouse.x,
            y: mouse.y,
            width: 0,
            height: 0,
        };
        if (this.captureZone) {
            this.captureZone.style.cursor = 'crosshair';
        }
    }

    private drawRect(e: any) {
        if (this.isDrawing) {
            const mouse = this.setMousePosition(e, false);
            this.cropDimensions = {
                x: mouse.x - this.mouseStart.x < 0 ? mouse.x - this.offsetLeft : this.mouseStart.x - this.offsetLeft,
                y: mouse.y - this.mouseStart.y < 0 ? mouse.y - this.offsetTop : this.mouseStart.y - this.offsetTop,
                width: Math.abs(mouse.x - this.mouseStart.x),
                height: Math.abs(mouse.y - this.mouseStart.y),
            };
            this.setRectangle();
        }
    }

    private setMousePosition(e: any, isStart = false): Point {
        const ev = e || window.event; // Moz || IE
        const mouse: Point = { x: 0, y: 0 };

        if (ev.pageX) {
            // Moz
            mouse.x = ev.clientX;
            mouse.y = ev.clientY;
        } else if (ev.clientX) {
            // IE
            mouse.x = ev.clientX + document.body.scrollLeft;
            mouse.y = ev.clientY + document.body.scrollTop;
        }

        if (isStart) {
            this.mouseStart.x = mouse.x;
            this.mouseStart.y = mouse.y;
        }

        return mouse;
    }

    private stopCapture() {
        setTimeout(() => {
            if (!this.captureTogglePressed) {
                if (this.capturingScreen) {
                    console.log("STOP CAPTURE");
                    this.capturingScreen = false;
                    this.SetReadonly(this.capturingScreen);
                    if (this.captureZone) { this.captureZone.style.cursor = 'default'; }
                    this.isDrawing = false;
                    this.cropDimensions = {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                    };
                    this.setRectangle();
                }
            }

        }, 10);

    }

    private endCapture() {
        if (this.capturingScreen) {
            console.log('END CAPTURE');
            this.canCaptureMath = false;
            let nodeCreatedId = '';
            this.capturingScreen = false;
            this.SetReadonly(this.capturingScreen);
            if (this.captureZone && this.isDrawing) {
                this.captureZone.style.cursor = 'default';
                this.isDrawing = false;
                let resImage: string | undefined = '';
                if (this.IsMathOCR) {
                    this.mathocrConfirmationService.confirm(this.translateService.instant('OCRSCAN'), this.authenticationService.getUserEmail()).subscribe({
                        next: result => {
                            if (result) {
                                let editNode = this.smService.getSelectedNode();
                                if (!this.mathocrConfirmationService.isCopyLatex) {
                                    if (!editNode) {
                                        this.smService.addNodeAndSelectIfNoSelection('');
                                        editNode = this.smService.getSelectedNode();
                                        nodeCreatedId = editNode.id;
                                    }
                                    this.smService.setImagePlaceholderToNodeId(editNode.id, true);
                                }
                                const email = this.authenticationService.getUserEmail();
                                this.mathOcrService.getFormulaFromImage(email, resImage as string, true).subscribe({
                                    next: (resp) => {
                                        if (resp.ok) {
                                            if (resp.result.ok) {
                                                const latex = resp.result.latex;
                                                if (latex) {
                                                    const latexFix = UiConstants.fixLatexFromOcr(latex);
                                                    if (this.mathocrConfirmationService.isCopyLatex) {
                                                        console.log("COPY LATEX " + latex);
                                                        this.clipboard.copy(latex);
                                                    } else {
                                                        this.latex2ImageSubscription = this.smeService.latex2image(latexFix, latex).subscribe({
                                                            next: (response: any) => {
                                                                const dataUrl = response.result;
                                                                if (dataUrl) {
                                                                    console.log(dataUrl)
                                                                    this.smService.setLatexToNode(editNode.id, latex);
                                                                    this.imageMapsService.insertImageFromSvg(dataUrl, editNode.id);
                                                                    // Safety timeout for reenable capture button
                                                                    setTimeout(() => { this.canCaptureMath = true; }, 5000);
                                                                }
                                                            },
                                                            error: (error: any) => {
                                                                console.error(JSON.stringify(error));
                                                                const errMsg = error.toString();
                                                                const check = 'Error: TeX parse error: Undefined control sequence ';
                                                                // if (errMsg.startsWith(check)) {
                                                                const errItem = errMsg.substring(check.length).replace(/\\\\/g, '\\');
                                                                console.log('ErrItem: ' + errItem);
                                                                const fbLatex = latex.replace(errItem, '\\error{' + errItem + '}');
                                                                // }
                                                                this.canCaptureMath = true;
                                                            }
                                                        });
                                                    }
                                                } else {
                                                    // Latex non riconosciuto
                                                    this.messageBoxService.showTextMessage(
                                                        this.messageBoxService.MODE_TYPE.OK,
                                                        this.translateService.instant('OCRSCAN'),
                                                        this.translateService.instant('OCR_LATEX_EMPTY')
                                                    );
                                                    if (nodeCreatedId) this.smService.deleteNode(nodeCreatedId);
                                                    this.canCaptureMath = true;
                                                }
                                            } else {
                                                // result.ok=false: errore scansione ocr
                                                this.confirmationService.confirm(
                                                    this.translateService.instant('OCRSCAN'),
                                                    this.translateService.instant(resp.result.message),
                                                    this.translateService.instant('BUTTON_OK'),
                                                    '');
                                                if (nodeCreatedId) this.smService.deleteNode(nodeCreatedId);
                                                this.canCaptureMath = true;
                                            }
                                        } else {
                                            // ok=false: errore della chiamata http
                                            this.confirmationService.confirm(
                                                this.translateService.instant('OCRSCAN'),
                                                this.translateService.instant('MSG_HTTP_CALL_ERROR'),
                                                this.translateService.instant('BUTTON_OK'),
                                                '');
                                            if (nodeCreatedId) this.smService.deleteNode(nodeCreatedId);
                                            this.canCaptureMath = true;
                                        }
                                    },
                                    error: (error) => {
                                        // messaggio di errore generico
                                        this.confirmationService.confirm(
                                            this.translateService.instant('OCRSCAN'),
                                            this.translateService.instant('MSG_OCRSCAN_GENERIC_ERROR'),
                                            this.translateService.instant('BUTTON_OK'),
                                            '');
                                        if (nodeCreatedId) this.smService.deleteNode(nodeCreatedId);
                                        this.canCaptureMath = true;
                                    }
                                });
                            } else {
                                this.canCaptureMath = true;
                            }
                        },
                        error: (error: any) => {
                            this.canCaptureMath = true;
                        }
                    });
                }

                setTimeout(() => {
                    this.captureService
                        .getImage(this.screen.nativeElement, false, {
                            ...this.cropDimensions,
                            x: this.cropDimensions.x + 1 + window.scrollX,
                            y: this.cropDimensions.y + 1 + window.scrollY,
                        })
                        .pipe(
                            take(1),
                            tap((img: string | undefined) => {
                                resImage = img;
                                const result: any = this.dataUrlToBlob(img);
                                if (result != null) {
                                    const now = new Date();
                                    result.lastModifiedDate = now;
                                    result.name = `camera-${now}.png`;
                                    if (this.IsMathOCR) {
                                        this.mathocrConfirmationService.setImage(img as string);
                                    } else {
                                        this.imageMapsService.insertImageFromFile(result, null);
                                    }
                                }
                            })
                        )
                        .subscribe();

                    this.cropDimensions = {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                    };
                    this.setRectangle();
                })
            }
        }
    }

    private setRectangle() {
        if (this.rect) {
            this.rect.style.left = this.cropDimensions.x + 'px';
            this.rect.style.top = this.cropDimensions.y + 'px';
            this.rect.style.width = this.cropDimensions.width + 'px';
            this.rect.style.height = this.cropDimensions.height + 'px';
            this.setMask(this.cropDimensions.x, this.cropDimensions.y, this.cropDimensions.width, this.cropDimensions.height);
        }
    }

    private setMask(x: number, y: number, width: number, height: number) {
        const w = Number(this.captureZone?.offsetWidth);
        const h = Number(this.captureZone?.offsetHeight);

        const pW = 100 / w;
        const pH = 100 / h;
        this.percCropX = pW * x;
        this.percCropY = pH * y;
        this.percCropX2 = pW * (x + width);
        this.percCropY2 = pH * (y + height);
    }

    /**
   * Convert base64/URLEncoded data component to raw binary data held in a string
   */
    private dataUrlToBlob = function (dataUrl: any) {
        let byteString;
        if (dataUrl != null) {
            if (dataUrl.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(dataUrl.split(',')[1]);
            } else {
                byteString = decodeURI(dataUrl.split(',')[1]);
            }
            const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
            const array = [];
            for (let i = 0; i < byteString.length; i++) {
                array.push(byteString.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], { type: mimeString });
        }
        return null;
    };
    //#endregion

    private checkTextSelection() {
        setTimeout(() => {
            const editor = CKEDITOR.instances['appuntieditor-container'];
            if (editor) {
                try {
                    const selection = editor.getSelection();
                    if (selection.getType() === CKEDITOR.SELECTION_TEXT) {
                        const selectedText = selection.getSelectedText();
                        this.isTextSelected = (selectedText.trim() !== '');
                        // console.log('+++this.isTextSelected=' + this.isTextSelected);
                    }
                } catch { }
                if (this.checkText) {
                    this.checkTextSelection();
                }
            }
        }, 250);
    }

    public CaptureMathOCR() {
        this.IsMathOCR = true;
        if (this.capturingScreen) {
            this.stopCapture();
        } else {
            console.log("START CAPTURE");
            this.capturingScreen = true;
            this.SetReadonly(this.capturingScreen);
            this.percCropX = 0;
            this.percCropY = 0;
            this.percCropX2 = 0;
            this.percCropY2 = 0;

            this.captureTogglePressed = true;
            setTimeout(() => this.captureTogglePressed = false, 20);
        }
    }
}
