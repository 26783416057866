import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmService } from 'supermappe-core';
import { Subscription } from 'rxjs';
import { CustomizeToolbarService } from '../customize-toolbar.service';
import { CustomToolbarDto } from '../custom-toolbar-dto';

@Component({
  selector: 'app-format-table-toolbar',
  templateUrl: './format-table-toolbar.component.html',
  styleUrls: ['./format-table-toolbar.component.scss']
})
export class FormatTableToolbarComponent implements OnInit, OnDestroy {

  selectionData: any;
  onChangeSelectionSubscription: Subscription | undefined;

  isTableLocked = false;
  canAddRow = true;
  canAddCol = true;

  constructor(
    private smService: SmService,
    private customizeToolbarService: CustomizeToolbarService
  ) { }

  ngOnInit() {
    this.onChangeSelectionSubscription =
      this.customizeToolbarService.onChangeVisibilityToolbar.subscribe((_customData: CustomToolbarDto) => {
        this.changeSelection(_customData);
      });
  }

  changeSelection(_selectionData: any): any {
    const LIMIT_ROWCOL = 10;
    this.selectionData = _selectionData;
    const isSingleNodeSelected = this.selectionData && (this.selectionData.nNodes + this.selectionData.nNodesImage + this.selectionData.nNodesImageFrame + this.selectionData.nNodesText + this.selectionData.nCells + this.selectionData.nCellsImage === 1);
    this.isTableLocked = isSingleNodeSelected && _selectionData.originalSelection.element &&
      _selectionData.originalSelection.element.nodeTable && _selectionData.originalSelection.element.nodeTable.tableId &&
      _selectionData.originalSelection.element.locked;
    this.canAddRow = this.selectionData && this.selectionData.tableSize && this.selectionData.tableSize.nRows < LIMIT_ROWCOL;
    this.canAddCol = this.selectionData && this.selectionData.tableSize && this.selectionData.tableSize.nCols < LIMIT_ROWCOL;
  }

  removeTable() {
    if (!this.isTableLocked) {
      this.smService.deleteTable();
    }
  }

  removeRow() {
    if (!this.isTableLocked) {
      this.smService.deleteRow();
    }
  }

  removeCol() {
    if (!this.isTableLocked) {
      this.smService.deleteColumn();
    }
  }

  addTableRowUp() {
    if (!this.isTableLocked && this.canAddRow) {
      this.smService.addTableRowUp();
    }
  }

  addTableRowDown() {
    if (!this.isTableLocked && this.canAddRow) {
      this.smService.addTableRowDown();
    }
  }

  addTableColumnRight() {
    if (!this.isTableLocked && this.canAddCol) {
      this.smService.addTableColumnRight();
    }
  }

  addTableColumnLeft() {
    if (!this.isTableLocked && this.canAddCol) {
      this.smService.addTableColumnLeft();
    }
  }

  lockTable(lock: boolean) {
    this.smService.lockTable(lock);
  }

  ngOnDestroy() {
    if (this.onChangeSelectionSubscription) { this.onChangeSelectionSubscription.unsubscribe(); }
  }

}
