<div mat-menu-item (click)="file.click()">
    <input #inputImage type="file" class="hidden" #file accept="image/*" (change)="insertImageFromLocal($event)" />
    <mat-icon color="primary" fontSet="material-icons-outlined">laptop</mat-icon>
    <span translate>FROM-LOCAL</span>
</div>
<div mat-menu-item (click)="insertImageFromUri()">
    <mat-icon color="primary">link</mat-icon>
    <span translate>FROM-URL</span>
</div>
<div mat-menu-item (click)="insertImageFromWeb()">
    <mat-icon color="primary" fontSet="material-icons-outlined">image_search</mat-icon>
    <span translate>FROM-GOOGLEIMAGES</span>
</div>
<div *ngIf="isGoogleUser()" mat-menu-item (click)="insertImageFromDrive()">
    <mat-icon color="primary" svgIcon="google-drive"></mat-icon>
    <span translate>FROM-GOOGLEDRIVE</span>
</div>

<div mat-menu-item *ngIf="isDesktop && hasCamera" (click)="showCameraDialog()">
    <mat-icon color="primary" class="material-icons-sharp" fonytSet="material-icons-filled">photo_camera</mat-icon>
    <span translate>FROM-CAMERA</span>
    <input type="file" class="hidden" #camera accept="image/*" capture="camera" (change)="insertImageFromLocal($event)" (click)="camera.click()" />
</div>
