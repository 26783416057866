export class OrderDto {
    locked: boolean;
    acceptedDate: string;
    origin: string;
    orderId: string;
    mechCode: string;
    domainLics: number;
    fullName: string;
    licenseType: string;
    networkUserId: number;
    randomId: string;
    refOrderId: string;
    slotCount: number;
    userEmail: string;
    startDate: string;
    dbStartDate: string;
    expireDate: string;
    dbExpireDate: string;
    creationDate: string;
    createdBy: string;
    lastModificationDate: string;
    lastModificationBy: string;
    lastAction: string;
    suspended: boolean;
    userType: string;
    planId: string;
    ocrMathScanQuota: number;
    ocrMathScanPolicy: string;
    ocrMathScansLeft: string;
    notes: string;
    z_history: any;

    constructor(json: any = null) {
        if (!json) {
            json = {};
        }
        this.locked = json.locked || false;
        this.acceptedDate = json.acceptedDate || '';
        this.origin = json.origin || '';
        this.orderId = json.orderId || '';
        this.mechCode = json.mechCode || '';
        this.domainLics = json.domainLics || 0;
        this.fullName = json.fullName || '';
        this.licenseType = json.licenseType || '';
        this.networkUserId = json.networkUserId || 0;
        this.randomId = json.randomId || '';
        this.refOrderId = json.refOrderId || '';
        this.slotCount = json.slotCount || 0;
        this.userEmail = json.userEmail || '';
        this.dbStartDate = json.startDate || '2000-01-01';
        this.userType = (json && json.userType ? json.userType : '');
        this.planId = (json && json.planId ? json.planId : 'edu');
        this.ocrMathScanQuota = (json && json.ocrMathScanQuota ? parseInt(json.ocrMathScanQuota) : 0);
        this.ocrMathScanPolicy = (json && json.ocrMathScanPolicy ? json.ocrMathScanPolicy : 'FREE');
        this.ocrMathScansLeft = '';
        if (json.startDate) {
            const [year, month, day]: string[] = this.dbStartDate.split('-');
            this.startDate = `${day}/${month}/${year}`;
        } else {
            this.startDate = '';
        }
        this.dbExpireDate = json.expireDate;
        if (json.expireDate) {
            const [year, month, day]: string[] = this.dbExpireDate.split('-');
            this.expireDate = `${day}/${month}/${year}`;
        } else {
            this.expireDate = '';
        }
        this.creationDate = json.creationDate;
        this.createdBy = json.createdBy || '';
        this.lastModificationDate = json.lastModificationDate;
        this.lastModificationBy = json.lastModificationBy || '';
        this.lastAction = json.lastAction || '';
        this.suspended = (json.suspended ? json.suspended : false);
        this.notes = (json.notes ? json.notes : '');
        this.z_history = (json.z_history ? json.z_history : null);
    }
}
