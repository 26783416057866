import { VirtualKeyboardLayout } from 'mathlive';

export const EN_GEOMETRY_KEYBOARD: VirtualKeyboardLayout = {
    id: "GEOMETRY",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            {
                latex: ' \\overline{\\placeholder{}}',
                tooltip: 'segment'
            },
            {
                latex: '\\overset{\\frown}{\\placeholder{}}',
                tooltip: 'arc'
            },
            {
                latex: ' \\widehat{\\placeholder{}}',
                tooltip: 'angle'
            },
            {
                latex: '\\angle',
                tooltip: 'angle'
            }
        ],
        // Second row
        [
            {
                latex: '\\alpha',
                tooltip: 'alpha'
            },
            {
                latex: '\\beta',
                tooltip: 'beta'
            },
            {
                latex: '\\gamma',
                tooltip: 'gamma'
            },
            {
                latex: '\\delta',
                tooltip: 'delta'
            }
        ],
        // Third row
        [
            {
                latex: '\\cong',
                tooltip: 'congruent'
            },
            {
                latex: '\\equiv',
                tooltip: 'coincident'
            },
            {
                latex: '\\sim',
                tooltip: 'similar'
            }
        ],
        // Fourth row
        [
            {
                latex: '\\parallel',
                tooltip: 'parallel'
            },
            {
                latex: '\\perp',
                tooltip: 'perpendicular'
            },
            {
                key: '°',
                tooltip: 'degrees'
            }
        ]
    ]
};
