import { Component, EventEmitter, Output, Input, ViewEncapsulation, NgZone, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../core/i18n.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'app-menu-color-picker',
    templateUrl: './menu-color-picker.component.html',
    styleUrls: ['./menu-color-picker.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MenuColorPickerComponent {

    // public colors: string[] = [];
    // [
    //   '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    //   '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    //   '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    //   '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    //   '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
    // ];

    // public items: MccColorPickerItem[] = [
    //   { text: 'Black', value: '#000000' },
    //   { text: 'White', value: '#FFFFFF' },
    //   { text: 'Gray', value: '#CCCCCC' },
    // ];


    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger | undefined;
    @ViewChildren(MatMenuTrigger) triggers: QueryList<MatMenuTrigger> | undefined;

    //    @Input() caption: string;
    @Input() colors: string[] = [];
    @Input() selectedColor: any;
    @Input() toolTip: string;
    //  @Input() pickerIcon: any;
    @Input() usedSizeColors: number;
    @Input() usedColorLabel: string;
    @Input() hideButtons: boolean;
    @Input() showTransparent: boolean;
    @Input() hideHexForms: boolean;
    @Input() btnCancel: string;
    @Input() btnConfirm: string;
    @Input() disabled = false;
    @Input() nocolor: string;
    @Input() colorLabel: string;
    @Input() hideUsedColors: boolean;


    public isOpen = false;

    @Output()
    onChangeColor = new EventEmitter<string>();
    @Output() onSelected = new EventEmitter<string>();

    constructor(private translateService: TranslateService) {
        this.btnCancel = this.translateService.instant(extract('CANCEL'));
        this.btnConfirm = this.translateService.instant(extract('OK'));
        this.usedSizeColors = 0;
        this.hideButtons = true;
        this.selectedColor = '#ff0000';
        this.toolTip = '';
        this.usedSizeColors = 0;
        this.usedColorLabel = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-MYCOLOR'));
        this.showTransparent = true;
        this.hideHexForms = false;
        this.colorLabel = '';
        this.nocolor = '';// this.translateService.instant(extract('NOCOLOR'));
        this.hideUsedColors = true;
    }

    // toggleColor() {
    //   if (!this.disabled) {
    //     this.isOpen = true;
    //   } else {
    //     this.isOpen = false;
    //   }
    // }

    // openMcc(m: any) {
    //   // if (!this.disabled) {
    //   //   m.openMenu();
    //   // } else {
    //   //   this.isOpen = false;
    //   // }
    // }

    // mouseExit(colorMenu: any) {
    //   colorMenu.closeMenu();

    // }


    closeMenu() {
        this.menuTrigger?.closeMenu();
    }


    openMenu() {
        // this.triggers.forEach((item) => {
        //     item.closeMenu();
        // });
        // this.menuTrigger.closeMenu();
        this.menuTrigger?.openMenu();
        // this.colorMenu.nativeElement.openMenu();
    }

    selected(e: any) {
        if (e !== '' && !this.disabled) {
            //  if (e !== '' && !this.hideButtons) {
            this.selectedColor = e;
            this.isOpen = false;

            this.onChangeColor.emit(this.selectedColor);
        }
    }

    changeColor(e: any) {
        if (e !== '' && this.hideButtons && !this.disabled) {
            this.selectedColor = e;
            this.isOpen = false;
            this.onChangeColor.emit(this.selectedColor);
        }
    }


}
