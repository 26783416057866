import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { SmEventBroker } from '../../shared/sm-event-broker';
import { SmService } from 'supermappe-core';
import { ImageMapsService } from './image-maps.service';
import { Subscription } from 'rxjs';
import { MapEditService } from '../map-edit.service';
import { MapStateService } from '../../shared/map-state.service';
import { PasteTabService } from '../../shared/paste-tab/paste-tab.service';
import { PasteBulletsService } from '../../shared/paste-bullets/paste-bullets.service';
import { PasteStructureService } from '../../shared/paste-structure/paste-structure.service';
import { Logger } from '../../core/logger.service';
import { ConfirmationService } from '../../shared/dialog/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from 'src/app/core/i18n.service';

const logger: Logger = new Logger('map-clipboard.service');

// declare let $: any;

@Injectable({
    providedIn: 'root'
})
export class MapClipboardService implements OnDestroy {
    content: any;
    canCopy = false;
    canPaste = false;
    // modifica chrome iOS
    onCopy = new EventEmitter();

    onCanCopy = new EventEmitter<boolean>();
    onCanPaste = new EventEmitter<boolean>();
    onShowPaste = new EventEmitter<boolean>();

    eventCopy: ClipboardEvent | undefined;

    private selectedEdgeId = '';

    selectionDataSubscription: Subscription | undefined;
    mapClipboardDataSubscription: Subscription | undefined;
    mapFragmentClipboardDataSubscription: Subscription | undefined;

    constructor(
        private smService: SmService,
        smEventBroker: SmEventBroker,
        private confirmationService: ConfirmationService,
        private translateService: TranslateService,
        private imageMapsService: ImageMapsService,
        private mapEditService: MapEditService,
        private mapStateService: MapStateService,
        private pasteTabService: PasteTabService,
        private pasteBulletsService: PasteBulletsService,
        private pasteStructureService: PasteStructureService) {
        //this.eventCopy = null;
        if (smEventBroker.selectionData) {
            this.selectionDataSubscription = smEventBroker.selectionData.subscribe((_selectionData: any) => {
                this.canCopy = _selectionData !== null && (_selectionData.nNodes + _selectionData.nNodesImage + _selectionData.nNodesImageFrame + _selectionData.nNodesText + _selectionData.nCells + _selectionData.nCellsImage + _selectionData.nEdges > 0);
                this.onCanCopy.emit(this.canCopy);
                if (_selectionData && _selectionData.class === 'edge' && _selectionData.element) {
                    this.selectedEdgeId = _selectionData.element.id;
                } else {
                    this.selectedEdgeId = '';
                }
            });
        }
        if (smEventBroker.mapClipboardData) {
            this.mapClipboardDataSubscription = smEventBroker.mapClipboardData.subscribe((data: any) => {
                if (data) {
                    this.content = JSON.parse(data);
                    this.canPaste = data !== null;
                    this.onCanPaste.emit((data));
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.selectionDataSubscription) { this.selectionDataSubscription.unsubscribe(); }
        if (this.mapClipboardDataSubscription) { this.mapClipboardDataSubscription.unsubscribe(); }
        if (this.mapFragmentClipboardDataSubscription) { this.mapFragmentClipboardDataSubscription.unsubscribe(); }
    }

    cut(e: ClipboardEvent) {
        if (this.smService) {
            this.smService.copySelection(e, 'all');
            this.smService.deleteSelection();
            this.eventCopy = e;
        }
    }

    showPaste(paste: boolean) {
        this.onShowPaste.emit(paste);
    }

    copy(e: ClipboardEvent, copyType: string) {
        if (this.smService) {
            this.smService.copySelection(e, copyType);
            this.eventCopy = e;
        }
    }

    pasteFragment() {
        if (this.smService && this.content && this.content !== null) {
            this.smService.pasteMapFragment(this.content);
        }
    }

    pasteFromHTML(dataHtml: string) {
        let pasted = false;
        if (this.smService && this.content !== null) {
            let node: any;
            if (dataHtml) {
                const tableData = this.smService.sm.pasteUtils.getTableDataFromHtml(dataHtml);
                if (tableData.isTable) {
                    this.showPasteTabDialog(tableData);
                } else if (tableData.isList) {
                    this.showPasteBulletsDialog(tableData);
                } else if (tableData.isMap) {
                    this.showPasteStructureDialog(tableData);
                } else {
                    if (this.selectedEdgeId) {
                        this.mapEditService.editEdgeFromHtml(dataHtml, '');
                    } else {
                        node = this.mapEditService.editNodeFromHtml(dataHtml, 0, 0, '');
                    }
                }
                pasted = true;
            }
        }
    }

    safeGetTypeText(clipboardItem: ClipboardItem, mimeType: string): Promise<string> {
        return new Promise((resolve, reject) => {
            clipboardItem.getType(mimeType)
                .then((blob: Blob) => {
                    return resolve(blob.text());
                }).catch((error: any) => {
                    return resolve('');
                });
        });
    }

    pasteData(data: any) {
        const insertingImage = false;
        let title = '';
        let pasted = false;
        if (this.smService && this.content !== null) {

            if (!data || data.length === 0 || (data.length > 0 && data[0].types.length === 0)) {
                this.pasteFragment();
            }
            data.forEach((item: ClipboardItem) => {
                this.safeGetTypeText(item, 'text/plain').then((_title: string) => {
                    title = _title;
                    if (title && title.trim() !== '') {
                        this.mapStateService.autoRenameNewMap(title);
                    } else if (!item.types || item.types.length === 0 || (item.types.length === 1 && item.types[0] === 'text/plain')) {
                        this.pasteFragment();
                    }
                    this.safeGetTypeText(item, 'text/html').then((dataHtml: string) => {
                        let node: any;
                        if (dataHtml) {
                            const tableData = this.smService.sm.pasteUtils.getTableDataFromHtml(dataHtml);
                            this.mapStateService.hasDeepContent = tableData.hasDeepContent;
                            if (tableData.isTable) {
                                this.showPasteTabDialog(tableData);
                            } else if (tableData.isList) {
                                this.showPasteBulletsDialog(tableData);
                            } else if (tableData.isMap) {
                                this.showPasteStructureDialog(tableData);
                            } else {
                                if (this.selectedEdgeId) {
                                    this.mapEditService.editEdgeFromHtml(dataHtml, '');
                                } else {
                                    node = this.mapEditService.editNodeFromHtml(dataHtml, 0, 0, '');
                                }
                            }
                            pasted = true;
                        }

                        // Paste link or text/table
                        if (dataHtml === '') {
                            this.safeGetTypeText(item, 'text/uri-list').then((linkText: string) => {
                                if (!linkText) {
                                    linkText = title;
                                }
                                if (linkText.endsWith('.jpeg') || linkText.endsWith('.jpg') || linkText.endsWith('.png')) {
                                    let l = linkText;
                                    if (linkText.includes('http')) {
                                        const s = linkText.split('http');
                                        l = 'http' + s[1];
                                    }
                                    this.imageMapsService.insertImageFromUri(l);
                                    linkText = '';
                                }
                                if (linkText !== '') {
                                    const tableData = this.smService.sm.pasteUtils.getTableDataFromText(linkText);
                                    this.mapStateService.hasDeepContent = tableData.hasDeepContent;
                                    if (tableData.isTable) {
                                        this.showPasteTabDialog(tableData);
                                    } else if (tableData.isList) {
                                        this.showPasteBulletsDialog(tableData);
                                    } else if (tableData.isMap) {
                                        this.showPasteStructureDialog(tableData);
                                    } else {
                                        if (this.selectedEdgeId) {
                                            this.mapEditService.editEdgeFromHtml(dataHtml, linkText);
                                        } else {
                                            this.mapEditService.editNodeFromHtml(dataHtml, 0, 0, linkText);
                                        }
                                    }
                                }
                            });
                        }

                        item.getType('image/png')
                            .then((blob: Blob) => {
                                const fileImg: File = new File([blob], "img");
                                if (!node) node = this.smService.getSelectedNode();
                                setTimeout(() => {
                                    //this.smService.getSelectedNode();
                                    this.imageMapsService.insertImageFromFile(fileImg, node);
                                }, 100);

                            });

                        // Paste file
                        if (data.clipboardData && data.clipboardData.files.length > 0 && !pasted) {
                            const dataFile = data.clipboardData.files[0];
                            if (dataFile && dataFile.type.indexOf('image') >= 0 && !insertingImage) {
                                if (!node) node = this.smService.getSelectedNode();
                                setTimeout(() => {
                                    //this.smService.getSelectedNode();
                                    this.imageMapsService.insertImageFromFile(dataFile, node);
                                }, 100);

                            }
                        }
                    });
                });
            });
        }
    }

    paste(e: ClipboardEvent) {
        const insertingImage = false;
        let pasted = false;
        if (this.smService && this.content !== null) {
            // Paste Map fragment
            const dataFragment = e.clipboardData?.getData('MapFragment');

            if (dataFragment) {
                const fragment = JSON.parse(dataFragment);
                this.smService.pasteMapFragment(fragment);
            }

            const title = e.clipboardData?.getData('text/plain');
            if (title && title.trim() !== '') {
                this.mapStateService.autoRenameNewMap(title);
            }

            // Paste html
            const dataHtml = e.clipboardData?.getData('text/html');
            let node: any;
            if (dataHtml) {
                const tableData = this.smService.sm.pasteUtils.getTableDataFromHtml(dataHtml);
                this.mapStateService.hasDeepContent = tableData.hasDeepContent;
                if (tableData.isTable) {
                    this.showPasteTabDialog(tableData);
                } else if (tableData.isList) {
                    this.showPasteBulletsDialog(tableData);
                } else if (tableData.isMap) {
                    this.showPasteStructureDialog(tableData);
                } else {
                    if (this.selectedEdgeId) {
                        this.mapEditService.editEdgeFromHtml(dataHtml, '');
                    } else {
                        node = this.mapEditService.editNodeFromHtml(dataHtml, 0, 0, '');
                    }
                }
                pasted = true;
            }

            // Paste link or text/table
            let linkText: string | undefined = undefined;
            if (dataHtml === '') {
                linkText = e.clipboardData?.getData('text/uri-list');
                if (linkText === '') {
                    linkText = e.clipboardData?.getData('text/plain');
                }
                if (linkText && linkText !== '') {
                    const tableData = this.smService.sm.pasteUtils.getTableDataFromText(linkText);
                    this.mapStateService.hasDeepContent = tableData.hasDeepContent;
                    if (tableData.isTable) {
                        this.showPasteTabDialog(tableData);
                    } else if (tableData.isList) {
                        this.showPasteBulletsDialog(tableData);
                    } else if (tableData.isMap) {
                        this.showPasteStructureDialog(tableData);
                    } else {
                        if (this.selectedEdgeId) {
                            this.mapEditService.editEdgeFromHtml(dataHtml, linkText);
                        } else {
                            this.mapEditService.editNodeFromHtml(dataHtml, 0, 0, linkText);
                        }
                    }
                }
            }

            // Paste file
            if (e.clipboardData && e.clipboardData.files.length > 0 && !pasted) {
                const dataFile = e.clipboardData.files[0];
                if (dataFile && dataFile.type.indexOf('image') >= 0 && !insertingImage) {
                    if (!node) node = this.smService.getSelectedNode();
                    setTimeout(() => {
                        //this.smService.getSelectedNode();
                        this.imageMapsService.insertImageFromFile(dataFile, node);
                    }, 100);

                }
            }
        }
    }

    private checkTableSize(tableData: any): string {
        let res = '';
        if (tableData && tableData.isTable) {
            if (tableData.rows <= 10 && tableData.cols <= 10) {
                res = 'normal';
            } else if ((tableData.rows <= 20 && tableData.cols <= 20)) {
                res = 'extended'
            } else {
                res = 'toobig';
            }
        }
        return res;
    }

    private showPasteTabDialog(tableData: any) {
        const noTabRowsCols = (tableData.rows <= 1 || tableData.cols <= 1);
        const tabSize = this.checkTableSize(tableData);
        if (tabSize === 'normal') {
            this.pasteTabService.show(noTabRowsCols, this.mapStateService.hasDeepContent)
                .subscribe({
                    next: result => {
                        if (result) {
                            this.smService.pasteTableAs(this.mapStateService.pasteMode, this.mapStateService.pasteWithDeepContent, tableData);
                        }
                    },
                    error: (error: any) => {
                        logger.error(error);
                    }
                });
        } else if (tabSize === 'extended') {
            this.confirmationService.confirm(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract('TABLE_WARNING_EXTENDED')).replace('{%r}', tableData.rows).replace('{%c}', tableData.cols),
                this.translateService.instant(extract('BUTTON_OK')),
                this.translateService.instant(extract('CANCEL')))
                .subscribe({
                    next: result => {
                        if (result) {
                            this.pasteTabService.show(noTabRowsCols, this.mapStateService.hasDeepContent)
                                .subscribe({
                                    next: result => {
                                        if (result) {
                                            this.smService.pasteTableAs(this.mapStateService.pasteMode, this.mapStateService.pasteWithDeepContent, tableData);
                                        }
                                    },
                                    error: (error: any) => {
                                        logger.error(error);
                                    }
                                });
                        }
                    },
                    error: (error: any) => {
                        logger.error(error);
                    }
                });
        } else if (tabSize === 'toobig') {
            this.confirmationService.confirm(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract('TABLE_WARNING_TOOBIG')).replace('{%r}', tableData.rows).replace('{%c}', tableData.cols).replace('{nl}', '\nl'),
                this.translateService.instant(extract('BUTTON_OK')),
                this.translateService.instant(extract('CANCEL')))
                .subscribe({
                    next: result => {
                        if (result) {
                            const node = this.mapEditService.editNodeFromHtml(tableData.html, 0, 0, '');
                        }
                    },
                    error: (error: any) => {
                        logger.error(error);
                    }
                });
        }
    }

    private showPasteBulletsDialog(tableData: any) {
        this.pasteBulletsService.show(this.mapStateService.hasDeepContent)
            .subscribe({
                next: result => {
                    if (result) {
                        this.smService.pasteBulletsAs(this.mapStateService.pasteMode, this.mapStateService.pasteWithDeepContent, tableData);
                    }
                },
                error: (error: any) => {
                    logger.error(error);
                }
            });
    }

    private showPasteStructureDialog(tableData: any) {
        this.pasteStructureService.show(this.mapStateService.hasDeepContent)
            .subscribe({
                next: result => {
                    if (result) {
                        this.smService.pasteStructureAs(this.mapStateService.pasteMode, this.mapStateService.pasteWithDeepContent, tableData);
                    }
                },
                error: (error: any) => {
                    logger.error(error);
                }
            });
    }

    insertHtmlText(elem: any) {
        const imgs = elem.getElementsByTagName('img');
        for (let index = imgs.length - 1; index >= 0; index--) {
            imgs[index].parentNode.removeChild(imgs[index]);
        }
        if (elem.innerHTML !== '') {
            this.smService.pasteHtmlText(elem.innerHTML);
        }
    }
}
