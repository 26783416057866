import { Component, OnInit, OnDestroy } from '@angular/core';
import { SmService } from 'supermappe-core';
import { Subscription } from 'rxjs';
import { CustomizeToolbarService } from '../customize-toolbar.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../../../core/i18n.service';
import { CustomToolbarDto } from '../custom-toolbar-dto';
import { UiConstants } from '../../../../shared/ui-constants';

@Component({
    selector: 'app-format-text-toolbar',
    templateUrl: './format-text-toolbar.component.html',
    styleUrls: ['./format-text-toolbar.component.scss']
})

export class FormatTextToolbarComponent implements OnInit, OnDestroy {

    // @ViewChild('font') font: MatSelect;
    // @ViewChild('fontsize') fontSize: MatSelect;

    fontFamilies = UiConstants.FONTS.fontFamilies;
    fontSizes = UiConstants.FONTS.fontSizes;
    colors = UiConstants.PALETTE.BASE;
    highlightColors = UiConstants.PALETTE.HIGHLIGHTERS;

    // public font: Font = new Font({
    //   family: 'Roboto',
    //   size: '14px',
    //   style: 'regular',
    //   styles: ['regular']
    // });

    public selectedFontFamily = '';
    public selectedFontSize = '';
    public selectedFontColor = '';
    public selectedHighlightColor = '';
    public isBold = false;

    public fontButtonTooltip = '';

    // public showInputSize = false;
    // public showInputFont = false;

    // public sizeSelect = false;
    // public styleSelect = false;

    public autoLoad = true;
    public isOpen = false;

    public totButtonVisible = 5;

    fontColorTooltip = '';
    fontColorHighlightTooltip = '';
    colorUsedLabel = '';
    fontColorIcon = 'format-color-text';
    highlightIcon = 'marker01';

    // imageIcon: any;
    selectedBorderWidth = '';
    onChangeToolbarVisibilitySubscription: Subscription | undefined;

    // public presetFonts = this._presetFonts;

    // private ids: Array<String>;

    constructor(private smService: SmService, private customizeToobarService: CustomizeToolbarService,
        private translateService: TranslateService) {
        this.fontColorTooltip = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-TEXT-COLOR'));
        this.fontColorHighlightTooltip = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-HIGHLIGHT'));
        this.colorUsedLabel = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-MYCOLOR'));
    }

    changeSelection(_selectionData: any): any {
        // TODO: Set data for font name, size, color, background, bold
        // this.ids = _selectionData.ids;
        this.updateFontInfo(_selectionData.textInfo);
        this.customizeToobarService.formatTextButtonVisible = this.totButtonVisible;
        this.customizeToobarService.updateTotButton();

    }

    // public togglePresetFonts(): void {
    //   this.presetFonts = this.presetFonts.length ? [] : this._presetFonts;
    // }

    // public toggleExtraOptions(): void {
    //   this.sizeSelect = !this.sizeSelect;
    //   this.styleSelect = !this.styleSelect;
    // }

    ngOnInit() {
        this.onChangeToolbarVisibilitySubscription =
            this.customizeToobarService.onChangeVisibilityToolbar.subscribe((_customData: CustomToolbarDto) => {
                this.changeSelection(_customData);
            });
        this.changeSelection(this.customizeToobarService.customToolbarDto);
    }

    ngOnDestroy(): void {
        if (this.onChangeToolbarVisibilitySubscription) { this.onChangeToolbarVisibilitySubscription.unsubscribe(); }
    }

    // toggleInputFont() {
    //   this.showInputFont = !this.showInputFont;
    //   if (!this.showInputFont) {
    //     this.changeFontFamily();
    //   }
    // }

    // toggleInputSize() {
    //   this.showInputSize = !this.showInputSize;
    //   if (!this.showInputSize) {
    //     this.changeFontSize();
    //   }
    // }

    toggleHighlightColor() {
        this.isOpen = true;
    }

    // fontPickerChange() {
    //   this.selectedFontName = this.font.family;
    //   this.smService.applyTextStyleToSelectedElements(this.TEXTSTYLE.FONT, this.selectedFontName);
    // }


    // toggleFontDropdown() {
    //   if (!this.font.panelOpen) {
    //     this.font.open();
    //   } else {
    //     this.font.close();
    //   }
    // }

    changeFontFamily(evt: any) {
        // this.selectedFontFamily = this.selectedFontFamily.replace(/"/g, '');
        this.selectedFontFamily = evt.currentTarget.innerText;
        this.smService.applyTextStyleToSelectedElements(UiConstants.TEXTSTYLE.FONT, this.selectedFontFamily);
    }

    // toggleFontSizeDropdown() {
    //   if (!this.fontSize.panelOpen) {
    //     this.fontSize.open();
    //   } else {
    //     this.fontSize.close();
    //   }
    // }

    changeFontSize(evt: any) {
        // this.selectedFontSize = parseInt(this.selectedFontSize, 10) + 'px';
        // const fontSizePx = parseInt(this.selectedFontSize, 10);
        const fontSizePx = parseInt(evt.currentTarget.innerText, 10);
        // this.selectedFontSize = Math.round(fontSizePx * 4 / 3) + 'px';
        this.selectedFontSize = fontSizePx + 'pt';
        this.smService.applyTextStyleToSelectedElements(UiConstants.TEXTSTYLE.FONTSIZE, this.selectedFontSize);
    }

    changeFontColor(e: any) {
        this.selectedFontColor = e;
        this.smService.applyTextStyleToSelectedElements(UiConstants.TEXTSTYLE.TEXTCOLOR, this.selectedFontColor);
    }

    changeHighlightColor(e: any) {
        this.selectedHighlightColor = e;
        this.isOpen = false;
        this.smService.applyTextStyleToSelectedElements(UiConstants.TEXTSTYLE.BACKCOLOR, this.selectedHighlightColor);
    }

    setBold() {
        this.isBold = !this.isBold;
        this.smService.applyTextStyleToSelectedElements(UiConstants.TEXTSTYLE.BOLD, this.isBold);
    }

    private updateFontInfo(style: any) {
        if (style && style.color && style.color !== '') {
            const fonts = style.fontName.split(',');
            if (fonts.length > 0) {
                this.selectedFontFamily = fonts[0].replace(/"/g, '');
            } else {
                this.selectedFontFamily = '';
            }
            this.selectedFontSize = Math.round((parseFloat(style.fontSize) * 3 / 4)) + ''; // core px -> UI pt
            this.selectedFontColor = style.color;
            this.selectedHighlightColor = style.background;
            this.isBold = style.isBold;
        } else {
            this.selectedFontFamily = 'Arial';
            this.selectedFontSize = '14';
            this.selectedFontColor = '';
            this.selectedHighlightColor = '';
            this.isBold = false;
        }
        this.fontButtonTooltip = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-FONT')); // + ': ' + this.selectedFontFamily;
    }

}
