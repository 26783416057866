<div [ngStyle]="style" class="extra-container" mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event)" (resizeStart)="onResizeStart($event)" (resizing)="onResizing($event)">
    <div class="extra-title">
        <div class="extra-title-icon">
            <mat-icon svgIcon="notebook">
            </mat-icon>
        </div>
        <div class="extra-title-text">
        </div>
        <div class="extra-close">
            <button mat-icon-button color="primary" matTooltip="{{'SEARCH_CLOSE' | translate}}">
                <mat-icon [attr.aria-label]="'SEARCH_CLOSE' | translate" (click)="closeExtra();" svgIcon="close-box-outline">
                </mat-icon>
            </button>
        </div>
    </div>

    <!-- <div>{{desmosUrl}}</div> -->
    <iframe class="extra-content" [src]="desmosUrl" height="100%" width="100%"></iframe>
    <!-- <iframe class="extra-panel" *ngIf="calculator" src="https://www.desmos.com/calculator?lang=it" height="100%" width="100%"></iframe>
  <iframe class="extra-panel" *ngIf="scientific" src="https://www.desmos.com/scientific?lang=it " height="100%" width="100%"></iframe>
  <iframe class="extra-panel" *ngIf="fourfunction" src="https://www.desmos.com/fourfunction?lang=it " height="100%" width="100%"></iframe>
  <iframe class="extra-panel" *ngIf="geometry" src="https://www.desmos.com/geometry?lang=it" height="100%" width="100%"></iframe> -->

    <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>

</div>
<div class="over-panel" [ngStyle]="styleover" #overpanel *ngIf="resizing"></div>
