import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../core/authentication/authentication.guard';

import { Route } from '../core/route.service';
import { MapHistoryComponent } from './map-history.component';

const routes: Routes = Route.withShell([
    { path: 'map-history/:mapId', component: MapHistoryComponent, data: { title: 'history map' }, canActivate: [AuthenticationGuard] }
]);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthenticationGuard]
})
export class MapHistoryRoutingModule { }
