import { Injectable } from '@angular/core';
import { SmService, DirtyData } from 'supermappe-core';
import { SmEventBroker } from '../sm-event-broker';
import { Observable } from 'rxjs';
import { MapStateService } from '../map-state.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class DirtyDataStatus {
  canUndo = false;
  canRedo = false;
  isDirty = false;
  index = -1;
}

@Injectable({
  providedIn: 'root'
})
export class DirtyDataService {
  public dirtyDataChange: Observable<DirtyDataStatus> | undefined;

  constructor(private smService: SmService, eventBroker: SmEventBroker, private mapStateService: MapStateService) {
    if (eventBroker.dirtyData) {
    this.dirtyDataChange = eventBroker.dirtyData
      .pipe(map((newDirtyData: DirtyData) => {
        const dirtyDataStatus = new DirtyDataStatus();
        dirtyDataStatus.canUndo = this.smService.canUndo();
        dirtyDataStatus.canRedo = this.smService.canRedo();
        dirtyDataStatus.isDirty = (newDirtyData.index !== this.mapStateService.lastSaveIndex);
        dirtyDataStatus.index = newDirtyData.index;
        return dirtyDataStatus;
      }));
    }
  }

  undo() {
    this.smService.undo();
  }

  redo() {
    this.smService.redo();
  }

}
