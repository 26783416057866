import { Color } from "material-community-components/color-picker";

export class PdfToolsPrefsDto {

    public default_highlight_color: string | Color;
    public default_underline_color: string | Color;
    public default_brush_color: string | Color;
    public default_text_color: string | Color;
    public default_fill_color: string | Color;
    public default_border_color: string | Color;

    public default_underline_width: number;
    public default_brush_opacity: number;
    public default_brush_width: number;
    public default_eraser_brush_width: number;
    public default_text_size: number;
    public default_fill_color_opacity: number;
    public default_border_color_opacity: any;


    public default_rectangle_opacity: number;
    public default_rectangle_color: string | Color;
    public default_rectangle_border_color: string | Color;
    public default_rectangle_width: number = 3;

    public default_circle_opacity: number;
    public default_circle_color: string | Color;
    public default_circle_border_color: string | Color;
    public default_circle_width: number = 3;


    public default_arrow_opacity: number;
    public default_arrow_color: string | Color;
    public default_arrow_width: number = 1;



    public default_check_opacity: number;
    public default_check_color: string | Color;
    public default_check_width: number = 12;

    public default_line_opacity: number;
    public default_line_color: string | Color;
    public default_line_width: number = 1;

    public copyHighligth: boolean;
    public copyUnderlined: boolean;


    constructor(json: any = {}) {

        this.default_highlight_color = (json && json.default_highlight_color) ? json.default_highlight_color : 'rgba(255, 255, 0, 1.0)';
        this.default_underline_color = (json && json.default_underline_color) ? json.default_underline_color : 'rgb(0, 178, 71)';
        this.default_brush_color = (json && json.default_brush_color) ? json.default_brush_color : 'rgb(255, 151, 61)';
        this.default_text_color = (json && json.default_text_color) ? json.default_text_color : 'rgb(0, 178, 243)';
        this.default_fill_color = (json && json.default_fill_color) ? json.default_fill_color : 'rgba(255, 237, 0,0.30)';
        this.default_border_color = (json && json.default_border_color) ? json.default_border_color : 'rgba(17, 153,158,1)';
        this.default_underline_width = (json && json.default_underline_width) ? json.default_underline_width : 1;
        this.default_brush_opacity = (json && json.default_brush_opacity) ? json.default_brush_opacity : 100;
        this.default_brush_width = (json && json.default_brush_width) ? json.default_brush_width : 5;
        this.default_eraser_brush_width = (json && json.default_eraser_brush_width) ? json.default_eraser_brush_width : 30;
        this.default_text_size = (json && json.default_text_size) ? json.default_text_size : 30;
        this.default_fill_color_opacity = (json && json.default_fill_color_opacity) ? json.default_fill_color_opacity : 100;
        this.default_border_color_opacity = (json && json.default_border_color_opacity) ? json.default_border_color_opacity : 100;


        this.default_rectangle_opacity = (json && json.default_rectangle_opacity) ? json.default_rectangle_opacity : 30;
        this.default_rectangle_color = (json && json.default_rectangle_color) ? json.default_rectangle_color : 'rgb(255, 236, 102)';
        this.default_rectangle_border_color = (json && json.default_rectangle_border_color) ? json.default_rectangle_border_color : 'rgb(0, 174, 200)';
        this.default_rectangle_width = (json && json.default_rectangle_width) ? json.default_rectangle_width : 1;

        this.default_circle_opacity = (json && json.default_circle_opacity) ? json.default_circle_opacity : 30;

        this.default_circle_color = (json && json.default_circle_color) ? json.default_circle_color : 'rgb(255, 236, 102)';
        this.default_circle_border_color = (json && json.default_circle_border_color) ? json.default_circle_border_color : 'rgb(0, 174, 200)';
        this.default_circle_width = (json && json.default_circle_width) ? json.default_circle_width : 1;


        this.default_arrow_opacity = (json && json.default_arrow_opacity) ? json.default_arrow_opacity : 100;

        this.default_arrow_color = (json && json.default_arrow_color) ? json.default_arrow_color : 'rgb(0, 174, 200)';
        this.default_arrow_width = (json && json.default_arrow_width) ? json.default_arrow_width : 1;

        this.default_check_opacity = (json && json.default_check_opacity) ? json.default_check_opacity : 100;
        this.default_check_color = (json && json.default_check_color) ? json.default_check_color : 'rgb(0, 178, 71)';
        this.default_check_width = (json && json.default_check_width) ? json.default_check_width : 12;


        this.default_line_opacity = (json && json.default_line_opacity) ? json.default_line_opacity : 100;

        this.default_line_color = (json && json.default_line_color) ? json.default_line_color : 'rgb(220, 79, 75)';
        this.default_line_width = (json && json.default_line_width) ? json.default_line_width : 1;

        this.copyHighligth = (json) ? json.copyHighligth : true;
        this.copyUnderlined = (json) ? json.copyUnderlined : true;
    }

}
