import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { Logger } from '../core/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { SmeService } from '../core/sme/sme.service';
import { Subscription } from 'rxjs';
import { IframeLoginService } from '../core/authentication/iframe-login-service';
import { environment } from '../../environments/environment';
import { MatomoTracker } from 'ngx-matomo-client';
import { FirebaseAuthService } from '../core/firebase/firebase-auth.service';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

const logger = new Logger('loggedInComponent');

@Component({
    selector: 'app-logged-in',
    templateUrl: './logged-in.component.html',
    styleUrls: ['./logged-in.component.scss']
})
export class LoggedInComponent implements AfterViewInit, OnDestroy {

    @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement> | undefined;
    ctx: CanvasRenderingContext2D | null = null;

    isLoading = true;
    isError = false;
    errorMessage = '';
    error = {};

    public expired = false;
    public id_token = '';
    public client_id = '';
    public doubleAccess = false;
    public askPrivacy = false;
    public isUserAdmin = false;
    public showDemo = false;
    public invalidCredentials = false;
    public eligibleForDemo = false;
    public isDemo = false;
    public demoFromStart = false;
    public scopeToAccept: any;



    private providerId = '';

    public user = {
        id: '',
        uid: '',
        email: '',
        fullName: '',
        given_name: '',
        family_name: '',
        picture: '',
        id_token: '',
        token: '',
        providerId: ''
    };


    routeFragmentSubscription: Subscription | undefined;
    hasUserAcceptedPrivacySubscription: Subscription | undefined;
    setSessionIdSubscription: Subscription | undefined;
    checkAndUnlockAdminOrdersSubscription: Subscription | undefined;
    setUserInfoSubscription: Subscription | undefined;
    logoutSubscription: Subscription | undefined;
    tokenResponse: any;
    tokenClient: any;
    email: string = '';
    compressLogo = false;
    logoUrlExtended = '';
    public logoUrlCompressed = '';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private smeService: SmeService,
        private authenticationService: AuthenticationService,
        private firebaseAuthService: FirebaseAuthService,
        private translateService: TranslateService,
        private iframeLoginService: IframeLoginService,
        private tracker: MatomoTracker,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver
    ) {
        this.breakpointObserver
            .observe(['(max-width:900px)'])
            .subscribe((state: BreakpointState) => {
                this.compressLogo = state.matches;
            });
        this.logoUrlExtended = (this.authenticationService.isLab() ? '../../assets/home/SMX_completo_lab.png' : '../../assets/home/SMX_completo_edu.png');
        this.logoUrlCompressed = (this.authenticationService.isLab() ? '../../assets/home/SMX_logo_lab.png' : '../../assets/home/SMX_logo_edu.png');
    }

    ngAfterViewInit(): void {
        this.demoFromStart = (localStorage.getItem('demoFromStart') === 'true' ? true : false);
        this.askPrivacy = false;
        this.showDemo = false;
        this.isDemo = false;
        this.isUserAdmin = false;
        this.invalidCredentials = false;
        this.isError = false;
        this.isLoading = true;
        this.error = '';

        this.errorMessage = '';

        if (this.route.snapshot.fragment) {
            this.expired = this.route.snapshot.fragment.includes('expired=1');
            this.doubleAccess = this.route.snapshot.fragment.includes('double_access=1');
            if (this.expired) {
                // Sessione scaduta
                this.authenticationService.logout();
                this.isLoading = false;
                this.isError = true;
                this.errorMessage = this.translateService.instant('ERR_LOGIN_SESSION_EXPIRED');
            } else if (this.doubleAccess) {
                // Controllo doppio accesso
                this.authenticationService.logout();
                this.isLoading = false;
                this.isError = true;
                this.errorMessage = this.translateService.instant('ERR_LOGIN_DOUBLE_ACCESS');
            }
        }
        else if (this.route.snapshot.queryParams) {
            this.expired = (this.route.snapshot.queryParams['expired'] === '1');
            this.doubleAccess = (this.route.snapshot.queryParams['double_access'] === '1');
            if (this.expired) {
                // Sessione scaduta
                this.authenticationService.logout();
                this.isLoading = false;
                //   this.isError = true;
                //   this.errorMessage = this.translateService.instant('ERR_LOGIN_SESSION_EXPIRED');
                // } else if (this.doubleAccess) {
                //   // Controllo doppio accesso
                //   this.authenticationService.logout();
                //   this.isLoading = false;
                //   this.isError = true;
                //   this.errorMessage = this.translateService.instant('ERR_LOGIN_DOUBLE_ACCESS');
                const error = this.route.snapshot.queryParams['error'] ? JSON.parse(this.route.snapshot.queryParams['error']) : '';
                console.log('GOOGLE LOGIN ERROR: ' + JSON.stringify(error));
                if (error.reason) {
                    console.log('Error reason: ' + error.reason);
                    this.errorMessage = this.translateService.instant(error.reason);
                } else if (error.error) {
                    console.log('Error error: ' + error.error);
                    const e = error.error;
                    this.errorMessage = this.translateService.instant('ERR_' + e.toUpperCase());
                } else {
                    console.log('NO REASON: generic google login error');
                    this.errorMessage = this.translateService.instant('ERR_LOGIN_GOOGLE');
                }
            } else {
                if (this.route.snapshot.queryParams['userInfo']) {
                    const responsePayload = JSON.parse(this.route.snapshot.queryParams['userInfo']);


                    if (responsePayload.state !== 'password' &&
                        responsePayload.state !== 'passwordless' &&
                        !responsePayload.state.includes('email=' + responsePayload.email)) {
                        this.authenticationService.logout();
                        this.isLoading = false;
                        this.isError = true;
                        this.errorMessage = this.translateService.instant('ERR_LOGIN_MIXED');
                    } else {

                        this.providerId = 'password';
                        if (responsePayload.state === 'passwordless') {
                            this.providerId = 'passwordless';
                        } else {
                            this.providerId = responsePayload.state == 'password' ? 'password' : 'google.com';
                        }
                        //const providerId = responsePayload.state === 'google.com' ? 'google.com' : 'password';
                        this.id_token = responsePayload.id_token;
                        this.client_id = environment.firebaseClientId;
                        this.user = {
                            id: responsePayload.id,
                            uid: responsePayload.id,
                            email: responsePayload.email,
                            fullName: responsePayload.fullName,
                            given_name: responsePayload.given_name,
                            family_name: responsePayload.family_name,
                            picture: this.getPicture(responsePayload.picture, responsePayload.fullName),
                            id_token: this.id_token,
                            token: responsePayload.access_token,
                            providerId: this.providerId

                        };
                        this.loginStep1();


                        // With custom page title
                        try {
                            this.tracker.trackPageView('User loggedin ' + responsePayload.email);
                        } catch (e: any) {
                            logger.error('Matomo error ' + e.message);
                        }
                    }

                } else {
                    this.authenticationService.logout();
                    this.isLoading = false;
                    this.isError = true;
                    this.errorMessage = this.translateService.instant('ERR_LOGIN_GOOGLE');
                    console.log('GOOGLE LOGIN ERROR: no userInfo in queryParams')
                }
            }
        }

    }

    getPicture(uri: string, fullName: string): string {
        if (!uri) {
            // No picture: generate a 96x96 JPEG format
            if (this.canvas) {
                this.ctx = this.canvas.nativeElement.getContext('2d', { willReadFrequently: true });
                const colors = this.getColors(fullName);
                this.drawBackground(colors.background);
                this.drawInitials(colors.foreground, fullName);
                uri = this.canvas.nativeElement.toDataURL('image/jpeg', 1.0);
            }
        }
        return uri;
    }

    private hexToRgb(hex: string) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    private getColors(fullName: string): any {
        const colors = { background: '#808080', foreground: '#000000' };
        // Background
        if (fullName) {
            let hash = 0;
            for (let i = 0; i < fullName.length; i++) {
                /* tslint:disable */
                hash = fullName.charCodeAt(i) + ((hash << 5) - hash);
                /* tslint:enable */
            }
            let color = '#';
            for (let i = 0; i < 3; i++) {
                /* tslint:disable */
                const value = (hash >> (i * 8)) & 0xFF;
                /* tslint:enable */
                color += ('00' + value.toString(16)).substr(-2);
            }
            colors.background = color;
        } else {
            colors.background = '#808080';
        }
        // Foreground hi-contrast
        const rgb = this.hexToRgb(colors.background);
        if (rgb && rgb.r && rgb.g && rgb.b) {
            const use_black = ((rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186);
            if (use_black) {
                colors.foreground = '#000000';
            } else {
                colors.foreground = '#FFFFFF';
            }
        }
        //
        return colors;
    }

    private drawBackground(color: string) {
        if (this.ctx) {
            this.ctx.fillStyle = color;
            this.ctx.fillRect(0, 0, 96, 96);
        }
    }

    private drawInitials(color: string, fullName: string) {
        if (fullName && this.ctx) {
            let initials = '';
            const names: Array<string> = fullName.split(' ');
            for (let i = 0; i < names.length; i++) {
                const name = names[i];
                // if (initials) initials += ' ';
                initials += name.substring(0, 1);
            }
            this.ctx.fillStyle = color;
            this.ctx.font = '40px Arial';
            this.ctx.textAlign = 'center';
            this.ctx.fillText(initials, 48, 62);
        }
    }

    findUserDemo(credentials: any) {
        let isDemo = false;
        if (credentials && credentials.admin) {
            isDemo = (credentials.admin.length === 1 && credentials.admin[0].origin === 'demo');
        }
        return isDemo;
    }
    findUserAdmin(credentials: any) {
        let isAdmin = false;
        if (credentials && credentials.admin) {
            for (let i = 0; i < credentials.admin.length; i++) {
                const adm = credentials.admin[i];
                if (adm.type === 'd' || adm.type === 'm') {
                    isAdmin = true;
                }
            }
        }
        return isAdmin;
    }

    acceptedPrivacy(data: any) {
        if (data) {
            const accepted = data.accepted;
            const newsletterChecked = data.newsletterChecked;
            const userType = data.userType;
            const ageRange = data.ageRange;
            if (accepted) {
                this.isLoading = true;
                const date = new Date().toISOString();
                this.loginStep2(date, newsletterChecked, userType, ageRange);
            }
        } else {
            this.loginClick();
        }
    }


    signInApplication(user: any, client_id: string) {
        return new Promise((resolve, reject) => {
            Promise.resolve(user).then((user) => {

                if (user.providerId === 'google.com') {
                    return this.authenticationService.signInApplicationWithGoogle(user, client_id);

                } else {
                    return this.authenticationService.signInApplicationWithPassword(user, client_id);
                }
            }).then((data) => {


                resolve(data);
            }).catch((error) => {
                return reject(error);
            });
        });
    }



    loginStep1() {

        this.signInApplication(this.user, this.client_id)
            .then(() => {
                this.isLoading = true;
                console.info('loggedIn OK!');
                const credentials = this.authenticationService.credentials;
                if (credentials !== null && credentials !== undefined) {
                    if (credentials?.valid || (this.firebaseAuthService.isPasswordLessUser)) {
                        // Credenziali VALIDE
                        this.isUserAdmin = this.findUserAdmin(credentials);
                        if (credentials?.locked && !this.isUserAdmin) {
                            const errorMessage = this.translateService.instant('ERR_LOCKED');
                            this.authenticationService.logout();
                            this.isLoading = false;
                            this.isError = true;
                            this.errorMessage = errorMessage;
                        } else if (credentials?.earlyStart && (!this.firebaseAuthService.isPasswordLessUser)) {

                            const dbStartDate = this.authenticationService.credentials?.startDate ? this.authenticationService.credentials?.startDate : '';
                            let dtStart = '';
                            let errorMessage = '';
                            if (dbStartDate) {
                                dtStart = dbStartDate.substring(8, 10) + '/' + dbStartDate.substring(5, 7) + '/' + dbStartDate.substring(0, 4);
                                errorMessage = this.translateService.instant('ERR_EARLY_START') + ' ' + dtStart;
                            } else {
                                errorMessage = this.translateService.instant('ERR_EARLY_START');
                            }
                            this.authenticationService.logout();
                            this.isLoading = false;
                            this.isError = true;
                            this.errorMessage = errorMessage;
                        } else {


                            this.isDemo = this.findUserDemo(credentials);
                            this.isUserAdmin = this.findUserAdmin(credentials);

                            const uid = this.firebaseAuthService.auth.currentUser?.uid;
                            if (uid) {
                                this.hasUserAcceptedPrivacySubscription = this.smeService.hasUserAcceptedPrivacy(uid).subscribe((resp: any) => {
                                    let res: string;
                                    if (resp.ok) {
                                        res = resp.data;
                                        this.askPrivacy = (res === undefined || res === null || res === 'null' || res === '');

                                        //  JHUBA: DEBUG: COMMENTARE!!!
                                        /// this.askPrivacy = true;
                                        ///////////////////////////////////////////////////


                                        if (!this.askPrivacy) {
                                            this.loginStep2(res, false, '', '');
                                        } else {
                                            this.isLoading = false;
                                        }
                                    } else {
                                        this.loginClick();
                                    }
                                });
                            } else {
                                this.loginClick();
                            }
                        }
                    } else {
                        // Credenziali NON VALIDE

                        if (this.demoFromStart) {
                            // Demo attivata al primo login (metodo primario)
                            this.requestDemo();
                        } else {
                            // Login regolare, richiesta demo (metodo secondario)
                            this.isLoading = false;
                            this.eligibleForDemo = (credentials?.eligibleForDemo) ? credentials.eligibleForDemo : false;
                            this.invalidCredentials = true;
                        }

                    }
                } else {
                    this.authenticationService.logout();
                    this.isLoading = false;
                    this.isError = true;
                    this.errorMessage = '';
                }

            })
            .catch((error) => {
                this.isLoading = false;
                this.isError = true;
                // this.errorMessage = this.translateService.instant('ERR_LOGIN_INVALID_CREDENTIALS');
                this.errorMessage = error.message;
                // this.router.navigate(['/home']);
                console.error('loggedIn ERROR: ' + error.message);
            });
        // } else {
        //     this.isLoading = false;
        //     this.isError = true;
        //     this.errorMessage = this.translateService.instant('ERR_LOGIN_INVALID_CREDENTIALS');
        // }
    }

    // linkwithGoogle() {
    //     this.firebaseAuthService.linkwithGoogle().then((data) => {
    //         console.log('linkwithGoogle OK: ' + data);

    //     }).catch((error) => {
    //     });
    // }

    async loginStep2(privacyAccepted: string, newsletterChecked: boolean, userType: string, ageRange: string) {
        // Check ad unlock locked admin orders
        // const ok = await this.checkAndUnlockAdminOrders(); --> NO, l'admin deve accettarli esplicitamente dalla pagina di access-config
        // Set session ID
        const sessionId = this.smeService.getSessionId();
        const credentials = this.authenticationService.credentials;
        this.setSessionIdSubscription = this.smeService.setSessionId(sessionId).subscribe(() => {
            console.log('setSessionId OK');
            if (newsletterChecked && credentials) {

                this.smeService.addUserToNewsletter(credentials.fullName, credentials.username, [userType]).subscribe({
                    next: () => {
                        console.log('Inserimento Newsletter OK');
                        this.loginStep3(privacyAccepted, newsletterChecked, userType, ageRange);
                    },
                    error: (error) => {
                        console.log('Inserimento Newsletter FALLITO');
                        logger.error('loggedIn ERROR: ' + error.message);
                        this.loginStep3(privacyAccepted, false, userType, ageRange);
                    }
                });
            } else {
                const newsletter = (this.askPrivacy ? false : true);
                this.loginStep3(privacyAccepted, newsletter, userType, ageRange);
            }
        });
    }

    loginStep3(privacyAccepted: string, newsletterChecked: boolean, userType: string, ageRange: string) {
        const credentials = this.authenticationService.credentials;
        const lastLogin = new Date().toISOString();
        if (credentials) {
            this.setUserInfoSubscription = this.smeService.setUserInfo(
                credentials.firebaseUserId,
                credentials.username,
                (this.firebaseAuthService.hasGoogleProvider() ? credentials.username + '' : ''),
                '',
                credentials.fullName,
                lastLogin,
                privacyAccepted,
                newsletterChecked,
                userType,
                ageRange
            ).subscribe({
                next: () => {
                    console.log('setUserInfo OK');
                    this.loginEnded();
                },
                error: (error) => {
                    console.log('setUserInfo FAIL: ' + error.message);
                    this.loginEnded();
                }
            });
        }
    }

    loginEnded() {
        this.isLoading = false;
        this.authenticationService.isUserSignin = false;
        if (this.providerId === 'passwordless') {
            if (this.demoFromStart) {
                this.requestDemo();
            }
            localStorage.removeItem('demoFromStart');
            localStorage.setItem('isPasswordless', '1');
            // this.saveProfile();
            this.router.navigate(['/user-profile', this.user?.id]);
        } else {
            // this.firebaseAuthService.testAndSendVerificationMail();
            localStorage.removeItem('demoFromStart');
            this.authenticationService.redirectToRequestPage();
            if (this.iframeLoginService.isIframeLogging()) {
                window.close();
            }
        }
    }

    saveProfile() {
        this.isLoading = false;
        const dialogRef = this.dialog.open(UserProfileComponent);

        const user = {
            id: this.user.id,
            uid: this.user.id,
            email: this.user.email,
            displayName: this.user.fullName,
            picture: this.user.picture,
            refreshToken: this.authenticationService.getUserRefreshToken(),
            token: this.authenticationService.getUserAccessToken()
        }
        dialogRef.componentInstance.user = user;
        dialogRef.afterClosed().subscribe(result => {
            this.askPrivacy = false;
            this.invalidCredentials = true;
            if (this.demoFromStart) {
                //attiva
                this.requestDemo();
            } else {
                this.eligibleForDemo = true;

            }


        });
    }

    // checkAndUnlockAdminOrders() {
    //     return new Promise((resolve, reject) => {
    //         let credChanged = false;
    //         const credentials = this.authenticationService.credentials;
    //         if (credentials && credentials.admin && credentials.admin.length > 0) {
    //             const orderIds = [];
    //             for (let i = 0; i < credentials.admin.length; i++) {
    //                 const order = credentials.admin[i];
    //                 if (order.locked === true) {
    //                     orderIds.push(order.orderId);
    //                     if (credentials.admin[i].locked === true) {
    //                         credentials.admin[i].locked = false;
    //                         credChanged = true;
    //                     }
    //                 }
    //             }
    //             if (orderIds.length > 0) {
    //                 this.checkAndUnlockAdminOrdersSubscription = this.smeService.unlockOrders(orderIds).subscribe({
    //                     next: () => {
    //                         // Orders unlocked
    //                         console.log('Orders unlocked');
    //                         if (credChanged) {
    //                             this.authenticationService.setCredentials(credentials);
    //                         }
    //                         return resolve(true);
    //                     },
    //                     error: (error: any) => {
    //                         console.error('ERROR unlocking orders: ' + JSON.stringify(error));
    //                         return resolve(false);
    //                     }
    //                 });
    //             } else {
    //                 // No administered order to unlock
    //                 return resolve(true);
    //             }
    //         } else {
    //             // No orders administered
    //             return resolve(true);
    //         }
    //     });
    // }

    ngOnDestroy() {
        if (this.routeFragmentSubscription) { this.routeFragmentSubscription.unsubscribe(); }
        // if (this.getWhitelistSubscription) { this.getWhitelistSubscription.unsubscribe(); }
        if (this.logoutSubscription) { this.logoutSubscription.unsubscribe(); }
        if (this.setSessionIdSubscription) { this.setSessionIdSubscription.unsubscribe(); }
        if (this.checkAndUnlockAdminOrdersSubscription) { this.checkAndUnlockAdminOrdersSubscription.unsubscribe(); }
        if (this.setUserInfoSubscription) { this.setUserInfoSubscription.unsubscribe(); }
        if (this.hasUserAcceptedPrivacySubscription) { this.hasUserAcceptedPrivacySubscription.unsubscribe(); }
    }

    loginClick() {
        this.isError = false;
        this.logoutSubscription = this.authenticationService.logout().subscribe(() => {
            const redirectPage = (this.expired || this.doubleAccess ? '' : localStorage.getItem('redirectPage'));
            this.router.navigate(['login', redirectPage]);
        });
    }

    requestDemo() {
        this.isLoading = true;
        const credentials = this.authenticationService.credentials;
        if (credentials) {
            this.smeService.activateDemo(credentials.fullName, credentials.username).subscribe({
                next: (resp) => {
                    this.invalidCredentials = false;
                    this.isDemo = true;
                    this.loginStep1();
                },
                error: (error: any) => {
                    this.isError = true;
                    this.errorMessage = error.message;
                }
            });
        }
    }

}
