import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { UserProfileRoutingModule } from '../user-profile/user-profile.routing.module';
import { MatDialogModule } from '@angular/material/dialog';
import { SetFullnameComponent } from './set-fullname/set-fullname.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SetPasswordComponent } from '../login-form/set-password/set-password.component';



@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forRoot(),
        SharedModule,
        CoreModule,
        //FlexLayoutModule,
        MaterialModule,
        MatDialogModule,
        UserProfileRoutingModule,
        MatFormFieldModule,
        MatInputModule
    ],

    declarations: [

        UserProfileComponent,
        SetFullnameComponent,
        SetPasswordComponent,
    ]
})
export class UserProfileModule { }
