<h2 mat-dialog-title class="title-name">{{title}}</h2>
<div mat-dialog-content>
    <div class="dialog-preferences">
        <div class="item-title">

            <mat-icon color="primary" class="fa fa-font"></mat-icon>
            <span class="annotation-name" translate>MAIN_MENU_FORMAT-TEXT
            </span>
        </div>

        <div class="item-container">
            <div class="params-container">
                <div class="param-container">
                    <span translate>COLOR</span>
                    <app-button-color-picker pickerIcon="square" [toolTip]="fontColorTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedFontColor" (onChangeColor)="changeFontColor($event)" [colors]="colors" [showTransparent]="false"></app-button-color-picker>
                </div>
                <div class="param-container">
                    <span translate>MAIN_MENU_FORMAT-TEXT-FONTSIZE</span>
                    <button #fontsizebutton class="fontsize-button" mat-menu mat-icon-button color="primary" matTooltip="{{'FORMAT_NODE_TOOLBAR-FONT-SIZE' | translate}}" [matMenuTriggerFor]="fontsize">
                        <span>{{selectedFontSize}}</span></button>

                    <mat-menu #fontsize="matMenu" [overlapTrigger]="false" id="fontsize-target">
                        <div *ngFor="let size of fontSizes" mat-menu-item (click)="changeFontSize($event)">
                            <mat-icon *ngIf="selectedFontSize === size.value">check</mat-icon>
                            <span [ngClass]="selectedFontSize === size.value ? 'menu-item-name':'menu-item-name-spacer'">{{size.value}}</span>
                        </div>
                    </mat-menu>
                </div>
            </div>
            <div class="example">
                <span [style]="fontStyle" translate> MAIN_MENU_FORMAT-TEXT

                </span>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="item-title">
            <mat-icon color="primary" class="fa fa-paint-brush"></mat-icon>
            <span class="annotation-name" translate>MAIN_MENU_FILE-TEXT-BACKCOLOR</span>
            <div class="param-container">
                <mat-slide-toggle color="accent" [checked]="copyHighligthChecked" (change)="onCopyHighligthChecked($event)"></mat-slide-toggle>
                <div>{{'PASTE_IN_NOTEPAD' | translate}}</div>
            </div>
        </div>

        <div class="item-container">
            <div class="params-container">
                <div class="param-container">
                    <span translate>COLOR</span>
                    <app-button-color-picker pickerIcon="square" [toolTip]="higlightColorTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedHighlightColor" (onChangeColor)="changeHighlightColor($event)" [colors]="highlightColors" [showTransparent]="false"></app-button-color-picker>
                </div>
                <!-- <div class="param-container">
                    <mat-slide-toggle color="accent" [checked]="copyHighligthChecked" (change)="onCopyHighligthChecked($event)"></mat-slide-toggle>
                    <div>{{'COPY_HIGHLIGHT' | translate}}</div>
                </div> -->

            </div>
            <div class="example">
                <span [style]="highlightStyle" translate> MAIN_MENU_FORMAT-TEXT

                </span>
            </div>
        </div>

        <mat-divider></mat-divider>
        <div class="item-title">
            <mat-icon color="primary">format_underlined</mat-icon>
            <span translate class="annotation-name">MAIN_MENU_FORMAT-TEXT-EFFECTS-UNDERLINE</span>
            <div class="param-container">
                <mat-slide-toggle [checked]="copyUnderlineChecked" color="accent" (change)="onCopyUnderlineChecked($event)"></mat-slide-toggle>
                <div>{{'PASTE_IN_NOTEPAD' | translate}}</div>
            </div>
        </div>

        <div class="item-container">
            <div class="params-container">
                <div class="param-container">
                    <span translate>COLOR</span>
                    <app-button-color-picker pickerIcon="square" [toolTip]="underlineColorTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedUnderlineColor" (onChangeColor)="changeUnderlineColor($event)" [colors]="colors" [showTransparent]="false"></app-button-color-picker>
                </div>
                <div class="param-container">
                    <span translate>MAIN_MENU_FORMAT-TEXT-FONTSIZE</span>
                    <mat-slider min="1" max="5" step="1" color="primary" discrete="true" (input)="changeUnderlineWidth($event)">
                        <input matSliderThumb [value]="underlineWidth" />
                    </mat-slider>
                </div>
                <!-- <div class="param-container">
                    <mat-slide-toggle [checked]="copyUnderlineChecked" color="accent" (change)="onCopyUnderlineChecked($event)"></mat-slide-toggle>
                    <div>{{'COPY_UNDERLINE' | translate}}</div>
                </div> -->

            </div>
            <div class="example">
                <span [style]="underlineStyle" translate> MAIN_MENU_FORMAT-TEXT

                </span>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="item-title">
            <mat-icon color="primary">gesture</mat-icon>
            <span translate class="annotation-name">BRUSH</span>
        </div>
        <div class="item-container">
            <div class="params-container">
                <div class="param-container">

                    <span translate>COLOR</span>
                    <app-button-color-picker pickerIcon="square" [toolTip]="brushColorTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedBrushColor" (onChangeColor)="changeBrushColor($event)" [colors]="colors" [showTransparent]="false"></app-button-color-picker>
                    <span translate>OPACITY</span>
                    <mat-slider min="0" [max]="100" step="1" discrete="true" (input)="changeBrushOpacity($event)">
                        <input matSliderThumb [value]="brushOpacity" />
                    </mat-slider>
                </div>
                <!-- <div class="param-container">
                    <span translate>OPACITY</span>
                    <mat-slider min="0" [max]="100" step="1" discrete="true" (input)="changeBrushOpacity($event)">
                        <input matSliderThumb [value]="brushOpacity" />
                    </mat-slider>
                </div> -->

                <div class="param-container">
                    <span translate>MAIN_MENU_FORMAT-TEXT-FONTSIZE</span>
                    <mat-slider min="5" max="40" step="5" discrete="true" (input)="changeBrushWidth($event)">
                        <input matSliderThumb [value]="brushWidth" />
                    </mat-slider>
                </div>
            </div>
            <span class="example" [style]="brushStyle"></span>
        </div>
        <mat-divider></mat-divider>
        <div class="item-title">
            <mat-icon color="primary" class="fa fa-window-maximize"></mat-icon>
            <span translate class="annotation-name">FORMAT_NODE_TOOLBAR-RECTANGLE</span>
        </div>
        <div class="item-container">
            <div class="params-container">
                <div class="param-container">
                    <span translate>COLOR</span>
                    <app-button-color-picker pickerIcon="square" [toolTip]="rectColorTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedRectColor" (onChangeColor)="changeRectColor($event)" [colors]="colors" [showTransparent]="false"></app-button-color-picker>
                    <span translate>OPACITY</span>
                    <mat-slider min="0" [max]="100" step="1" discrete="true" (input)="changeRectOpacity($event)">
                        <input matSliderThumb [value]="rectOpacity" />
                    </mat-slider>
                </div>
                <div class="param-container">
                    <span translate>BORDER_COLOR</span>
                    <app-button-color-picker pickerIcon="square" [toolTip]="rectBorderColorTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedRectBorderColor" (onChangeColor)="changeRectBorderColor($event)" [colors]="colors" [showTransparent]="false"></app-button-color-picker>
                    <!-- <span translate>MAIN_MENU_FORMAT-TEXT-FONTSIZE</span> -->
                    <!-- <mat-slider min="1" max="10" step="1" discrete="true" (input)="changeRectWidth($event)">
                        <input matSliderThumb [value]="rectWidth" />
                    </mat-slider> -->
                </div>

                <!-- <div class="param-container">
                    <span translate>OPACITY</span>
                    <mat-slider min="0" [max]="100" step="1" discrete="true" (input)="changeRectOpacity($event)">
                        <input matSliderThumb [value]="rectOpacity" />
                    </mat-slider>
                </div> -->
                <!-- <div class="param-container">
                    <span translate>MAIN_MENU_FORMAT-TEXT-FONTSIZE</span>
                    <mat-slider min="1" max="10" step="1" discrete="true" (input)="changeRectWidth($event)">
                        <input matSliderThumb [value]="rectWidth" />
                    </mat-slider>
                </div> -->

            </div>
            <div class="example" [style]="rectangleStyle"></div>
        </div>
        <mat-divider></mat-divider>
        <div class="item-title">
            <mat-icon color="primary" class="annotation-icon fa fa-circle"></mat-icon>
            <span translate class="annotation-name">CIRCLE</span>
        </div>
        <div class="item-container">
            <div class="params-container">
                <div class="param-container">
                    <span translate>COLOR</span>
                    <app-button-color-picker pickerIcon="square" [toolTip]="circleColorTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedCircleColor" (onChangeColor)="changeCircleColor($event)" [colors]="colors" [showTransparent]="false"></app-button-color-picker>
                    <span translate>OPACITY</span>
                    <mat-slider min="0" [max]="100" step="1" discrete="true" (input)="changeCircleOpacity($event)">
                        <input matSliderThumb [value]="circleOpacity" />
                    </mat-slider>
                </div>
                <div class="param-container">
                    <span translate>BORDER_COLOR</span>
                    <app-button-color-picker pickerIcon="square" [toolTip]="circleBorderColorTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedCircleBorderColor" (onChangeColor)="changeCircleBorderColor($event)" [colors]="colors" [showTransparent]="false"></app-button-color-picker>
                    <!-- <span translate>MAIN_MENU_FORMAT-TEXT-FONTSIZE</span> -->
                    <!-- <mat-slider min="1" max="10" step="1" discrete="true" (input)="changeCircleWidth($event)">
                        <input matSliderThumb [value]="circleWidth" />
                    </mat-slider> -->
                </div>
                <!-- <div class="param-container">
                    <span translate>OPACITY</span>
                    <mat-slider min="0" [max]="100" step="1" discrete="true" (input)="changeCircleOpacity($event)">
                        <input matSliderThumb [value]="circleOpacity" />
                    </mat-slider>
                </div>
                <div class="param-container">
                    <span translate>MAIN_MENU_FORMAT-TEXT-FONTSIZE</span>
                    <mat-slider min="1" max="10" step="1" discrete="true" (input)="changeCircleWidth($event)">
                        <input matSliderThumb [value]="circleWidth" />
                    </mat-slider>
                </div> -->
            </div>
            <div class="example" [style]="circleStyle"></div>
        </div>
        <mat-divider></mat-divider>
        <div class="item-title">
            <mat-icon color="primary">call_made</mat-icon>
            <span translate class="annotation-name">PREF_EDGE</span>
        </div>
        <div class="item-container">
            <div class="params-container">
                <div class="param-container">
                    <span translate>COLOR</span>
                    <app-button-color-picker pickerIcon="square" [toolTip]="arrowColorTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedArrowColor" (onChangeColor)="changeArrowColor($event)" [colors]="colors" [showTransparent]="false"></app-button-color-picker>
                    <span translate>OPACITY</span>
                    <mat-slider min="0" [max]="100" step="1" discrete="true" (input)="changeArrowOpacity($event)">
                        <input matSliderThumb [value]="arrowOpacity" />
                    </mat-slider>
                </div>
                <!-- <div class="param-container">
                    <span translate>OPACITY</span>
                    <mat-slider min="0" [max]="100" step="1" discrete="true" (input)="changeArrowOpacity($event)">
                        <input matSliderThumb [value]="arrowOpacity" />
                    </mat-slider>
                </div> -->
                <div class="param-container">
                    <span translate>MAIN_MENU_FORMAT-TEXT-FONTSIZE</span>
                    <mat-slider min="1" max="10" step="1" discrete="true" (input)="changeArrowWidth($event)">
                        <input matSliderThumb [value]="arrowWidth" />
                    </mat-slider>
                </div>
            </div>
            <mat-icon class="example arrow-style" [ngStyle]="{'color': arrowColor}">call_made</mat-icon>
        </div>
        <mat-divider></mat-divider>
        <div class="item-title">
            <mat-icon color="primary" class="fa fa-minus"></mat-icon>
            <span translate class="annotation-name">LINE</span>
        </div>
        <div class="item-container">
            <div class="params-container">
                <div class="param-container">
                    <span translate>COLOR</span>
                    <app-button-color-picker pickerIcon="square" [toolTip]="lineColorTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedLineColor" (onChangeColor)="changeLineColor($event)" [colors]="colors" [showTransparent]="false"></app-button-color-picker>

                    <span translate>OPACITY</span>
                    <mat-slider min="0" [max]="100" step="1" discrete="true" (input)="changeLineOpacity($event)">
                        <input matSliderThumb [value]="lineOpacity" />
                    </mat-slider>
                </div>
                <!-- <div class="param-container">
                    <span translate>OPACITY</span>
                    <mat-slider min="0" [max]="100" step="1" discrete="true" (input)="changeLineOpacity($event)">
                        <input matSliderThumb [value]="lineOpacity" />
                    </mat-slider>
                </div> -->
                <div class="param-container">
                    <span translate>MAIN_MENU_FORMAT-TEXT-FONTSIZE</span>
                    <mat-slider min="1" max="10" step="1" discrete="true" (input)="changeLineWidth($event)">
                        <input matSliderThumb [value]="lineWidth" />
                    </mat-slider>
                </div>
            </div>
            <!-- <span class="example arrow-style fa-minus"></span> -->
            <svg style="width: 40px; height: 40px;">
                <line x1="0" y1="40px" x2="40px" y2="0" [style]="lineStyle" />
            </svg>

        </div>
        <mat-divider></mat-divider>
        <div class="item-title">
            <mat-icon color="primary" class="annotation-icon">check</mat-icon>
            <span translate class="annotation-name">CHECK</span>
        </div>
        <div class="item-container">
            <div class="params-container">
                <div class="param-container">

                    <span translate>COLOR</span>
                    <app-button-color-picker pickerIcon="square" [toolTip]="checkColorTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedCheckColor" (onChangeColor)="changeCheckColor($event)" [colors]="colors" [showTransparent]="false"></app-button-color-picker>
                    <!-- <span translate>OPACITY</span>
                    <mat-slider min="0" [max]="100" step="1" discrete="true" (input)="changeCheckOpacity($event)">
                        <input matSliderThumb [value]="checkOpacity" />
                    </mat-slider> -->
                </div>
                <!-- <div class="param-container">
                    <span translate>OPACITY</span>
                    <mat-slider min="0" [max]="100" step="1" discrete="true" (input)="changeCheckOpacity($event)">
                        <input matSliderThumb [value]="checkOpacity" />
                    </mat-slider>
                </div> -->
                <!-- <div class="param-container">
                    <span translate>MAIN_MENU_FORMAT-TEXT-FONTSIZE</span>
                    <mat-slider min="1" max="10" step="1" discrete="true" (input)="changeCheckWidth($event)">
                        <input matSliderThumb [value]="checkWidth" />
                    </mat-slider>
                </div> -->
            </div>
            <mat-icon class="example arrow-style" [ngStyle]="{'color': checkColor}">check</mat-icon>
        </div>
    </div>
</div>

<mat-dialog-actions class="bottom-panel">
    <button mat-stroked-button (click)="restoreDefault()">
        <span translate>PREF_RESET</span>
    </button>
    <div class="button-spacer"></div>
    <button mat-stroked-button [mat-dialog-close]="true" (click)="closeWin()">
        <span translate>CANCEL</span>
    </button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true" (click)="save()" cdkFocusInitial>
        <span translate>OK</span>
    </button>
</mat-dialog-actions>
