import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '../logger.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TokenInterceptor } from '../http/token.interceptor';
import { I18nService } from '../i18n.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { SmeService } from '../sme/sme.service';
import { Subscription, Unsubscribable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


declare let google: any;


// const routes = {
//   apiCheckGoogleEmail: '/api/checkGoogleEmail'
// };

const APP_ID = environment.configFirebase.messagingSenderId;
const CLIENT_ID = environment.firebaseClientId;
// const SCOPES: string[] = [
//   'https://www.googleapis.com/auth/drive', // Accesso ai file presenti nel Drive dell'utente e a quelli condivisi pubblicamente
//   'https://www.googleapis.com/auth/classroom.courses.readonly' // Accesso ai corsi di Classroom (bottone di condivisione)
//   //
//   // 'https://www.googleapis.com/auth/drive.file', // Accesso ai soli file gestiti dall'app (problemi di share pubblico in attesa di validazione console google)
//   // 'https://www.googleapis.com/auth/drive.metadata',
//   // 'https://www.googleapis.com/auth/drive.appdata',
//   // // 'https://www.googleapis.com/auth/drive.scripts',
//   // // 'https://www.googleapis.com/auth/drive.metadata.readonly',
//   // // 'https://www.googleapis.com/auth/drive.photos.readonly',
//   // // 'https://www.googleapis.com/auth/drive.readonly',
//   // 'https://www.googleapis.com/auth/userinfo.profile',
//   // 'https://www.googleapis.com/auth/userinfo.email',
//   // 'https://www.googleapis.com/auth/classroom.courses.readonly'
// ];

const TOKEN_EXPIRATION_LIMIT = 1800; // Mezz'ora

const logger: Logger = new Logger('GoogleService');

declare let gapi: any;
// var gothic: any;

// JHUBA: Non usare (login flow = redirect)
export interface GoogleUser {
    getBasicProfile(): {
        getId(): any,
        getEmail(): any,
        getName(): any,
        getImageUrl(): any
    };
}

@Injectable({
    providedIn: 'root'
})
export class GoogleService {

    private gapiLoading = false;
    public apiLoaded = false;
    public tokenClient: any;
    public googleError: any;
    // private access_token: any;
    picker: any;
    public fileChoosen = new EventEmitter<any>();
    public folderChoosen = new EventEmitter<any>();
    public accessTokebSuscription: Subscription | undefined;


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private zone: NgZone,
        private i18nService: I18nService,
        private translateService: TranslateService,
        private http: HttpClient,
        private smeService: SmeService
    ) {
        this.googleError = '';
    }


    public signOut(): void {
        // gapi.auth2.signOut();
        // (window as any).gothic.signOut();
    }

    public loadPicker(): Promise<void> {
        return new Promise((resolve) => {
            gapi.load('picker', {
                'callback': () => {
                    this.zone.run(() => {
                        resolve();
                    });
                }
            });
        });
    }

    // public isValidScopes(scopes: any): boolean {
    //   return this.checkScopes(scopes);
    // }

    private loadGapiLib(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (gapi && gapi.client && gapi.client.drive) {
                this.gapiLoading = false;
                return resolve(true);
            } else {
                if (!this.gapiLoading) {
                    this.gapiLoading = true;
                    gapi.load('client', (err1: any, res1: any) => {
                        gapi.client.load('drive', 'v3', (err2: any, res2: any) => {
                            this.gapiLoading = false;
                            return resolve(true);
                        });
                    });
                }
            }
        });
    }



    private doGoogleTestOperation(accessToken: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.loadGapiLib().then((loaded: boolean) => {
                if (loaded) {
                    gapi.client.setToken({ access_token: accessToken });
                    const request = gapi.client.drive.files.list({ pageSize: 1, fields: 'kind' });
                    this.googleError = '';
                    request.execute((resp: any) => {
                        if (resp.kind) {
                            return resolve(true);
                        } else {
                            this.googleError = resp.code;
                            return resolve(false);
                        }
                    });
                }
            });
        });
    }



    public checkScopes(grantedScopes: any): boolean {
        if (!grantedScopes) {
            return false;
        } else {
            const grantScopes = grantedScopes.split(' ');
            let valid = true;
            const needScopes = environment.scope.split(' ');
            for (let i = 0; i < needScopes.length; i++) {
                const needScope = needScopes[i];
                if (grantScopes.indexOf(needScope) === -1) {
                    valid = false;
                }
            }
            return valid;
        }
    }

    // non funziona
    //   public checkGoogleEmail(email: string): any {
    //     const body = {
    //       email: email
    //     };
    //     return this.http.post<Response>(routes.apiCheckGoogleEmail, body, { headers: TokenInterceptor.buildNoTokenHeaders() })
    //       .pipe(map((res: Response) => res));
    //   }

    public findGoogleFileInfo(googleId: string) {
        return new Promise((resolve, reject) => {
            gapi.client.load('drive', 'v3', () => {
                const request = gapi.client.drive.files.get({
                    fileId: googleId,
                    fields: 'name,thumbnailLink'
                });
                request.execute((resp: any) => {
                    return resolve({ name: resp.name, thumbnailLink: resp.thumbnailLink });
                });
            });
        });
    }

    // Finds or creates SuperMappeX folder and return folder id

    public getSMXFolderIds() {
        const folderIds = { smx: '', docs: '', classroom: '' };
        let accessToken = '';
        return new Promise((resolve, reject) => {
            Promise.resolve().then(() => {
                return this.smeService.getAccessTokenPromise();
            }).then((_accessToken: string) => {
                accessToken = _accessToken;
                return this.loadGapiLib();
            }).then((loaded: boolean) => {
                if (loaded) {
                    gapi.client.setToken({ access_token: accessToken });
                }
                return this.getSmxFolderId('root', 'SuperMappeX');
            }).then((_smxFolderId: string) => {
                folderIds.smx = _smxFolderId;
                return this.getSmxFolderId(folderIds.smx, 'docs');
            }).then((_docsFolderId: any) => {
                folderIds.docs = _docsFolderId;
                return this.getSmxFolderId('root', 'Classroom SMX');
            }).then((_classroomFolderId: any) => {
                folderIds.classroom = _classroomFolderId;
                return resolve(folderIds);
            }).catch((smxError: any) => {
                console.log(`ERROR GOOGLE DRIVE getSMXFolderId: ${smxError}`);
                return reject(smxError);
            });
        });
    }

    public getClassroomFolderId() {
        let accessToken = '';
        return new Promise((resolve, reject) => {
            Promise.resolve().then(() => {
                return this.smeService.getAccessTokenPromise();
            }).then((_accessToken: string) => {
                accessToken = _accessToken;
                return this.loadGapiLib();
            }).then((loaded: boolean) => {
                if (loaded) {
                    gapi.client.setToken({ access_token: accessToken });
                }
                return this.getSmxFolderId('root', 'Classroom SMX');
            }).then((_classroomFolderId: any) => {
                return resolve(_classroomFolderId);
            }).catch((smxError: any) => {
                console.log(`ERROR GOOGLE DRIVE getSMXFolderId: ${smxError}`);
                return reject(smxError);
            });
        });
    }


    private getSmxFolderId(parentId: string, folderName: string): Promise<string> {
        return new Promise((resolve, reject) => {
            Promise.resolve().then(() => {
                return this.getFolderId(parentId);
            }).then((_parentId: string) => {
                parentId = _parentId;
                return this.listSMXFolders(folderName);
            }).then((_smxFolderId: any) => {
                return this.checkSMXFolders(parentId, _smxFolderId);
            }).then((_smxFolderId: string) => {
                if (_smxFolderId) {
                    return Promise.resolve(_smxFolderId);
                } else {
                    return this.createSMXFolder(folderName, parentId);
                }
            }).then((_smxFolderId: string) => {
                return resolve(_smxFolderId);
            });
        });
    }

    private getFolderId(folderId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            gapi.client.drive.files.get(
                { fileId: folderId }
            ).then((res: any) => {
                return resolve(res.result.id);
            }).catch((err: any) => {
                const error = err.result;
                const smxError = {
                    code: err.response.status + ' ' + err.response.statusText,
                    httpCode: err.response.status,
                    message: error.message,
                    reason: error.reason
                };
                console.error(`[getRootId] Error: ${JSON.stringify(smxError)}`);
                return reject(smxError);
            });
        });
    }

    private listSMXFolders(folderName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            gapi.client.drive.files.list(
                {
                    q: 'name=\'' + folderName + '\' and mimeType=\'application/vnd.google-apps.folder\' and trashed=false',
                    fields: 'files(id,name,mimeType,parents,ownedByMe)'
                }
            ).then((res: any) => {
                return resolve(res.result.files);
            }).catch((err: any) => {
                const error = err;
                const smxError = {
                    code: err.response.status + ' ' + err.response.statusText,
                    httpCode: err.response.status,
                    message: error.message,
                    reason: error.reason
                };
                console.error(`[listSMXFolders] Error: ${JSON.stringify(smxError)}`);
                return reject(smxError);
            });
        });
    }

    private checkSMXFolders(parentId: string, folders: any): Promise<string> {
        return new Promise((resolve, reject) => {
            let folderId = '';
            if (parentId !== '' && folders && folders.length > 0) {
                for (let i = 0; i < folders.length; i++) {
                    const folder = folders[i];
                    if (folder.ownedByMe && folder.parents && folder.parents.length > 0) {
                        for (let j = 0; j < folder.parents.length; j++) {
                            const parent = folder.parents[j];
                            if (parent === parentId) {
                                folderId = folder.id;
                            }
                        }
                    }
                }
            }
            return resolve(folderId);
        });
    }

    private createSMXFolder(name: string, parentId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const fileMetadata = {
                name: name,
                parents: [parentId],
                mimeType: 'application/vnd.google-apps.folder',
            };
            gapi.client.drive.files.create({ resource: fileMetadata, fields: 'id', })
                .then((res: any) => {
                    return resolve(res.result.id);
                }).catch((err: any) => {
                    const error = err.errors[0];
                    const smxError = {
                        code: err.response.status + ' ' + err.response.statusText,
                        httpCode: err.response.status,
                        message: error.message,
                        reason: error.reason
                    };
                    console.error(`[createSMXFolder] Error: ${JSON.stringify(smxError)}`);
                    return reject(smxError);
                });
        });
    }

    public createPickerSearchImage(accessToken: string, language: string, textToSearch: string) {
        const view = new google.picker.ImageSearchView();
        view.setQuery(textToSearch);
        const picker = new google.picker.PickerBuilder()
            .addView(view)
            // .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
            .setAppId(APP_ID)
            .setOAuthToken(accessToken)
            .setLocale(language)
            // .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .setCallback(this.onPickerSearchChosen.bind(this))
            .build();
        if (this.picker) { this.closePicker(); }
        this.picker = picker;
        this.picker.setVisible(true);
    }

    public createVideoPicker(accessToken: string, language: string, textToSearch: string) {
        const view = new google.picker.VideoSearchView();
        view.setSite('https://www.youtube.com/'); // google.picker.VideoSearchView.YOUTUBE); <-- non funziona!!!
        view.setQuery(textToSearch);
        const picker = new google.picker.PickerBuilder()
            .addView(view)
            // .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
            .setAppId(APP_ID)
            .setOAuthToken(accessToken)
            .setLocale(language)
            // .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .setCallback(this.onPickerSearchChosen.bind(this))
            .build();
        if (this.picker) { this.closePicker(); }
        this.picker = picker;
        this.picker.setVisible(true);
    }

    onPickerSearchChosen(data: any) {
        this.zone.run(() => {
            const action = data[google.picker.Response.ACTION];
            if (action === google.picker.Action.CANCEL) {
                this.closePicker();
                this.fileChoosen.emit({ fileId: '' });
            } else if (action === google.picker.Action.PICKED) {
                this.closePicker();
                const doc = data[google.picker.Response.DOCUMENTS][0];
                let imageUrl = '';
                if (doc.thumbnails.length > 0) {
                    imageUrl = doc.thumbnails[doc.thumbnails.length - 1].url;
                }
                const url = doc[google.picker.Document.URL];
                const name = doc.name;
                const fileId: string = doc[google.picker.Document.ID];
                const mimeType: string = doc.mimeType;
                const resourceKey: string = doc.resourceKey;
                this.fileChoosen.emit({ fileId: fileId, name: name, mimeType: mimeType, imageUrl: imageUrl, url: url, resourceKey: resourceKey });
            }
        });
    }

    public createPicker(accessToken: string, picker: any, language: string, imagesOnly: boolean): void {
        if (!picker) {
            const filterType = (imagesOnly ? google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS : google.picker.ViewId.DOCS);
            const view = new google.picker.DocsView(filterType).setParent('root').setIncludeFolders(true);

            picker = new google.picker.PickerBuilder()
                .addView(view)
                .setAppId(APP_ID)
                .setOrigin(window.origin)
                .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
                .setOAuthToken(accessToken)
                .setLocale(language)
                .setCallback(this.onPickerChosen.bind(this))
                .build();
            this.picker = picker;
            this.picker.setVisible(true);
        }
    }


    public createPdfPicker(accessToken: string, picker: any, language: string): void {
        if (!picker) {
            const filterType = google.picker.ViewId.DOCS;
            const view = new google.picker
                .DocsView(google.picker.ViewId.DOCS)
                .setParent('root')

                .setIncludeFolders(true)
                .setQuery('*.pdf');
            view.setMimeTypes('application/pdf');
            picker = new google.picker.PickerBuilder()
                .addView(view)
                .setAppId(APP_ID)
                .setOrigin(window.origin)
                .setSelectableMimeTypes('application/pdf')
                .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
                .setOAuthToken(accessToken)
                .setLocale(language)
                .setCallback(this.onPickerChosen.bind(this))
                .build();
            this.picker = picker;
            this.picker.setVisible(true);
        }
    }

    public createFolderPicker(accessToken: string, picker: any, language: string): void {


        // if (!picker) {

        const titlePicker = this.translateService.instant('SELECT_FOLDER');

        const view = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
            .setParent('root')
            .setMimeTypes('application/vnd.google-apps.folder')
            .setSelectFolderEnabled(true);
        // const view = new google.picker.DocsView(google.picker.ViewId.FOLDERS);

        // view.setMimeTypes('application/vnd.google-apps.folder');
        // view.setSelectFolderEnabled(true);
        // view.setQuery('Il Mio Drive');

        const APP_ID = environment.configFirebase.messagingSenderId;
        picker = new google.picker.PickerBuilder()
            .addView(view)
            .setAppId(APP_ID)
            .setOrigin(window.origin)
            .setOAuthToken(accessToken)
            .setTitle(titlePicker)
            .setLocale(language)
            .setCallback(this.onPickerFolderChosen.bind(this))
            .build();
        this.picker = picker;
        this.picker.setVisible(true);

        // }
    }
    onPickerFolderChosen(data: any) {
        this.zone.run(() => {
            const action = data[google.picker.Response.ACTION];
            if (action === google.picker.Action.CANCEL) {
                this.closePicker();
                this.folderChoosen.emit({ foldeId: '' });
            } else if (action === google.picker.Action.PICKED) {
                this.closePicker();
                const doc = data[google.picker.Response.DOCUMENTS][0];
                const googlefolderId = doc['id'];

                const resourceKey: string = doc.resourceKey;
                this.folderChoosen.emit({ folderId: googlefolderId });
            }
        });

    }


    public createSMEPicker(accessToken: string, picker: any, language: string): void {
        if (!picker) {
            const view = new google.picker
                .DocsView(google.picker.ViewId.DOCS)
                .setParent('root')
                .setIncludeFolders(true)
                .setQuery('*.sme');

            picker = new google.picker.PickerBuilder()
                .addView(view)
                .setAppId(APP_ID)
                .setOrigin(window.origin)
                .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
                .setOAuthToken(accessToken)
                .setLocale(language)
                .setCallback(this.onPickerChosen.bind(this))
                .build();
            this.picker = picker;
            this.picker.setVisible(true);
        }
    }


    closePicker() {
        if (this.picker) {
            this.picker.setVisible(false);
        }
    }


    onPickerChosen(data: any) {
        this.zone.run(() => {
            const action = data[google.picker.Response.ACTION];
            if (action === google.picker.Action.CANCEL) {
                this.closePicker();
                this.fileChoosen.emit({ fileId: '' });
            } else if (action === google.picker.Action.PICKED) {
                this.closePicker();
                const doc = data[google.picker.Response.DOCUMENTS][0];
                const url = doc[google.picker.Document.URL];
                const name = doc.name;
                const fileId: string = doc[google.picker.Document.ID];
                const mimeType: string = doc.mimeType;
                const resourceKey: string = doc.resourceKey;
                this.fileChoosen.emit({ fileId: fileId, name: name, mimeType: mimeType, resourceKey: resourceKey, url: url });
            }
        });
    }

    // isFirstLoggedUser() {
    //   return new Promise((resolve, reject) => {
    //     gapi.auth.authorize({ 'client_id': CLIENT_ID, 'scope': environment.scope, 'immediate': true, 'approval_prompt': 'auto' },
    //       (authResult: any) => {
    //         if (authResult && authResult.authuser) {
    //           return resolve(authResult.authuser === '0');
    //         } else {
    //           return resolve(false);
    //         }
    //       });
    //   });
    // }

    isFirstLoggedUser() {
        return new Promise((resolve, reject) => {
            this.smeService.getAuthUserPromise().then((authUser: string) => {
                const isFirstLoggedUser = (authUser === '0');
                return resolve(isFirstLoggedUser);
            }).catch((error: any) => {
                return reject(error);
            });
        });
    }

    showShareDialog(googleFileId: string) {
        this.accessTokebSuscription = this.smeService.getAccessToken()
            .subscribe((_accessToken: string) => {

                // console.log(`showShareDialog APPID: ${APP_ID} fileId: ${googleFileId} token:...`);
                // gapi.auth.authorize({ 'client_id': CLIENT_ID, 'scope': SCOPES, 'immediate': true, 'approval_prompt': 'auto' },
                //   (authResult: any) => {
                //     if (authResult.authuser === '0') {
                gapi.load('drive-share', () => {
                    const s = new gapi.drive.share.ShareClient(APP_ID);
                    s.setOAuthToken(_accessToken);
                    s.setItemIds([googleFileId]);
                    s.showSettingsDialog();
                });
                //   } else {
                //     console.error(`GDRIVE SHARE ERROR: unsupported multiple logged users`);
                //   }
                // }
                // );
            });
    }

    initTokenClient(email: string) {
        const loginUri = '/api/loggedin2gen/';
        const tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: environment.firebaseClientId,
            scope: environment.scope,

            hint: email,
            auto_prompt: false,
            ux_mode: 'redirect',
            redirect_uri: loginUri
        });
        tokenClient.requestAccessToken();

    }

    initCodeClient(email: string) {
        const loginUri = '/api/loggedin2gen/';

        const client = google.accounts.oauth2.initCodeClient({
            client_id: environment.firebaseClientId,
            scope: environment.scope,
            hint: email,
            auto_prompt: false,
            ux_mode: 'redirect',
            redirect_uri: loginUri,
            prompt: '',
        });
        client.requestCode();
    }

    uploadToDrive(smeBlob: Blob, fileName: string): Promise<string> {
        let accessToken = '';
        return new Promise((resolve, reject) => {
            Promise.resolve().then(() => {
                return this.smeService.getAccessTokenPromise();
            }).then((_accessToken: string) => {
                accessToken = _accessToken;
                return this.loadGapiLib();
            }).then((loaded: boolean) => {
                if (loaded) {
                    gapi.client.setToken({ access_token: accessToken });
                }
                return this._uploadToDrive(smeBlob, fileName, accessToken);
            }).then((googleUri) => {
                return resolve(googleUri);
            }).catch((smxError: any) => {
                console.log(`ERROR GOOGLE DRIVE getSMXFolderId: ${smxError}`);
                return reject(smxError);
            });
        });
    }

    _uploadToDrive(smeBlob: Blob, fileName: string, accessToken: string): Promise<string> {
        return new Promise((resolve, reject) => {
            //     const fileMetadata = {
            //         name: fileName,
            //         parents: ['root']
            //     }
            //     const form = new FormData();
            //     form.append('metadata', new Blob([JSON.stringify(fileMetadata)], { type: 'application/json' }));
            //     form.append('file', smeBlob);
            //     fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
            //         method: 'POST',
            //         headers: new Headers({ 'Authorization': 'Bearer ' + gapi.auth.getToken().access_token }),
            //         body: form
            //     }).then(res =>
            //         res.json()
            //     ).then(res =>
            //         console.log(res)
            //     );
            // });

            const options = {
                path: `https://www.googleapis.com/upload/drive/v3/files`,
                method: 'POST',
                params: {},
                body: smeBlob
            };
            gapi.client.request(options).execute((res: any) => {
                if (res && res.id) {
                    const googleUri = `https://drive.google.com/file/d/${res.id}?usp=drive_link`;
                    return resolve(googleUri);
                } else {
                    return reject('NO GOOGLE ID');
                }
            });
        });
    }

    // getAccessToken() {
    //   // Re-entrant function to request user consent.
    //   // Returns an access token to the callback specified in google.accounts.oauth2.initTokenClient
    //   // Use a user gesture to call this function and obtain a new, valid access token
    //   // when the previous token expires and a 401 status code is returned by Google API calls.
    //   return new Promise((resolve, reject) => {
    //     const creds = this.authenticationService.credentials;
    //     if (creds.accessToken) {
    //       return resolve(creds.accessToken);
    //     }
    //     else {
    //       return reject('');
    //     }
    //     // this.doGoogleTestOperation(creds.accessToken).then((valid) => {
    //     //   if (valid) {


    //     //     return resolve(creds.accessToken);
    //     //   } else {

    //     //     if (this.googleError === 401) {
    //     //       // this.router.navigate(['loggedin', window.location.href]);
    //     //       return reject(this.googleError);
    //     //     } else {
    //     //       const email = (creds) ? creds.googleUserEmail : '';
    //     //       this.tokenClient = google.accounts.oauth2.initTokenClient({
    //     //         client_id: CLIENT_ID,
    //     //         scope: environment.scope,
    //     //         hint: email,
    //     //         auto_prompt: false,
    //     //         prompt: '',


    //     //         callback: (tokenResponse: any) => {
    //     //           creds.accessToken = tokenResponse.access_token;

    //     //           return resolve(tokenResponse.access_token);

    //     //         }
    //     //       });
    //     //     }
    //     //   }
    //     //   this.tokenClient.requestAccessToken();

    //     // });
    //   });
    // }



}

