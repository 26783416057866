<div class="invalid-credentials-container">
    <div class="logo-container">
        <img routerLink="/home" class="logo-image" src="../../assets/home/SMX_Doc_Header.png?v=1" />
    </div>
    <h1 class="title" translate>IC_SUBTITLE</h1>
    <div class="content-container">
        <mat-card class="left-content">
            <button color="warn" mat-flat-button (click)="goToWebsiteClick('invalid-credentials','school')" type="submit">{{'IC_BTN_WEBSITE_SCHOOL' | translate}}</button>
            <button color="warn" mat-flat-button (click)=" goToWebsiteClick('invalid-credentials','private')" type="submit">{{'IC_BTN_WEBSITE_PRIVATE' | translate}}</button>
            <div class="info-text info-text-top-spacer" translate>IC_WEBSITE</div>
        </mat-card>
        <!--<div class="center-content"></div>-->
        <mat-card *ngIf="eligibleForDemo" class="right-content">
            <button color="accent" mat-flat-button (click)="demoClick()">{{'IC_DEMO_REQUEST_BUTTON' | translate}}</button>
            <div class="info-text info-text-bold info-text-medium info-text-top-spacer" translate>IC_REQUEST_DEMO_1</div>
            <div class="info-text" translate>IC_REQUEST_DEMO_2</div>
            <div *ngIf="userName" class="info-text" translate>IC_USER_NAME</div>
            <div *ngIf="userName" class="info-text info-text-bold"> {{userName}} </div>
            <div *ngIf="userName" class="info-text" translate>IC_USER_EMAIL</div>
            <div *ngIf="!userName" class="info-text" translate>IC_USER_NAME_EMAIL</div>
            <div class="info-text info-text-bold email"> {{userEmail}}</div>
        </mat-card>
    </div>
    <div class="footer">
        <div class="footer-text" translate>IC_LOGOUT</div>
        <div class="footer-text-link" (click)="logoutClick()" translate>IC_LOGOUT_BUTTON</div>
    </div>
</div>


<!--
    OLD COMPONENT
    <div class="invalid-credentials">
    <div class="content">
        <div class="brand">
            <a routerLink="/home" translate>
                <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
            </a>
        </div>
        <div>
            <span class="info-subtitle" translate>IC_SUBTITLE</span>
        </div>
        <br />
        <div class="columns">
            <div class="left-column">
                <button color="primary" mat-button class="button-buy" (click)="goToWebsiteClick('invalid-credentials','school')" type="submit">{{'IC_BTN_WEBSITE_SCHOOL' | translate}}</button>
                <button color="primary" mat-button class="button-buy" (click)="goToWebsiteClick('invalid-credentials','private')" type="submit">{{'IC_BTN_WEBSITE_PRIVATE' | translate}}</button><br />
                <br />
                <br />
                <span class="info-text" translate>IC_WEBSITE</span><br />
            </div>
            <div class="center-column"></div>
            <div *ngIf="eligibleForDemo" class="right-column">

                <button color="primary" mat-button class="button-try" (click)="demoClick()">{{'IC_DEMO_REQUEST_BUTTON' | translate}}</button><br />
                <br />
                <br />
                <span class="info-text-emp" translate>IC_REQUEST_DEMO_1</span><br />
                <br />
                <span class="info-text" translate>IC_REQUEST_DEMO_2</span><br />
                <br />
                <span class="info-text" translate>IC_USER_NAME</span><span class="info-text"></span><span class="info-text-emp"> {{userName}} </span>
                <span class="info-text" translate>IC_USER_EMAIL</span><span class="info-text"></span><span class="info-text-emp"> {{userEmail}}</span>
            </div>
        </div>
        <div>
            <br />
            <br />
            <span class="info-text black" translate>IC_LOGOUT</span><span class="link-text black" (click)="logoutClick()" translate>IC_LOGOUT_BUTTON</span>
        </div>
    </div>
</div>-->
