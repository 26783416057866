import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';
import { Subscription } from 'rxjs';
import { DesmosService } from '../../shared/commands/desmos.service';
import { QuickEditService } from '../../shared/commands/quick-edit.service';
import { MapStateService } from '../../shared/map-state.service';
import { SmService } from 'supermappe-core';
import { WebSearchService } from '../../shared/commands/web-search.service';
import { UiConstants } from '../../shared/ui-constants';
import { CustomSelectElement } from '../toolbar/custom-toolbar/custom-select-element-dto';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-desmos-panel',
    templateUrl: './desmos-panel.component.html',
    styleUrls: ['./desmos-panel.component.scss']
})
export class DesmosPanelComponent implements OnInit {

    @ViewChild('speechInput') speechInput: ElementRef | undefined;
    @ViewChild('htmlView') public htmlView: ElementRef | undefined;
    @ViewChild('titleAppuntiEdit') titleAppuntiEdit: ElementRef | undefined;
    @ViewChild('titleAppuntiView') titleAppuntiView: ElementRef | undefined;
    @ViewChild('extraContainer') extraContainer: ElementRef | undefined;
    @ViewChild('overpanel') overPanel: ElementRef | undefined;

    public style: object = {};
    public styleover: object = {};
    public mapinteraction = false;
    private newTop = 0;

    public ttsEnable = false;
    private _isTTSEnabled = false;
    private newWidth = 0;
    private plainText = '';
    private _currLanguage = UiConstants.LANGUAGES[0].value;
    public languages: CustomSelectElement[] = new Array<CustomSelectElement>();
    selectedLangIcon = UiConstants.LANGUAGES[0].icon;
    onGetVoicesSubscription: Subscription | undefined;
    onToggleWebSearchSubscription: Subscription | undefined;
    onResizeSubscription: Subscription | undefined;
    onToggleDesmosSubscrition: Subscription | undefined;
    rateValue = 1;
    public speechEditEnabled = false;
    onSpeechEditEnableSubscription: Subscription | undefined;
    speechRecordSubscription: Subscription | undefined;
    mapMoving: Subscription | undefined;
    geometry = false;
    fourfunction = false;
    calculator = false;
    scientific = false;
    resizing = false;
    public desmosUrl: SafeResourceUrl | undefined;

    constructor(private desmosService: DesmosService, private webSearchService: WebSearchService, private mapStateService: MapStateService, private smService: SmService, private quickEditService: QuickEditService) {

    }

    ngOnInit() {
        this.desmosUrl = this.desmosService.desmosUrl;
        // Modifica altezza in base alla visibilità del pannello search
        const extraContainer: any = document.getElementsByClassName('extra-container')[0];
        if (this.webSearchService.webSearchPanelOpen) {
            extraContainer.style.top = this.newTop + 45 + 'px'; //  '40px';
        }
        this.onToggleDesmosSubscrition = this.desmosService.onToggleDesmosPanel.subscribe((opened: boolean) => {
            this.desmosUrl = this.desmosService.desmosUrl;
            // this.calculator = this.desmosService.typeDesmos == "calculator";
            // this.scientific = this.desmosService.typeDesmos == "scientific";
            // this.fourfunction = this.desmosService.typeDesmos == "fourfunction";
            // this.geometry = this.desmosService.typeDesmos == "geometry";
        });

        this.onToggleWebSearchSubscription = this.webSearchService.onToggleWebSearchPanel.subscribe((opened: boolean) => {
            if (opened) {

                extraContainer.style.top = this.newTop + 45 + 'px'; //  '40px';
                this.newTop = this.newTop + 45;
            } else {
                const top = this.newTop - 45;
                this.newTop = (top >= 0) ? top : 0;
                extraContainer.style.top = this.newTop + 'px';
            }
            this.resizeEditor();
        });

    }
    resizeEditor(width?: number) {
        //
    }
    closeExtra() {
        if (this.quickEditService.isQuickEditEnabled()) {
            this.quickEditService.toggleQuickEdit();
        }
        if (this.quickEditService.isSpeechEditEnabled()) {
            this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.NOTES);
        }
        this.desmosService.toggleDesmosPanel();
    }
    onResizeStart(event: any) {
        this.resizing = true;
        //  this.mapinteraction = true;
        // this.editAppunti(false);
        this.mapStateService.setFocusOnMap(false);
        this.mapStateService.canEnableQuickEdit = false;
        this.smService.setEnableKeyPresses(false);
        this.quickEditService.onCanEdit.emit(false);
        //   this.mapStateService.setEditingState(true);
    }

    public validate(event: ResizeEvent): boolean {
        const MIN_DIMENSIONS_PX = 290;
        if (
            event.rectangle.width &&
            event.rectangle.height &&
            (event.rectangle.width < MIN_DIMENSIONS_PX ||
                event.rectangle.height < MIN_DIMENSIONS_PX)
        ) {
            return false;
        }
        return true;
    }

    public onResizing(event: ResizeEvent) {
        if (this.validate(event)) {
            // this.overPanel.nativeElement.width = event.rectangle.width;
            this.styleover = {
                position: 'fixed',
                left: `${event.rectangle.left}px`,
                top: `${event.rectangle.top}px`,
                width: `${event.rectangle.width}px`,
                height: `${event.rectangle.height}px`
            };
        }
    }

    onResizeEnd(event: any) {
        this.resizing = false;
        // this.overPanel.nativeElement.hidden = true;
        // setTimeout(() => {
        //     this.cd.detectChanges();
        // });

        this.newWidth = event.rectangle.width;
        this.mapStateService.setFocusOnMap(true);
        this.mapStateService.canEnableQuickEdit = true;
        this.smService.setEnableKeyPresses(true);
        this.quickEditService.onCanEdit.emit(true);
        //  this.mapStateService.setEditingState(false);
        // this.extraContainer.nativeElement.width = event.rectangle.width;
        //  this.newTop = event.rectangle.top;
        this.style = {
            width: `${event.rectangle.width}px`
        };
        this.styleover = {
            position: 'fixed',
            left: `${event.rectangle.left}px`,
            top: `${event.rectangle.top}px`,
            width: `${event.rectangle.width}px`,
            height: `${event.rectangle.height}px`
        };


        let w = 290;
        if (event.rectangle.width > 290) {
            w = event.rectangle.width;
        }
        this.resizeEditor(w);
        // this.desmosService.resize();


        // this.editAppunti(false);
    }
}



