<!-- <div class="finder-container"> -->
<form (onsubmit)="findInMap()" class="finder-container">
    <div class="drag-handle" cdkDragHandle>
        <mat-icon fontSet="material-icons-outlined">drag_indicator</mat-icon>
    </div>
    <button mat-icon-button matTooltip="{{'MAIN_TOOLBAR_FIND' | translate}}" (click)="findInMap()">
        <mat-icon color="primary">search</mat-icon>
    </button>
    <input #textToFind id="textToFind" tyoe="submit" matInput class="input-search" matFocus spellcheck="false" type="text" (focus)="enterEdit()" (blur)="exitEdit()" placeholder="{{'SEARCH_IN_MAP_PLACEHOLDER' | translate}}" autocomplete="off" />
    <button [hidden]="empty" mat-icon-button class="button-clear" matTooltip="{{'SEARCH_CLEAR' | translate}}" (click)="clearSearch()">
        <mat-icon color="primary">close</mat-icon>
    </button>
    <button mat-icon-button (click)="findPrevious()">
        <mat-icon color="primary" fontSet="material-icons-outlined">navigate_before</mat-icon>
    </button>
    <button mat-icon-button (click)="findNext()">
        <mat-icon color="primary" fontSet="material-icons-outlined">navigate_next</mat-icon>
    </button>
    <div class="close-button">
        <button mat-icon-button color="primary" matTooltip="{{'SEARCH_CLOSE' | translate}}" (click)="closeSearch();">
            <mat-icon [attr.aria-label]="'SEARCH_CLOSE' | translate" fontSet="material-icons-outlined">close
            </mat-icon>
        </button>
    </div>
</form>
<!-- </div> -->
