export class CustomToolbarDto {
    originalSelection: any;
    // tipo di selezione
    noSelection: boolean;
    nodeSelected: boolean;
    edgeSelected: boolean;
    isTableSelected: boolean;
    onlyNodeInTableSelected: boolean;
    elementselected: number;
    multiSelection: boolean;
    //  mixedSelection: boolean;
    imageSelected: boolean;
    brushColor: any;
    borderColor: any;
    ids: Array<string>;
    penWidth: any;
    textLayout: any;
    isFramed = false;
    titleText =  '';
    titleHtml = '';
    deepText = '';
    deepHtml = '';
    nodeType = '';
    edgeType = '';
    textInfo: any;
    language = '';
    canArrange = false; // only 1 node or 1 cell and edges
    nNodes: number; // node without image count (*)
    nNodesImage: number; // node with image count (*)
    nNodesImageFrame: number; // node with framed image count (*)
    nNodesText: number; // node text-only (*)
    nCells: number; // table cells without image count (*)
    nCellsImage: number; // table cells with image count (*) => total number of selected nodes
    nEdges: number; // edge count
    nImages: number; // images number count (included in node count)
    tableSize: any; // Table size

    constructor() {
        this.originalSelection = null;
        this.noSelection = true;
        this.nodeSelected = false;
        this.edgeSelected = false;
        this.isTableSelected = false;
        this.onlyNodeInTableSelected = false;
        this.multiSelection = false;
        this.imageSelected = false;
        this.elementselected = 0;
        this.ids = new Array<string>();
        this.titleText = '';
        this.titleHtml = '';
        this.deepText = '';
        this.deepHtml = '';
        this.textInfo = {};
        this.nNodes = 0;
        this.nNodesImage = 0;
        this.nNodesImageFrame = 0;
        this.nNodesText = 0;
        this.nCells = 0;
        this.nCellsImage = 0;
        this.nEdges = 0;
        this.nImages = 0;
        this.tableSize = { nCols: 0, nRows: 0 };
    }
}
