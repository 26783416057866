<h2 mat-dialog-title class="title">{{title}}</h2>
<div class="content">
    <div class="input-table">
        <div translate>NUM_ROW</div>
        <input #row type="number" class="input-table-stepper" [min]="minRow" max="10" name="row-num" [value]="rowNum" required (input)="onChangeRow($event)" />
    </div>
    <div class="input-table">
        <div translate>NUM_COL</div>
        <input #col type="number" class="input-table-stepper" [min]="minCol" max="10" name="col-num" [value]="colNum" required (input)="onChangeCol($event)" />
    </div>
</div>
<!-- <span>{{error}}</span> -->
<div mat-dialog-actions>
    <button mat-stroked-button type="button" (click)="cancelOp()" translate>
        <span translate>CANCEL</span>
    </button>
    <button type="button" mat-flat-button color="primary" (click)="closeDialog()" cdkFocusInitial translate>
        <span translate>OK</span>
    </button>
</div>
