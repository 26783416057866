import { Component, OnInit } from '@angular/core';
import { Logger } from '../core/logger.service';
import { MapStateService } from '../shared/map-state.service';
import { GoogleService } from '../core/google/google.service';
import { Subscription } from 'rxjs';
import { SmeService } from '../core/sme/sme.service';
import { Router } from '@angular/router';
import { FirebaseService } from '../core/firebase/firebase.service';
import { AuthenticationService } from '../core/authentication/authentication.service';

const logger: Logger = new Logger('edit-menu-component');

@Component({
    selector: 'app-map-new',
    templateUrl: './map-new.component.html',
    styleUrls: ['./map-new.component.scss']
})
export class MapNewComponent implements OnInit {

    createNewMapSubscription: Subscription | undefined;
    isLoading = false;
    error = '';

    constructor(
        private mapStateService: MapStateService,
        private router: Router,
        private smeService: SmeService,
        private authenticationService: AuthenticationService,
        private firebaseService: FirebaseService) {
        this.isLoading = true;
    }

    ngOnInit() {

        this.newMap();
    }

    public newMap() {
        logger.debug('new map');
        this.mapStateService.setBusyState(true);
        this.error = '';
        // this.router.navigate(['gdrive-picker']);
        this.mapStateService.isNew = true;
        if (this.createNewMapSubscription) { this.createNewMapSubscription.unsubscribe(); }
        // this.googleService.getSMXFolderIds().then((folderIds: any) => {
        this.createNewMapSubscription = this.smeService.createNewMap('')
            .subscribe({
                next: (mapId: string) => {
                    this.isLoading = false;
                    logger.info(`new Map ${mapId} created`);
                    this.firebaseService.addSharedStateChangeListener('', mapId, this.authenticationService.getUserEmail())
                    this.router.navigate(['map-edit', mapId]);
                    this.mapStateService.setBusyState(false);
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.mapStateService.setBusyState(false);
                    if (error.status === 504) {
                        this.mapStateService.setStateError('GATEWAY_TIMEOUT');
                    } else {
                        const errorCode = (error.code) ? error.code : error.statusText;
                        this.mapStateService.setStateError(errorCode);
                    }
                }
            });
    }

}
