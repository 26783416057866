import { Injectable, OnDestroy } from '@angular/core';
import { SmService, ZoomData } from 'supermappe-core';
import { SmEventBroker } from '../sm-event-broker';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ZoomService implements OnDestroy {
  centerX = 0;
  centerY = 0;

  public selectionData: Observable<any> | undefined;
  public zoomDataChange: Observable<any> | undefined;

  selectionDataSubscription: Subscription | undefined;

  constructor(private smService: SmService, smEventBroker: SmEventBroker) {
    this.selectionDataSubscription = smEventBroker.selectionData?.subscribe((_selectionData: any) => {
      this.centerX = _selectionData.scrCenterX;
      this.centerY = _selectionData.scrCenterY;
    });

    if (smEventBroker && smEventBroker.zoomData) {
    this.zoomDataChange = smEventBroker.zoomData
      .pipe(map((newZoomData: ZoomData) => {
        // console.log(JSON.stringify(newZoomData));
        return newZoomData;
      }));
    }
  }

  ngOnDestroy() {
    if (this.selectionDataSubscription) { this.selectionDataSubscription.unsubscribe(); }
  }

  public zoom(direction: number) {
    const center = { x: this.centerX, y: this.centerY };
    this.smService.changeZoomCenter(center);
    this.smService.changeZoomFactor(this.smService.getZoomFactor() + (0.1 * Math.sign(direction)), false);
    this.smService.redraw();
  }

  public setZoomScale(centerX: number, centerY: number, scale: number) {
    const center = { x: centerX, y: centerY };
    this.smService.changeZoomCenter(center);
    this.smService.changeZoomFactor(scale, false);
    this.smService.redraw();
  }

  public getZoomFactor(): number {
    return this.smService.getZoomFactor();
  }
}




