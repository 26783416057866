import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SmService } from 'supermappe-core';
import { ConfirmationService } from '../dialog/confirmation.service';

@Injectable({
    providedIn: 'root'
})
export class ArrangeService {

    constructor(
        private smService: SmService,
        private translateService: TranslateService,
        private confirmationService: ConfirmationService
    ) {
    }

    public arrangeMap() {
        const title = this.translateService.instant('OUTLINE_ARRANGE');
        let message = this.translateService.instant('ARRANGE_BASE');
        const ro = this.smService.getMapDefaultReadOrderInfo();
        if (ro && ro.mapProps) {
            if (ro.mapProps.lockedNodes || ro.mapProps.curveEdges || ro.mapProps.pairEdges) {
                message += '\n\n' + this.translateService.instant('ARRANGE_WARNING_START');
                if (ro.mapProps.lockedNodes) {
                    message += '\n' + this.translateService.instant('ARRANGE_WARNING_LOCKED');
                }
                if (ro.mapProps.curveEdges) {
                    message += '\n' + this.translateService.instant('ARRANGE_WARNING_CURVES');
                }
                if (ro.mapProps.pairEdges) {
                    message += '\n' + this.translateService.instant('ARRANGE_WARNING_PAIRS');
                }
                message += '\n' + this.translateService.instant('ARRANGE_WARNING_END');
            }
        }
        message += '\n\n' + this.translateService.instant('ARRANGE_CONFIRM');
        const ok = this.translateService.instant('BUTTON_OK');
        const cancel = this.translateService.instant('CANCEL');
        this.confirmationService.confirm(title, message, ok, cancel)
            .subscribe({
                next: result => {
                    if (result) {
                        this.smService.arrangeMap();
                    }
                },
                error: (error: any) => {
                    console.error(error);
                }
            });

    }

}
