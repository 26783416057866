<div [formGroup]="orderForm" [hidden]="!loaded">

    <div class="access-container">
        <!-- TIPO UTENTE -->
        <div class="access-form-entry">
            <div class="form-entry-title" translate>IC_USER_TYPE_TITLE</div>
            <div class="form-entry-control">
                <select formControlName="selectUserType" class="form-select">
                    <option *ngFor="let ut of userTypes" [ngValue]="ut.value">{{ut.name}}</option>
                </select>
            </div>
        </div>
        <!-- PIANO -->
        <div class="access-form-entry">
            <div class="form-entry-title" translate>ACCESSCONFIG_PLAN</div>
            <div class="form-entry-control">
                <select formControlName="planId" class="form-select">
                    <option *ngFor="let p of planIds" [ngValue]="p.id">{{p.name}}</option>
                </select>
            </div>
        </div>
        <!-- SCANSIONI OCR MATEMATICO -->
        <div *ngIf="orderForm.get('planId')?.value==='lab'" class="access-form-entry">
            <div class="form-entry-title" translate>ACCESSCONFIG_OCR_QUOTA</div>
            <div class="form-entry-control">
                <input class="form-input" type="number" autocomplete="off" spellcheck="false" formControlName="ocrMathScanQuota" />
            </div>
        </div>
        <div *ngIf="orderForm.get('planId')?.value==='lab'" class="access-form-entry">
            <div class="form-entry-title" translate>ACCESSCONFIG_OCR_POLICY</div>
            <div class="form-entry-control">
                <select formControlName="ocrMathScanPolicy" class="form-select">
                    <option *ngFor="let s of scanPolicies" [ngValue]="s.id">{{s.name | translate}}</option>
                </select>
            </div>
        </div>
        <!-- ACQUIRENTE -->
        <div class="access-form-entry">
            <div class="form-entry-title" translate>ACCESSCONFIG_USER_NAME</div>
            <div class="form-entry-control">
                <input class="form-input" type="text" placeholder="{{'ACCESSCONFIG_USER_NAME_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" formControlName="fullName" />
            </div>
            <div class="form-entry-error" *ngIf="f.fullName.errors && f.fullName.errors['required']" translate>FIELD_REQUIRED</div>
        </div>
        <!-- EMAIL -->
        <div class="access-form-entry">
            <div class="form-entry-title" translate>ACCESSCONFIG_USERS_PLACEHOLDER</div>
            <div class="form-entry-control">
                <input class="form-input" type="text" placeholder="{{'ACCESSCONFIG_USERS_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" formControlName="userEmail" />
            </div>
            <div class="form-entry-error" *ngIf="f.userEmail.errors && f.userEmail.errors['required']" translate>FIELD_REQUIRED</div>
            <div class="form-entry-error" *ngIf="f.userEmail.errors && f.userEmail.errors['email']" translate>NOT_VALID_EMAIL_MSG</div>
        </div>
        <!-- START DATE -->
        <div class="access-form-entry">
            <div class="form-entry-title" translate>START_DATE</div>
            <div class="form-entry-control">
                <mat-form-field>
                    <input matInput [matDatepicker]="startDate" autocomplete="off" spellcheck="false" formControlName="startDate" readonly />
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-entry-error" *ngIf="f.expireDate.errors && f.expireDate.errors['required']" translate>FIELD_REQUIRED</div>
        </div>
        <!-- EXPIRE DATE -->
        <div class="access-form-entry">
            <div class="form-entry-title" translate>EXPIRE_DATE</div>
            <div class="form-entry-control">
                <mat-form-field>
                    <input matInput [matDatepicker]="expireDate" autocomplete="off" spellcheck="false" formControlName="expireDate" readonly />
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="expireDate"></mat-datepicker-toggle>
                    <mat-datepicker #expireDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-entry-error" *ngIf="f.expireDate.errors && f.expireDate.errors['required']" translate>FIELD_REQUIRED</div>
        </div>
    </div>
    <!-- ORDER ORIGIN -->
    <div class="access-form-entry">
        <div class="form-entry-title" translate>ORDER-ORIGIN</div>
        <div class="form-entry-control">
            <select class="form-select" formControlName="origin">
                <option *ngFor="let o of origins" [ngValue]="o.id">{{o.name | translate}}</option>
            </select>
        </div>
        <!--<div class="form-entry-error" translate>FIELD_REQUIRED</div>-->
    </div>
    <!-- SUSPEND SUBSCRIPTION -->
    <div class="access-form-entry">
        <div class="form-entry-title" translate>SUSPEND_SUBSCRIPTION</div>
        <div class="form-entry-control">
            <mat-slide-toggle class="form-checkbox" color="accent" formControlName="suspended"></mat-slide-toggle>
            <!--<input class="form-checkbox" type="checkbox" autocomplete="off" formControlName="suspended" />-->
        </div>
        <!--<div class="form-entry-error" translate>FIELD_REQUIRED</div>-->
    </div>
    <!-- SUBSCRIPTION NOTES-->
    <div class="access-form-entry">
        <div class="form-entry-title" translate>SUBSCRIPTION_NOTES</div>
        <div class="form-entry-control">
            <textarea class="form-textarea" formControlName="notes"></textarea>
        </div>
        <!--<div class="form-entry-error" translate>FIELD_REQUIRED</div>-->
    </div>
    <!-- ACCESS SLOTS -->
    <div *ngIf="showSlot" class="access-form-entry">
        <div class="form-entry-title" translate>ACCESSCONFIG_SLOTS</div>
        <div class="form-entry-control">
            <input class="form-input" type="number" autocomplete="off" spellcheck="false" formControlName="slotCount" />
        </div>
        <!--<div class="form-entry-error" translate>FIELD_REQUIRED</div>-->
    </div>
    <!-- UTENZE DOMINIO -->
    <div *ngIf="showDomainLics" class="access-form-entry">
        <div class="form-entry-title" translate>ACCESSCONFIG_DOMAINLICS</div>
        <div class="form-entry-control">
            <input class="form-input" type="number" autocomplete="off" spellcheck="false" formControlName="domainLics" />
        </div>
        <div class="form-entry-description" translate>ACCESSCONFIG_DOMAINLICS_DESC</div>
        <div class="form-entry-error" *ngIf="showSlot && f.slotCount.errors" translate>FIELD_REQUIRED</div>
        <div class="form-entry-error" *ngIf="showSlot && orderForm.get('slotCount')?.errors?.['min']" translate>MIN_ERROR</div>
        <div class="form-entry-error" *ngIf="showSlot && orderForm.get('slotCount')?.errors?.['max']" translate>MAX_ERROR</div>
    </div>





    <!--<div class="access-domain">-->

    <!--<span class="item1" translate>ACCESSCONFIG_PLAN</span>
        <select class="item2 map-input-name" formControlName="planId">
            <option *ngFor="let p of planIds" [ngValue]="p.id">{{p.name}}</option>
        </select>-->

    <!--<span class="item1" translate>OCR_SCANS_LEFT</span>
        <input class="item2 map-input-name" type="number" autocomplete="off" formControlName="ocrMathPixScansLeft" />-->

    <!--<span class="item1" translate>ACCESSCONFIG_USER_NAME</span>
        <input class="item2 map-input-name" type="text" placeholder="{{'ACCESSCONFIG_USER_NAME_PLACEHOLDER' | translate}}" autocomplete="off" formControlName="fullName" />
        <span></span>
        <span *ngIf="f.fullName.errors && f.fullName.errors['required']" class="invalid-feedback" translate>FIELD_REQUIRED</span>
        <span *ngIf="!f.fullName.errors"></span>-->

    <!--<span class="item1" translate>ACCESSCONFIG_USERS_PLACEHOLDER</span>
        <input class="item2 map-input-name" type="text" placeholder="{{'ACCESSCONFIG_USERS_PLACEHOLDER' | translate}}" autocomplete="off" formControlName="userEmail" />
        <span></span>
        <span *ngIf="f.userEmail.errors && f.userEmail.errors['required']" class="invalid-feedback2" translate>FIELD_REQUIRED</span>
        <span *ngIf="f.userEmail.errors && f.userEmail.errors['email']" class="invalid-feedback2" translate>NOT_VALID_EMAIL_MSG</span>
        <span *ngIf="!f.userEmail.errors"> </span>-->

    <!--<span class="item1" translate>START_DATE</span>
        <mat-form-field class="start-date-field">
            <input matInput class="map-input-date" [matDatepicker]="startDate" autocomplete="off" formControlName="startDate" readonly />
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <span *ngIf="f.startDate.errors && f.startDate.errors['required']" class="invalid-feedback3" translate>FIELD_REQUIRED</span>
        <span *ngIf="!f.startDate.errors"> </span>-->

    <!--<span class="item1" translate>EXPIRE_DATE</span>
        <mat-form-field class="expire-date-field">
            <input matInput class="map-input-date" [matDatepicker]="expireDate" autocomplete="off" formControlName="expireDate" readonly />
            <mat-datepicker-toggle matSuffix [for]="expireDate"></mat-datepicker-toggle>
            <mat-datepicker #expireDate></mat-datepicker>
        </mat-form-field>
        <span *ngIf="f.expireDate.errors && f.expireDate.errors['required']" class="invalid-feedback4" translate>FIELD_REQUIRED</span>
        <span *ngIf="!f.expireDate.errors"> </span>-->

    <!--<span class="item1" translate>ORDER-ORIGIN</span>
        <select class="item2 map-input-name" formControlName="origin">
            <option *ngFor="let o of origins" [ngValue]="o.id">{{o.name | translate}}</option>
        </select>-->

    <!--<span class="item1" translate>SUSPEND_SUBSCRIPTION</span>
        <input class="item2 checkbox" type="checkbox" autocomplete="off" formControlName="suspended" />-->

    <!--<span class="item1" translate>SUBSCRIPTION_NOTES</span>
        <textarea class="item2 notes" formControlName="notes"></textarea>-->

    <!-- <div *ngIf="showSlot"> -->
    <!--<span *ngIf="showSlot" class="item1" translate>ACCESSCONFIG_SLOTS</span>
        <input *ngIf="showSlot" class="item2 map-input-number" type="number" autocomplete="off" formControlName="slotCount" />-->

    <!--<span *ngIf="showDomainLics" class="item1" translate>ACCESSCONFIG_DOMAINLICS</span>
        <input *ngIf="showDomainLics" class="item2 map-input-number" type="number" autocomplete="off" formControlName="domainLics" />
        <span *ngIf="showDomainLics" class="item1" translate>ACCESSCONFIG_DOMAINLICS_DESC</span>

        <span></span>

        <span *ngIf="showSlot && f.slotCount.errors" class="invalid-feedback">
            <span *ngIf="showSlot && f.slotCount.errors['required']" translate>FIELD_REQUIRED</span>
            <span *ngIf="showSlot && orderForm.get('slotCount')?.errors?.['min']" translate>MIN_ERROR</span>
            <span *ngIf="showSlot && orderForm.get('slotCount')?.errors?.['max']" translate>MAX_ERROR</span>
        </span>
        <span *ngIf="showSlot && !f.slotCount.errors"> </span>-->
    <!--</div>-->
</div>
