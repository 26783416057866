<div class="access-form" [formGroup]="notificationForm" [hidden]="!loaded">
    <div class="access-domain">
        <span class="item1" translate>NOTIFICATION_DATE</span>
        <mat-form-field class="date-field item2">
            <input matInput [matDatepicker]="dateCreated" autocomplete="off" spellcheck="false" formControlName="dateCreated" />
            <mat-datepicker-toggle matSuffix [for]="dateCreated"></mat-datepicker-toggle>
            <mat-datepicker #dateCreated></mat-datepicker>
        </mat-form-field>
        <span class="item1" translate>NOTIFICATION_TITLE</span>
        <input class="item2 map-input-name" type="text" placeholder="{{'NOTIFICATION_TITLE_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" formControlName="title" />

        <!-- <span *ngIf="f.title.errors && f.title.errors.required" class="invalid-feedback access-desc" translate>FIELD_REQUIRED</span>
    <span *ngIf="!f.title.errors"></span> -->
        <span class="item1 all-columns" translate>NOTIFICATION_CHECK</span>
        <mat-checkbox formControlName="active"></mat-checkbox>


        <span class="item1" translate>NOTIFICATION_TYPE</span>
        <mat-form-field class="item2">
            <mat-select [(value)]="selectedNotification" (change)="optionSelected($event)">
                <mat-option *ngFor="let notificationIcon of iconNotifications" [value]="notificationIcon">
                    <img [src]="notificationIcon.icon" [alt]="notificationIcon.viewValue" />
                    {{notificationIcon.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- <input class="item2 map-input-name" type="text" *ngIf="showUploadImage()" placeholder="{{'INSERT_IMAGE' | translate}}" autocomplete="off" formControlName="icon" /> -->

        <!-- <input #inputImage type="file" class="hidden" #file accept="image/*" (change)="insertImage()" />
    <button mat-button [disabled]="loadingMap" class="open-button" (click)="file.click()">
      <img src="../../assets/home/apri-PC.png?v=1" />
      <span translate>OPEN_FROM_LOCAL</span>
    </button> -->

        <span class="item1" translate>INPUT_LINK_URL</span>
        <input class="item2 map-input-name" type="text" placeholder="{{'INPUT_LINK_URL' | translate}}" autocomplete="off" spellcheck="false" formControlName="link" />
        <span class="item1" translate>NOTIFICATION_DESC</span>
        <input class="item2 map-input-name" type="text" autocomplete="off" formControlName="notes" placeholder="{{'NOTIFICATION_DESC_PLACEHOLDER' | translate}}" />

        <!-- <span class="access-desc" translate>ACCESSCONFIG_USERS</span> -->
        <!-- </div> -->
    </div>
</div>
