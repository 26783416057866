<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<div class="main-content">
    <div class="access-title">
        <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
            <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
        </a>
        <span class="access-subtitle">{{'USERDESKTOP_TITLE' | translate}} {{user}} </span>
        <!-- <span class="access-subtitle">{{getRoleType()}}</span> -->
    </div>


    <div class="admin-config">
        <mat-accordion class="expansion-panel">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="domain-subtitle">
                        <button mat-button class="title-button">
                            {{'ACCESSCONFIG_INSERT_DOMAIN' | translate}}
                        </button>
                    </mat-panel-title>
                </mat-expansion-panel-header>


                <div *ngIf="panelOpenState">
                    <form [formGroup]="accessConfigForm" novalidate (ngSubmit)="onSubmit()">
                        <div class="access-name">
                            <span translate>ACCESSCONFIG_NAME</span>
                            <input type="text" placeholder="{{'ACCESSCONFIG_NAME_PLACEHOLDER' | translate}}" autocomplete="off" class="map-input-name access-col2" formControlName="accessName" />
                            <span *ngIf="f['accessName'].errors" class="invalid-feedback">
                                <span *ngIf="f['accessName']?.errors?.['required']" translate>EMPTY_NAME_NOT_ALLOWED</span>
                            </span>
                            <span *ngIf="!f['accessName']?.errors"> </span>
                            <span class="access-desc" translate>ACCESSCONFIG_NAME_DESC</span>
                        </div>
                        <app-admin-config [parentForm]="accessConfigForm" [requiredDomain]="true" #adminconfig [canEditDomain]="true" [isSingleUser]="false">
                        </app-admin-config>

                        <button color="primary" [disabled]="!enabledForm()" mat-button class="ok-button" type="submit">{{'ACCESSCONFIG_SAVE' | translate}}</button>
                        <button color="primary" type="reset" mat-button>{{'ACCESSCONFIG_RESET' | translate}}</button>
                    </form>
                </div>

            </mat-expansion-panel>
        </mat-accordion>


    </div>

    <div class="order-list">
        <app-order-list #orderlist [openFirstElement]="false" [isLoading]="isLoading" (startLoading)="startLoadingList()" (endLoading)="endLoadingList()" [enabledDetail]="!enabledDetail()"></app-order-list>
    </div>


</div>
