import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { UiConstants } from '../../../shared/ui-constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-date-config',
    templateUrl: './date-config.component.html',
    styleUrls: ['./date-config.component.scss']
})
export class DateConfigComponent implements OnInit {

    @Input() dateForm = new FormGroup({
        planId: new FormControl(),
        // ocrMathScanQuota: new FormControl(),
        // ocrMathScanPolicy: new FormControl(),
        selectUserType: new FormControl(),
        startDate: new FormControl(),
        expireDate: new FormControl(),
        origin: new FormControl(),
        sendMail: new FormControl()
    });

    @Input() licenseType: string | undefined;
    @Input() userType: string | undefined;
    @Input() planId: string | undefined;
    // @Input() ocrMathScanQuota: number | undefined;
    // @Input() ocrMathScanPolicy: string | undefined;
    @Input() startDate: string | undefined;
    @Input() expireDate: string | undefined;
    @Input() role: string | undefined;

    public userTypes = UiConstants.USER_TYPES;
    // public userType = this.userTypes[0].value;
    public origin: string | undefined;
    public sendMail = false;

    loaded = false;

    public origins = UiConstants.ORIGINS;
    public planIds = UiConstants.PLAN_IDS;
    public scanPolicies = UiConstants.OCRMATH_POLICIES;

    constructor(
        private formBuilder: FormBuilder,
        private translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.createForm();
        this.populateUserTypes();
        let ut = this.userTypes[0].value;
        let pId = this.planIds[0].id;
        let ocrRules;
        switch (this.licenseType) {
            case 's':
                ut = 'PRIVATO';
                pId = 'lab';
                ocrRules = UiConstants.OCRMATH_RULES.single;
                break;
            case 'm':
                ut = 'SCUOLA';
                pId = 'lab';
                ocrRules = UiConstants.OCRMATH_RULES.multiple;
                break;
            case 'd':
                ut = 'SCUOLA';
                pId = 'edu';
                ocrRules = UiConstants.OCRMATH_RULES.domain;
                break;
            default:
                ut = 'PRIVATO';
                pId = 'edu';
                ocrRules = UiConstants.OCRMATH_RULES.single;
                break;
        }
        this.dateForm?.get('selectUserType')?.setValue(ut);
        this.dateForm?.get('planId')?.setValue(pId);
        // this.dateForm?.get('ocrMathScanQuota')?.setValue(ocrRules.quota);
        // this.dateForm?.get('ocrMathScanPolicy')?.setValue(ocrRules.policy);
        if (this.startDate) {
            const start = new Date(this.startDate);
            this.dateForm?.get('startDate')?.setValue(start);
        }
        if (this.expireDate) {
            const expire = new Date(this.expireDate);
            this.dateForm.get('expireDate')?.setValue(expire);
        }
        if (this.role === 'anastasis' || this.role === 'admin') {
            origin = 'manual';
        } else if (this.role === 'aid') {
            origin = 'aid';
        } else if (this.role === 'spain') {
            origin = 'spain';
        } else {
            // Normal user
            origin = 'manual';
        }
        this.dateForm.get('origin')?.setValue(origin);
        this.sendMail = this.getSendMail(origin);
        this.dateForm.get('sendMail')?.setValue(this.sendMail);
        this.loaded = true;
    }

    populateUserTypes() {
        for (let i = 0; i < this.userTypes.length; i++) {
            if (this.userTypes[i].value !== '') {
                this.userTypes[i].name = this.translateService.instant('IC_USER_TYPE_' + this.userTypes[i].value);
            }
        }
    }

    public originChanged(event: any) {
        if (event) {
            const origin = this.dateForm.get('origin')?.value;
            this.sendMail = this.getSendMail(origin);
            this.dateForm.get('sendMail')?.setValue(this.sendMail);
        }
    }

    private getSendMail(origin: string) {
        return (origin !== 'spain');
    }

    private createForm() {
        const startDate = (this.startDate) ? new Date(this.startDate) : undefined;
        const expireDate = (this.expireDate) ? new Date(this.expireDate) : undefined;
        this.dateForm = this.formBuilder.group({
            selectUserType: [false, null], //[Validators.required]],
            planId: [this.planId, Validators.required],
            // ocrMathScanQuota: [this.ocrMathScanQuota, [Validators.min(0)]],
            // ocrMathScanPolicy: [this.ocrMathScanPolicy, Validators.required],
            startDate: [startDate, Validators.required],
            expireDate: [expireDate, Validators.required],
            origin: [this.origin, Validators.required],
            sendMail: [this.sendMail, Validators.nullValidator]
        });

    }

    get f() { return this.dateForm.controls; }

}


