export class UserStatsDto {

  private data: { [index: string]: any } = {
    STAT_NEWMAP: 0,
    STAT_OPENMAP: 0,
    STAT_ADDNODE: 0,
    STAT_ADDTABLE: 0,
    STAT_WORDS: 0,
    STAT_ADDIMAGE: 0,
    STAT_ADDVIDEO: 0,
    STAT_ADDLINK: 0,
    STAT_GDOCCREATED: 0,
    STAT_PRINT: 0,
    STAT_SHOW: 0,
    STAT_VIEWSHARED: 0,
    STAT_COPYSHARED: 0,
    STAT_SHARECLASSROOM: 0,
    STAT_SHAREFB: 0,
    STAT_SHARELINK: 0
  }
  public UserStatsDto() { }

  public addStat(id: string, qty: number) {
    // if (!this.data.hasOwnProperty(id)) {
    //   this.data[id] = 0;
    // } 
    // this.data[id] += qty;
    // if (this.data.hasOwnProperty(id)) {
    this.data[id] += qty;
    // }
  }

  public getStat(id: string) {
    // let qty = 0;
    // if (this.hasOwnProperty(id)) {
    const qty = this.data[id] || 0;
    // }
    return qty;
  }
}
