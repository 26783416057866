export class SmxNodeAspect {
    public shape: string;
    public background_color: string;
    public border_color: string;

    constructor(shape?: string, background_color?: string, border_color?: string) {
        this.shape = shape || 'PREF_SHAPE_RECT';
        this.background_color = background_color || 'rgba(255,255,255,1.0)';
        this.border_color = border_color || 'rgba(8,117,194,1.0)';
    }
}
