<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<div class="main-content">
    <div class="header-container">
        <button mat-button class="date-button" [ngClass]="{'hidden':isLoading}" (click)="goback()">
            <mat-icon color="primary">arrow_back</mat-icon>
            <span class="date"> {{selectedItem}}</span>
        </button>
        <div class="map-name">{{mapName}}</div>
        <button mat-button class="header-button" [disabled]="!imageBase64" (click)="restoreRevision()" [ngClass]="{'hidden':isLoading}">
            <mat-icon fontSet="material-icons-outlined">restore</mat-icon>
            <span translate>RESTORE</span>
        </button>
        <button mat-button class="header-button" [disabled]="!imageBase64" (click)="copyRevision()" [ngClass]="{'hidden':isLoading}">
            <mat-icon fontSet="material-icons-outlined">file_copy</mat-icon>
            <span translate>RECENT_MENU_CREATECOPY</span>
        </button>
    </div>
    <div class="version-header">
        <div class="version-title" translate>VERSION</div>
    </div>
    <div class="versions-list-container">
        <div class="versions-container">
            <mat-accordion multi="false">
                <mat-expansion-panel *ngFor="let h of historyMapsMain" (opened)="loadHistoryMap(h.lastDayUpdate)" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{h.lastDayUpdate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list class="map-list">
                        <mat-button-toggle-group mat-line class="history-list-item" [(value)]="selectedHistory" vertical>
                            <mat-button-toggle *ngFor="let historyMap of historyMapsSorted" class="list-button" [value]="historyMap" (change)="viewMapImage(historyMap)"> {{historyMap.lastUpdate.toLocaleString()}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </mat-list>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="detail-content" *ngIf="imageBase64">
        <img [src]="imageBase64" *ngIf="imageBase64" class="map-preview" />
    </div>
    <div class="divider"></div>
</div>
