<app-loader *ngIf="uploading" [versioned]="true" [isLoading]="uploading" [message]="'PLEASE_WAIT'"></app-loader>
<div class="pdf-container">

    <div class="pdf-title-container">

        <div class="pdf-title-icon">
            <button mat-icon-button color="primary" matTooltip="{{'MY_PDF' | translate}}" matTooltipPosition="above" (click)="openHomePdf()" *ngIf="!noPDfOpen">
                <mat-icon color="primary">arrow_back</mat-icon>
            </button>
            <mat-icon class="material-icons-sharp" fontSet="material-icons-filled" [ngStyle]="{'color': colorPDF}">picture_as_pdf</mat-icon>
        </div>

        <div class="pdf-title-text">


            <span class="title-pdf">PDF</span>
            <span class="name-pdf">{{currentFileName}}</span>
        </div>

        <div class="pdf-close">
            <button mat-icon-button color="primary" matTooltip="{{'SEARCH_CLOSE' | translate}}" matTooltipPosition="above" (click)="closeExtra();">
                <mat-icon [attr.aria-label]="'SEARCH_CLOSE' | translate" fontSet="material-icons-outlined">close</mat-icon>
            </button>
        </div>
    </div>

    <div class="pdf-toolbar" *ngIf="!noPDfOpen">
        <div class="pdf-toolbar-top">
            <div class="pdf-toolbar-top-left">
                <button class="green-color" *ngIf="!isTalking" mat-icon-button matTooltip="{{'PLAY' | translate}}" [attr.aria-label]="'PLAY' | translate" (click)="TTSRead()" matTooltipPosition="above">
                    <mat-icon class="extra-button">play_arrow</mat-icon>
                </button>
                <button class="red-color" *ngIf="isTalking" mat-icon-button matTooltip="{{'STOP' | translate}}" [attr.aria-label]="'STOP' | translate" (click)="TTSStop()" matTooltipPosition="above">
                    <mat-icon class="extra-button">stop</mat-icon>
                </button>
                <div class="pdf-toolbar-input-container">
                    <input class="pdf-toolbar-top-input" #speedInput type="number" min="0.5" max="1.5" step="0.1" (focus)="speedGotFocus()" (blur)="speedLostFocus()" value="{{rateValue}}" (change)="changeSpeechRate($event)" />
                </div>
                <div class="pdf-toolbar-language-container">
                    <button class="pdf-toolbar-lang-button" matTooltip="{{'LANGUAGE_TOOLBAR' | translate}}" matTooltipPosition="above">
                        <img color="white" [src]="selectedLangIcon" [matMenuTriggerFor]="languageMenu" />
                    </button>
                    <mat-menu class="language-menu-container" #languageMenu="matMenu" color="white" [overlapTrigger]=" false">
                        <div class="language-menu-item-container" *ngFor="let l of languages" mat-menu-item (click)="changeLanguage($event)" [id]="l.value">
                            <img [src]="l.icon" class="language-icon" />
                            <span translate>{{l.viewValue}}</span>
                        </div>
                    </mat-menu>
                </div>
                <!-- <button *ngIf="editVisible" mat-icon-button color="primary" (click)="speechEdit()" [ngClass]="{'speechedit-mode': speechEditEnabled}" matTooltip="{{'MAIN_TOOLBAR_SPEECHEDIT' | translate}}" matTooltipPosition="above">
                    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_SPEECHEDIT' | translate">settings_voice</mat-icon>
                </button> -->
                <div *ngIf="useKaraoke">
                    <button mat-button [matMenuTriggerFor]="menu" class="voicebtn">
                        <span> {{'VOICES' | translate}} </span>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngFor="let v of filterVoices" (click)="changeVoice($event)" [id]="v.name">
                            <span class="voice-span">{{v.name}} - {{v.lang}}</span>
                            <mat-icon color="primary" *ngIf="v.localService" fontSet="material-icons-outlined">mic</mat-icon>
                            <mat-icon color="primary" *ngIf="!v.localService" fontSet="material-icons-outlined">voice_chat</mat-icon>
                        </button>
                    </mat-menu>
                </div>
                <!-- <button mat-icon-button color="primary" (click)="readAllText()">
                    <mat-icon>settings_voice</mat-icon>
                </button> -->
                <!-- <button *ngIf="editVisible" mat-icon-button color="primary" (click)="speechEdit()" [ngClass]="{'speechedit-mode': speechEditEnabled}" matTooltip="{{'MAIN_TOOLBAR_SPEECHEDIT' | translate}}" matTooltipPosition="above">
                    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_SPEECHEDIT' | translate">settings_voice</mat-icon>
                </button> -->
            </div>

            <div class="pdf-toolbar-top-right">
                <button *ngIf="isLab" [ngClass]="{'capture-mode':capturingScreen, 'disabled':!canCaptureMath}" [disabled]="!canCaptureMath" mat-icon-button color="primary" matTooltip="{{'CAPTURE_MATH' | translate}}" [attr.aria-label]="'CAPTURE_MATH' | translate" (click)="CaptureMathOCR()" matTooltipPosition="above">
                    <img [src]="mathOCR_icon" class="language-con" />
                </button>
                <button mat-icon-button color="primary" [disabled]="!isTextSelected" matTooltip="{{'INSERT_TEXT' | translate}}" [attr.aria-label]="'INSERT_TEXT' | translate" (click)="InsertText()" matTooltipPosition="above">
                    <mat-icon class="extra-button" fontSet="material-icons-outlined">playlist_add</mat-icon>
                </button>
                <button mat-icon-button color="primary" matTooltip="{{'CAPTURE' | translate}}" [attr.aria-label]="'CAPTURE' | translate" (click)="Capture()" [ngClass]="{'capture-mode': capturingScreen}" matTooltipPosition="above">
                    <mat-icon class="extra-button">photo_size_select_large</mat-icon>
                </button>
                <button mat-icon-button color="primary" [disabled]="!isTextSelected" matTooltip="{{'INSERT_DEEP' | translate}}" [attr.aria-label]="'INSERT_DEEP' | translate" (click)="InsertDeep()" matTooltipPosition="above">
                    <mat-icon class="custom-icon" svgIcon="add-deep"></mat-icon>
                </button>
            </div>

        </div>
        <div class="pdf-toolbar-bottom">
            <div class="capture-info" [hidden]="!capturingScreen">
                <span class="capture-info-text" translate>CAPTURE_INFO
                </span>
            </div>
            <!-- <div class="speechnotes-container" [hidden]="!speechEditEnabled">
                <mat-icon>mic</mat-icon>
                <span #speechInput> </span>
            </div> -->
        </div>

    </div>

    <div [hidden]="noPDfOpen" class="pdf-editor">
        <!-- <div *ngIf="!noPDfOpen" class="note-editor"> -->
        <div class="overlay" #over>
            <div #screen>
                <div class="pdf-content" (focus)="setPdfFocus()">
                    <app-pdf-master [ngClass]="capturingScreen ? 'disable-input':''" #pdfMaster (onAnnotationSetText)="onAnnotationSetText($event)" (loadedPdf)="endLoadedPdf($event)" (onOpenConfig)="onOpenConfig($event)" (readSelectedText)="readSelectedText($event)" (onShowMessage)="showMessage($event)" (onChangeConfig)="changeConfig($event)" (dirtyChange)="dirtyChange($event)" (onClick)="onClickPdf($event)" (printPdf)="PrintPdf()" (imageCaptured)="ImageCaptured($event)"></app-pdf-master>
                    <div *ngIf="capturingScreen" class="capture-over" [ngStyle]="{'clip-path': 'polygon(0% 0%, '+percCropX+'% 0%, '+percCropX+'% '+percCropY2+'%, '+percCropX2+'% '+percCropY2+'%, '+percCropX2+'% '+percCropY+'%,'+percCropX+'% '+percCropY+'%,'+percCropX+'% 0%,100% 0%, 100% 100%, 0% 100%)'}"></div>
                </div>
            </div>
        </div>
        <div class="rectangle" #rect></div>

    </div>

    <div class="pdf-editor" *ngIf="noPDfOpen">
        <app-pdf-home #pdfRecents (startLoading)="startLoading()" (endLoading)="endLoading()" (fromLocal)="openPDFFromLocal($event)" (renamePDF)="renamePdf($event)" (fromDrive)="openPDFFromDrive()" (fromRecent)="openFromRecent($event)" (fromLink)="openFromLink($event)" (dowloadRecent)="downloadRecent($event)" (onClickPdf)="onClickPdf($event)"></app-pdf-home>
    </div>

</div>
