import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ConvertToImageService } from '../../../shared/commands/convert-to-image.service';
import { SmService } from 'supermappe-core';

import { TtsService } from '../../../shared/commands/tts.service';
import { ShareMapService } from '../../../shared/share-map/share-map.service';
import { FbService } from '../../../shared/commands/fb.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { MapStateService } from '../../../shared/map-state.service';
import { Router } from '@angular/router';
import { PrintMapService } from '../../../shared/commands/print-map.service';
import { ZoomService } from '../../../shared/commands/zoom.service';
import { MapFoldingService } from '../../../shared/commands/map-folding.service';
import { CopyAndOpenMapService } from '../../../shared/commands/copy-and-open-map.service';
import { MapShowService } from '../../../map-show/map-show.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FirebaseService } from '../../../core/firebase/firebase.service';

@Component({
    selector: 'app-view-menu',
    templateUrl: './view-menu.component.html',
    styleUrls: ['./view-menu.component.scss']
})
export class ViewMenuComponent implements OnInit, OnDestroy {

    @Input() mapId = '';
    @Output() startLoading = new EventEmitter<any>();
    @Output() endLoading = new EventEmitter<boolean>();
    @Input() isLoading = true;
    ttsEnable = false;
    canCopy = false;
    canEdit = true;
    logged = false;
    findActive = false;
    isUserExpired = false;

    onLoadedSubscription: Subscription | undefined;
    onSwitchToEditShowSubscription: Subscription | undefined;

    // isHandMode: boolean;
    selectiondata: any;
    mapStateOpen = false;

    constructor(private router: Router,
        private smService: SmService,
        // private eventBroker: SmEventBroker,
        private convertToImageService: ConvertToImageService,
        private shareMapService: ShareMapService,
        // private interactionModeService: InteractionmodeService,
        private ttsService: TtsService,
        private printService: PrintMapService,
        // private domSanitizer: DomSanitizer,
        private zoomService: ZoomService,
        // private smeService: SmeService,
        private mapFoldingService: MapFoldingService,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private mapStateService: MapStateService,
        private copyAndOpenMapService: CopyAndOpenMapService,
        private fbService: FbService,
        private mapShowService: MapShowService,
        private firebaseService: FirebaseService) {
    }

    ngOnInit() {
        this.ttsEnable = false;
        // this.isHandMode = false;
        this.mapStateOpen = true;
        this.logged = this.authenticationService.isAuthenticated();
        this.canEdit = this.logged && !this.mapStateService.readOnly && !this.isUserExpired;
        this.canCopy = this.logged && this.mapStateService.readOnly;
        this.isUserExpired = this.authenticationService.isUserExpired();
        this.onLoadedSubscription = this.mapStateService.onLoaded.subscribe((error: any) => {
            this.canEdit = this.logged && !this.mapStateService.readOnly && !this.isUserExpired;
            this.canCopy = this.logged && this.mapStateService.readOnly;
        });
        if (this.logged) {
            this.fbService.initShareMap(this.mapId);
        }

        this.onSwitchToEditShowSubscription = this.mapShowService.onSwitchToEditShow.subscribe((isEditShow: boolean) => {
            if (this.findActive) {
                this.findActive = false;
            }
        });
    }

    ngOnDestroy() {
        if (this.onLoadedSubscription) { this.onLoadedSubscription.unsubscribe(); }
        if (this.onSwitchToEditShowSubscription) { this.onSwitchToEditShowSubscription.unsubscribe(); }
    }

    centerMap() {
        this.smService.resizeMap();
    }

    zoom(direction: number) {
        this.zoomService.zoom(direction);
    }

    TTSRead() {
        if (this.smService) {
            this.smService.toggleTTS();
            this.ttsEnable = this.smService.isTTSEnabled();
            if (this.ttsEnable) {
                this.ttsService.speak(this.translateService.instant("TTS_ENABLED_SPEECHSYNTHESIS_MESSAGE"), this.translateService.currentLang);
            } else {
                this.ttsService.stop();
            }
        }
    }

    printMap(qrcodes: boolean) {
        this.printService.printMap(this.mapStateService.limitPrintSize, qrcodes);
    }

    convertToImage(qrcodes: boolean) {

        this.convertToImageService.convert(true, qrcodes, this.mapStateService.id, this.mapStateService.googleDocFileId);
    }

    shareMap() {
        this.shareMapService.showShareMapDialog(this.mapStateService.id, this.mapStateService.name, false, true, this.mapStateService.isShared, false);
    }

    setFoldingState(open: boolean) {
        this.mapFoldingService.setFoldingState(open);
    }

    addMapEvent(type: string, payload: string) {
        const userEmail = this.authenticationService.getUserEmail();
        const userName = this.authenticationService.getUserName();
        const userIcon = this.authenticationService.getUserImageUrl();
        return this.firebaseService.addMapEvent('no-guid', type, userEmail, userName, userIcon, this.mapStateService.id, payload);
    }

    navigateToEdit() {
        this.addMapEvent('user_out', '').then(() => {
            if (!this.authenticationService.isAuthenticated()) {
                this.router.navigate(['login', 'map-edit/' + this.mapStateService.id]);
            } else {
                window.location.href = 'map-edit/' + this.mapStateService.id;
            }
            // this.router.navigate(['home']);
        }).catch((error) => {
            if (!this.authenticationService.isAuthenticated()) {
                this.router.navigate(['login', 'map-edit/' + this.mapStateService.id]);
            }
        });
        // this.router.navigate(['map-edit', this.mapStateService.id]);
        // window.location.href = 'map-edit/' + this.mapStateService.id;
    }


    copyMap() {
        this.isLoading = true;
        this.startLoading.emit();

        //this.copyAndOpenMapService.endLoading = this.endLoading;
        this.copyAndOpenMapService.copyAndOpenMap(this.mapStateService.id, this.mapStateService.name, undefined, () => { this.endLoading?.emit(); }).then(() => {
            this.endLoading?.emit(true);
        }).catch((err) => {
            this.endLoading?.emit(false);
        });

    }

    mapShowing() {
        this.mapShowService.showing(this.mapStateService.id);
    }

    navigateToLogin() {
        this.router.navigate(['login', 'map-view/' + this.mapStateService.id]);
    }
}
