import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nobrowser',
  templateUrl: './nobrowser.component.html',
  styleUrls: ['./nobrowser.component.scss']
})
export class NobrowserComponent {

  constructor() { }

}
