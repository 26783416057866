<div class="custom-toolbar">
    <button #fontbutton class="font-button mat-tooltip-trigger" mat-menu mat-icon-button [matMenuTriggerFor]="font" color="primary" matTooltip="{{fontButtonTooltip}}" [style.font-family]="selectedFontFamily">
        <span class="font-span">Aa</span></button>
    <mat-menu #font="matMenu" [overlapTrigger]="false" id="font-target" [style.font-family]="selectedFontFamily">
        <div *ngFor="let font of fontFamilies" mat-menu-item (click)="changeFontFamily($event)">
            <mat-icon *ngIf="selectedFontFamily === font.name">check</mat-icon>
            <span [ngClass]="selectedFontFamily === font.name ? 'menu-item-name':'menu-item-name-spacer'" [style.font-family]="font.name">{{font.name}}</span>
        </div>
    </mat-menu>
    <app-button-color-picker [pickerIcon]="fontColorIcon" [toolTip]="fontColorTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedFontColor" (onChangeColor)="changeFontColor($event)" [colors]="colors" [showTransparent]="false"></app-button-color-picker>
    <button #fontsizebutton class="fontsize-button mat-tooltip-trigger" mat-menu mat-icon-button matTooltip="{{'FORMAT_NODE_TOOLBAR-FONT-SIZE' | translate}}" color="primary" [matMenuTriggerFor]="fontsize">
        <span class="fontsize-span">{{selectedFontSize}}</span></button>
    <mat-menu #fontsize="matMenu" [overlapTrigger]="false" id="fontsize-target">
        <div *ngFor="let size of fontSizes" mat-menu-item (click)="changeFontSize($event)">
            <mat-icon *ngIf="selectedFontSize === size.value">check</mat-icon>
            <span [ngClass]="selectedFontSize === size.value ? 'menu-item-name':'menu-item-name-spacer'">{{size.value}}</span>
        </div>
    </mat-menu>
    <button mat-icon-button class="mat-tooltip-trigger" color="primary" matTooltip="{{'FORMAT_NODE_TOOLBAR-BOLD' | translate}}" [ngClass]="{'bold-mode ': isBold}" (click)="setBold()">
        <mat-icon [attr.aria-label]="'FORMAT_NODE_TOOLBAR-BOLD' | translate" fontSet="material-icons-outlined">format_bold</mat-icon>
    </button>
    <app-button-color-picker [pickerIcon]="highlightIcon" [toolTip]="fontColorHighlightTooltip" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedHighlightColor" (onChangeColor)="changeHighlightColor($event)" [colors]="highlightColors"></app-button-color-picker>
</div>
