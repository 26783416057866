<div *ngIf="isMine && showGoogleDoc">
  <!-- Google doc (se non ce l'ha già è grigia e lo crea direttaente) -->
  <div class="custom-item" *ngIf="showGoogleDoc && !hasGoogleDoc" mat-menu-item color="primary" matTooltip="{{'CREATE_GOOGLE_DOC' | translate}}" (click)="createOrUpdateGoogleDoc()">
    <mat-icon class="custom-icon" color="primary" svgIcon="file-plus"></mat-icon>
    <span class="custom-span" translate>GOOGLE_DOC</span>
  </div>
  <!-- Google doc (se ce l'ha già è blu e apre il menu)-->
  <div *ngIf="showGoogleDoc && hasGoogleDoc" class="custom-item" mat-menu-item color="primary" [matMenuTriggerFor]="googleDocMenu">
    <mat-icon class="custom-icon" color="warn" svgIcon="doc-google"></mat-icon>
    <span class="custom-span" translate>GOOGLE_DOC</span>
  </div>
  <mat-menu #googleDocMenu [overlapTrigger]="false">
    <div class="custom-item" mat-menu-item color="primary" (click)="createOrUpdateGoogleDoc()">
      <mat-icon class="custom-icon" color="primary" svgIcon="file-restore"></mat-icon>
      <span class="custom-span" translate>UPDATE_GOOGLE_DOC</span>
    </div>
    <div class="custom-item" mat-menu-item color="primary" (click)="openGoogleDoc()">
      <mat-icon class="custom-icon" color="primary" svgIcon="arrow-top-right"></mat-icon>
      <span class="custom-span" translate>OPEN_GOOGLE_DOC</span>
    </div>
  </mat-menu>
</div>
