<!-- #menuTrigger="matMenuTrigger" -->
<!-- <div mat-menu-item mat-menu-item-submenu-trigger aria-haspopup="true" role="menuitem" (mouseenter)="openMenu()" class="picker-container" [disabled]="disabled" [matMenuTriggerFor]="colorMenu" color="primary"> -->
<!-- <mat-icon class="custom-icon" mat-icon [svgIcon]="pickerIcon"></mat-icon>
  <span class="custom-span">{{caption}}</span>
  <mat-menu class="mouseleave" #colorMenu="matMenu">
    <div (mouseleave)="closeMenu()"> -->
<!-- <mcc-color-picker *ngIf="showTransparent" [selectedColor]="selectedColor" [hideButtons]="true" [overlay]="false" (selected)="selected($event)" (change)="changeColor($event)" [usedSizeColors]="usedSizeColors" [usedColorLabel]="usedColorLabel" hideEmptyUsedColors [hideColorPickerSelector]="true" [hideHexForms]="true">
    <mcc-color-picker-collection [colors]="colors" [size]="colors.length" [transparent]="showTransparent"></mcc-color-picker-collection>
</mcc-color-picker> -->
<mcc-color-picker [selectedColor]="selectedColor" [hideButtons]="true" [overlay]="false" (selected)="selected($event)" (change)="changeColor($event)" [usedSizeColors]="usedSizeColors" [usedColorLabel]="usedColorLabel" hideEmptyUsedColors hideTransparentUsedColors [hideColorPickerSelector]="true" [hideUsedColors]="hideUsedColors" [hideHexForms]="true">
    <mcc-color-picker-collection *ngIf="showTransparent" label="{{nocolor}}" [size]="0" [transparent]="showTransparent"></mcc-color-picker-collection>
    <mcc-color-picker-collection [colors]="colors" [size]="colors.length" label="{{colorLabel}}" [transparent]="false"></mcc-color-picker-collection>
</mcc-color-picker>

<!-- </div>
  </mat-menu> -->

<!-- </div> -->
