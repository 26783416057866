import { Component, OnInit, ViewChild, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { ImageMapsService } from '../commands/image-maps.service';
import { ResourceUpload } from '../../core/firebase/resource-upload';
import { DeviceService } from '../../core/device.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CaptureCameraComponent } from '../capture-camera/capture-camera.component';
import { ExtraItem, ExtraService } from '../commands/extra.service';
import { Subscription } from 'rxjs';
import { GoogleService } from '../../core/google/google.service';
import { I18nService } from '../../core/i18n.service';
import { SmService } from 'supermappe-core';
import { SmeService } from '../../core/sme/sme.service';
import { WebSearchService } from '../../shared/commands/web-search.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

declare let google: any;

@Component({
    selector: 'app-image-menu',
    templateUrl: './image-menu.component.html',
    styleUrls: ['./image-menu.component.scss']
})
export class ImageMenuComponent implements OnInit, OnDestroy {

    @ViewChild('inputImage')
    public inputImage: ElementRef | undefined;

    isDesktop = false;
    hasCamera = false;
    currentImageUpload: ResourceUpload | undefined;
    error = '';
    picker: any;

    cameraSubscription: Subscription | undefined;
    fileChoosenSubscription: Subscription | undefined;

    constructor(
        private webSearchService: WebSearchService,
        private dialog: MatDialog,
        private imageMapsService: ImageMapsService,
        private smeService: SmeService,
        private googleService: GoogleService,
        private deviceService: DeviceService,
        private extraService: ExtraService,
        private i18nService: I18nService,
        private authenticationService: AuthenticationService,
        private smService: SmService) { }

    ngOnInit() {
        this.deviceService.hasCamera()
            .then((_hasCamera) => {
                this.hasCamera = _hasCamera;
            });
        this.isDesktop = this.deviceService.isDesktopDevice();
    }

    ngOnDestroy() {
        if (this.cameraSubscription) { this.cameraSubscription.unsubscribe(); }
    }

    isGoogleUser() {
        return this.authenticationService.isGoogleUser() || this.authenticationService.linkedWithGoogle();
    }

    insertImageFromUri() {
        this.imageMapsService.insertImageFromUriWithDialog();
    }

    insertImageFromLocal(event: any) {
        const currentImageFile: File = event.target.files[0];

        this.imageMapsService.insertImageFromFile(currentImageFile, null);
        if (this.inputImage) {
            this.inputImage.nativeElement.value = '';
        }
    }

    insertImageFromWeb() {
        //PREVIOUS IMPLEMENTATION
        /*let accessToken = '';
        if (this.fileChoosenSubscription) { this.fileChoosenSubscription.unsubscribe(); }
        this.fileChoosenSubscription = this.googleService.fileChoosen.subscribe((result: any) => {
            const uri = result.imageUrl;
            if (uri && uri !== '') {

                this.imageMapsService.insertImageFromUri(uri);
            }

            this.fileChoosenSubscription?.unsubscribe();
        });
        let textToSearch = '';
        const node = this.smService.getSelectedNode();
        if (node && node.titleText) {
            textToSearch = node.titleText;
        }
        this.smeService.getAccessTokenPromise()
            .then((_accessToken: string) => {
                accessToken = _accessToken;
                return this.googleService.loadPicker();
            }).then(() => {
                const language = this.i18nService.language;
                this.googleService.createPickerSearchImage(accessToken, language, textToSearch);
            });*/

        const node = this.smService.getSelectedNode();
        if (node && node.titleText) {
            this.webSearchService.textSearch = node.titleText;
        } else {
            this.webSearchService.textSearch = "";
        }

        if (this.webSearchService.webSearchPanelOpen) {
            this.webSearchService.toggleWebSearchPanel();
        }

        this.webSearchService.searchOnWeb('googleimages');
        this.webSearchService.disableMagicSearch();
    }

    insertImageFromDrive() {
        let accessToken = '';
        if (this.fileChoosenSubscription) { this.fileChoosenSubscription.unsubscribe(); }
        this.fileChoosenSubscription = this.googleService.fileChoosen.subscribe((result: any) => {
            const _fileId = result.fileId;
            if (_fileId && _fileId !== '') {
                const fileId = _fileId;
                this.imageMapsService.insertImageFromDrive(fileId);
            }
            this.fileChoosenSubscription?.unsubscribe();
        });
        this.smeService.getAccessTokenPromise()
            .then((_accessToken: string) => {
                accessToken = _accessToken;
                return this.googleService.loadPicker();
            }).then(() => {
                const language = this.i18nService.language;
                this.googleService.createPicker(accessToken, this.picker, language, true);
            });
    }

    insertImageFromGoogle() {
        if (!this.extraService.isExtraPanelOpen) {
            this.extraService.toggleExtraPanel(ExtraItem.CLOSE);
        }
    }

    showCameraDialog() {
        const isDesktop: boolean = this.deviceService.isDesktopDevice();
        if (isDesktop) {
            // Su desktop non si può usare la gestione tramite input tag:
            // apriamo una dialog nostra per catturare il video
            const dialogConfig = new MatDialogConfig();
            dialogConfig.autoFocus = true;
            const dialogRef = this.dialog.open(CaptureCameraComponent, dialogConfig);
            if (this.cameraSubscription) { this.cameraSubscription.unsubscribe(); }
            this.cameraSubscription = dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.imageMapsService.insertImageFromFile(result, null);
                }
            });
        }
    }
}
