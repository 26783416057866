import { VirtualKeyboardLayout } from 'mathlive';

export const EN_ANALYSIS_KEYBOARD: VirtualKeyboardLayout = {
    id: "ANALYSIS",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            {
                class: 'small',
                latex: '\\sum_{\\placeholder{}}^\\placeholder{}',
                tooltip: 'summation'
            },
            {
                class: 'small',
                latex: '\\prod_{\\placeholder{}}^\\placeholder{}',
                tooltip: 'production'
            },
            {
                class: 'small',
                latex: '\\int_\\!\\placeholder{}\\,\\mathrm{d}x',
                tooltip: 'primitive'
            },
            {
                class: 'small',
                latex: ' \\int_\\placeholder{}\\!\\placeholder{}\\,\\mathrm{d}x',
                tooltip: 'integral'
            },
            {
                class: 'small',
                latex: '\\int_{\\placeholder{}}^{\\placeholder{}}\\!\\placeholder{}\\,\\mathrm{d}x',
                tooltip: 'integral'
            }
        ],
        // Second row
        [
            {
                latex: '\\lim_{x\\to0^+}',
                tooltip: 'limit for x that tends to zero from right'
            },
            {
                latex: '\\lim_{x\\to0^-}',
                tooltip: 'limit for x that tends to zero from left'
            },
            {
                latex: '\\lim_{x\\to0}',
                tooltip: 'limit for x that tends to zero'
            },
            {
                latex: '\\lim_{x\\to-\\infty}',
                tooltip: 'limit for x that tends to minus infinity'
            },
            {
                latex: ' \\lim_{x\\to+\\infty}',
                tooltip: 'limit for x that tends to plus infinity'
            }
        ],
        // Third row
        [
            {
                latex: '\\infty',
                tooltip: 'infinity'
            },
            {
                latex: '\\epsilon',
                tooltip: 'epsilon'
            },
            {
                latex: '\\delta',
                tooltip: 'delta'
            },
            {
                latex: '\\theta',
                tooltip: 'theta'
            },
            {
                class: 'small',
                latex: '\\frac{\\mathrm{d}}{\\mathrm{d}x}',
                tooltip: 'derived from x"'
            }
        ],
        // Fourth row
        [
            {
                latex: '\\to',
                tooltip: 'that tends to'
            }
        ]
    ]
};
