export class CustomSelectElement {
    public viewValue: string;
    public value: any;
    public icon: string;
    public hidden: boolean;
    public browserTTS: boolean;

    constructor(_value: any, _viewValue: string, _icon: string, _hidden = false, browserTTS = true) {
        this.value = _value;
        this.viewValue = _viewValue;
        this.icon = _icon;
        this.hidden = _hidden;
        this.browserTTS = browserTTS;
    }
}
