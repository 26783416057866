import { Injectable, EventEmitter } from '@angular/core';
import { MapStateService } from '../map-state.service';
import { SmService } from 'supermappe-core';
import { GoogleService } from '../../core/google/google.service';
import { SmeService } from '../../core/sme/sme.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../core/i18n.service';
import { Subscription } from 'rxjs';
import { ConfirmationService } from '../dialog/confirmation.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Logger } from '../../core/logger.service';
import { throwError as observableThrowError } from 'rxjs';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { Router } from '@angular/router';
import { MapOperationType } from '../map-operations';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

const routes = {
    apiFindGoogleDocFileIdbyMapId: '/api/findgoogledocfileidbymapid2gen'
};

const logger: Logger = new Logger('CreateGoogleDocService');

@Injectable({
    providedIn: 'root'
})
export class CreateGoogleDocService {

    public onGoogleDocFileIdChange = new EventEmitter<string>();

    private confirmSubscription: Subscription | undefined;
    private statShareSubscription: Subscription | undefined;

    private matSnackbarRef: MatSnackBarRef<any> | undefined;

    constructor(
        private mapStateService: MapStateService,
        private smService: SmService,
        private googleService: GoogleService,
        private smeService: SmeService,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private confirmationService: ConfirmationService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private http: HttpClient
    ) { }

    private getLocaleStrings(): any {
        let author = '';
        if (this.smService.sm && this.smService.sm.map && this.smService.sm.map.mapProps && this.smService.sm.map.mapProps.author) {
            author = this.smService.sm.map.mapProps.author;
        } else {
            author = 'Anastasis';
        }
        return {
            notes: this.translateService.instant(extract('DOC_NOTES')),
            mapImages: this.translateService.instant(extract('DOC_MAP_IMAGES')),
            author: this.translateService.instant(extract('DOC_AUTHOR')) + ' - ' + author,
            credits: this.translateService.instant(extract('DOC_CREDITS'))
        };
    }

    public createGoogleDoc() {
        this.mapStateService.setBusyState(true);
        const json = this.smService.getMapDefaultReadOrderInfo();
        json.image = 'map.jpg';
        const locale = this.getLocaleStrings();
        const updated = this.mapStateService.googleDocFileId !== '';
        const notes = this.smService.getNotes();
        this.googleService.getSMXFolderIds().then((folderIds: any) => {
            this.smeService.createNewGoogleDocument(this.mapStateService.name, json, locale, notes, this.mapStateService.id, folderIds.docs, this.mapStateService.googleDocFileId)
                .subscribe({

                 next: (id: string) => {
                        this.onGoogleDocFileIdChange.emit(id);
                        this.mapStateService.googleDocFileId = id;
                        this.mapStateService.setBusyState(false);
                        if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
                        this.statShareSubscription = this.smeService.addCustomMapStat(this.mapStateService.id, MapOperationType.STAT_GDOCCREATED, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
                        const msg = updated ? this.translateService.instant(extract('GOOGLE_DOC_UPDATED')) : this.translateService.instant(extract('GOOGLE_DOC_CREATED'));
                        this.openSnackBar(msg, this.translateService.instant(extract('OPEN')), id);
                        // if (this.confirmSubscription) { this.confirmSubscription.unsubscribe(); }
                        // this.confirmSubscription = this.confirmationService.confirm(
                        //     this.translateService.instant(extract('GOOGLE_DOC')),
                        //     this.translateService.instant(extract('GOOGLE_DOC_CREATED')),
                        //     this.translateService.instant(extract('BUTTON_OK')),
                        //     '').subscribe((result) => {
                        //         // Stat
                        //         if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
                        //         this.statShareSubscription = this.smeService.addCustomMapStat(this.mapStateService.id, MapOperationType.STAT_GDOCCREATED, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
                        //     });
                    },
                   error: (error: any) => {
                        // JHUBA: METTERE QUI DIALOG ERRORE
                        if (error) {
                            if (error.status === 401) {
                                this.authenticationService.logout().subscribe(() => {
                                    this.router.navigate(['login']);
                                });
                                 
                            }
                        }
                        this.mapStateService.setBusyState(false);
                       // alert(`ERROR: ${error}`);
                    }});
        });
    }

    public findGoogleDocFileIdbyMapId(mapId: string) {
        const body = {
            mapId: mapId
        };
        return this.http.post(routes.apiFindGoogleDocFileIdbyMapId, body, {})
            .pipe(
                map(
                    (res: any) => {
                        const id = res.id || '';
                        this.mapStateService.googleDocFileId = id;
                        this.onGoogleDocFileIdChange.emit(id);
                    }
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public openGoogleDoc(googleFileId: string) {
        const url = 'https://docs.google.com/document/d/' + googleFileId + '/edit';
        window.open(url, '_blank');
    }

    private openSnackBar(message: string, action: string, googleDocId: string) {
        this.matSnackbarRef = this._snackBar.open(message, action, { verticalPosition: 'top', horizontalPosition: 'center', duration: 5000 });
        this.matSnackbarRef.onAction().subscribe(() => {
            // console.log('***HAI PREMUTO ACTION***');
            if (this.matSnackbarRef) {
                this.matSnackbarRef.dismiss();
                this.openGoogleDoc(googleDocId);
            }
        });
        this.matSnackbarRef.afterDismissed().subscribe(() => {
            // console.log('***SNACKBAR CHIUSO SENZA AZIONE***');
        });
    }

}
