import { VirtualKeyboardLayout } from 'mathlive';

export const EN_LOG_EXP_KEYBOARD: VirtualKeyboardLayout = {
    id: "LOG_EXP",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            '[separator-5]'
        ],
        // Second row
        [
            {
                latex: '\\log_{\\placeholder{}}\\left(\\placeholder{}\\right)',
                tooltip: 'base-n logarythm'
            },
            {
                latex: '\\log\\left(\\placeholder{}\\right)',
                tooltip: 'logarithm'
            },
            {
                latex: '\\log_{10}\\left(\\placeholder{}\\right)',
                tooltip: 'base-10 logarithm'
            },
            {
                latex: '\\ln\\left(\\placeholder{}\\right)',
                tooltip: 'natural logarithm'
            }
        ],
        // Third row
        [
            {
                latex: '10^{\\placeholder{}}',
                tooltip: 'ten elevated to'
            },
            {
                latex: '{e}^{{x}}',
                tooltip: 'exponential function'
            }
        ],
        // Fourth row
        [
            '[separator-5]'
        ]
    ]
};
