import { VirtualKeyboardLayout } from 'mathlive';

export const EN_PRIMARY_KEYBOARD: VirtualKeyboardLayout = {
    id: "PRIMARY",
    labelClass: 'MLK__tex',
    rows: [
        // First row
        [
            {
                key: '(',
                label: '(',
                tooltip: 'open round bracket'
            },
            {
                key: ')',
                label: ')',
                tooltip: 'closed round bracket'
            },
            '[separator-5]',
            {
                key: '7',
                label: '7',
                tooltip: ''
            },
            {
                key: '8',
                label: '8',
                tooltip: ''
            },
            {
                key: '9',
                label: '9',
                tooltip: ''
            },
            {
                latex: ':',
                label: ':',
                tooltip: 'divided by'
            },
            '[separator-5]',
            {
                latex: '\\pi',
                tooltip: 'pi'
            },
            {
                latex: '\\degree',
                tooltip: 'degrees'
            },
            {
                latex: '\\%',
                tooltip: 'percent'
            },
        ],
        // Second row
        [
            {
                key: '[',
                label: '[',
                tooltip: 'open square bracket'
            },
            {
                key: ']',
                label: ']',
                tooltip: 'closed square bracket'
            },
            '[separator-5]',
            {
                key: '4',
                label: '4',
                tooltip: ''
            },
            {
                key: '5',
                label: '5',
                tooltip: ''
            },
            {
                key: '6',
                label: '6',
                tooltip: ''
            },
            {
                latex: '\\times',
                tooltip: 'multiplied by'
            },
            '[separator-5]',
            {
                class: 'small',
                latex: '\\frac{\\placeholder{}}{\\placeholder{}}',
                tooltip: 'fraction'
            },
            {
                latex: '#@^{#0}}',
                tooltip: 'power'
            },
            {
                latex: '\\sqrt{#0}',
                tooltip: 'square root'
            },
        ],
        // Third row
        [
            {
                key: '{',
                label: '{',
                tooltip: 'open curly bracket'
            },
            {
                key: '}',
                label: '}',
                tooltip: 'closed curly bracket'
            },
            '[separator-5]',
            {
                key: '1',
                label: '1',
                tooltip: ''
            },
            {
                key: '2',
                label: '2',
                tooltip: ''
            },
            {
                key: '3',
                label: '3',
                tooltip: ''
            },
            {
                key: '-',
                label: '-',
                tooltip: 'minus'
            },
            '[separator-5]',
            {
                label: '<',
                latex: '<',
                tooltip: 'less than'
            },
            {
                label: '>',
                latex: '>',
                tooltip: 'greater than'
            },
            {
                latex: '\\overline{\\placeholder{}}',
                tooltip: 'overbar'
            },
        ],
        // Fourth row
        [
            {
                latex: '\\pm',
                tooltip: 'plus or minus'
            },
            {
                latex: '\\neq',
                tooltip: 'not equal'
            },
            '[separator-5]',
            {
                key: '0',
                label: '0',
                tooltip: ''
            },
            {
                key: ',',
                label: ',',
                tooltip: 'comma'
            },
            {
                key: '=',
                label: '=',
                tooltip: 'equals'
            },
            {
                key: '+',
                label: '+',
                tooltip: 'plus'
            },
            '[separator-5]',
            {
                label: '≤',
                latex: '\\le',
                tooltip: 'less or equal than'
            },
            {
                label: '≥',
                latex: '\\ge',
                tooltip: 'greater or equal than'
            },
            {
                latex: "\\infty",
                tooltip: 'infinity'
            }
        ]
    ]
};
