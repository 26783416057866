import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SmeService } from 'src/app/core/sme/sme.service';
import { UiConstants } from '../ui-constants';

@Component({
    selector: 'app-confirm-math-ocrdialog',
    templateUrl: './confirm-math-ocrdialog.component.html',
    styleUrls: ['./confirm-math-ocrdialog.component.scss']
})
export class ConfirmMathOCRDialogComponent implements OnInit {
    public email = '';
    public title = '';
    public image = '';
    public imgClessidra = 'assets/node/clessidra.png';
    public isImageSet = false;
    public isScansSet = false;
    public scansLeft = 0;
    public scansInfo = '';

    private _message = '';
    get message(): string {
        return this._message
    }
    set message(value: string) {
        this._message = value.replace(/\n/g, '<br/>');
    }

    public ok = true;
    public cancel = '';
    public insert = '';
    public copyLatex = '';
    public mathOCR_icon: string = "assets/icons/icon_mathocr.png";
    public serviceRef: any;

    public get disabled(): boolean {
        return (!this.isImageSet || !this.isScansSet || this.scansLeft <= 0);
    }

    constructor(
        public dialogRef: MatDialogRef<ConfirmMathOCRDialogComponent>,
        private smeService: SmeService,
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.smeService.getOcrMathPixScanCount(this.email).subscribe({
            next: (res: any) => {
                console.log('getOcrMathPixScanCount OK: ' + JSON.stringify(res));
                this.scansInfo = UiConstants.getScansInfo(res.result, this.translateService);
                this.scansLeft = res.result.scansLeft;
                this.isScansSet = true;
            },
            error: (error => {
                console.error(`getOcrMathPixScanCount error: ${error}`);
            })
        })
    }

    public onCopyLatex() {
        this.serviceRef.isCopyLatex = true;
        this.dialogRef.close(true);

    }
}
