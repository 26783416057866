import { VirtualKeyboardLayout } from 'mathlive';

export const IT_GREEK_KEYBOARD: VirtualKeyboardLayout = {
    id: "GREEK",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // αβγδεζηθικλμνξοπρστυφχψω
        // ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩ
        // First row
        [

            { label: 'α', shift: { label: 'Α' }, tooltip: 'alfa', },
            { label: 'β', shift: 'Β', tooltip: 'beta' },
            { label: 'γ', shift: 'Γ', tooltip: 'gamma' },
            { label: 'δ', shift: 'Δ', tooltip: 'delta' },
            { label: 'ε', shift: 'Ε', tooltip: 'epsilon' },
            { label: 'ζ', shift: 'Ζ', tooltip: 'zeta' },
            { label: 'η', shift: 'Η', tooltip: 'eta' },
            { label: 'θ', shift: 'Θ', tooltip: 'theta' },

        ],
        // Second row
        [
            { label: 'ι', shift: 'Ι', tooltip: 'iota' },
            { label: 'κ', shift: 'Κ', tooltip: 'kappa' },
            { label: 'λ', shift: 'Λ', tooltip: 'lambda' },
            { label: 'μ', shift: 'Μ', tooltip: 'mu' },
            { label: 'ν', shift: 'Ν', tooltip: 'nu' },
            { label: 'ξ', shift: 'Ξ', tooltip: 'xi' },
            { label: 'ο', shift: 'Ο', tooltip: 'omicron' },
            { label: 'π', shift: 'Π', tooltip: 'pi' },
        ],
        // Third row
        [
            { label: 'ρ', shift: 'Ρ', tooltip: 'rho' },
            { label: 'σ', shift: 'Σ', tooltip: 'sigma' },
            { label: 'τ', shift: 'Τ', tooltip: 'tau' },
            { label: 'υ', shift: 'Υ', tooltip: 'upsilon' },
            { label: 'φ', shift: 'Φ', tooltip: 'fi' },
            { label: 'χ', shift: 'Χ', tooltip: 'chi' },
            { label: 'ψ', shift: 'Ψ', tooltip: 'psi' },
            { label: 'ω', shift: 'Ω', tooltip: 'omega' },
        ],
        // Fourth row
        [
            '[separator-5]'
        ]
    ]
};
