import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { MapCopyComponent } from './map-copy.component';
import { AuthenticationGuard } from '../core/authentication/authentication.guard';

const routes: Routes = Route.withShell([
  { path: 'map-copy', component: MapCopyComponent, data: { title: extract('TITLE_COPY_MAP') }, canActivate: [AuthenticationGuard] }
]);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthenticationGuard]
})
export class MapCopyRoutingModule { }
