import { Injectable } from '@angular/core';
import { SmService } from 'supermappe-core';
import { MapStateService } from '../map-state.service';
import { QRCodesService } from './qrcodes.service';
import jspdf, { HTMLOptions, jsPDFOptions } from 'jspdf';

@Injectable({
    providedIn: 'root'
})
export class ConvertToImageService {

    constructor(private smService: SmService, private mapStateService: MapStateService, private qrCodesService: QRCodesService) { }

    convert(stamps: boolean, qrcodes: boolean, mapLink: string, gdocLink: string) {
        return new Promise((resolve, reject) => {
            if (!qrcodes) {
                mapLink = '';
                gdocLink = '';
            }
            this.insertImageInDocument(qrcodes).then(() => {
                return this.qrCodesService.injectQRCodesInPage(mapLink, gdocLink);
            }).then(() => {
                const imageBase64 = this.smService.convertToJpegImage(stamps, null, this.mapStateService.limitPrintSize, qrcodes);
                this.removeImgQrCode();
                const filename = (this.mapStateService.name || 'map') + '.jpg';
                const a = document.createElement('a');
                const blob = this.dataURItoBlob(imageBase64.image);
                a.href = URL.createObjectURL(blob); // dataURI;
                a['download'] = filename;
                const e = document.createEvent('MouseEvents');
                // Use of deprecated function to satisfy TypeScript.
                if (document.defaultView) {
                    e.initMouseEvent('click', true, false, document.defaultView, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                }
                a.dispatchEvent(e);
            }).catch(error => {
                return reject(error);
            });
        });
    }

    convertToPdf(stamps: boolean, mapLink: string, gdocLink: string) {
        return new Promise((resolve, reject) => {
            this.insertImageInDocument(true).then(() => {
                return this.qrCodesService.injectQRCodesInPage(mapLink, gdocLink);
            }).then(() => {
                const imageBase64 = this.smService.convertToJpegImage(stamps, null, 5000, true);
                this.removeImgQrCode();
                const filename = (this.mapStateService.name || 'map') + '.pdf';
                const a = document.createElement('a');
                const extent = this.smService.getMapExtent();
                const extentWidth = (extent.x2 - extent.x1);
                const extentHeight = (extent.y2 - extent.y1);
                const or = (extentHeight > extentWidth) ? 'p' : 'l';
                // if (extentHeight > 5000 || extentWidth > 5000) {
                //   extentHeight = 5000;
                //   extentWidth = 5000;
                // }
                const doc = new jspdf(or, 'pt', [extentWidth, extentHeight]);
                // const blob = this.dataURItoBlob(imageBase64.image);
                doc.addImage(imageBase64.image, 'JPEG', 0, 0, extentWidth, extentHeight);
                doc.save(filename);
                //  a.href = URL.createObjectURL(doc);
                a['download'] = filename;
                const e = document.createEvent('MouseEvents');
                // Use of deprecated function to satisfy TypeScript.
                if (document.defaultView) {
                    e.initMouseEvent('click', true, false, document.defaultView, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                    a.dispatchEvent(e);
                }
            }).catch(error => {
                return reject(error);
            });
        });
    }

    convertToPdfDocument(html: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const options: jsPDFOptions = {
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4',
            };
            const doc = new jspdf(options);
            const filename = (this.mapStateService.name || 'map') + '.pdf';
            Promise.resolve().then(async () => {
                const htmlOptions: HTMLOptions = { autoPaging: 'text', margin: [10, 0, 10, 0] };
                await doc.html(html, htmlOptions);
                doc.save(filename);
                return resolve(filename);
            }).catch(error => {
                return reject(error);
            });
        });
    }


    private removeImgQrCode() {
        let oldel = document.getElementById('map-qrcode');
        if (oldel) { oldel.remove(); }
        oldel = document.getElementById('gdoc-qrcode');
        if (oldel) { oldel.remove(); }
        oldel = document.getElementById('map-qrcode-symbol');
        if (oldel) { oldel.remove(); }
        oldel = document.getElementById('gdoc-qrcode-symbol');
        if (oldel) { oldel.remove(); }
        oldel = document.getElementById('stamp-image');
        if (oldel) { oldel.remove(); }
    }

    private insertImageInDocument(qrcodes: boolean) {
        return new Promise((resolve, reject) => {
            const el = document.createElement('img');
            let parentEl = document.getElementById('div-stamp-image');
            if (parentEl) {
                parentEl.appendChild(el);
                el.id = 'stamp-image';
                el.onload = (() => {
                    if (qrcodes) {
                        const mapimg = document.createElement('img');
                        parentEl = document.getElementById('div-map-qrcode-symbol');
                        if (parentEl) {
                            parentEl.appendChild(mapimg);
                            mapimg.id = 'map-qrcode-symbol';
                            mapimg.onload = (() => {
                                const docimg = document.createElement('img');
                                docimg.id = 'gdoc-qrcode-symbol';
                                parentEl = document.getElementById('div-gdoc-qrcode-symbol');
                                if (parentEl) {
                                    parentEl.appendChild(docimg);
                                    docimg.onload = (() => {
                                        return resolve('');
                                    });
                                    docimg.src = 'assets/doc-google-256.png?v=1';
                                }
                            });

                            mapimg.src = 'assets/sm-document-256.png?v=1';
                        }
                    } else {
                        return resolve('');
                    }

                });
                el.src = 'assets/fattocon.png?v=3';
            }
        });

    }

    public dataURItoBlob(dataURI: any) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = decodeURI(dataURI.split(',')[1]);
        }
        // separate out the mime component
        // const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const filetype = 'image/jpeg';

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: filetype });
    }

    private selectElement(element: any): boolean {
        if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNode(element);
            selection?.removeAllRanges();
            selection?.addRange(range);
            return true;
        }
        return false;
    }

    public copyMapImage(): boolean {
        this.mapStateService.isCopyingMapImage = true;
        const imageBase64 = this.smService.convertToJpegImage(true, null, this.mapStateService.limitPrintSize, false);
        const img = document.createElement('img');
        img.id = 'tempImage';
        img.src = imageBase64.image;
        document.body.appendChild(img);
        if (this.selectElement(img)) {
            document.execCommand('copy');
            window?.getSelection()?.removeAllRanges();
        } else {
            console.log('copyMapImage not supported');
            this.mapStateService.isCopyingMapImage = false;
            return false;
        }
        document.body.removeChild(img);
        this.mapStateService.isCopyingMapImage = false;
        return true;
    }

}
