import { Injectable, EventEmitter, Output } from '@angular/core';

declare let CKEDITOR: any;

@Injectable({
    providedIn: 'root'
})
export class NotepadService {

    @Output() onTextFromPdfReceived = new EventEmitter<string>();
    constructor() {

    }

    insertText(text: string) {

        this.onTextFromPdfReceived.emit(text);
    }
}
