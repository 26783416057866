import { Component, OnInit, OnDestroy } from '@angular/core';

import { SmService } from 'supermappe-core';
import { CustomizeToolbarService } from '../customize-toolbar.service';

import { CustomToolbarDto } from '../custom-toolbar-dto';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../../../core/i18n.service';
import { CustomSelectElement } from '../custom-select-element-dto';
import { UiConstants } from '../../../../shared/ui-constants';
import { SmEventBroker } from '../../../../shared/sm-event-broker';

import { MapStateService } from '../../../../shared/map-state.service';

@Component({
    selector: 'app-format-element-toolbar',
    templateUrl: './format-element-toolbar.component.html',
    styleUrls: ['./format-element-toolbar.component.scss']
})
export class FormatElementToolbarComponent implements OnInit, OnDestroy {
    backgroundTooltip = '';
    usedColorLabel = '';
    backgroundIcon = 'format-color-fill';
    borderTooltip = '';
    borderIcon = 'pencil01';

    colors = UiConstants.PALETTE.BASE;

    public bordersWidth: CustomSelectElement[];
    private baseTextPositions: CustomSelectElement[];
    public textPositions: CustomSelectElement[];
    public tableTextPositions: CustomSelectElement[];
    public nodeShapes: CustomSelectElement[];
    public isFramed: boolean = false;
    public nodeText = false;
    public nodeSelected = false;
    public tableSelected = false;
    public imageSelected = false;
    public hideNodeShape = false;
    public onlyNodeSelected = false;
    public nodeOrTableSelected = false;
    public nodeOrCellsSelected = false;
    public edgeArrowRightChecked = false;
    public edgeArrowLeftChecked = false;
    public edgeSelected = false;
    public isSingleElementSelected = false;
    public isFormatCopying = false;
    public totButtonVisible = 6; // 7

    selectedBorderColor = '#FFFFFF';
    selectedBackgroundColor = '#FFFFFF';
    selectedBorderIcon = 'border-1';
    selectedTextPosition = 'inside';
    selectedTextPosIcon = 'inside';
    selectedNodeShapeIcon = 'rectangle';
    // imageIcon: any;
    selectedBorderWidth = '';
    onChangeToolbarVisibilitySubscription: Subscription | undefined;
    onFormatCopiedSubscription: Subscription | undefined;

    constructor(
        private smService: SmService,
        private smEventBroker: SmEventBroker,
        private customizeToobarService: CustomizeToolbarService,
        private mapStateService: MapStateService,

        private translateService: TranslateService) {
        this.backgroundTooltip = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-BACKGROUND-COLOR'));
        this.usedColorLabel = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-MYCOLOR'));
        this.borderTooltip = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-BORDER-COLOR'));
        // this.imageIcon = '<mat-icon id="border-icon" class="under-select" mat-prefix [svgIcon]="'
        // + this.selectedBorderIcon
        //   + '"></mat-icon>';



        this.bordersWidth = UiConstants.BORDERSWIDTH;

        this.baseTextPositions = UiConstants.TEXTPOSITIONS;

        this.textPositions = this.baseTextPositions;

        this.tableTextPositions = UiConstants.TABLETEXTPOSITIONS;

        this.nodeShapes = UiConstants.NODESHAPES;
    }

    ngOnInit() {
        this.onChangeToolbarVisibilitySubscription =
            this.customizeToobarService.onChangeVisibilityToolbar.subscribe((_customData: CustomToolbarDto) => {
                this.changeSelection(_customData);
            });
        this.changeSelection(this.customizeToobarService.customToolbarDto);
        if (this.smEventBroker.formatCopiedData) {
            this.onFormatCopiedSubscription = this.smEventBroker.formatCopiedData.subscribe(() => {
                this.isFormatCopying = false;
                this.mapStateService.isFormatCopying = this.isFormatCopying;
            });
        }

    }

    ngOnDestroy(): void {
        if (this.onChangeToolbarVisibilitySubscription) { this.onChangeToolbarVisibilitySubscription.unsubscribe(); }
        if (this.onFormatCopiedSubscription) { this.onFormatCopiedSubscription.unsubscribe(); }
    }

    changeSelection(_selectionData: any): any {
        //
        if (_selectionData.brushColor) { this.selectedBackgroundColor = _selectionData.brushColor; }
        if (_selectionData.borderColor) { this.selectedBorderColor = _selectionData.borderColor; }
        if (_selectionData.isFramed !== undefined) { this.isFramed = _selectionData.isFramed; }
        // this.isFrameAvailable = (_selectionData.nNodesImage + _selectionData.nNodesImageFrame) > 0;
        this.nodeText = _selectionData.nodeType === 'text';

        this.nodeSelected = _selectionData.nodeSelected;
        this.edgeSelected = _selectionData.edgeSelected;
        // this.tableSelected = _selectionData.onlyNodeInTableSelected;
        this.tableSelected = _selectionData.isTableSelected;
        this.imageSelected = _selectionData.imageSelected;

        this.nodeOrTableSelected = ((this.nodeSelected && _selectionData.nodeType !== 'text') || this.tableSelected || _selectionData.onlyNodeInTableSelected);
        this.nodeOrCellsSelected = (this.nodeSelected || this.tableSelected);

        // this.onlyNodeSelected = this.nodeSelected && !this.tableSelected;
        if (this.tableSelected && !_selectionData.imageSelected && _selectionData.nCellsImage === 0) {
            // Remove from topping list
            this.textPositions = [];
            this.tableTextPositions.forEach(element => {
                this.textPositions.push(element);
            });
        } else {
            this.textPositions = [];
            this.baseTextPositions.forEach(element => {
                this.textPositions.push(element);
            });
        }
        if (this.edgeSelected) {
            if (_selectionData.edgeType === 'both') {
                this.edgeArrowLeftChecked = true;
                this.edgeArrowRightChecked = true;
            } else if (_selectionData.edgeType === 'forward') {
                this.edgeArrowLeftChecked = false;
                this.edgeArrowRightChecked = true;
            } else if (_selectionData.edgeType === 'backward') {
                this.edgeArrowLeftChecked = true;
                this.edgeArrowRightChecked = false;
            } else {
                this.edgeArrowLeftChecked = false;
                this.edgeArrowRightChecked = false;
            }
        }
        const nShaped = _selectionData.nNodes + _selectionData.nNodesText;
        this.hideNodeShape = (nShaped === 0);
        this.isSingleElementSelected = (_selectionData.elementselected === 1);

        this.totButtonVisible = 0;
        if (_selectionData.nodeSelected) {
            if (this.hideNodeShape) {
                this.totButtonVisible = 8;
            } else if (this.nodeText) {
                this.totButtonVisible = 6;
            } else {
                this.totButtonVisible = 9;
            }
        }
        if (_selectionData.edgeSelected) {
            if (_selectionData.nodeSelected) {
                this.totButtonVisible += 4;
            } else {
                this.totButtonVisible = 6;
            }
        }

        if (_selectionData.onlyNodeInTableSelected) {
            this.totButtonVisible = 5;
        }

        if (this.isSingleElementSelected) {
            this.totButtonVisible++;
        }

        this.customizeToobarService.formatElementButtonVisible = this.totButtonVisible;
        this.customizeToobarService.updateTotButton();

    }

    changeTextPosition(event: any) {
        this.selectedTextPosition = event.value;
        this.selectedTextPosIcon = event.icon;
        this.smService.setSelectionTextLayout(this.selectedTextPosition);
    }

    changeNodeShape(value: any) {
        this.selectedNodeShapeIcon = value;
        this.smService.setSelectionShape(value);
    }

    changeBackgroundColor(e: any) {
        if (e !== '') {
            this.selectedBackgroundColor = e;
            this.smService.setNodeBackgroundColor(e);
        }
    }

    changeBorderColor(e: any) {
        if (e !== '') {
            this.selectedBorderColor = e;
            this.smService.setBorderColor(e);
        }
    }

    changeBorderWidth(value: any) {
        //
        this.selectedBorderIcon = value.icon;
        this.selectedBorderWidth = value.value;
        this.smService.setSelectionWidth(this.selectedBorderWidth);
        // this.smService.setNodeWidth(this.selectedBorderWidth);
    }

    bringSelectionToFront() {
        this.smService.bringSelectionToFront();
    }

    sendSelectionToBack() {
        this.smService.sendSelectionToBack();
    }

    setSelectedEdgesTerminals(param: string) {
        if (param === 'left') {
            this.edgeArrowLeftChecked = !this.edgeArrowLeftChecked;
        }
        if (param === 'right') {
            this.edgeArrowRightChecked = !this.edgeArrowRightChecked;
        }
        this.smService.setSelectedEdgesTerminals(this.edgeArrowLeftChecked, this.edgeArrowRightChecked);
    }

    breakEdge() {
        this.smService.breakEdge();
    }

    curvedEdge(curved: boolean) {
        this.smService.curveEdge(curved);
    }

    copyFormat() {
        this.smService.startCopyFormat(!this.isFormatCopying);
        this.isFormatCopying = !this.isFormatCopying;
        this.mapStateService.isFormatCopying = this.isFormatCopying;
    }
}
