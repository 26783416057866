import { Injectable, EventEmitter } from '@angular/core';
import { SmService } from 'supermappe-core';
import { YoutubeService } from './youtube.service';
import { MapStateService } from '../map-state.service';
import { QRCodesService } from './qrcodes.service';
// import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PrintMapService {
  public onImageUpdated = new EventEmitter<any>();
  public useQRcodes: boolean;

  constructor(
    private smService: SmService,
    private youtubeService: YoutubeService,
    private mapStateService: MapStateService,
    private qRCodesService: QRCodesService
  ) {
    this.useQRcodes = false;
  }

  printMap(desiredsize: any, qrcodes: boolean) {
    return new Promise((resolve, reject) => {
      const mapId = this.mapStateService.id;
      const gdocId = this.mapStateService.googleDocFileId;
      this.insertImageInDocument(qrcodes).then(() => {
        return this.qRCodesService.injectQRCodesInPage(mapId, gdocId);
      }).then(() => {
        this.useQRcodes = qrcodes;
        this.youtubeService.clearAllVideos();
        const imageBase64 = this.smService.convertToImage(true, desiredsize, this.mapStateService.limitPrintSize, qrcodes);
        this.onImageUpdated.next(imageBase64);
        return resolve('');
      }).catch((err: any) => {
        return reject(err);
      });

    });
  }

  private insertImageInDocument(qrcodes: boolean) {
    return new Promise((resolve, reject) => {
      const el = document.createElement('img');
      let parentEl = document.getElementById('div-stamp-image');
      if (parentEl) {
        parentEl.appendChild(el);
        el.id = 'stamp-image';
        el.onload = (() => {
          if (qrcodes) {
            const mapimg = document.createElement('img');
            parentEl = document.getElementById('div-map-qrcode-symbol');
            if (parentEl) {
              parentEl.appendChild(mapimg);
              mapimg.id = 'map-qrcode-symbol';
              mapimg.onload = (() => {
                const docimg = document.createElement('img');
                docimg.id = 'gdoc-qrcode-symbol';
                parentEl = document.getElementById('div-gdoc-qrcode-symbol');
                if (parentEl) parentEl.appendChild(docimg);
                docimg.onload = (() => {
                  return resolve('');
                });
                docimg.src = 'assets/doc-google-256.png?v=1';
              });

              mapimg.src = 'assets/sm-document-256.png?v=1';
            }
          } else {
            return resolve('');
          }

        });
        el.src = 'assets/fattocon.png?v=3';
      }
    });

  }


}
