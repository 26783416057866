import { Component, OnInit, Input } from '@angular/core';
import { NotificationsDto } from '../notifications-data-dto';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../core/i18n.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'app-menu-notification',
    templateUrl: './menu-notification.component.html',
    styleUrls: ['./menu-notification.component.scss']
})
export class MenuNotificationComponent {

    @Input() notification: NotificationsDto | undefined;
    @Input() menuTrigger: MatMenuTrigger | undefined;

    //this.notificationsMenu.openMenu();

    constructor(private translateService: TranslateService) { }

    private lead0(n: number, len: number) {
        let s1 = String(n);
        while (s1.length < len) {
            s1 = '0' + s1;
        }
        return s1;
    }

    public toLocalDate(dbDate: any) {
        // In formato yyyy-MM-ddThh:mm.xxx:ssZ
        let s = '';
        if (dbDate) {
            const d = new Date(dbDate);
            s = /*this.translateService.instant(extract('DATE_NOTIFICATION')) + ': ' +*/
                this.lead0(d.getDate(), 2) + '/' +
                this.lead0(d.getMonth() + 1, 2) + '/' +
                this.lead0(d.getFullYear(), 4);
        }
        return s;
    }

    notificationClicked(event: any) {
        if (!this.notification) { return; }
        let res = this.notification.link;
        if (res !== '' && !res.startsWith('data:image') && !res.startsWith('http://') && !res.startsWith('https://')) {
            res = 'http://' + res;
        }
        if (res !== '') {
            window.open(res, '_blank');
            if (this.menuTrigger) {
                this.menuTrigger.closeMenu();
            }
        }
    }

    public hasLink(): boolean {
        return (this.notification && this.notification.link) ? true : false;
    }

    public deleteNotification(event: any) {
        console.log('deletenotification');
        event.stopPropagation();
    }

    public getNotificationTooltip(): string {
        let tooltip = '';
        if (this.hasLink()) {
            tooltip = this.translateService.instant(extract('NOTIFICATION_OPEN_TOOLTIP'));
        }
        return tooltip;
    }
}
