import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpHeaders
} from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap, take } from 'rxjs/operators';
import { FirebaseAuthService } from '../firebase/firebase-auth.service';

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private auth: FirebaseAuthService
    ) {
        console.log('token interceptor constructor');
    }

    /**
     * return Gli Header HTTP impostati in modo che non venga cercato il token Firebase dal TokenInterceptor.
     */
    public static buildNoTokenHeaders(): HttpHeaders {
        const headers = new HttpHeaders({ 'Authorization': '' });
        return headers;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.has('Authorization') || request.url.startsWith('./assets/')) {
            return next.handle(request);
        } else {
            return this.auth.getUserIdToken().pipe(
                take(1),
                switchMap((token: any) => {
                    // console.log(token);
                    if (token) {
                        request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
                    }
                    return next.handle(request).pipe(
                        catchError(response => {
                            console.log(`Token intercept STATUS: ${response.status}`);
                            if (response.status === 401 || response.status === 403 || response.status === 404) {
                                return next.handle(request.clone({ setHeaders: { "Authorization": `Bearer ${token}` } }));
                            } else {
                                const err =
                                    (response && response.error && response.error.reason && (response.error.reason.length > 0 || response.error.reason !== '') ?
                                        response.error.reason[0] :
                                        response.error.message
                                    );
                                return throwError(() => new Error(err));
                            }
                        })
                    )
                })
            );
        }
    }
}
