<h2 mat-dialog-title class="header">{{title}}</h2>

<mat-dialog-content>
    <div class="accordion" mdc-dialog-content>
        <mat-accordion multi="true" class="order-panel">
            <!-- <mat-expansion-panel *ngFor="let order of ; let i = index; first as isFirst" [expanded]="isFirst && openFirstElement" ([ngModel])="subscription.panelOpenState"> -->
            <mat-expansion-panel (opened)="openHistoryItem(i)" *ngFor="let order of history ; let i = index">
                <mat-expansion-panel-header class="expansion-header">
                    <mat-panel-title>
                        <div class="order-item">
                            <div class="item-1">
                                <span class="title-span">{{'ORDER-HISTORY-DATE' | translate}} </span>
                                <span class="title-span">{{order.date}}</span>
                            </div>
                            <div class="item-2">
                                <span class="title-span">{{'ACCESSCONFIG_REFORDERID' | translate}}</span>
                                <span class="item-span">{{getRefOrderId(order.refOrderId,order.lastAction)}}</span>
                            </div>
                            <div class="item-3">
                                <span class="title-span">{{'ACCESSCONFIG_EXPIREDATE' | translate}}</span>
                                <span class="item-span">{{toLocalDate(order.expireDate)}}</span>
                            </div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="order-body">
                    <div class="item-1 order-subitem">
                        <span class="title-span item-sub1">{{'ACCESSCONFIG_USER_NAME' | translate}}</span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'fullName')}">{{order.fullName}}</span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'userEmail')}">({{order.userEmail}})</span>
                        <span class="title-span item-sub1">{{'ACCESSCONFIG_ORDERID' | translate}}</span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'orderId')}">{{order.orderId}} </span>
                        <span class="title-span item-sub1">{{'ACCESSCONFIG_ORIGIN' | translate}}</span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'origin')}">{{getOriginType(order.origin)}} </span>
                        <span class="title-span item-sub1">{{'ACCESSCONFIG_REFORDERID' | translate}} </span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'refOrderId')}">{{getRefOrderId(order.refOrderId,order.lastAction)}}</span>
                        <span class="title-span item-sub1">{{'IC_USER_TYPE_TITLE' | translate}}</span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'userType')}">{{order.userType}}</span>
                        <span class="title-span item-sub1">{{'ACCESSCONFIG_PLAN' | translate}}</span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'planId')}">{{order.planId}}</span>
                        <span class="title-span item-sub1">{{'ACCESSCONFIG_LICENSETYPE' | translate}}</span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'domainLics')}">{{getLicenseType(order.licenseType, order.domainLics)}}</span>
                        <span class="title-span item-sub1">{{'ACCESSCONFIG_USERS' | translate}}: </span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'slotCount')}">{{order.slotCount}} </span>
                        <span class="title-span item-sub1">{{'START_DATE' | translate}}</span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'startDate')}">{{order.startDate}} </span>
                        <span class="title-span item-sub1">{{'ACCESSCONFIG_EXPIREDATE' | translate}}</span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'expireDate')}">{{order.expireDate}}</span>
                        <span *ngIf="order.planId==='lab'" class="title-span item-sub1">{{'ACCESSCONFIG_OCR_QUOTA' | translate}} </span>
                        <span *ngIf="order.planId==='lab'" class="item-span item-sub2">{{order.ocrMathScanQuota}} </span>
                        <span *ngIf="order.planId==='lab'" class="title-span item-sub1">{{'ACCESSCONFIG_OCR_POLICY' | translate}} </span>
                        <span *ngIf="order.planId==='lab'" class="item-span item-sub2">{{'OCRSCAN_POLICY_' + order.ocrMathScanPolicy | translate}} </span>
                        <span class="title-span item-sub1">{{'ACCESSCONFIG_CREATIONDATE' | translate}}</span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'creationDate')}">{{toLocalDateTime(order.creationDate)}}</span>
                        <span class="title-span item-sub1">{{'ACCESSCONFIG_CREATEDBY' | translate}}</span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'createdBy')}">{{order.createdBy}}</span>
                        <span class="title-span item-sub1">{{'ACCESSCONFIG_LASTMODIFICATIONDATE' | translate}}</span>
                        <span class="item-span item-sub2" [ngStyle]="{'background-color': c(order,'lastModificationDate')}">{{toLocalDateTime(order.lastModificationDate)}}</span>
                        <span class="title-span item-sub1">{{'ACCESSCONFIG_LASTMODIFICATIONBY' | translate}}</span>
                        <span *ngIf="order.price" class="title-span item-sub1">{{'ACCESSCONFIG_PRICE' | translate}}</span>
                        <span *ngIf="order.price" class="item-span item-sub2">{{order.price}} </span>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
    <button mat-button class="ok-button" (click)="dialogRef.close(ok)" translate>
        <span translate>OK</span>
    </button>
</mat-dialog-actions>
