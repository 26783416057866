<app-loader *ngIf="!imageLoaded" [isLoading]="imageLoaded" [message]="'PLEASE_WAIT'"></app-loader>
<div mdc-dialog-draggable-title translate>
    <h2 class="title" mat-dialog-title [matTooltip]="nodeText">{{nodeText}}</h2>
</div>
<div *ngIf="imageLoaded" class="content">
    <div *ngIf="editMode" class="toolbar">
        <button mat-stroked-button [mat-dialog-close]="true" (click)="editImage()">
            <mat-icon color="primary" [attr.aria-label]="'EDIT_IMAGE' | translate" svgIcon="image-edit-outline"></mat-icon>
            <span>{{'EDIT_IMAGE' | translate}}</span>
        </button>
        <div class="spacer"></div>
        <button mat-icon-button color="primary" matTooltip="{{'OPEN_IN_NEW_WINDOW' | translate}}" (click)="openImage()">
            <mat-icon [attr.aria-label]="'OPEN_IN_NEW_WINDOW' | translate" fontSet="material-icons-outlined">open_in_new</mat-icon>
        </button>
    </div>
    <div class="image-container">
        <img [src]="imageNodeUrl" class="image" />
    </div>
</div>
