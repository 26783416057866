import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { LoginFormComponent } from '../login-form/login-form.component';
import { CoreModule } from '../core/core.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordRequestComponent } from './password-request/password-request.component';
import { FinishSignUpComponent } from './finish-sign-up/finish-sign-up.component';
import { FinishSignUpRoutingModule } from './finish-sign-up/finish-sign-up-routing.module';
import { MessageSentComponent } from '../login-form/message-sent/message-sent.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { NewUserProfileComponent } from '../login-form/new-user-profile/new-user-profile.component';
import { NewUserProfileRoutingModule } from '../login-form/new-user-profile/new-user-profile.routing.module';
import { EmailRequestComponent } from '../login-form/email-request/email-request.component';
import { ForgotPasswordSentComponent } from './forgot-password-sent/forgot-password-sent.component';



@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forRoot(),
        SharedModule,
        CoreModule,
        MaterialModule,
        LoginRoutingModule,
        FinishSignUpRoutingModule,
        NewUserProfileRoutingModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule
    ],
    declarations: [
        EmailRequestComponent,
        LoginComponent,
        LoginFormComponent,
        ForgotPasswordComponent,
        ForgotPasswordSentComponent,
        PasswordRequestComponent,
        FinishSignUpComponent,
        MessageSentComponent,
        NewUserProfileComponent
    ]
})
export class LoginModule { }
