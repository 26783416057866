import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { AdminStatsComponent } from './admin-stats.component';

const routes: Routes = [
  { path: 'admin-stats', component: AdminStatsComponent, data: {} }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AdminStatsRoutingModule { }
