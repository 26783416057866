<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''" [versioned]="true"></app-loader>
<!-- <div *ngIf="!compressLogo" class="smx-logo-extended" routerLink="/home" translate>
    <img class="smx-logo-image-extended" src="{{logoUrlExtended}}?v=1" />
</div>
<div *ngIf="compressLogo" class="smx-logo-compressed" routerLink="/home" translate>
    <img class="smx-logo-image-compressed" src="{{logoUrlCompressed}}?v=1" />
</div> -->
<h2 mat-dialog-title class="header">{{ 'MAIN_MENU_FILE-IMPORT-MAP' | translate }}</h2>
<button class="close-button" mat-icon-button aria-label="close dialog" mat-dialog-close color="primary">
    <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
    <span>{{'IMPORT_DESCRIPTION' | translate}}</span>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button (click)="file.click()" color="accent">
        <mat-icon>computer</mat-icon>
        <span> {{ 'GDRIVE_PICKER_TITLE' | translate }}</span>

    </button>
    <button mat-flat-button mat-dialog-close (click)="openMap()" color="accent" [disabled]="disableOpen">
        <mat-icon>file_upload</mat-icon>
        <span> {{ 'MAIN_MENU_FILE-IMPORT-MAP' | translate }}</span>

    </button>
</mat-dialog-actions>
<input type="file" class="input-hidden" #file accept=".sme" (change)="openFile($event)" />
