<div class="extra-title-container">
    <div *ngIf="!editUrl" class="no-edit-url">{{urlInput}}</div>
    <input *ngIf="editUrl" class="extra-url-input" type="text" matInput placeholder="Inserisci l'url" [(ngModel)]="urlInput" (focus)="enterEdit()" (blur)="exitEdit()" (keydown.enter)="changeUrl()" />
    <div *ngIf="editUrl" class="extra-url-input-icon">
        <button mat-icon-button (click)="changeUrl();">
            <mat-icon svgIcon="search-web">
            </mat-icon>
        </button>
    </div>
    <div class="extra-close">
        <button mat-icon-button color="primary" matTooltip="{{'SEARCH_CLOSE' | translate}}" (click)="closeExtra();">
            <mat-icon [attr.aria-label]="'SEARCH_CLOSE' | translate" svgIcon="close-box-outline">
            </mat-icon>
        </button>
    </div>
</div>
<div class="extra-content-container">
    <iframe class="extra-webbrowser" #iframe [src]="url" data-cookieconsent="marketing" allow-top-navigation-by-user-activation allow-top-navigation webkitallowfullscreen mozallowfullscreen allowfullscreen>
    </iframe>
</div>
