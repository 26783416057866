import { Component, HostListener, OnInit } from '@angular/core';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatomoTracker } from 'ngx-matomo-client';
import { FirebaseAuthService } from '../core/firebase/firebase-auth.service';


@Component({
    selector: 'app-admin-page',
    templateUrl: './admin-page.component.html',
    styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {

    public isLoading = false;
    public user = '';
    public role = '';

    @HostListener('window:storage')
    onStorageChange() {
        console.log('change...');
        if (this.user !== this.firebaseAuthService.auth.currentUser?.email && this.firebaseAuthService.auth.currentUser !== null) {
            {
                this.authenticationService.reloadCredentials();
                this.user = this.authenticationService.getUserEmail();
                this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials?.role : '';
                this.accessConfig();

            }
        }

    }

    accessConfig() {

        let redirectUri;
        if (this.role === '') {
            redirectUri = '/user-desktop';
        } else
            if (this.role === 'spain') {
                redirectUri = /*window.location.origin + */ '/spain-desktop';
            } else if (this.role === 'aid') {
                redirectUri = /*window.location.origin + */ '/aid-desktop';
            } else if (this.role === 'anastasis') {
                redirectUri = /*window.location.origin + */ '/ana-desktop';
            } else if (this.role === 'admin') {
                redirectUri = /*window.location.origin + */ '/admin-page';
            }


        this.router.navigate([redirectUri]);
    }

    constructor(private router: Router, private authenticationService: AuthenticationService, private firebaseAuthService: FirebaseAuthService,
        private translateService: TranslateService, private tracker: MatomoTracker) {
        this.authenticationService.reloadCredentials();
        this.user = this.authenticationService.getUserEmail();
        this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials?.role : '';


    }

    ngOnInit() {
        this.authenticationService.reloadCredentials();
        this.user = this.authenticationService.getUserEmail();
        this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials?.role : '';


    }

    getRoleType() {
        return this.translateService.instant('ROLE_' + (this.role.toLocaleUpperCase() || 'USER'));
    }

    accessSettings() {
        let redirectUri;
        if (this.role === '') {
            redirectUri = /*window.location.origin + */'/user-desktop';
        } else
            if (this.role === 'aid') {
                redirectUri = /*window.location.origin + */ '/aid-desktop';
            } else
                if (this.role === 'anastasis') {
                    // TODO da cambiare quando ci sarà il nuovo
                    redirectUri = /*window.location.origin + */ '/ana-desktop';
                } else
                    if (this.role === 'admin') {
                        // TODO da cambiare quando ci sarà il nuovo
                        redirectUri = /*window.location.origin + */ '/ana-desktop';
                    }
        // window.open(redirectUri, '_blank');
        try {
            this.tracker.trackPageView('Pagina Amministrazione' + redirectUri + ' ' + this.authenticationService.getUserEmail() + ' con ruolo ' + this.role);
        } catch (e: any) {
            console.error('Matomo error ' + e.message);
        }
        this.router.navigate([redirectUri]);
    }

    notificationsSettings() {
        this.router.navigate(['admin-notifications']);
    }

    usersSettings() {
        this.router.navigate(['admin-users']);
    }

}
