import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { EditDataService } from '../commands/edit-data.service';
import { SmService } from 'supermappe-core';
import { MapStateService } from '../map-state.service';
import { Subscription } from 'rxjs';
import { QuickEditService } from '../commands/quick-edit.service';
import { UiConstants } from '../ui-constants';
import { TranslateService } from '@ngx-translate/core';
import { TtsService } from '../../shared/commands/tts.service';

declare let CKEDITOR: any;

@Component({
    selector: 'app-map-editor',
    templateUrl: './map-editor.component.html',
    styleUrls: ['./map-editor.component.scss']
})
export class MapEditorComponent implements OnInit, OnDestroy {
    // editor
    editVisible = false;


    editorValue = '';
    editX: any;
    editY: any;
    editWidth: any;
    editHeight: any;

    editDataServiceSubscription: Subscription | undefined;

    private backEdit: any;
    private obscurer: any;
    private editor: any;
    public config: any;

    @Output()
    onOpened = new EventEmitter<void>();

    @Output()
    onClosed = new EventEmitter<string>();


    private startEditorHeight: number;
    private isEditorReady: boolean;
    private plainText = '';
    private _currLanguage = UiConstants.LANGUAGES[0].value;

    constructor(
        private mapStateService: MapStateService,
        private editDataService: EditDataService,
        private smService: SmService,
        private ttsService: TtsService,
        private translateService: TranslateService,
        private quickEditService: QuickEditService) {

        const cLang = UiConstants.findLanguage(this.translateService.currentLang, UiConstants.LANGUAGES);
        this._currLanguage = cLang.value;

        this.config = {
            allowedContent: true,
            height: 200,
            autoGrow_minHeight: 200,
            autoGrow_maxHeight: 1000,
            autoGrow_bottomSpace: 50,
            startupFocus: true,
            enterMode: CKEDITOR.ENTER_BR
        };
        CKEDITOR.config.language = this.translateService.currentLang.substr(0, 2);
        CKEDITOR.config.font_defaultLabel = UiConstants.nodeFontStyle['font']; //'Arial';

        CKEDITOR.config.fontSize_defaultLabel = UiConstants.nodeFontStyle['size'] + 'pt';//'14';
        CKEDITOR.config.fontSize_sizes = '8/8pt;9/9pt;10/10pt;11/11pt;12/12pt;14/14pt;16/16pt;18/18pt;24/24pt;30/30pt;36/36pt;48/48pt';

        CKEDITOR.disableAutoInline = true;
        CKEDITOR.config.defaultLanguage = 'it';
        CKEDITOR.config.toolbarLocation = 'top';
        CKEDITOR.config.removePlugins = 'magicline';


        CKEDITOR.config.font_names = '';
        const myFonts = ['Arial', 'Verdana', 'sans-serif', 'Architects Daughter', 'IBM Plex Mono', 'Montserrat', 'Roboto', 'Special Elite'];


        for (let i = 0; i < myFonts.length; i++) {
            CKEDITOR.config.font_names = CKEDITOR.config.font_names + ';' + myFonts[i];
            myFonts[i] = 'http://fonts.googleapis.com/css?family=' + myFonts[i].replace(' ', '+');
        }

        CKEDITOR.config.contentsCss = ['/assets/ckeditor/contentssmx.css'].concat(myFonts);
        this.isEditorReady = false;
        this.startEditorHeight = 0;
    }

    ngOnInit() {

        if (this.editDataService && this.editDataService.editDataChange) {
            this.editVisible = false;
            this.editDataServiceSubscription = this.editDataService.editDataChange.subscribe((editData: any) => {
                if (editData && editData.width > 0 && this.editDataServiceSubscription) {
                    this.onOpened.emit();
                    this.quickEditService.onCanEdit.emit(false);
                    this.setEditor(editData);
                    this.editDataService.openEditor();
                    this.smService.setEnableKeyPresses(false);
                }
            });
            this.setBackEditVisible(false);
        }
    }

    ngOnDestroy(): void {
        if (this.editDataServiceSubscription) { this.editDataServiceSubscription.unsubscribe(); }
        this.editor = null;
        if (typeof CKEDITOR !== 'undefined') {
            for (const instanceName in CKEDITOR.instances) {
                if (typeof CKEDITOR.instances[instanceName] !== 'undefined') {
                    CKEDITOR.instances[instanceName].destroy();
                }
            }
        }
    }

    setBackEditVisible(value: boolean) {
        const visible = (value ? 'visible' : 'collapse');
        if (!this.backEdit) {
            this.backEdit = document.getElementById('backedit');
        }
        if (this.backEdit) {
            this.backEdit.style.visibility = visible;
        }
        if (!this.obscurer) {
            this.obscurer = document.getElementById('obscurer');
        }
        if (this.obscurer) {
            this.obscurer.style.visibility = visible;
        }
    }

    TTSRead() {
        if (this.smService) {
            const textToRead = this.getText();
            this.ttsService.speak(textToRead, this._currLanguage, true);
        }
    }

    getSelectionText() {
        let text: string | undefined = '';
        if (this.editVisible) {
            text = CKEDITOR.instances['editor-container'].getSelection().getSelectedText();
        } else {
            if (window?.getSelection()) {

                text = window.getSelection()?.toString();
            }
        }
        return text;
    }

    public getText() {
        let textToRead = this.getSelectionText();
        if (!textToRead) {
            const CKEditor = CKEDITOR.instances['editor-container'];
            let appuntiEditor = '';
            if (CKEditor) {
                appuntiEditor = CKEditor.getData();
            }
            const elem = document.createElement('div');
            elem.innerHTML = appuntiEditor;
            this.plainText = elem.innerText;
            this.plainText = this.plainText.replace(/\r?\n|\r/gm, ' '); // remove line breaks
            this.plainText = this.plainText.replace(/\s\s+/g, ' ')?.trim(); // remove double spaces

            textToRead = this.plainText;
        }
        if (textToRead === undefined) {
            textToRead = '';
        }
        return textToRead;
    }

    setEditor(editData: any) {
        if (this.editor) {
            this.editor.destroy();
            this.editor = null;
        }
        const el = this.smService.getSelectedElement();
        const backcolor = (el && el.nodeData) ? el.nodeData.brushColor : 'white';
        const elementType = (el && el.className) ? el.className : '';

        // Spell Checker language
        if (editData.language !== '') {
            CKEDITOR.config.language = editData.language.substr(0, 2);
            CKEDITOR.config.scayt_sLang = editData.language.replace(/-/g, '_');
            this.mapStateService.setSpellCheckerLanguages(editData.language);
        }
        CKEDITOR.config.toolbarLocation = 'top';
        CKEDITOR.config.font_defaultLabel = UiConstants.nodeFontStyle['font'];
        CKEDITOR.config.fontSize_defaultLabel = UiConstants.nodeFontStyle['size'] + 'pt';

        this.startEditorHeight = editData.height;
        this.smService.setEnableKeyPresses(false);
        this.mapStateService.setEditingState(true);
        this.setBackEditVisible(true);
        const isNode = editData.isNode || editData.isCell;

        // Normalize html style
        let html = (editData.titleHtml !== '' ? editData.titleHtml : UiConstants.getDefaultHtmlForTitle('', isNode));
        html = UiConstants.normalizeHtmlForTitle(html, isNode);
        this.editorValue = html;

        const _editor = CKEDITOR.inline('editor-container', {
            extraPlugins: 'language,selectall,colorbutton,justify,copyformatting,font,contextmenu,menu',
            language: CKEDITOR.config.language,
            toolbar: [

                { name: 'styles', items: ['Font', 'FontSize'] },
                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
                { name: 'colors', items: ['TextColor', 'BGColor'] },
                '/',
                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo'] },
                { name: 'paragraph', items: ['BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight'] },
                { name: 'basicstyles', items: ['CopyFormatting', 'RemoveFormat'] },
                { name: 'insert', items: ['SpecialChar'] }
            ],
        });


        _editor.on('instanceReady', (evt: any) => {

            // VOCE MENU CUSTOM
            _editor.addCommand("commandRead", {
                exec: () => {
                    //Leggi
                    this.TTSRead();
                }
            });
            _editor.addCommand("commandSelectAll", {
                exec: () => {
                    //Seleziona tutto
                    _editor.execCommand('selectAll')
                }
            });

            _editor.contextMenu.addListener(function () {
                return {
                    commandRead: CKEDITOR.TRISTATE_OFF,
                    commandSelectAll: CKEDITOR.TRISTATE_OFF
                };
            });

            // Add custom group
            _editor.addMenuGroup('customGroup');

            // groups: 'customGroup','image','table'
            _editor.addMenuItems({
                commandRead: {
                    label: 'Leggi',
                    command: 'commandRead',
                    group: 'customGroup',
                    order: 1
                },
                commandSelectAll: {
                    label: 'Seleziona tutto',
                    command: 'commandSelectAll',
                    group: 'customGroup',
                    order: 1
                }
            });
            // fine VOCE MENU CUSTOM


            //effetto grafico che oscura il testo del nodo mentre e' in editing
            const obscurer = document.getElementById('obscurer');
            if (obscurer && this.mapStateService && this.mapStateService.canvasOrigin) {
                obscurer.style.flex = 'none';
                obscurer.style.position = 'absolute';

                let editDataWidthOffset = 0; //usato per contare il border del nodo se esiste
                let editDataYposOffset = 0; //usato per spostare l'obscurer se il testo e' over
                if (elementType == "Node") {
                    if (el && el.nodeData) {
                        //colore dell'obscurer
                        if (el.nodeData.brushColor !== 'transparent')
                            obscurer.style.backgroundColor = el.nodeData.brushColor;
                        else {
                            obscurer.style.backgroundColor = 'gray';
                        }

                        //visibilita' obscurer
                        if (el.nodeData.textLayout !== 'none') {
                            obscurer.style.visibility = "visible";
                            //larghezza obscurer basata sul bordo del nodo (il bordo e' inner)
                            if (el.nodeData.penWidth !== 0) {
                                editDataWidthOffset = el.nodeData.penWidth * 4;
                            }

                            //compenso per la posizione del testo
                            if (el.nodeData.textLayout == "over") {
                                editDataYposOffset = 5;
                            }

                        } else {
                            obscurer.style.visibility = "hidden";
                        }
                    }
                }
                else if (elementType == "Edge") {
                    obscurer.style.backgroundColor = 'gray';
                }

                const zw = (editData.width - editDataWidthOffset) * editData.zoom;
                const zh = editData.height * editData.zoom + 10;
                const x1 = editData.x - (zw / 2) + this.mapStateService.canvasOrigin.x;
                const y1 = editData.y - (zh / 2) + editDataYposOffset + this.mapStateService.canvasOrigin.y;

                obscurer.style.left = x1 + 'px';
                obscurer.style.top = y1 + 'px';
                obscurer.style.width = zw + 'px';
                obscurer.style.height = zh + 'px';

            }
            evt.editor.on('resize', (reEvent: any) => {
                alert('The editor resized');
                if (!this.backEdit) {
                    this.backEdit = document.getElementById('backedit');
                }
                this.backEdit.height = this.editor.container.$.clientHeight;
            });


            //campo di testo ck editor
            const editor = document.getElementById('editor-container');
            if (editor && this.mapStateService && this.mapStateService.canvasOrigin) {
                editor.style.visibility = 'hidden';
                editor.style.backgroundColor = (backcolor === 'transparent' ? 'white' : backcolor);
                editor.style.flex = 'none';
                editor.style.position = 'absolute';
                editor.style.minWidth = editData.width + 'px';
                editor.style.minHeight = editData.height + 'px';

                //la posizione viene calcolata dopo perche' bisogna aspettare che l'editor diventi grande in base al contenuto
                /*const x2 = editData.x - (editData.width / 2) + this.mapStateService.canvasOrigin.x;
                const y2 = editData.y - (editData.height / 2) + this.mapStateService.canvasOrigin.y;
                editor.style.left = x2 + 'px';
                editor.style.top = y2 + 'px';*/

                editor.style.width = editData.width + 'px';
                // editor.style.height = 'auto';

                this.editor = CKEDITOR.instances['editor-container'];
                this.editor.setData(this.editorValue);
                this.editor.resetDirty();
                this.editor.resetUndo();
                this.editVisible = true;
                setTimeout(() => {
                    if (this.mapStateService && this.mapStateService.canvasOrigin) {
                        const textCenterX = ((editData.width * editData.zoom) / 2) + (editData.x - ((editData.width * editData.zoom) / 2) + this.mapStateService.canvasOrigin.x);
                        const textCenterY = ((editData.height * editData.zoom) / 2) + (editData.y - ((editData.height * editData.zoom) / 2) + this.mapStateService.canvasOrigin.y);
                        editor.style.left = textCenterX - editor.offsetWidth / 2 + 'px';
                        editor.style.top = textCenterY - editor.offsetHeight / 2 + 'px';
                    }
                    editor.style.visibility = 'visible';
                    this.editor.container.focus();
                }, 10);
                this.isEditorReady = true;
            }
        });
        (<any>window)._editor = _editor;

    }

    public backEditClick() {
        if (this.isEditorReady) {
            this.mapStateService.setEditingState(false);
            this.setBackEditVisible(false);
            if (this.editVisible) {
                this.editVisible = false;
                const selectedElement = this.smService.getSelectedElement();
                const isNode = (selectedElement.className === 'Node');
                const data = { titleHtml: '', deepHtml: null, height: 0, width: 0, deltaH: 0, titleText: '' };
                this.editorValue = this.editor.getData();
                this.mapStateService.autoRenameNewMap(this.editor.editable().getText());
                // this.editorValue = UiConstants.getHtmlTitle(this.editorValue, isNode);
                const text = this.mapStateService.getPlainText(this.editorValue);
                data.height = this.editor.container.$.clientHeight;
                data.width = this.editor.container.$.clientWidth;
                data.titleHtml = this.editorValue;
                data.deepHtml = null;
                data.deltaH = this.editor.container.$.clientHeight - this.startEditorHeight;
                data.titleText = text;
                data.titleHtml = UiConstants.normalizeHtmlForTitle(data.titleHtml, isNode);
                this.smService.editSelectedElement(data);
                this.editDataService.closeEditor();
                this.isEditorReady = false;
                this.smService.setEnableKeyPresses(true);
                this.obscurer.style.left = '0';
                this.obscurer.style.top = '0';
                this.obscurer.style.width = '10px';
                this.obscurer.style.height = '10px';
                this.onClosed.emit(text);
                this.quickEditService.onCanEdit.emit(true);
                (<any>window)._editor = undefined;
            }
        }
        this.smService.setEnableKeyPresses(true);
    }
}
