<h2 mat-dialog-title class="math-keyboards-title" translate>PREF_TITLE_MATH_KEYBOARD</h2>
<div class="math-keyboards-container">
    <!-- <mat-icon class="top-icon material-icons-sharp" fontSet="material-icons-outlined">functions</mat-icon> -->
    <div class="desc-item desc-title" translate>PREF_MATH_KEYBOARDS</div>
    <span class="desc-item" translate>PREF_DESC_MATH_KEYBOARDS</span>
    <div class="inline-center">
        <mat-button-toggle-group [hideSingleSelectionIndicator]="true" class="option-group" (change)="onKeyboardLayoutToggleGroupChanged($event)" [value]="baseKeyboardType">
            <mat-button-toggle #advancedKeyboardLayout class="option-button" mat-button-toggle value="BASIC">
                <div class="option-button-title" translate>PREF_MATH_KEYBOARD_TYPE_BASIC</div>
                <img class="option-button-image" src="../../assets/toolbar/tastiera_completa.png" />
            </mat-button-toggle>
            <mat-button-toggle #baseKeyboardLayout class="option-button" mat-button-toggle value="PRIMARY">
                <div class="option-button-title" translate>PREF_MATH_KEYBOARD_TYPE_PRIMARY</div>
                <img class="option-button-image" src="../../assets/toolbar/tastiera_base.png" />
            </mat-button-toggle>
        </mat-button-toggle-group>
        <div class="desc-keyb1" translate>PREF_DESC_MATH_KEYBOARD_TYPE_BASIC</div>
        <div class="desc-keyb2" translate>PREF_DESC_MATH_KEYBOARD_TYPE_PRIMARY</div>
    </div>
    <span class="desc-item" translate>PREF_DESC_MATH_KEYBOARD_PREFS_1</span>
    <span class="desc-item bold" translate>PREF_DESC_MATH_KEYBOARD_PREFS_2</span>
    <mat-dialog-actions>
        <button mat-flat-button color="primary" (click)="close()" translate>
            <span translate>BUTTON_OK</span>
        </button>
    </mat-dialog-actions>
</div>
