import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-input-dialog',
    templateUrl: './input-dialog.component.html',
    styleUrls: ['./input-dialog.component.scss']
})


export class InputDialogComponent {

    @ViewChild('dlgname') dlgName: ElementRef | undefined;

    public title = '';
    public question = '';
    public name = '';
    public mapId = '';
    public urlToOpen = '';
    public checkMsg = '';
    public isChecked = false;
    public preview = '';
    public tooltip = '';




    constructor(public dialogRef: MatDialogRef<InputDialogComponent>, private router: Router) {
    }

    onChange(event: any) {
        if (event && event.target)
            this.name = event.target.value;
    }

    onChangeCheckbox(newValue: boolean) {
        this.isChecked = newValue;
    }

    public onKeyPress(event: any) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.closeDialog();
            return false;
        }
        return true;
    }


    closeDialog() {
        // const uri: string = encodeURI(window.location.origin + '/map-copy?mapid=' + this.mapId + '&name=' + this.name);
        if (this.urlToOpen !== '') {
            const uri: string = encodeURI(this.urlToOpen + '&name=' + this.name);
            window.open(uri, '_blank');

        }
        const res = { name: this.name, isChecked: this.isChecked };
        this.dialogRef.close(res);
    }

    cancelOp() {
        this.dialogRef.close('');
    }

}



