import { VirtualKeyboardLayout } from 'mathlive';

export const IT_GEOMETRY_KEYBOARD: VirtualKeyboardLayout = {
    id: "GEOMETRY",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            {
                latex: ' \\overline{\\placeholder{}}',
                tooltip: 'segmento'
            },
            {
                latex: '\\overset{\\frown}{\\placeholder{}}',
                tooltip: 'arco'
            },
            {
                latex: ' \\widehat{\\placeholder{}}',
                tooltip: 'angolo'
            },
            {
                latex: '\\angle',
                tooltip: 'angolo'
            }
        ],
        // Second row
        [
            {
                latex: '\\alpha',
                tooltip: 'alfa'
            },
            {
                latex: '\\beta',
                tooltip: 'beta'
            },
            {
                latex: '\\gamma',
                tooltip: 'gamma'
            },
            {
                latex: '\\delta',
                tooltip: 'delta'
            }
        ],
        // Third row
        [
            {
                latex: '\\cong',
                tooltip: 'congruente'
            },
            {
                latex: '\\equiv',
                tooltip: 'coincidente'
            },
            {
                latex: '\\sim',
                tooltip: 'simile'
            }
        ],
        // Fourth row
        [
            {
                latex: '\\parallel',
                tooltip: 'parallelo'
            },
            {
                latex: '\\perp',
                tooltip: 'perpendicolare'
            },
            {
                key: '°',
                tooltip: 'gradi'
            }
        ]
    ]
};
