import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { GdriveOpenRoutingModule } from './gdrive-open-routing.module';
import { GdriveOpenComponent } from './gdrive-open.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        //FlexLayoutModule,
        MaterialModule,
        SharedModule,
        GdriveOpenRoutingModule
    ],
    declarations: [
        GdriveOpenComponent
    ]
})
export class GdriveOpenModule { }
