<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="'PLEASE_WAIT'"></app-loader>

<h2 mat-dialog-title class="header">{{'PASSWORD_RECOVERY' | translate}}</h2>
<button class="close-button" mat-icon-button aria-label="close dialog" mat-dialog-close color="primary">
    <mat-icon>close</mat-icon>
</button>

<mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="submit()" class="form-container">
        <div translate>FORGOT_PASSWORD_MSG</div>
        <mat-form-field appearance="outline">
            <input type="text" matInput placeholder="Email" formControlName="username" autocomplete="false" spellcheck="false" />
        </mat-form-field>

    </form>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
    <button mat-flat-button type="button" (click)="goBack()">{{'CANCEL' | translate}}</button>
    <button [disabled]="!form.valid" mat-flat-button color="warn" type="submit" (click)="submit()" translate>{{'SEND' | translate}}</button>

</mat-dialog-actions>
