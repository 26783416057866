<div id="math-editor-container" class="math-editor-container">
    <div class="math-field-container">

        <mat-icon class="error-icon red" *ngIf="descErrorFormula" matTooltip="{{descErrorFormula}}" [attr.aria-label]="'MATH_ERROR_FORMULA' | translate">report_problem</mat-icon>

        <mat-icon class="error-icon red" *ngIf="descErrorImage" matTooltip="{{descErrorImage}}" [attr.aria-label]="'MATH_ERROR_IMAGE' | translate">broken_image</mat-icon>

        <math-field id="mathfield" class="math-field" [ngClass]="descErrorFormula || descErrorImage ? 'error-outline':''">{{latex}}</math-field>

        <div class="math-button-container">
            <button [disabled]="descErrorFormula!=='' || !isLatexChanged" mat-icon-button color="primary" matTooltip="{{'MATH_OK' | translate}}" (click)="onConfirmToolPressed()">
                <mat-icon [attr.aria-label]="'MATH_OK' | translate" fontSet="material-icons-outlined">done</mat-icon>
            </button>
        </div>

        <div *ngIf="!isHeaderCompressed" class="math-button-container">
            <button mat-icon-button color="primary" matTooltip="{{'MATH_CANCEL' | translate}}" (click)="onCancelToolPressed()">
                <mat-icon [attr.aria-label]="'MATH_CANCEL' | translate" class="material-icons-sharp" fontSet="material-icons-filled">delete</mat-icon>
            </button>
        </div>

        <div *ngIf="!isHeaderCompressed" class="math-button-container">
            <button mat-icon-button [ngClass]="{'tool-selected': isLatexMode}" color="primary" matTooltip="{{'MATH_LATEX' | translate}}" (click)="onToggleLatexModePressed()">
                <mat-icon [attr.aria-label]="'LATEX' | translate" svgIcon="latex"></mat-icon>
            </button>
        </div>

        <div *ngIf="!isHeaderCompressed" class="math-button-container">
            <button mat-icon-button color="primary" matTooltip="{{'MATH_LATEX_INFO' | translate}}" (click)="onLatexInfoPressed()">
                <mat-icon [attr.aria-label]="'MATH_LATEX_INFO' | translate">info</mat-icon>
            </button>
        </div>
        <!-- <div *ngIf="!isHeaderCompressed" class="math-button-container">
            <button mat-icon-button color="primary" matTooltip="{{'PLAY' | translate}}" (click)="onReadLatexPressed()">
                <mat-icon [attr.aria-label]="'PLAY' | translate">play_arrow</mat-icon>
            </button>
        </div> -->
        <div *ngIf="isHeaderCompressed" class="math-button-container" style="margin-right: 30px;">
            <button mat-icon-button color="primary" [matMenuTriggerFor]="mathEditorMenu">
                <mat-icon color="primary" fontset="material-icons-outlined">more_vert</mat-icon>
            </button>
        </div>

        <div class="close-button-container">
            <button mat-icon-button color="primary" matTooltip="{{'MATH_CLOSE' | translate}}" (click)="onCloseToolPressed()">
                <mat-icon [attr.aria-label]="'MATH_CLOSE' | translate" fontSet="material-icons-outlined">close</mat-icon>
            </button>
        </div>
    </div>
    <div id="keyboard-container" class="keyboard-container"></div>
</div>

<mat-menu #mathEditorMenu="matMenu" [overlapTrigger]="false">
    <div mat-menu-item color="primary" (click)="onCancelToolPressed()">
        <mat-icon [attr.aria-label]="'MATH_CANCEL' | translate" color="primary" class="material-icons-sharp" fontSet="material-icons-filled">delete</mat-icon>
        <span>{{'MATH_CANCEL' | translate}}</span>
    </div>

    <div mat-menu-item [ngClass]="{'tool-selected': isLatexMode}" color="primary" (click)="onToggleLatexModePressed()">
        <mat-icon [attr.aria-label]="'LATEX' | translate" color="primary" svgIcon="latex"></mat-icon>
        <span>{{'LATEX' | translate}}</span>
    </div>

    <div mat-menu-item color="primary" (click)="onLatexInfoPressed()">
        <mat-icon [attr.aria-label]="'MATH_LATEX_INFO' | translate" color="primary">info</mat-icon>
        <span>{{'MATH_LATEX_INFO' | translate}}</span>
    </div>
    <!--<div mat-menu-item color="primary" (click)="onReadLatexPressed()">
            <mat-icon [attr.aria-label]="'PLAY' | translate" color="primary">play_arrow</mat-icon>
            <span>{{'PLAY' | translate}}</span>
        </div>-->
</mat-menu>
