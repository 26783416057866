import { Injectable } from "@angular/core";
import { MathReaderInterface, ReadEntityDto } from "./math-reader.interface";
import { convertLatexToMathMl } from "mathlive";

export enum VERTBAR { absValue, suchAs }

@Injectable({
    providedIn: 'root'
})
export class MathReaderBaseService implements MathReaderInterface {


    constructor() { }
    protected vertBar: VERTBAR = VERTBAR.absValue;
    private absOpen = false;

    getTextToRead(latex: string): string {
        const fixedLatex = this.fixLatex(latex);
        const mathML = convertLatexToMathMl(fixedLatex);
        const fixedMathML = this.fixMathML(mathML);
        console.log('***********************************************************');
        console.log(`latex: ${fixedLatex}`);
        console.log('***********************************************************');
        console.log(`MathML: ${fixedMathML}`);
        console.log('***********************************************************');
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(fixedMathML + '', "text/xml");
        this.absOpen = false;
        // JHUBA: da rivedere: se | in numero pari->valore assoluto, dispari->tale che
        const matches = mathML.match(/\|/g);
        const count = matches ? matches.length : 0;
        this.vertBar = (count % 2 === 0 ? VERTBAR.absValue : VERTBAR.suchAs);
        //
        const text = this.readElement(xmlDoc);
        return text;
    }

    private fixLatex(latex: string) {
        latex = latex.replace(/\\overline/g, '\\bar');
        latex = latex.replace(/\\overrightarrow/g, '\\vec');
        latex = latex.replace(/\\widehat/g, '\\hat');
        latex = latex.replace(/&#x20d7;/g, '→');
        latex = latex.replace(/\\mbox{sen}/g, '\\sin');
        latex = latex.replace(/\\mbox{arccot}/g, '\\arccot');
        return latex;
    }

    private fixMathML(mathML: string) {
        mathML = mathML.replace(/&#x21e;/g, '≠');
        mathML = mathML.replace(/&ne;/g, '≠');
        mathML = mathML.replace(/&#x20d7;/g, '→');
        mathML = mathML.replace(/&#8290;/g, '');
        mathML = mathML.replace(/&#x2200;/g, '∀');
        mathML = mathML.replace(/&#x2203;/g, '∃');
        mathML = mathML.replace(/&#x2204;/g, '∄');
        mathML = mathML.replace(/&#x005e;/g, '⌒');
        return mathML;
    }

    readElement(elm: any): string {
        let text = '';
        if (elm) {
            const elmText = elm.textContent;
            if (elm.tagName === 'mfrac' && /^[dⅆ][dⅆ]x$/.test(elmText)) {
                text = this.add(text, this.getddx());
            } else {
                let accent = false;
                let child0 = null;
                let child1 = null;
                if (elm.attributes && elm.attributes['accent']) {
                    accent = (elm.attributes['accent'].value === 'true')
                }
                if (elm.children) {
                    if (elm.children.length > 0) child0 = elm.children[0];
                    if (elm.children.length > 1) child1 = elm.children[1];
                }
                // console.log(`readElement: ${elm.tagName}`);
                switch (elm.tagName) {
                    case 'mo':
                    case 'mi':
                    case 'mn':
                        if (elm.tagName === 'mo' && elmText === '|') {
                            this.absOpen = !this.absOpen;
                        }
                        if (elm.tagName !== 'mo' || elmText !== '|' || this.absOpen) {
                            const r = this.readEntity(elmText, elm, elm.parentElement, accent, child0, child1);
                            // console.log('Add entity text: ' + r.text);
                            text = this.add(text, r.text);
                        }
                        break;
                    case 'msubsup':
                        if (elm.children && elm.children.length >= 3) {
                            const op = this.readEntity(child0.textContent, child0, elm, accent, child0, child1);
                            const sub = this.readElement(elm.children[1]);
                            const sup = this.readElement(elm.children[2]);
                            text = this.add(text, op.prefix);
                            text = this.add(text, op.text);
                            text = this.add(text, op.prefixSub);
                            text = this.add(text, sub);
                            text = this.add(text, op.suffixSub);
                            text = this.add(text, op.prefixSup);
                            text = this.add(text, sup);
                            text = this.add(text, op.suffixSup);
                            text = this.add(text, op.suffix);
                        }
                        break;
                    case 'msub':
                    case 'munder':
                        if (elm.children && elm.children.length >= 2) {
                            const op = this.readEntity(child0.textContent, child0, elm, accent, child0, child1);
                            const sub = this.readElement(elm.children[1]);
                            text = this.add(text, op.prefix);
                            text = this.add(text, op.text);
                            text = this.add(text, op.prefixSub);
                            text = this.add(text, sub);
                            text = this.add(text, op.suffixSub);
                            text = this.add(text, op.suffix);
                        }
                        break;
                    case 'msup':
                    case 'mover':
                        if (elm.children && elm.children.length >= 2) {
                            const op = this.readEntity(child0.textContent, child0, elm, accent, child0, child1);
                            const simplified = this.simplify(elm);
                            const sup = this.readElement(elm.children[1]);
                            text = this.add(text, op.prefix);
                            text = this.add(text, op.text);
                            if (simplified) {
                                text = this.add(text, simplified);
                                op.skipChild = true;
                            }
                            if (!op.skipChild) {
                                text = this.add(text, op.prefixSup);
                                text = this.add(text, sup);
                                text = this.add(text, op.suffixSup);
                            }
                            text = this.add(text, op.suffix);
                        }
                        break;
                    case 'msqrt':
                        if (elm.children && elm.children.length >= 1) {
                            const op = this.readEntity('sqrt', elm, null, accent, child0, child1);
                            const arg = this.readElement(elm.children[0]);
                            text = this.add(text, op.prefix);
                            text = this.add(text, op.text);
                            text = this.add(text, arg);
                            text = this.add(text, op.suffix);
                        }
                        break;
                    case 'mroot':
                        if (elm.children && elm.children.length >= 1) {
                            const index = this.readElement(elm.children[1]);
                            const op = this.readEntity('root', elm, null, accent, child0, child1);
                            const arg = this.readElement(elm.children[0]);
                            text = this.add(text, op.prefix);
                            text = this.add(text, op.text);
                            text = this.add(text, arg);
                            text = this.add(text, op.suffix);
                        }
                        break;
                    case 'mfrac':
                        const simplified = this.simplify(elm);
                        if (simplified) {
                            text = this.add(text, simplified);
                        } else {
                            const op = this.readEntity('frac', elm, null, accent, child0, child1);
                            const num = this.readElement(elm.children[0]);
                            const denom = this.readElement(elm.children[1]);
                            text = this.add(text, op.prefix);
                            text = this.add(text, op.text);
                            text = this.add(text, op.prefixSup);
                            text = this.add(text, num);
                            text = this.add(text, op.suffixSup);
                            text = this.add(text, op.prefixSub);
                            text = this.add(text, denom);
                            text = this.add(text, op.suffixSub);
                            text = this.add(text, op.suffix);
                        }
                        break;
                    default:
                        // Other tags will be scanned for children (eg: mrow)
                        if (elm.children && elm.children.length > 0) {
                            for (let i = 0; i < elm.children.length; i++) {
                                const child = elm.children[i];
                                const s = this.readElement(child);
                                text = this.add(text, s);
                                // console.log(`Partial: ${text}`);
                            }
                        }
                        break;
                }
            }
        }
        return text;
    }

    private add(text: string, textContent: any): string {
        if (text) text += ' ';
        text += textContent;
        return text;
    }

    private simplify(elm: any): string {
        let text = '';
        if (elm.tagName === 'mfrac' && elm.children && elm.children.length === 2) {
            // Fractions
            const child0 = elm.children[0];
            const child1 = elm.children[1];
            if (child0.tagName === 'mn' && child1.tagName === 'mn') {
                text = this.getSimpleFraction(child0.textContent, child1.textContent);
            }
        } else if (elm.tagName === 'msup' && elm.children && elm.children.length === 2) {
            const child0 = elm.children[0];
            const child1 = elm.children[1];
            if (child0.tagName === 'mn' && child1.tagName === 'mo' &&
                (child1.textContent === '+' || child1.textContent === '-' || child1.textContent === '−')
            ) {
                // 0+, 0-
                text = this.getSimpleZeroFrom(child1.textContent);
            } else {
                // Power elevations
                if (child1.tagName === 'mn') {
                    text = this.getSimpleElevation(child1.textContent);
                }
            }
        }
        return text;
    }

    //------------------------------------------------------
    // Following methods can be overridden by subclasses

    readEntity(op: string, elm: any, parentElm: any, accent: boolean, child0: any, child1: any): ReadEntityDto {
        return new ReadEntityDto();
    }

    getSimpleFraction(num: string, denom: string): string {
        throw new Error("Method not implemented.");
    }

    getSimpleElevation(elev: string): string {
        throw new Error("Method not implemented.");
    }

    getSimpleZeroFrom(symbol: string): string {
        throw new Error("Method not implemented.");
    }

    getddx(): string {
        throw new Error("Method not implemented.");
    }

}  
