import { Injectable } from "@angular/core";
import { MathReaderBaseService, VERTBAR } from "./math-reader-base.service";
import { ReadEntityDto } from "./math-reader.interface";

@Injectable({
    providedIn: 'root'
})
export class MathReaderItService extends MathReaderBaseService {

    private op: string = '';
    private elm: any = null;
    private parentElm: any = null;
    private accent: boolean = false;
    private child0: any = null;
    private child1: any = null;
    //
    private r: ReadEntityDto = new ReadEntityDto();
    private tag: string = '';
    private parentTag: string = '';

    override readEntity(op: string, elm: any, parentElm: any, accent: boolean, child0: any, child1: any): ReadEntityDto {
        this.r = new ReadEntityDto();
        this.op = op;
        this.elm = elm;
        this.parentElm = parentElm;
        this.accent = accent;
        this.child0 = child0;
        this.child1 = child1;
        this.tag = elm.tagName;
        this.parentTag = parentElm?.tagName;
        if (!this.r.found()) this.operators();
        if (!this.r.found()) this.mathBaseOperators();
        if (!this.r.found()) this.parenthesis();
        if (!this.r.found()) this.entities();
        if (!this.r.found()) this.sets();
        if (!this.r.found()) this.greek();
        if (!this.r.found()) this.geometry();
        if (!this.r.found()) this.trigonometry();
        if (!this.r.found()) this.logarhythmAndExponentials();
        if (!this.r.found()) this.others();
        return this.r;
    }

    private operators() {
        switch (this.op) {
            case 'sqrt':
                this.r.text = 'radice quadrata di'
                this.r.suffix = ''
                break;
            case 'root':
                if (this.child1) {
                    const index = this.readElement(this.child1);
                    this.r.text = 'radice con ìndice ' + index + ' di'
                    this.r.suffix = ''
                }
                break;
            case '∫':
                this.r.text = 'integrale';
                this.r.prefixSub = 'da';
                this.r.prefixSup = 'a';
                this.r.suffix = 'di';
                break;
            case '∑':
                this.r.text = 'sommatoria';
                this.r.prefixSub = 'da';
                this.r.prefixSup = 'a';
                this.r.suffix = 'di';
                break;
            case '∏':
                this.r.text = 'produttoria';
                this.r.prefixSub = 'da';
                this.r.prefixSup = 'a';
                this.r.suffix = 'di';
                break;
            case 'lim':
                this.r.text = 'limite';
                this.r.prefixSub = 'per';
                this.r.suffix = 'di';
                break;
            case 'frac':
                this.r.text = 'frazione;';
                this.r.prefixSup = '';
                this.r.suffixSup = '';
                this.r.prefixSub = 'su',
                    this.r.suffixSub = '';
                this.r.suffix = '';
                break;
            case '|':
                if (this.vertBar === VERTBAR.absValue) {
                    this.r.text = 'valore assoluto di';
                    this.r.prefixSup = '';
                    this.r.suffixSup = '';
                    this.r.suffix = '';
                } else {
                    this.r.text = 'tale che';
                    this.r.prefixSup = '';
                    this.r.suffixSup = '';
                    this.r.suffix = '';
                }
                break;
        }
    }

    private mathBaseOperators() {
        switch (this.op) {
            case ".":
                this.r.text = 'punto';
                break
            case ",":
                this.r.text = 'virgola';
                break
            case '+':
                this.r.text = 'più';
                break
            case '-':
                this.r.text = 'meno';
                break
            case '⋅':
                this.r.text = 'per';
                break
            case '×':
                this.r.text = 'per';
                break
            case ':':
                this.r.text = 'diviso';
                break
            case '/':
                this.r.text = 'diviso';
                break
            case '=':
                this.r.text = 'uguale a';
                break
            case '≠':
                this.r.text = 'diverso da';
                break
            case '<':
                this.r.text = 'minore di';
                break
            case '>':
                this.r.text = 'maggiore di';
                break
            case '≤':
                this.r.text = 'minore o uguale a';
                break
            case '≥':
                this.r.text = 'maggiore o uguale a';
                break
            case '±':
                this.r.text = 'più o meno';
                break
            case '°':
                this.r.text = 'gradi';
                break
            case '%':
                this.r.text = 'percento';
                break
        }
    }

    private parenthesis() {
        switch (this.op) {
            case '(':
                this.r.text = 'aperta tonda';
                break
            case ')':
                this.r.text = 'chiusa tonda';
                break
            case '[':
                this.r.text = 'aperta quadra';
                break
            case ']':
                this.r.text = 'chiusa quadra';
                break
            case '{':
                this.r.text = 'aperta graffa';
                break
            case '}':
                this.r.text = 'chiusa graffa';
                break
        }
    }

    private entities() {
        switch (this.op) {
            case '∞':
                this.r.text = 'infinito';
                break;
            case '→':
                this.r.text = 'che tende a';
                break;
        }
    }

    private sets() {
        switch (this.op) {
            case '∪':
                this.r.text = 'unione';
                break
            case '∩':
                this.r.text = 'intersezione';
                break
            case '∈':
                this.r.text = 'appartiene a';
                break
            case '¬':
                this.r.text = 'negazione di';
                break
            case '⊂':
                this.r.text = 'incluso strettamente in';
                break;
            case '⊆':
                this.r.text = 'incluso in';
                break;
            case '⊃':
                this.r.text = 'include strettamente';
                break;
            case '⊇':
                this.r.text = 'include';
                break;
            case '∀':
                this.r.text = 'per ogni';
                break;
            case '∃':
                this.r.text = 'esiste';
                break;
            case '∄':
                this.r.text = 'non esiste';
                break;
            case '∨':
                this.r.text = 'oppure';
                break;
            case '∧':
                this.r.text = 'e';
                break;
            case '⊻':
                this.r.text = 'oppure esclusivo';
                break;
            case '⟹':
                this.r.text = 'implica';
                break;
            case '⟸':
                this.r.text = 'è implicato da';
                break;
            case '↔':
                this.r.text = 'se e solo se';
                break;
            case '∅':
                this.r.text = 'insieme vuoto';
                break;
            case 'ℕ':
                this.r.text = 'insieme dei numeri naturali';
                break;
            case 'ℤ':
                this.r.text = 'insieme dei numeri interi';
                break;
            case 'ℚ':
                this.r.text = 'insieme dei numeri razionali';
                break;
            case 'ℝ':
                this.r.text = 'insieme dei numeri reali';
                break;
            case 'ℂ':
                this.r.text = 'insieme dei numeri complessi';
                break;
            case 'ℜ':
                this.r.text = 'relazione';
                break;
        }
    }

    private greek() {
        switch (this.op) {
            case 'Α':
            case 'α':
            case '∝': this.r.text = 'alfa'; break;
            case 'Β':
            case 'β': this.r.text = 'beta'; break;
            case 'Γ':
            case 'γ': this.r.text = 'gamma'; break;
            case 'Δ':
            case 'δ': this.r.text = 'delta'; break;
            case 'Ε':
            case 'ε':
            case 'ϵ': this.r.text = 'epsilon'; break;
            case 'Ζ':
            case 'ζ': this.r.text = 'zeta'; break;
            case 'Η':
            case 'η': this.r.text = 'eta'; break;
            case 'Θ':
            case 'θ': this.r.text = 'teta'; break;
            case 'Ι':
            case 'ι': this.r.text = 'iota'; break;
            case 'Κ':
            case 'κ': this.r.text = 'kappa'; break;
            case 'Λ':
            case 'λ': this.r.text = 'lambda'; break;
            case 'Μ':
            case 'μ': this.r.text = 'mu'; break;
            case 'Ν':
            case 'ν': this.r.text = 'nu'; break;
            case 'Ξ':
            case 'ξ': this.r.text = 'xi'; break;
            case 'Ο':
            case 'ο': this.r.text = 'omicron'; break;
            case 'Π':
            case 'π': this.r.text = 'pigreco'; break;
            case 'Ρ':
            case 'ρ': this.r.text = 'rho'; break;
            case 'Σ':
            case 'σ':
            case 'ς': this.r.text = 'sigma'; break;
            case 'Τ':
            case 'τ': this.r.text = 'tau'; break;
            case 'Υ':
            case 'υ': this.r.text = 'upsilon'; break;
            case 'Φ':
            case 'φ':
            case 'ϕ': this.r.text = 'fi'; break;
            case 'Χ':
            case 'χ': this.r.text = 'ki'; break;
            case 'Ψ':
            case 'ψ': this.r.text = 'psi'; break;
            case 'Ω':
            case 'ω': this.r.text = 'omega'; break;
        }
    }

    private geometry() {
        switch (this.op) {
            case '≅':
                this.r.text = 'è congruente con';
                break;
            case '≡':
                this.r.text = 'è coincidente con';
                break;
            case '∼':
                this.r.text = 'è simile a';
                break;
            case '∥':
                this.r.text = 'è parallelo a';
                break;
            case '⊥':
                this.r.text = 'è perpendicolare a';
                break;
        }
    }

    private trigonometry() {
        switch (this.op) {
            case 'sin':
            case 'sen':
                this.r.text = 'seno di';
                break;
            case 'cos':
                this.r.text = 'coseno di';
                break;
            case 'tan':
                this.r.text = 'tangente di';
                break;
            case 'sec':
                this.r.text = 'secante di';
                break;
            case 'csc':
                this.r.text = 'cosecante di';
                break;
            case 'cot':
                this.r.text = 'cotangente di';
                break;
            case 'arcsin':
                this.r.text = 'arcoseno di';
                break;
            case 'arccos':
                this.r.text = 'arcocoseno di';
                break;
            case 'arctan':
                this.r.text = 'arcotangente di';
                break;
            // NON ESISTE IN LATEX/MATHML
            case 'arccot':
                this.r.text = 'arcocotangente di';
                break;
        }
    }

    private logarhythmAndExponentials() {
        switch (this.op) {
            case 'log':
                if (this.parentTag === 'msub') {
                    this.r.text = 'logaritmo in base';
                    this.r.suffix = 'di';
                } else {
                    this.r.text = 'logaritmo di';
                }
                break;
            case 'ln':
                this.r.text = 'logaritmo naturale di';
                break;
        }
    }

    private others() {
        if (this.parentTag === 'mover' || this.parentTag === 'msup' || this.parentTag === 'msubsup') {
            if (this.accent) {
                if (this.child1 && this.child1.tagName === 'mo' && this.child1.textContent === '→') {
                    // Vector
                    this.r.prefix = 'vettore'
                    this.r.text = this.op;
                    this.r.skipChild = true;
                } else if (this.child1 && this.child1.tagName === 'mo' && this.child1.textContent === '⌒') {
                    // Angle
                    this.r.prefix = 'angolo'
                    this.r.text = this.op;
                    this.r.skipChild = true;
                } else {
                    // Overbar
                    if (this.op.length === 1 && /^[a-z]+$/.test(this.op)) {
                        // Measure
                        this.r.prefix = 'misura di'
                        this.r.text = this.op;
                    } else if (this.op.length === 1 && /^[A-Z]+$/.test(this.op)) {
                        // Complementary set
                        this.r.prefix = 'insieme complementare di'
                        this.r.text = this.op;
                    } else if (this.op.length >= 2 && /^[A-Z]+$/.test(this.op)) {
                        // Segment
                        this.r.prefix = 'segmento'
                        this.r.text = this.op;
                    } else if (/^[0-9]+$/.test(this.op)) {
                        // Periodic number
                        this.r.prefix = ''
                        this.r.text = this.op;
                        this.r.suffix = 'periodico'
                    }
                }
            } else {
                if (this.child1 && this.child1.tagName === 'mo' && this.child1.textContent === '⌢') {
                    // Arc
                    this.r.prefix = 'arco'
                    this.r.text = this.op;
                    this.r.skipChild = true;
                } else {
                    // Something ^ and/or _
                    this.r.text = this.op;
                    this.r.prefixSup = 'elevato alla';
                    this.r.prefixSub = '';
                }
            }
        } else {
            // Keep op as it is
            // console.log('--->UNKNOWN OP: ' + op);
            this.r.text = this.op;
        }
    }

    //----------------------------------------------------------------------------
    // Simplification
    //
    override getSimpleFraction(num: string, denom: string): string {
        let text;
        let n1 = '';
        let n2 = '';
        if (num === '1') {
            n1 = 'un';
            n2 = 'uno';
        } else {
            n1 = n2 = num;
        }
        let d = '';
        switch (denom) {
            case '2': d = (num === '1' ? 'mezzo' : 'mezzi'); break;
            case '3': d = (num === '1' ? 'terzo' : 'terzi'); break;
            case '4': d = (num === '1' ? 'quarto' : 'quarti'); break;
            case '5': d = (num === '1' ? 'quinto' : 'quinti'); break;
            case '6': d = (num === '1' ? 'sesto' : 'sesti'); break;
            case '7': d = (num === '1' ? 'settimo' : 'settimi'); break;
            case '8': d = (num === '1' ? 'otto' : 'ottavi'); break;
            case '9': d = (num === '1' ? 'nono' : 'noni'); break;
            case '10': d = (num === '1' ? 'decimo' : 'decimi'); break;
            case '11': d = (num === '1' ? 'undicesimo' : 'undicesimi'); break;
            case '12': d = (num === '1' ? 'dodicesimo' : 'dodicesimi'); break;
            case '13': d = (num === '1' ? 'tredicesimo' : 'tredicesimi'); break;
            case '14': d = (num === '1' ? 'quattordicesimo' : 'quattordicesimi'); break;
            case '15': d = (num === '1' ? 'quindicesimo' : 'quindicesimi'); break;
            case '16': d = (num === '1' ? 'sedicesimo' : 'sedicesimi'); break;
            case '17': d = (num === '1' ? 'diciassettesimo' : 'diciassettesimi'); break;
            case '18': d = (num === '1' ? 'diciottesimo' : 'diciottesimi'); break;
            case '19': d = (num === '1' ? 'diciannovesimo' : 'diciannovesimi'); break;
            case '20': d = (num === '1' ? 'ventesimo' : 'ventesimi'); break;
            case '21': d = (num === '1' ? 'ventunesimo' : 'ventunesimi'); break;
            case '22': d = (num === '1' ? 'ventiduesimo' : 'ventiduesimi'); break;
            case '23': d = (num === '1' ? 'ventitreesimo' : 'ventitreesimi'); break;
            case '24': d = (num === '1' ? 'ventiquattresimo' : 'ventiquattresimi'); break;
            case '25': d = (num === '1' ? 'venticinquesimo' : 'venticinquesimi'); break;
            case '26': d = (num === '1' ? 'ventiseiesimo' : 'ventiseiesimi'); break;
            case '27': d = (num === '1' ? 'ventisettesimo' : 'ventisettesimi'); break;
            case '28': d = (num === '1' ? 'ventottesimo' : 'ventottesimi'); break;
            case '29': d = (num === '1' ? 'ventinovesimo' : 'ventinovesimi'); break;
            case '30': d = (num === '1' ? 'trentesimo' : 'trentesimi'); break;
            case '31': d = (num === '1' ? 'trentunesimo' : 'trentunesimi'); break;
            case '32': d = (num === '1' ? 'trentaduesimo' : 'trentaduesimi'); break;
            case '33': d = (num === '1' ? 'trentatreesimo' : 'trentatreesimi'); break;
            case '34': d = (num === '1' ? 'trentaquattresimo' : 'trentaquattresimi'); break;
            case '35': d = (num === '1' ? 'trentacinquesimo' : 'trentacinquesimi'); break;
            case '36': d = (num === '1' ? 'trentaseiesimo' : 'trentaseiesimi'); break;
            case '37': d = (num === '1' ? 'trentasettesimo' : 'trentasettesimi'); break;
            case '38': d = (num === '1' ? 'trentottesimo' : 'trentottesimi'); break;
            case '39': d = (num === '1' ? 'trentanovesimo' : 'trentanovesimi'); break;
            case '40': d = (num === '1' ? 'quarantesimo' : 'quarantesimi'); break;
            case '41': d = (num === '1' ? 'quarantunesimo' : 'quarantunesimi'); break;
            case '42': d = (num === '1' ? 'quarantaduesimo' : 'quarantaduesimi'); break;
            case '43': d = (num === '1' ? 'quarantatreesimo' : 'quarantatreesimi'); break;
            case '44': d = (num === '1' ? 'quarantaquattresimo' : 'quarantaquattresimi'); break;
            case '45': d = (num === '1' ? 'quarantacinquesimo' : 'quarantacinquesimi'); break;
            case '46': d = (num === '1' ? 'quarantaseiesimo' : 'quarantaseiesimi'); break;
            case '47': d = (num === '1' ? 'quarantasettesimo' : 'quarantasettesimi'); break;
            case '48': d = (num === '1' ? 'quarantottesimo' : 'quarantottesimi'); break;
            case '49': d = (num === '1' ? 'quarantanovesimo' : 'quarantanovesimi'); break;
            case '50': d = (num === '1' ? 'cinquantesimo' : 'cinquantesimi'); break;
            case '51': d = (num === '1' ? 'cinquantunesimo' : 'cinquantunesimi'); break;
            case '52': d = (num === '1' ? 'cinquantaduesimo' : 'cinquantaduesimi'); break;
            case '53': d = (num === '1' ? 'cinquantatreesimo' : 'cinquantatreesimi'); break;
            case '54': d = (num === '1' ? 'cinquantaquattesimo' : 'cinquantaquattesimi'); break;
            case '55': d = (num === '1' ? 'cinquantacinquesimo' : 'cinquantacinquesimi'); break;
            case '56': d = (num === '1' ? 'cinquantaseiesimo' : 'cinquantaseiesimi'); break;
            case '57': d = (num === '1' ? 'cinquantasettesimo' : 'cinquantasettesimi'); break;
            case '58': d = (num === '1' ? 'cinquantottesimo' : 'cinquantottesimi'); break;
            case '59': d = (num === '1' ? 'cinquantanovesimo' : 'cinquantanovesimi'); break;
            case '60': d = (num === '1' ? 'sessantesimo' : 'sessantesimi'); break;
            case '61': d = (num === '1' ? 'sessantunesimo' : 'sessantunesimi'); break;
            case '62': d = (num === '1' ? 'sessantaduesimo' : 'sessantaduesimi'); break;
            case '63': d = (num === '1' ? 'sessantatreesimo' : 'sessantatreesimi'); break;
            case '64': d = (num === '1' ? 'sessantaquattresimo' : 'sessantaquattresimi'); break;
            case '65': d = (num === '1' ? 'sessantacinquesimo' : 'sessantacinquesimi'); break;
            case '66': d = (num === '1' ? 'sessantaseiesimo' : 'sessantaseiesimi'); break;
            case '67': d = (num === '1' ? 'sessantasettesimo' : 'sessantasettesimi'); break;
            case '68': d = (num === '1' ? 'sessantottesimo' : 'sessantottesimi'); break;
            case '69': d = (num === '1' ? 'sessantanovesimo' : 'sessantanovesimi'); break;
            case '70': d = (num === '1' ? 'settantesimo' : 'settantesimi'); break;
            case '71': d = (num === '1' ? 'settantunesimo' : 'settantunesimi'); break;
            case '72': d = (num === '1' ? 'settantaduesimo' : 'settantaduesimi'); break;
            case '73': d = (num === '1' ? 'settantatreesimo' : 'settantatreesimi'); break;
            case '74': d = (num === '1' ? 'settantaquattresimo' : 'settantaquattresimi'); break;
            case '75': d = (num === '1' ? 'settantacinquesimo' : 'settantacinquesimi'); break;
            case '76': d = (num === '1' ? 'settantaseiesimo' : 'settantaseiesimi'); break;
            case '77': d = (num === '1' ? 'settantasettesimo' : 'settantasettesimi'); break;
            case '78': d = (num === '1' ? 'settantottesimo' : 'settantottesimi'); break;
            case '79': d = (num === '1' ? 'settantanovesimo' : 'settantanovesimi'); break;
            case '80': d = (num === '1' ? 'ottantesimo' : 'ottantesimi'); break;
            case '81': d = (num === '1' ? 'ottantunesimo' : 'ottantunesimi'); break;
            case '82': d = (num === '1' ? 'ottantaduesimo' : 'ottantaduesimi'); break;
            case '83': d = (num === '1' ? 'ottantatreesimo' : 'ottantatreesimi'); break;
            case '84': d = (num === '1' ? 'ottantaquattresimo' : 'ottantaquattresimi'); break;
            case '85': d = (num === '1' ? 'ottantacinquesimo' : 'ottantacinquesimi'); break;
            case '86': d = (num === '1' ? 'ottantaseiesimo' : 'ottantaseiesimi'); break;
            case '87': d = (num === '1' ? 'ottantasettesimo' : 'ottantasettesimi'); break;
            case '88': d = (num === '1' ? 'ottantottesimo' : 'ottantottesimi'); break;
            case '89': d = (num === '1' ? 'ottantanovesimo' : 'ottantanovesimi'); break;
            case '90': d = (num === '1' ? 'novantesimo' : 'novantesimi'); break;
            case '91': d = (num === '1' ? 'novantunesimo' : 'novantunesimi'); break;
            case '92': d = (num === '1' ? 'novantaduesimo' : 'novantaduesimi'); break;
            case '93': d = (num === '1' ? 'novantatreesimo' : 'novantatreesimi'); break;
            case '94': d = (num === '1' ? 'novantaquattresimo' : 'novantaquattresimi'); break;
            case '95': d = (num === '1' ? 'novantacinquesimo' : 'novantacinquesimi'); break;
            case '96': d = (num === '1' ? 'novantaseiesimo' : 'novantaseiesimi'); break;
            case '97': d = (num === '1' ? 'novantasettesimo' : 'novantasettesimi'); break;
            case '98': d = (num === '1' ? 'novantottesimo' : 'novantottesimi'); break;
            case '99': d = (num === '1' ? 'novantanovesimo' : 'novantanovesimi'); break;
            case '100': d = (num === '1' ? 'centesimo' : 'centesimi'); break;
        }
        if (d) {
            text = n1 + ' ' + d;
        } else {
            text = n2 + ' fratto ' + denom;
        }
        return text;
    }

    override getSimpleElevation(elev: string): string {
        let text = '';
        let s = '';
        switch (elev) {
            case '2': s = 'seconda'; break;
            case '3': s = 'terza'; break;
            case '4': s = 'quarta'; break;
            case '5': s = 'quinta'; break;
            case '6': s = 'sesta'; break;
            case '7': s = 'settima'; break;
            case '8': s = 'ottava'; break;
            case '9': s = 'nona'; break;
            case '10': s = 'decima'; break;
        }
        if (s) {
            text = 'alla ' + s;
        } else {
            text = 'alla ' + elev;
        }
        return text;
    }

    override getSimpleZeroFrom(symbol: string): string {
        return symbol;
    }

    override getddx(): string {
        return "dé-su deìcs";
    }

}
