import { Component, OnInit, OnDestroy, HostListener, EventEmitter } from '@angular/core';
import { Logger } from '../core/logger.service';
import { Router } from '@angular/router';
import { ResourceUpload } from '../core/firebase/resource-upload';
import { SmeService } from '../core/sme/sme.service';
import { TranslateService } from '@ngx-translate/core';
import { GoogleService } from '../core/google/google.service';
import { MapStateService } from '../shared/map-state.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { UserPreferenceService } from '../shared/user-preference.service';
import { RegisteredUserService } from '../core/authentication/registered-user.service';
import { Compiler } from '@angular/core';
import { AccessDto } from '../access-config/common/access-dto';
import { ImportMapService } from '../shared/commands/import-map.service';
import { MapOperationType } from '../shared/map-operations';
import { MessageBoxService } from '../shared/dialog/messagebox.service';
import { MatomoTracker } from 'ngx-matomo-client';
import { FirebaseAuthService } from '../core/firebase/firebase-auth.service';
import { MapImportComponent } from '../map-import/map-import.component';
import { MatDialog } from '@angular/material/dialog';

const logger: Logger = new Logger('HomeComponent');

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    public backColor: string;
    public loadingMap: boolean;
    public isLoading = false;
    public isUserAboutToExpire: boolean;
    public isUserExpired: boolean;
    public isGoogleUser: boolean;
    public expiryDate: string;
    public hasSubscriptions = false;
    public connected = false;
    public noMap = false;
    public textSearch = '';
    private isDragged = false;
    public isDemo = false;
    // error: string;
    loginError = false;
    currentMapUpload: ResourceUpload | undefined;
    uploadMapSubscription: Subscription | undefined;
    createWorkMapSubscription: Subscription | undefined;
    createNewMapSubscription: Subscription | undefined;
    statNewMapSubscription: Subscription | undefined;
    statOpenMapSubscription: Subscription | undefined;
    findRegisteredUserSubscription: Subscription | undefined;
    checkSessionIdSubscription: Subscription | undefined;
    hasUserAcceptedPrivacySubscription: Subscription | undefined;
    online: any;
    popupBlockedSubscription: Subscription | undefined;
    onMapCreatedSubscription: Subscription | undefined;
    currentUser = '';

    @HostListener('window:storage')
    onStorageChange() {
        console.log('change...');
        if (this.firebaseAuthService.auth.currentUser !== null && this.currentUser !== this.firebaseAuthService.auth.currentUser?.email) {
            {

                this.initialize();
            }
        }

    }

    @HostListener('document:contextmenu', ['$event'])
    hideDefaultCtxMenu() {
        return false;
    }

    constructor(private router: Router,
        private compiler: Compiler,
        private title: Title,
        private firebaseAuthService: FirebaseAuthService,
        private translateService: TranslateService,
        private googleService: GoogleService,
        private smeService: SmeService,
        private authenticationService: AuthenticationService,
        private registeredUserService: RegisteredUserService,
        private mapStateService: MapStateService,
        private importMapService: ImportMapService,
        private userPreferenceService: UserPreferenceService,
        private tracker: MatomoTracker,
        private dialog: MatDialog,
        private messageBoxService: MessageBoxService) {
        this.loadingMap = false;
        this.isUserAboutToExpire = false;
        this.isGoogleUser = false;
        this.isUserExpired = false;
        this.expiryDate = '';
        mapStateService.isSomeOperationStarted = false;
        this.backColor = (this.authenticationService.isLab() ? '#F9C091' : '#96C9CE');
        this.firebaseAuthService.auth.onAuthStateChanged((user) => {
            if (this.firebaseAuthService.reloadCredentials() === undefined && user === null) {
                this.router.navigate(['/login', '/home'], { replaceUrl: true });
            }

        });


    }

    initialize() {

        try {
            this.tracker.trackPageView('Home');
        } catch (e: any) {
            logger.error('Matomo error ' + e.message);
        }
        this.authenticationService.reloadCredentials();
        this.currentUser = this.authenticationService.getUserEmail();
        this.backColor = (this.authenticationService.isLab() ? '#F9C091' : '#96C9CE');


        if (this.authenticationService.credentials && this.authenticationService.credentials.valid) {
            // Credenziali valide
            this.compiler.clearCache();
            this.isLoading = true;
            this.loadingMap = false;
            this.online = navigator.onLine;
            this.isUserAboutToExpire = this.authenticationService.isUserAboutToExpire();
            this.isUserExpired = this.authenticationService.isUserExpired();
            this.isGoogleUser = this.authenticationService.isGoogleUser();
            this.expiryDate = this.toLocalDate(this.authenticationService.getExpiryDate());
            this.hasSubscriptions = this.authenticationService.hasSubscriptions();
            this.title.setTitle(this.translateService.instant('APP_NAME'));
            // setTimeout(() => {
            //     logger.info('START getAccessToken');

            //     this.smeService.mustRelogged().subscribe({
            //         next: (redoLogin: boolean) => {
            //             if (redoLogin) {
            //                 const creds = this.authenticationService.credentials;
            //                 if (creds) {
            //                     this.smeService.revokeToken(creds.accessToken).subscribe(() => {
            //                         this.authenticationService.logout().subscribe(() => {
            //                             this.router.navigate(['login', '/home']);
            //                         });
            //                     });
            //                 } else {
            //                     this.router.navigate(['login', '/home']);
            //                 }
            //             } else {
            logger.info('Check double access SUCCESS');
            // Check whitelist
            if (this.authenticationService.credentials?.username) {
                const safeEmail = AccessDto.removePointFromEmail(this.authenticationService.credentials.username);
                this.findRegisteredUserSubscription = this.registeredUserService.findRegisteredUser(safeEmail).subscribe((registeredUser: any) => {
                    if (registeredUser.valid) {
                        const reload = (registeredUser.config !== this.authenticationService.credentials?.config);
                        this.authenticationService.updateRegisteredUser(registeredUser.startDate, registeredUser.expireDate, registeredUser.admin, registeredUser.config);
                        if (reload) {
                            (window as any).location.reload();
                        }
                        // const cfg = this.authenticationService.getModuleConfig();
                        // console.log('CFG: ' + JSON.stringify(cfg));
                        const demoStatus = (registeredUser.admin && registeredUser.admin.length === 1 && registeredUser.admin[0].origin === 'demo');
                        this.isDemo = demoStatus;
                        this.mapStateService.onUserIsDemo.emit(demoStatus);
                        this.isUserExpired = this.authenticationService.isUserExpired();
                        // this.expiryDate = this.toLocalDate(this.authenticationService.getExpiryDate());
                        this.expiryDate = this.toLocalDate(registeredUser.expireDate);
                        this.hasSubscriptions = this.authenticationService.hasSubscriptions();
                        const credentials = this.authenticationService.credentials;
                        if (credentials?.firebaseUserId) {
                            this.hasUserAcceptedPrivacySubscription = this.smeService.hasUserAcceptedPrivacy(credentials.firebaseUserId).subscribe((resp: any) => {
                                if (resp.ok) {
                                    if (resp.data === '') {
                                        // Privacy non accettata: logout e login
                                        this.messageBoxService.showTextMessage(
                                            this.messageBoxService.MODE_TYPE.OK,
                                            this.translateService.instant('MSG_MISSING_PRIVACY_TITLE'),
                                            this.translateService.instant('MSG_MISSING_PRIVACY_TEXT'),
                                        ).subscribe(() => {
                                            this.authenticationService.logout().subscribe(() => {
                                                this.router.navigate(['login', '/home']);
                                            });
                                        });
                                    }
                                }
                            });
                        } else {
                            this.authenticationService.logout();
                            this.isLoading = false;
                            this.loginError = true;
                            this.showError('ERR_LOGIN_INVALID_CREDENTIALS');
                        }
                    } else {
                        this.authenticationService.logout();
                        this.isLoading = false;
                        this.loginError = true;
                        this.showError('ERR_LOGIN_INVALID_CREDENTIALS');
                    }
                });
            }
            logger.info('END getAccessToken');
            // setTimeout(() => {
            this.checkSessionIdSubscription = this.smeService.checkSessionId().subscribe((accessOK: any) => {
                let resp: any;
                if (accessOK.ok) {
                    resp = accessOK.result;
                }
                if (!accessOK.ok || !resp) {
                    // Doppio accesso logout
                    logger.info('Check double access FAILED');
                    this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'double_access=1' }));
                }
            });

            // }, 0); //4000
            //         }
            //     },
            //     error: (error: any) => {
            //         if (navigator.onLine) {
            //             this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'expired=1' }));
            //             logger.error('Home  getAccessToken error: ' + error);
            //         } else {
            //             const errorCode = (error.code) ? error.code : 'OFFLINE_GENERIC';
            //             this.showError(errorCode);
            //         }
            //     }
            //  });
            //     }, 10);
            // } else {
            //     // Credenziali non valide
            //     this.isLoading = false;
            //     this.loginError = true;
            //     // this.error = ' '; // this.translateService.instant('ERR_LOGIN_INVALID_CREDENTIALS');
            //     this.authenticationService.logout().subscribe(() => {
            //         this.router.navigate(['/login', '/home'], { replaceUrl: true });
            //     });
            // }
        } else {
            this.authenticationService.logout().subscribe(() => {
                this.router.navigate(['/login', '/home'], { replaceUrl: true });
            });

        }
        // (window as any).authenticationService = this.authenticationService;
        // });
    }



    ngOnInit() {
        this.initialize();

    }

    ngOnDestroy() {
        if (this.uploadMapSubscription) { this.uploadMapSubscription.unsubscribe(); }
        if (this.createWorkMapSubscription) { this.createWorkMapSubscription.unsubscribe(); }
        if (this.createNewMapSubscription) { this.createNewMapSubscription.unsubscribe(); }
        if (this.statNewMapSubscription) { this.statNewMapSubscription.unsubscribe(); }
        if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
        if (this.findRegisteredUserSubscription) { this.findRegisteredUserSubscription.unsubscribe(); }
        if (this.checkSessionIdSubscription) { this.checkSessionIdSubscription.unsubscribe(); }
        if (this.hasUserAcceptedPrivacySubscription) { this.hasUserAcceptedPrivacySubscription.unsubscribe(); }
        if (this.popupBlockedSubscription) this.popupBlockedSubscription.unsubscribe();
        if (this.onMapCreatedSubscription) this.onMapCreatedSubscription.unsubscribe();
    }

    openMapFromGdrive() {
        this.mapStateService.isSomeOperationStarted = true;
        // this.error = '';
        if (this.authenticationService.isAuthenticated()) {
            this.router.navigate(['gdrive-picker']);

        } else {
            this.router.navigate(['login', '/home']);
        }

    }

    searchClick(event: any) {
        const search = (event && event.target && event.target.value ? event.target.value : '');
        this.textSearch = search;
        this.userPreferenceService.onRecentSearch.emit(this.textSearch);
    }

    clearSearch() {
        this.textSearch = '';
        this.userPreferenceService.onRecentSearch.emit(this.textSearch);
    }

    openMap(file: any) {
        ////////////////////////////////////////////////
        // Uncomment to try error on local open
        // this.showError('HOME_ERROR_GENERIC_MAP_LOAD');const storageRef = firebase.storage().ref();
        // return;
        ////////////////////////////////////////////////
        if (!this.loadingMap) {
            let url = '';
            if (file.name.toLowerCase().endsWith('.sme')) {
                // SME: Load
                this.mapStateService.isSomeOperationStarted = true;
                this.loadingMap = true;
                this.isLoading = true;
                // this.error = '';
                if (this.authenticationService.isAuthenticated()) {
                    if (this.popupBlockedSubscription) this.popupBlockedSubscription.unsubscribe();

                    if (this.popupBlockedSubscription) this.popupBlockedSubscription.unsubscribe();
                    if (this.onMapCreatedSubscription) this.onMapCreatedSubscription.unsubscribe();
                    this.onMapCreatedSubscription = this.importMapService.onMapCreated.subscribe((_url) => {
                        url = _url;
                    })
                    this.popupBlockedSubscription = this.importMapService.onPopupBlocked.subscribe((blocked: boolean) => {

                        this.isLoading = false;
                        const title = this.translateService.instant('WARNING');
                        const message = this.translateService.instant('MSG_BLOCKED_POPUP');

                        this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK, title, message).subscribe(() => {



                            const redirectUri = url;//window.location.origin + '/map-open/' + url;
                            window.open(redirectUri, '_blank');

                            // const dialogRef = this.dialog.open(MapImportComponent, {

                            // });
                        });
                    });
                    this.importMapService.openMap(file, true);
                } else {
                    this.router.navigate(['login']);
                }
            } else {
                // SMA/SMAM: Error
                // this.error = this.translateService.instant('ERROR_MAP_FORMAT_NOT_SUPPORTED');
                this.showError('ERROR_MAP_FORMAT_NOT_SUPPORTED');
            }
            // se apre da draganddrop
            // let currentMapFile = file;
            // if (file.target !== undefined) {
            //   apre da comando openfile

            //   currentMapFile = file.target.files[0];
            // }

            // logger.info(`Opening ${currentMapFile.name}...`);
            // const extension: string = currentMapFile.name.split('.').pop();
            // if (extension === 'sme') {
            //   this.currentMapUpload = new ResourceUpload(currentMapFile);
            //   this.googleService.getAccessToken().then((accessToken: string) => {
            //     if (this.uploadMapSubscription) { this.uploadMapSubscription.unsubscribe(); }
            //     this.uploadMapSubscription = this.smeService.uploadMap(accessToken, this.currentMapUpload)
            //       .subscribe((fileRef: any) => {
            //         logger.info('Map uploaded');
            //         if (this.createWorkMapSubscription) { this.createWorkMapSubscription.unsubscribe(); }
            //         this.createWorkMapSubscription = this.smeService.createWorkMapFromStorage(fileRef, accessToken)
            //           .subscribe((mapId: string) => {
            //             this.isLoading = false;
            //             logger.info(`Map ${mapId} created`);
            //             window.location.href = 'map-edit/' + mapId;
            //             this.router.navigate(['map-edit/', mapId]);
            //           }, (error: any) => {
            //             logger.error('createWorkMapFromStorage().subscribe: ' + error);
            //             this.isLoading = false;
            //             let errorCode = '';
            //             if (error.status === 504) {
            //               errorCode = 'GATEWAY_TIMEOUT';
            //             } else {
            //               errorCode = (error.code) ? error.code : error.statusText;
            //             }
            //             this.showError(errorCode);
            //           });
            //       }, (error: any) => {
            //         logger.error('uploadMap().subscribe: ' + error);
            //         this.isLoading = false;
            //         let errorCode = '';
            //         if (error.status === 504) {
            //           errorCode = 'GATEWAY_TIMEOUT';
            //         } else {
            //           errorCode = (error.code) ? error.code : 'HOME_ERROR_GENERIC_MAP_LOAD';
            //         }
            //         this.showError(errorCode);
            //       });
            //   }).catch((error) => {
            //     if (navigator.onLine) {
            //       this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'expired=1' }));
            //       logger.error('Home  getAccessToken error: ' + error);
            //     } else {
            //       const errorCode = (error.code) ? error.code : 'OFFLINE_GENERIC';
            //       this.showError(errorCode);
            //     }
            //   });
            // } else {
            //   this.showError('ERROR_WRONG_FILETYPE');
            // }
        }

    }

    public newMap() {
        if (!this.loadingMap) {
            logger.debug('new map');
            this.mapStateService.isSomeOperationStarted = true;
            this.loadingMap = true;
            this.isLoading = true;
            // let accessToken = '';
            //let smxFolderId = '';
            // smxFolderId = '';

            //TEST ENOUGH DRIVE SPACE 1MB
            /*   this.smeService.hasUserEnoughDriveQuota(1000000)
                   .then((ok: boolean) => {
                       if (ok) {
                           console.log("OK!!!!!!!!!!!!!!!!!!!!!!!!!");
                       } else {
                           console.log("NOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO");
                       }
                   })
                   .then((err: any) => {
   
                   });
               return;*/

            // JHUBA: FOLDER SUPERMAPPEX - uncomment
            // this.googleService.getSMXFolderIds()
            //     .then((_smxFolderIds: any) => {
            //         smxFolderId = (_smxFolderIds && _smxFolderIds.smx ? _smxFolderIds.smx : '');
            this.mapStateService.isNew = true;
            if (this.authenticationService.isAuthenticated()) {


                if (this.statNewMapSubscription) { this.statNewMapSubscription.unsubscribe(); }
                this.statNewMapSubscription = this.smeService.addCustomMapStat('newmap', MapOperationType.STAT_NEWMAP, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
                if (this.createNewMapSubscription) { this.createNewMapSubscription.unsubscribe(); }
                this.createNewMapSubscription = this.smeService.createNewMap('')
                    .subscribe({
                        next: (mapId: string) => {
                            if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
                            this.statOpenMapSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_OPENMAP, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
                            this.isLoading = false;
                            logger.info(`new Map ${mapId} created`);
                            this.router.navigate(['map-edit/', mapId]);
                            // window.location.href = 'map-edit/' + mapId;
                        },
                        error: (error: any) => {
                            logger.error(error);
                            this.isLoading = false;
                            let errorCode = '';
                            if (error.status === 504) {
                                errorCode = 'GATEWAY_TIMEOUT';
                            } else {
                                errorCode = (error.code) ? error.code : error.statusText;
                                errorCode = (error.error.reason) ? error.error.reason : error.statusText;
                                if (error.error.reason) {
                                    this.translateService.instant(errorCode);
                                }
                            }
                            this.showError(errorCode);
                        }
                    });
                // .catch((err: any) => {
                //     //ERROR DURING DRIVE QUOTA CHECK
                //     const errorCode = 'OFFLINE_GENERIC';
                //     this.showError(errorCode);
                //     this.mapStateService.isSomeOperationStarted = false;
                //     this.loadingMap = false;
                //     this.isLoading = false;
                // });
                // }).catch((error) => {
                //     if (navigator.onLine) {
                //         logger.error('Home getAccessToken error: ' + error);
                //         this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'expired=1' }));
                //     } else {
                //         const errorCode = (error.code) ? error.code : 'OFFLINE_GENERIC';
                //         this.showError(errorCode);
                //         this.isLoading = false;
                //     }
                // });
            } else {
                this.router.navigate(['login', '/home']);
            }
        }
    }

    private showError(errorCode: string) {
        try {
            // JHUBA ERROR DEBUG---------------------------------------------------
            // throw new Error('cippo');
            // JHUBA ERROR DEBUG---------------------------------------------------
            // this.error = this.translateService.instant(extract(errorCode));
            const title = this.translateService.instant('WARNING');
            const message = this.translateService.instant(errorCode);
            this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK, title, message);
        } catch (err) {
            // this.error = 'ERROR: ' + errorCode;
            this.messageBoxService.showGenericError(errorCode);
        }
    }

    public hideError() {
        if (navigator.onLine) {
            // this.error = '';
            this.online = true;
            this.isLoading = false;
            this.loadingMap = false;
            if (this.loginError) { window.location.href = 'login'; }
        }
    }

    public errorRecents(err: string) {
        this.isLoading = false;
        this.loadingMap = false;
        this.mapStateService.isSomeOperationStarted = false;

        this.showError(err);
    }

    public startLoadingRecents() {
        this.isLoading = true;
        this.loadingMap = true;
    }

    public endLoadingRecents(noMap: boolean) {
        this.isLoading = false;
        this.loadingMap = false;
        this.noMap = noMap;
    }

    public renewSubscriptions() {
        this.router.navigate(['/user-desktop']);
    }

    dragOver(event: any) {
        event.preventDefault();
        if (!this.isUserExpired) {
            this.isDragged = true;
        }
    }

    drop(event: any) {
        event.preventDefault();
        if (!this.isUserExpired) {
            this.isDragged = false;
            if (event.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                // Open first file only
                if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
                    if (event.dataTransfer.items[0].kind === 'file') {
                        const dataFile = event.dataTransfer.files[0];
                        const extension = dataFile.name.split('.').pop();
                        if (extension === 'sme') {
                            const file = event.dataTransfer.items[0].getAsFile();

                            this.openMap(file);
                        }
                    }
                }
            }
        }
    }

    dragEnd(event: any) {
        this.preventAndStop(event);
        if (!this.isUserExpired) {
            this.isDragged = false;
            // console.log('DRAG_END:' + event);
        }
    }

    preventAndStop(event: any) {
        event.stopPropagation();
        event.preventDefault();
    }

    // onKeyDown($event: any): void {
    //     // Detect platform
    //     if (navigator.platform.match('Mac')) {
    //         this.handleMacKeyEvents($event);
    //     } else {
    //         this.handleWindowsKeyEvents($event);
    //     }
    // }

    // handleMacKeyEvents($event: any) {
    //     // MetaKey documentation
    //     // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/metaKey
    //     const charCode = String.fromCharCode($event.which).toLowerCase();
    //     if ($event.metaKey && charCode === 's') {
    //         // Action on Cmd + S
    //         console.log('+++++STOP MAP UPDATE LOOP');
    //         this.mapStateService.isSomeOperationStarted = true;
    //         $event.preventDefault();
    //     }
    // }

    // handleWindowsKeyEvents($event: any) {
    //     const charCode = String.fromCharCode($event.which).toLowerCase();
    //     if ($event.ctrlKey && charCode === 's') {
    //         // Action on Ctrl + S
    //         console.log('+++++STOP MAP UPDATE LOOP');
    //         this.mapStateService.isSomeOperationStarted = true;
    //         $event.preventDefault();
    //     }
    // }

    private lead0(n: number, len: number) {
        let s1 = String(n);
        while (s1.length < len) {
            s1 = '0' + s1;
        }
        return s1;
    }

    private toLocalDate(date: string) {
        // In formato yyyy-MM-ddThh:mm.xxx:ssZ
        let s = '';
        if (date) {
            const d = new Date(date);
            s =
                this.lead0(d.getDate(), 2) + '/' +
                this.lead0(d.getMonth() + 1, 2) + '/' +
                this.lead0(d.getFullYear(), 4);
        }
        return s;
    }

}
